/** @jsxImportSource @emotion/react */
import * as UI from "@goodgym/components";
import * as Icons from "@goodgym/icons";
import React from "react";
import sauconyBlack from "./images/saucony-logo-black.png";
import saucony from "./images/saucony-logo.png";
import raleigh from "./images/raleigh-logo.png";

export type FooterProps = UI.BoxProps & { inverted?: boolean };

const pageLinks = [
  { label: "Reports", href: "/v3/reports" },
  { label: "Stories", href: "/v3/stories" },
  { label: "About", href: "/v3/about" },
  { label: "FAQs", href: "/faqs" },
  { label: "Diversity", href: "/diversity" },
  { label: "Jobs", href: "/jobs" },
  { label: "Contact us", href: "/v3/contact" },
  { label: "GoodGym heroes", href: "/heroes" },
  { label: "GoodGym race team", href: "/race_team" },
];

const referrerLinks = [
  { label: "Request a task for an older person", href: "/v3/request-a-mission" },
  { label: "Request a task for a community project", href: "/v3/request-a-task" },
];

const socialLinks = [
  {
    label: "Facebook",
    Icon: Icons.Facebook,
    href: "https://www.facebook.com/goodgym",
  },
  {
    label: "Twitter",
    Icon: Icons.Twitter,
    href: "https://www.twitter.com/goodgym",
  },
  {
    label: "Instagram",
    Icon: Icons.Instagram,
    href: "https://www.instagram.com/goodgym/",
  },
  {
    label: "YouTube",
    Icon: Icons.YouTube,
    href: "https://www.youtube.com/user/GoodGym1",
  },
];

const legalLinks = [
  {
    label: "33 Holborn, London, EC1N 2HT",
    href: "https://goo.gl/maps/ocoo3ftYXEeT6Q3R8",
  },
  { label: "Reg. Charity 1160988", href: "/charity" },
  { label: "Terms of Service", href: "/terms" },
  { label: "Privacy Policy", href: "/v3/privacy" },
  { label: "Code of Conduct", href: "/v3/conduct" },
];

const Footer: React.FC<FooterProps> = ({ inverted = false, ...props }) => {
  return (
    <UI.Box
      sx={{
        bgcolor: inverted ? "white.main" : "black.main",
        minHeight: "200px",
        textAlign: "center",
        pt: 3,
        pb: 9,
        px: 1,
        ...props.sx,
      }}
    >
      <UI.Logo color={inverted ? "black" : "white"} height={96} />
      <UI.Box sx={{ mt: 3, mb: 2 }}>
        <UI.Typography
          variant="body2"
          color={inverted ? "neutral.main" : "white.main"}
          sx={{ my: 1 }}
        >
          Supported by
        </UI.Typography>
        <UI.Box
          sx={{
            display: "flex",
            justifyContent: "center",
            gap: "30px",
            alignItems: "center",
          }}
        >
          <UI.Link href="https://www.saucony.com/" target="_blank">
            <img
              src={inverted ? sauconyBlack : saucony}
              alt="Saucony"
              style={{ height: "28px", width: "152px" }}
            />
          </UI.Link>
          <UI.Link href="https://www.raleigh.co.uk/gb/en/" target="_blank">
            <img
              src={raleigh}
              alt="Raleigh"
              style={{ height: "18px", width: "152px" }}
            />
          </UI.Link>
        </UI.Box>
        <UI.Grid
          container
          columnSpacing={2}
          rowSpacing={1}
          justifyContent="center"
          sx={{ my: 1 }}
        >
          {pageLinks.map(({ label, href }) => (
            <UI.Grid key={href} item>
              <UI.Link
                href={href}
                color={inverted ? "neutral.main" : "white.main"}
                variant="body2"
              >
                {label}
              </UI.Link>
            </UI.Grid>
          ))}
        </UI.Grid>

        <UI.Grid
          container
          columnSpacing={2}
          rowSpacing={1}
          justifyContent="center"
          sx={{ my: 1 }}
        >
          {referrerLinks.map(({ label, href }) => (
            <UI.Grid key={href} item>
              <UI.Link
                href={href}
                color={inverted ? "neutral.main" : "white.main"}
                variant="body2"
              >
                {label}
              </UI.Link>
            </UI.Grid>
          ))}
        </UI.Grid>

        <UI.Grid
          container
          columnSpacing={3}
          rowSpacing={1}
          justifyContent="center"
          sx={{ mt: 2, mb: 1 }}
        >
          {socialLinks.map(({ Icon, href, label }) => (
            <UI.Grid key={href} item>
              <UI.Link
                target="_blank"
                href={href}
                color={inverted ? "neutral.main" : "white.main"}
                variant="h3"
                aria-label={label}
              >
                <Icon />
              </UI.Link>
            </UI.Grid>
          ))}
        </UI.Grid>

        <UI.Grid
          container
          columnSpacing={2}
          rowSpacing={1}
          justifyContent="center"
          sx={{ my: 1 }}
        >
          {legalLinks.map(({ label, href }) => (
            <UI.Grid key={href} item sx={{ px: 1 }}>
              <UI.Link
                href={href}
                color={inverted ? "neutral.main" : "white.main"}
                variant="body2"
              >
                {label}
              </UI.Link>
            </UI.Grid>
          ))}
        </UI.Grid>
      </UI.Box>
    </UI.Box>
  );
};

export default Footer;
