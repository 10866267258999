/** @jsxImportSource @emotion/react */
import React from "react";
import * as Icons from "@goodgym/icons";
import * as UI from "@goodgym/components";
import * as T from "@goodgym/graphql/types";
import * as u from "@goodgym/util";
import _ from "lodash";
import { useBreakpoint } from "@goodgym/hooks";

export type BreakpointIndicatorProps = UI.ChipProps & {};

const BreakpointIndicator: React.FC<BreakpointIndicatorProps> = ({
  ...props
}) => {
  const [loaded, setLoaded] = React.useState(false);
  React.useEffect(() => {
    setLoaded(true);
  }, [setLoaded]);

  const breakpoint = useBreakpoint();
  if (!u.isDev || !loaded) return null;

  return (
    <UI.Chip
      {...props}
      sx={{
        position: "fixed",
        top: "2px",
        right: "2px",
        "& .MuiChip-label": { pl: 0, pr: 0 },
        color: "white.main",
        backgroundColor: "secondary.main",
        zIndex: 9999,
        fontSize: "10px",
        height: "18px",
        width: "18px",
      }}
      label={breakpoint}
    />
  );
};

export default BreakpointIndicator;
