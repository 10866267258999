/** @jsxImportSource @emotion/react */
import * as UI from "@goodgym/components";
import * as T from "@goodgym/graphql/types";
import NotesIcon from "@mui/icons-material/Notes";
import PeopleAltIcon from "@mui/icons-material/PeopleAlt";
import SpeakerNotesIcon from "@mui/icons-material/SpeakerNotes";
import React from "react";

export type TestPairingCompletedProps = UI.BoxProps & {
  testPairing: T.TestPairingFragment;
};

const TestPairingCompleted: React.FC<
  React.PropsWithChildren<TestPairingCompletedProps>
> = ({ ...props }) => {
  const { 
    runnerVisitFeedback, 
    runnerVisitNotes, 
    runnerWantsPairing,
    coach
  } = props.testPairing;

  return (
    <UI.Box
      {...props}
      sx={{
        borderStyle: "solid",
        borderWidth: "0.5px",
        p: 2,
        borderColor: "#E11018",
        borderRadius:"20px"
      }}
    >
      <UI.Typography variant="h4" sx={{ my: 1 }}>
        Trial visit is completed
      </UI.Typography>
      <UI.Typography sx={{ my: 1 }}>
        <SpeakerNotesIcon fontSize="small" sx={{ mr: 1 }} />
        How did your test visit go?: {runnerVisitFeedback}
      </UI.Typography>
      <UI.Typography sx={{ my: 1 }}>
        <NotesIcon fontSize="small" sx={{ mr: 1 }} />
        Is there anything you would like to let us know?: {runnerVisitNotes}
      </UI.Typography>
      <UI.Typography sx={{ my: 1 }}>
        <PeopleAltIcon fontSize="small" sx={{ mr: 1 }} />
        Visit {coach.name} on a weekly basis?: {runnerWantsPairing ? "Yes" : "No"}
      </UI.Typography>
    </UI.Box>
  );
};

export default TestPairingCompleted;
