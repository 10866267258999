/** @jsxImportSource @emotion/react */
import React from "react";
import * as Icons from "@goodgym/icons";
import * as UI from "@goodgym/components";
import * as T from "@goodgym/graphql/types";
import * as u from "@goodgym/util";
import _ from "lodash";

export type CollapsedWorkoutsProps = {
  items: T.NewsfeedQuery["newsfeed"]["items"];
} & UI.ButtonProps;

const CollapsedWorkouts: React.FC<CollapsedWorkoutsProps> = ({
  items,
  ...props
}) => {
  return (
    <UI.Button fullWidth variant="outlined" color="neutral" {...props}>
      <Icons.UnfoldMore />
      Show {items.length > 1 ? items.length : ""}{" "}
      {u.inflect("workout", items.length)}
    </UI.Button>
  );
};

export default CollapsedWorkouts;
