/** @jsxImportSource @emotion/react */
import React from "react";
import * as Icons from "@goodgym/icons";
import * as UI from "@goodgym/components";
import * as T from "@goodgym/graphql/types";
import * as u from "@goodgym/util";
import _ from "lodash";
import * as C from "./components";

export type NavbarMobileProps = {};

const NavbarMobile: React.FC<NavbarMobileProps> = () => {

  return (
    <>
      <UI.AppBar position="fixed" color="white">
        <UI.Container>
          <UI.Toolbar disableGutters>
            <C.Account />
            <UI.Box flexGrow={1} />
            <UI.Box>
              <UI.Logo />
            </UI.Box>
            <UI.Box flexGrow={1} />
            <C.Notifications />
          </UI.Toolbar>
        </UI.Container>
      </UI.AppBar>
      <UI.Toolbar />
    </>
  );
};

export default NavbarMobile;
