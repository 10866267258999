/** @jsxImportSource @emotion/react */
import React from "react";
import * as Icons from "@goodgym/icons";
import * as UI from "@goodgym/components";
import * as T from "@goodgym/graphql/types";
import * as u from "@goodgym/util";
import _ from "lodash";

export type EmojiProps = UI.BoxProps & {
  emoji: string;
};

const emojis: { [key: string]: number } = {
  calendar: 128467,
  mapPin: 128205,
};

const Emoji: React.FC<EmojiProps> = ({ ...props }) => {
  const { emoji, ...rest } = props;
  return (
    <UI.Box component="span" {...rest}>
      {emoji in emojis ? String.fromCodePoint(emojis[emoji]) : emoji}
    </UI.Box>
  );
};

export default Emoji;
