/** @jsxImportSource @emotion/react */
import { useCheerMutation } from "@goodgym/graphql/hooks";
import * as T from "@goodgym/graphql/types";
import * as UI from "@goodgym/components";
import * as u from "@goodgym/util";

export type CheerMutationHook = (params: {
  runner: Optional<T.CurrentRunnerFragment>;
  cheerable: T.CheerableItem;
  uncheered: T.RunnerFragment[];
}) => React.MouseEventHandler;

export const useCheer: CheerMutationHook = ({
  runner,
  cheerable,
  uncheered,
}) => {
  const [cheer] = useCheerMutation({
    onError: console.log,
    variables: {
      cheerableType: u.cheers.cheerableType(cheerable),
      cheerableActivityId: u.cheers.cheerableActivityId(cheerable),
      runnerIds: u.cheers.cheerableRunners(cheerable).map((r) => r.id),
    },
    optimisticResponse: {
      response: {
        __typename: "CheerMutationPayload",
        cheerable: {
          __typename: cheerable.__typename,
          id: cheerable.id,
          cheers: [
            ...cheerable.cheers.concat(
              uncheered.map((r) => ({
                __typename: "Cheer",
                id: `tmp-${r.id}`,
                runner: { ...runner!, __typename: "Runner" },
                recipient: r,
              }))
            ),
          ],
        },
      },
    },
  });
  return () => cheer();
};
