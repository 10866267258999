/** @jsxImportSource @emotion/react */
import React from "react";
import * as Icons from "@goodgym/icons";
import * as UI from "@goodgym/components";
import * as T from "@goodgym/graphql/types";
import * as u from "@goodgym/util";
import _ from "lodash";
import { useAuth } from "@goodgym/auth";

export type AddressProps = UI.BoxProps & {
  address?: Optional<T.AddressFragment>;
  noIcon?: boolean;
};

const Address: React.FC<React.PropsWithChildren<AddressProps>> = ({
  address,
  noIcon,
  ...props
}) => {
  const { runner } = useAuth();
  if (!address) return null;
  return (
    <UI.Box
      {...props}
      sx={{
        display: "flex",
        alignContent: "center",
        color: "neutral.dark",
        mb: 3,
        ...props.sx,
      }}
    >
      {!noIcon && (
        <UI.Box sx={{ width: "32px", flexShrink: 0 }}>
          <Icons.Room sx={{ verticalAlign: "middle", fontSize: "22px" }} />
        </UI.Box>
      )}
      <UI.Box>
        {[address.line1, address.line2, address.city, address.postcode].map(
          (line, index) =>
            line && (
              <UI.Typography key={index} variant="body1">
                {line}
              </UI.Typography>
            )
        )}

        {address.lat && address.lng && (
          <UI.Typography variant="subtitle1" sx={{ mt: "4px" }}>
            <UI.Link
              sx={{ mt: 1, color: "secondary.dark" }}
              href={u.geo.runnerDirectionsLink(runner, address)}
            >
              Get directions
            </UI.Link>
          </UI.Typography>
        )}
      </UI.Box>
    </UI.Box>
  );
};

export default Address;
