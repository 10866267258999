/** @jsxImportSource @emotion/react */
import { useAuth } from "@goodgym/auth";
import * as UI from "@goodgym/components";
import React from "react";
import { useReportsPage } from "../../hooks";

export type ReportsFilterProps = UI.BoxProps & {
  state: ReturnType<typeof useReportsPage>;
};
const ReportsFilter: React.FC<React.PropsWithChildren<ReportsFilterProps>> = ({
  state,
  ...props
}) => {
  const { runner: currentRunner } = useAuth();

  return (
    <UI.Box {...props}>
      <UI.Grid container spacing={1}>
        <UI.Grid item xs={12} md={4} sx={{ mt: 3, mx: 2 }}>
          <UI.Typography variant="subtitle1" sx={{ mb: 1 }}>
            Area
          </UI.Typography>
          <UI.AreaSearch
            fullWidth
            autoFocus
            placeholder={state.area?.name || "Enter a city or borough"}
            onAreaSelected={state.setArea}
          />
          <UI.Button
            variant="text"
            size="small"
            color="secondary"
            onClick={() => {
              localStorage.removeItem("area");
              state.setArea(null);
            }}
            sx={{ alignSelf: "center", ml: -1.5 }}
          >
            Clear
          </UI.Button>
        </UI.Grid>
        <UI.Grid item xs={12} md={4} sx={{ my: 3, mx: 2 }}>
          <UI.Typography variant="subtitle1" sx={{ mb: 1 }}>
            Session Type
          </UI.Typography>
          <UI.Select
            fullWidth
            labelId="session-label"
            value={state.sessionType || "All"}
            onChange={(e) => {
              state.setSessionType(e.target.value);
            }}
          >
            <UI.MenuItem value="All">All</UI.MenuItem>
            <UI.MenuItem value="Mission">Missions</UI.MenuItem>
            <UI.MenuItem value="CommunityMission">
              Community Missions
            </UI.MenuItem>
            <UI.MenuItem value="GroupRun">Group Runs</UI.MenuItem>
            <UI.MenuItem value="Party">Socials</UI.MenuItem>
            <UI.MenuItem value="TrainingSession">Training Sessions</UI.MenuItem>
            <UI.MenuItem value="Race">Races</UI.MenuItem>
          </UI.Select>
        </UI.Grid>
        {currentRunner && (
          <UI.Grid
            item
            xs={12}
            md={2.5}
            sx={{ display: "flex", alignItems: "center", mt: 3 }}
          >
            <UI.Box
              display="flex"
              sx={{
                alignItems: "center",
              }}
            >
              <UI.Checkbox
                onChange={() => state.setIncludeRunner(!state.includeRunner)}
              />
              <UI.Typography variant="subtitle1">
                Only sessions you attended
              </UI.Typography>
            </UI.Box>
          </UI.Grid>
        )}
      </UI.Grid>
    </UI.Box>
  );
};

export default ReportsFilter;
