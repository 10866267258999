/** @jsxImportSource @emotion/react */
import React from "react";
import * as Icons from "@goodgym/icons";
import * as UI from "@goodgym/components";
import * as T from "@goodgym/graphql/types";
import * as u from "@goodgym/util";
import _ from "lodash";

export type MenuOptionProps = UI.BoxProps & {
  option: T.MenuOptionFragment;
  onClose: () => any;
};

const MenuOption: React.FC<MenuOptionProps> = ({
  option,
  onClose,
  ...props
}) => {
  const Icon = (Icons as any)[option.icon] || Icons.Star;

  const listItemIcon = (
    <UI.ListItemIcon>
      <Icon />
    </UI.ListItemIcon>
  );
  return option.method === "POST" ? (
    <UI.Form action={option.url}>
      <UI.MenuItem
        component={UI.ButtonBase}
        type="submit"
        disabled={option.disabled}
        sx={{ width: "100%" }}
      >
        {listItemIcon} {option.label}{" "}
        {option.completed && (
          <Icons.CheckCircle
            sx={{
              ml: 1,
              mt: "2px",
              color: "success.light",
              verticalAlign: "bottom",
            }}
          />
        )}
      </UI.MenuItem>
    </UI.Form>
  ) : (
    <UI.MenuItem
      component={UI.Link}
      disabled={option.disabled}
      onClick={onClose}
      href={option.url}
    >
      {listItemIcon} {option.label}
      {option.completed && (
        <Icons.CheckCircle
          sx={{
            ml: 1,
            mt: "2px",
            color: "success.light",
            verticalAlign: "bottom",
          }}
        />
      )}
    </UI.MenuItem>
  );
};

export default MenuOption;
