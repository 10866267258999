/** @jsxImportSource @emotion/react */
import React from "react";
import * as Icons from "@goodgym/icons";
import * as UI from "@goodgym/components";
import * as T from "@goodgym/graphql/types";
import * as u from "@goodgym/util";
import _ from "lodash";

export type CloseButtonProps = {
  onClick: React.MouseEventHandler<HTMLButtonElement>;
};

const CloseButton: React.FC<CloseButtonProps> = ({ onClick }) => {
  return (
    <UI.IconButton
      aria-label="close"
      onClick={onClick}
      sx={{
        position: "absolute",
        right: 4,
        top: 4,
        color: "grey",
      }}
    >
      <Icons.Close />
    </UI.IconButton>
  );
};

export default CloseButton;
