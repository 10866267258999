/** @jsxImportSource @emotion/react */
import * as UI from "@goodgym/components";
import * as T from "@goodgym/graphql/types";
import React from "react";

export type HeaderProps = UI.BoxProps & {
  runner: Optional<T.CurrentRunnerFragment>;
  socialVisit: T.CoachReferralFragment;
};

const Header: React.FC<React.PropsWithChildren<HeaderProps>> = ({
  socialVisit,
  ...props
}) => {
  // const taskforceInCMArea =
  //   u.roles.hasAnyRole(runner, ["TASK_FORCE"]) &&
  //   runner?.homeArea?.id === session.area?.id &&
  //   session.type === "community mission";

  return (
    <UI.Box {...props}>
      <UI.Typography aria-label="title of the session" variant="h4">
        {socialVisit.name}{" "}
      </UI.Typography>
      <UI.Typography
        aria-label="strapline of socialVisit"
        variant="subtitle1"
        color="neutral"
        sx={{ color: "neutral.main", mt: 1 }}
      >
        {socialVisit.reasonForReferral}
      </UI.Typography>
    </UI.Box>
  );
};

export default Header;
