/** @jsxImportSource @emotion/react */
import React from "react";
import * as Icons from "@goodgym/icons";
import * as UI from "@goodgym/components";
import * as T from "@goodgym/graphql/types";
import * as u from "@goodgym/util";
import * as images from "./images";
import _ from "lodash";

export type TestimonialsProps = {};

const testimonials = [
  {
    name: "Judy",
    image: images.judy,
    area: "Lambeth",
    text: "GoodGym combines doing good with running, which helps me forget I'm even exercising. It's great!",
  },
  {
    name: "Mark",
    image: images.mark,
    area: "Islington",
    text: "Running with GoodGym is great because I get fit, do good and get to hang with a load of awesome people.",
  },
  {
    name: "Hazel",
    image: images.hazel,
    area: "Barnet",
    text: "Before GoodGym I always lost motivation to stick at running or the gym but now I run two or three times a week.",
  },
];

const Testimonials: React.FC<TestimonialsProps> = ({}) => {
  return (
    <>
      <UI.Typography variant="h1">What people say</UI.Typography>
      <UI.Grid container columnSpacing={3}>
        {testimonials.map(({ name, image, area, text }, index) => (
          <UI.Grid key={index} item xs={12} md={4}>
            <UI.Avatar
              src={image}
              sx={{
                height: "200px",
                width: "200px",
                margin: "auto",
                textAlign: "center",
                my: 3,
              }}
              alt={`Photo of ${name} from ${area}`}
              imgProps={{ loading: "lazy" }}
            />
            <UI.Typography
              variant="h5"
              component="p"
              sx={{ my: 2, fontWeight: "normal" }}
            >
              {text}
            </UI.Typography>
            <UI.Typography variant="body1" color="black.light">
              {name}, {area}
            </UI.Typography>
          </UI.Grid>
        ))}
      </UI.Grid>
    </>
  );
};

export default Testimonials;
