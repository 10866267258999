import React from "react";
import Banner from "@goodgym/components/Banner";
import IconCards from "../../components/IconCards";
import * as UI from "@goodgym/components";
import styled from "styled-components";
import AccessTimeFilledIcon from "@mui/icons-material/AccessTimeFilled";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CancelIcon from "@mui/icons-material/Cancel";
import ReportIcon from "@mui/icons-material/Report";
import NumbersIcon from "@mui/icons-material/Numbers";
import {
  workIconData,
  taskIconData,
  bannerData,
  listItemNotTask,
  listItemTask,
} from "./data";
import { ImQuotesLeft, ImQuotesRight } from "react-icons/im";
import Emoji from "a11y-react-emoji";
import * as I from "./data/images";

const BannerImage = styled.img`
  object-position: 0% 15%;
  width: 100%;
  max-height: 21rem;
  object-fit: cover;
`;
const RequestPage: React.FC = () => {
  return (
    <>
      <Banner
        title={bannerData.title}
        tagline={bannerData.tagline}
        buttonText={bannerData.buttonText}
        image={bannerData.image}
        linkForButton={bannerData.linkForButton}
      />
      <UI.Container>
        <UI.Typography
          variant="h5"
          sx={{
            lineHeight: 1.8,
            fontWeight: "normal",
            marginTop: 1,
            marginBottom: 3,
          }}
        >
          GoodGym volunteers love to help out community organisations and
          charities in their local area. They run to provide free volunteering
          time for tasks which benefit where they live.
        </UI.Typography>
      </UI.Container>
      <UI.Container>
        <UI.Grid
          container
          spacing={3}
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          {taskIconData.map((element, index) => (
            <IconCards
              key={index}
              image={element.image}
              tagline={element.tagline}
            />
          ))}
        </UI.Grid>
      </UI.Container>
      <UI.Container
        sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}
      >
        <UI.Typography
          variant="h5"
          sx={{
            lineHeight: 1.8,
            fontWeight: "normal",
            backgroundColor: "#fff3cd",
            textAlign: "center",
            py: 2,
            my: 3,
            width: { lg: "55%", md: "65%", sm: "80%", xs: "100%" },
          }}
        >
          <Emoji symbol="⚠️" label="warning" /> Making a request for an isolated
          older person?{" "}
          <a
            href="/request-a-mission"
            style={{ textDecoration: "none", color: "red" }}
          >
            Find out more
          </a>
        </UI.Typography>
      </UI.Container>
      <UI.Box sx={{ position: "relative", marginBottom: "70px" }}>
        <BannerImage src={I.MidBannerImage} alt="midBannerImage" />
        <UI.Typography
          color="#FFF"
          variant="h1"
          sx={{
            position: "absolute",
            bottom: "20px",
            left: "20px",
            typography: { lg: "h1", sm: "h2", xs: "h2" },
            textTransform: "uppercase",
          }}
        >
          How it works
        </UI.Typography>
      </UI.Box>
      <UI.Container>
        <UI.Typography
          variant="h5"
          sx={{
            lineHeight: 1.8,
            fontWeight: "normal",
            marginTop: 1,
            marginBottom: 3,
          }}
        >
          GoodGym is a registered charity with lots of friendly runners who like
          to keep fit and help people. We stop off on our runs to help community
          projects with practical tasks.
        </UI.Typography>
      </UI.Container>
      <UI.Container>
        <UI.Grid container spacing={3}>
          {workIconData.map((element, index) => (
            <IconCards
              key={index}
              image={element.image}
              tagline={element.tagline}
              title={element.title}
            />
          ))}
        </UI.Grid>
      </UI.Container>
      <UI.Container
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          marginBottom: "100px",
          marginTop: 3,
        }}
      >
        <UI.Card
          sx={{
            width: { lg: "56%", md: "70%", sm: "75%", xs: "100%" },
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
            padding: 1,
            paddingBottom: 3,
            borderRadius: "5px",
          }}
        >
          <UI.Typography variant="h2" sx={{ padding: 2 }}>
            Request a task
          </UI.Typography>
          <UI.Box
            sx={{ backgroundColor: "red", height: "8px", width: "110%" }}
          ></UI.Box>
          <UI.Typography
            variant="h5"
            sx={{
              lineHeight: 1.8,
              fontWeight: "normal",
              marginTop: 1,
              marginBottom: 3,
              textAlign: "center",
            }}
          >
            If you are part of a organisation that supports older people you can
            request support for practical tasks. To find out more please email{" "}
            <a
              href="mailto:missioncontrol@goodgym.org"
              style={{ textDecoration: "none", color: "red" }}
            >
              {" "}
              missioncontrol@goodgym.org{" "}
            </a>{" "}
            and one of our team can set you up to make referrals and provide all
            the information you will need.
          </UI.Typography>
        </UI.Card>
      </UI.Container>
      <UI.Container>
        <UI.Grid
          container
          sx={{
            display: "flex",
            justifyContent: "center",
            gap: "20px",
            marginBottom: "50px",
          }}
        >
          <UI.Grid item xs={12} md={3}>
            <UI.Typography variant="h6">Tasks we can help with</UI.Typography>
            <UI.List>
              {listItemTask.map((element, index) => (
                <UI.ListItem key={index}>
                  <UI.ListItemIcon>
                    <CheckCircleIcon sx={{ color: "green" }} />
                  </UI.ListItemIcon>
                  <UI.Typography variant="h5" sx={{ fontWeight: "normal" }}>
                    {element}
                  </UI.Typography>
                </UI.ListItem>
              ))}
            </UI.List>
          </UI.Grid>
          <UI.Grid item xs={12} md={3}>
            <UI.Typography variant="h6">
              Tasks we cannot help with
            </UI.Typography>
            <UI.List>
              {listItemNotTask.map((element, index) => (
                <UI.ListItem key={index}>
                  <UI.ListItemIcon>
                    <CancelIcon sx={{ color: "red" }} />
                  </UI.ListItemIcon>
                  <UI.Typography variant="h5" sx={{ fontWeight: "normal" }}>
                    {element}
                  </UI.Typography>
                </UI.ListItem>
              ))}
            </UI.List>
          </UI.Grid>
          <UI.Grid item xs={12} md={3}>
            <UI.Typography variant="h6">
              When submitting a task we will ask for
            </UI.Typography>
            <UI.List>
              <UI.ListItem>
                <UI.ListItemIcon>
                  <ReportIcon sx={{ color: "#090a0a" }} />
                </UI.ListItemIcon>
                <UI.Typography variant="h5" sx={{ fontWeight: "normal" }}>
                  Details of any hazards at the task
                </UI.Typography>
              </UI.ListItem>
              <UI.ListItem>
                <UI.ListItemIcon>
                  <NumbersIcon sx={{ color: "#090a0a" }} />
                </UI.ListItemIcon>
                <UI.Typography variant="h5" sx={{ fontWeight: "normal" }}>
                  How many people you require
                </UI.Typography>
              </UI.ListItem>
              <UI.ListItem>
                <UI.ListItemIcon>
                  <AccessTimeFilledIcon sx={{ color: "	#090a0a" }} />
                </UI.ListItemIcon>
                <UI.Typography variant="h5" sx={{ fontWeight: "normal" }}>
                  How long you feel the task will take
                </UI.Typography>
              </UI.ListItem>
            </UI.List>
          </UI.Grid>
        </UI.Grid>
      </UI.Container>
      <UI.Container>
        <UI.Typography
          variant="h6"
          sx={{
            display: { md: "flex" },
            justifyContent: { md: "center" },
            alignItems: { md: "center" },
            gap: "8px",
            marginBottom: "50px",
          }}
        >
          To see reports from recent group runs please{" "}
          <a
            href="/v3/reports"
            style={{ textDecoration: "none", color: "red" }}
          >
            {" "}
            click here
          </a>
        </UI.Typography>
      </UI.Container>
      <UI.Container>
        <UI.Grid
          container
          spacing={1}
          sx={{
            gap: "30px",
            display: "flex",
            justifyContent: "center",
          }}
        >
          <UI.Grid item xs={12} md={3} sx={{ margin: 2 }}>
            <UI.Typography variant="h5" sx={{ fontWeight: "normal" }}>
              <p>
                <ImQuotesLeft style={{ fontSize: "15px" }} /> We enjoy working
                with GoodGym and would like to keep working with them for as
                long as possible! They have helped with cutting off the grass,
                planting flowers, ploughing the flower beds, litter picking and
                many other tasks. The runners are very passionate about their
                work which makes the results look effortless.{" "}
                <ImQuotesRight style={{ fontSize: "15px" }} />
              </p>
              -- Honor Oak Playground, Lewisham
            </UI.Typography>
          </UI.Grid>
          <UI.Grid item xs={12} md={3} sx={{ margin: 2 }}>
            <UI.Typography variant="h5" sx={{ fontWeight: "normal" }}>
              <p>
                {" "}
                <ImQuotesLeft style={{ fontSize: "15px" }} /> I just wanted to
                say a big thank you for clearing our pond area on Wednesday
                evening. It has helped us out a great deal and it will make a
                big difference to the children's learning as they can access
                their pond area once again.{" "}
                <ImQuotesRight style={{ fontSize: "15px" }} />
              </p>
              Lawford Mead Primary School, Chelmsford
            </UI.Typography>
          </UI.Grid>
          <UI.Grid item xs={12} md={3} sx={{ margin: 2 }}>
            <UI.Typography variant="h5" sx={{ fontWeight: "normal" }}>
              <p>
                <ImQuotesLeft style={{ fontSize: "15px" }} /> Huge thank you to
                you and the runners who came to help clean up our alleyway on
                Saturday. Valiant efforts on the blackberry bush meant that it
                is now clear and we can cycle up the lane without fear of
                brambles. Love to all at GoodGym, thanks again.{" "}
                <ImQuotesRight style={{ fontSize: "15px" }} />
              </p>
              Annie, Bristol resident
            </UI.Typography>
          </UI.Grid>
        </UI.Grid>
      </UI.Container>
      <UI.Container>
        <UI.Typography
          variant="h2"
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            textTransform: "uppercase",
            textAlign: "center",
            paddingTop: 3,
          }}
        >
          Frequently asked questions
        </UI.Typography>
        <UI.Typography
          variant="h5"
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            padding: 2,
            fontWeight: "normal",
          }}
        >
          If you have any questions please get in touch and we’ll do our best to
          help.
        </UI.Typography>
        <UI.Typography
          variant="h5"
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
            fontWeight: "normal",
          }}
        >
          <a
            href="mailto:tasks@goodgym.org"
            style={{ textDecoration: "none", color: "red" }}
          >
            tasks@goodgym.org&nbsp;
          </a>{" "}
          <a
            href="tel:0203 432 3920"
            style={{ textDecoration: "none", color: "red" }}
          >
            0203 432 3920
          </a>
        </UI.Typography>
      </UI.Container>
      <UI.Container>
        <UI.Grid
          container
          spacing={1}
          sx={{
            gap: "50px",
            padding: 2,
            display: "flex",
            justifyContent: "center",
            marginTop: "30px",
            marginBottom: "30px",
          }}
        >
          <UI.Grid item xs={12} md={3}>
            <UI.Typography variant="h4" sx={{ marginBottom: "15px" }}>
              How much does it cost?
            </UI.Typography>
            <UI.Typography variant="h5" sx={{ fontWeight: "normal" }}>
              It's free - GoodGym tasks are performed by our volunteers and do
              not expect payment for their time.
            </UI.Typography>
          </UI.Grid>
          <UI.Grid item xs={12} md={3}>
            <UI.Typography variant="h4" sx={{ marginBottom: "15px" }}>
              How do I get started?
            </UI.Typography>
            <UI.Typography variant="h5" sx={{ fontWeight: "normal" }}>
              All you need to do is sign up and provide the organisation's
              details and we'll be in touch to organise a task to help them.
            </UI.Typography>
          </UI.Grid>
          <UI.Grid item xs={12} md={3}>
            <UI.Typography variant="h4" sx={{ marginBottom: "15px" }}>
              What sort of tasks are possible?
            </UI.Typography>
            <UI.Typography variant="h5" sx={{ fontWeight: "normal" }}>
              Our volunteers are not professional tradesmen, so we're not able
              to do certain tasks such as: advanced painting, felling trees,
              putting up complex furniture.
            </UI.Typography>
          </UI.Grid>
        </UI.Grid>
      </UI.Container>
      <UI.Container
        sx={{
          display: "center",
          justifyContent: "center",
          alignItems: "center",
          marginTop: 3,
          marginBottom: "80px",
        }}
      >
        <UI.Button variant="contained" color="primary" href="/referrers">
          Make a request
        </UI.Button>
      </UI.Container>
    </>
  );
};

export default RequestPage;
