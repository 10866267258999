/** @jsxImportSource @emotion/react */
import React from "react";
import * as Icons from "@goodgym/icons";
import * as UI from "@goodgym/components";
import * as T from "@goodgym/graphql/types";
import * as u from "@goodgym/util";
import _ from "lodash";

export type HelpProps = UI.BoxProps & {
  session: { ledBy?: Optional<T.RunnerFragment> };
  askQuestion: () => any;
};

const Help: React.FC<HelpProps> = ({ session, askQuestion, ...props }) => {
  return (
    <UI.Box {...props}>
      <UI.Typography variant="h5" sx={{ mb: 1 }}>
        Need help?
      </UI.Typography>
      <UI.Button
        onClick={askQuestion}
        fullWidth
        variant="outlined"
        color="neutral"
        sx={{ my: 1 }}
      >
        Ask {session.ledBy?.name} a question
      </UI.Button>
      <UI.Button
        fullWidth
        variant="outlined"
        color="neutral"
        sx={{ my: 1 }}
        href="https://www.facebook.com/groups/406276916245285"
        target="_blank"
        rel="noopener noreferrer"
      >
        Visit the GoodGym Facebook group
      </UI.Button>
      <UI.Button
        fullWidth
        variant="outlined"
        color="neutral"
        sx={{ my: 1 }}
        href="https://www.goodgym.org/faqs"
        target="_blank"
        rel="noopener noreferrer"
      >
        Read frequently asked questions
      </UI.Button>
    </UI.Box>
  );
};

export default Help;
