/** @jsxImportSource @emotion/react */
import { useAuth } from "@goodgym/auth";
import * as UI from "@goodgym/components";
import { useFollow, useUnblock, useUnfollow } from "@goodgym/graphql/mutations";
import * as T from "@goodgym/graphql/types";
import { useHover } from "@goodgym/hooks";
import React from "react";
import * as C from "./components";

export type FollowButtonProps = UI.ButtonProps & {
  runner: T.RunnerFragment & T.FollowStatusFragment;
  myTeam?: boolean;
};

const FollowButton: React.FC<FollowButtonProps> = ({ runner, myTeam, ...props }) => {
  const { runner: you } = useAuth();
  const [hover, listeners] = useHover();
  const unblock = useUnblock({ runner });
  const follow = useFollow();
  const unfollow = useUnfollow({ runner });

  const [showFollowDialog, setShowFollowDialog] = React.useState(false);

  if (!you) {
    // no logged in runner
    return null;
  } else if (runner.blockedByYou) {
    return (
      <UI.Button
        {...props}
        color="primary"
        variant={hover ? "contained" : "outlined"}
        {...(listeners as any)}
        fullWidth
        onClick={unblock}
      >
        {hover ? "Unblock" : "Blocked"}
      </UI.Button>
    );
  } else if (runner.followedByYou) {
    return (
      <UI.Button
        {...props}
        color={myTeam ? "primary" : "secondary"}
        variant={hover ? "contained" : "outlined"}
        {...(listeners as any)}
        fullWidth
        sx={{ ...props.sx, color: hover ? "white.main" : myTeam ? "black.main": "secondary:dark" }}
        onClick={unfollow}
      >
        {hover ? "Unfollow" : "Following"}
      </UI.Button>
    );
  } else {
    return (
      <>
        <UI.Button
          sx={{ ...props.sx, color: hover ? "white.main" : undefined }}
          color={myTeam ? "primary" : "secondary"}
          fullWidth
          onClick={() => setShowFollowDialog(true)}
        >
          {runner.followsYou ? "Follow back" : "Follow"}
        </UI.Button>
        {showFollowDialog && (
          <C.FollowDialog
            runner={runner}
            open={showFollowDialog}
            onCancel={() => setShowFollowDialog(false)}
            onConfirm={(message) => {
              setShowFollowDialog(false);
              follow(runner)(message);
            }}
          />
        )}
      </>
    );
  }
};

export default FollowButton;
