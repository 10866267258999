/** @jsxImportSource @emotion/react */
import * as UI from "@goodgym/components";
import { useDrawers } from "@goodgym/drawers";
import { useReportsFeedQuery } from "@goodgym/graphql/hooks";
import * as T from "@goodgym/graphql/types";
import * as u from "@goodgym/util";
import _ from "lodash";
import React from "react";
import InfiniteScroll from "react-infinite-scroller";
import { useTheme } from "@goodgym/components";

export type ReportsFeedProps = UI.BoxProps & {
  area?: Optional<T.AreaFragment>;
  organisation?: Optional<T.OrganisationFragment>;
  runner?: Optional<T.RunnerFragment | T.CurrentRunnerFragment>;
  sessionType?: Optional<string>;
};

const ReportsFeed: React.FC<ReportsFeedProps> = ({
  area,
  organisation,
  runner,
  sessionType,
  ...props
}) => {
  const { error, data, fetchMore } = useReportsFeedQuery({
    variables: {
      areaId: area?.id,
      organisationId: organisation?.id,
      runnerId: runner?.id,
      sessionType: sessionType,
    },
    notifyOnNetworkStatusChange: true,
  });

  const loadMore = () => {
    fetchMore({
      variables: {
        before: _.last(data?.results.reports)?.publishedAt,
      },
    });
  };

  const [loadingMore, setLoadingMore] = React.useState(false);

  const theme = useTheme();
  const md = UI.useMediaQuery(theme.breakpoints.up("md"));

   const handleLoadMore = async () => {
     if (!loadingMore && more) {
       setLoadingMore(true);
       await loadMore();
       setLoadingMore(false);
     }
   };
  const { showReport } = useDrawers();

  if (error) return <UI.Error />;
  if (!data) return <UI.Loading />;

  const Feed = () => (
    <>
      {reports.map(
        (report) =>
          report.publishedAt && (

            <UI.Grid key={report.id} item xs={12} md={6} xl={4} sx={{my:2}}>
              <UI.Card key={report.id} sx={{ height: "100%" }}>
                <UI.Link
                  href={u.links.report(report)}
                  onClick={(e) => showReport(e, report)}
                  color="black.main"
                >
                  <UI.CardMedia image={report.imageUrl} title={report.title} />
                </UI.Link>
                <UI.CardContent>
                  <UI.AreaChip
                    area={report.session.area}
                    sx={{ mr: 1, mb: 1 }}
                  />
                  <UI.SessionTypeChip
                    session={report.session}
                    sx={{ margin: 1 }}
                  />
                  <UI.Box sx={{ mt: 2, mb: 1 }}>
                    <UI.AvatarGroup
                      max={7}
                      sx={{
                        justifyContent: "flex-end",
                        ml: "-2px",
                        mb: "-2px",
                      }}
                    >
                      {report.session.attendees.map((runner) => (
                        <UI.RunnerAvatar
                          key={runner.id}
                          runner={runner}
                          noStatus
                        />
                      ))}
                    </UI.AvatarGroup>
                  </UI.Box>
                  <UI.Typography gutterBottom variant="h5" component="h2">
                    <UI.Link
                      href={u.links.report(report)}
                      onClick={(e) => showReport(e, report)}
                      color="black.main"
                    >
                      {report.title}
                    </UI.Link>
                  </UI.Typography>

                  <UI.Typography color="textSecondary" variant="body2">
                    {u.time.formatDate(report.session.startedAt)}
                  </UI.Typography>
                  {report.writtenBy && (
                    <UI.Typography color="textSecondary" variant="body2">
                      Written by <UI.RunnerLink runner={report.writtenBy} />
                    </UI.Typography>
                  )}

                  <UI.Snippet
                    sx={{ mt: 2 }}
                    href={u.links.report(report)}
                    content={report.description}
                  />
                </UI.CardContent>
              </UI.Card>
              </UI.Grid>
            
          )
      )}
    </>
  );

  const { reports, more } = data.results;

  return (
    <UI.Box {...props}>
      {!data ? (
        <UI.Loading key="loading" />
      ) : (
        <>
          {reports.length === 0 && (
            <UI.Box key="empty" sx={{ mb: 2 }}>
              <UI.Typography variant="h5" color="neutral.main">
                Nothing to show here yet...
              </UI.Typography>
            </UI.Box>
          )}
         
            {!md && ( <UI.Grid sx={{ alignItems: "center", mb: 2 }}>
              <InfiniteScroll
                hasMore={more}
                loadMore={loadMore}
                initialLoad={false}
                loader={<UI.InfiniteScrollLoader key="loader" more={more} />}
              >
                <Feed />
              </InfiniteScroll>
            </UI.Grid>
            )}  

                   
            {md && (
              <UI.Grid container spacing={3} sx={{ alignItems: "center", mb: 2 }}>
              <>
                <Feed />
              </> </UI.Grid>
            )}
       
        </>
      )}
      {/* Load More Button */}
      {more && md && (
        <UI.Box sx={{ textAlign: "center", mt: 2 }}>
          <UI.Button
            variant="text"
            onClick={handleLoadMore}
            disabled={loadingMore}
            sx={{
              fontSize: "30px",
              textTransform: "uppercase",
              fontStyle: "italic",
            }}
          >
            {loadingMore ? "Loading..." : "Load More"}
          </UI.Button>
        </UI.Box>
      )}
    </UI.Box>
  );
};

export default ReportsFeed;
