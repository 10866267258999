/** @jsxImportSource @emotion/react */
import React from "react";
import * as Icons from "@goodgym/icons";
import * as UI from "@goodgym/components";
import * as T from "@goodgym/graphql/types";
import * as u from "@goodgym/util";
import _ from "lodash";
import { useSearchParams } from "react-router-dom";
import { useAuth } from "@goodgym/auth";
import { useDeleteGroupFitnessActivity } from "@goodgym/graphql/mutations";

export type GettingThereProps = UI.BoxProps & {
  session: T.SessionPageQuery["session"];
};

const GettingThere: React.FC<React.PropsWithChildren<GettingThereProps>> = ({
  session,
  ...props
}) => {
  const { runner } = useAuth();
  const [params, setParams] = useSearchParams();
  const deleteGroupFitnessActivity = useDeleteGroupFitnessActivity();
  const showSignupModal = (gfa?: T.GroupFitnessActivityFragment) =>
    gfa
      ? setParams({ ...params, "session-signup": "true", "gfa-id": gfa.id })
      : setParams({ ...params, "session-signup": "true", "gfa-id": "NONE" });

  if (!u.session.isCommunityMission(session)) return null;

  const signedUpToGfa = !!session.groupFitnessActivities.find((gfa) => {
    return !!gfa.signups.find((s) => s.id === runner?.id);
  });

  return (
    <UI.Box {...props}>
      <UI.Typography variant="h5" color="primary.main" sx={{ mt: 5, mb: 2 }}>
        Getting there
        <Icons.DirectionsRun sx={{ mx: 1 }} />
        
      </UI.Typography>
      {session.groupFitnessActivities.length === 0 && (
        <UI.Box>
          <UI.Typography sx={{ mb: 2 }}>
            For this session we're going to meet at the task, so you'll need to
            make your own way there. We encourage you to walk, run, or cycle to
            do so!
          </UI.Typography>
          {(!session.registerMax ||
            session.registerMax > session.signups.length) && (
            <UI.List>
              {u.roles.hasSessionAdmin(runner, session) && !signedUpToGfa && (
                <UI.ListItem disableGutters>
                  <UI.ListItemIcon sx={{ mr: 2 }}>
                    <UI.Avatar style={{ fontSize: "28px" }}>
                      <Icons.Person />
                    </UI.Avatar>
                  </UI.ListItemIcon>
                  <UI.ListItemText
                    primary={
                      <UI.Link
                        sx={{ color: "black.main" }}
                        href={u.links.newGroupFitnessActivity(session)}
                      >
                        Invite others to run, walk, or cycle with you
                      </UI.Link>
                    }
                    secondary="Let people know where and when to meet you"
                  />
                  <UI.ListItemIcon sx={{ ml: 2 }}>
                    <UI.IconButton
                      href={u.links.newGroupFitnessActivity(session)}
                    >
                      <Icons.ArrowForward />
                    </UI.IconButton>
                  </UI.ListItemIcon>
                </UI.ListItem>
              )}
            </UI.List>
          )}
        </UI.Box>
      )}
      {session.groupFitnessActivities.length > 0 && (
        <>
          <UI.Typography>
            There are a few options for getting to the task:
          </UI.Typography>
          <UI.List>
            {session.type !== "group run" && (
              <UI.ListItem disableGutters>
                <UI.ListItemIcon sx={{ mr: 2 }}>
                  <UI.Avatar style={{ fontSize: "28px" }}>
                    <Icons.Person />
                  </UI.Avatar>
                </UI.ListItemIcon>
                <UI.ListItemText
                  primary={
                    <UI.Link
                      sx={{ color: "black.main", cursor: "pointer" }}
                      onClick={() => showSignupModal()}
                    >
                      Make your own way there
                    </UI.Link>
                  }
                  secondary="We encourage you to walk, run, or cycle"
                />
                <UI.ListItemIcon sx={{ ml: 2 }}>
                  <UI.IconButton onClick={() => showSignupModal()}>
                    <Icons.ArrowForward />
                  </UI.IconButton>
                </UI.ListItemIcon>
              </UI.ListItem>
            )}

            {u.roles.hasSessionAdmin(runner, session) && (
              <UI.ListItem disableGutters>
                <UI.ListItemIcon sx={{ mr: 2 }}>
                  <UI.Avatar style={{ fontSize: "28px" }}>
                    <Icons.Person />
                  </UI.Avatar>
                </UI.ListItemIcon>
                <UI.ListItemText
                  primary={
                    <UI.Link
                      sx={{ color: "black.main" }}
                      href={u.links.newGroupFitnessActivity(session)}
                    >
                      Invite others to run, walk, or cycle with you
                    </UI.Link>
                  }
                  secondary="Let people know where and when to meet you"
                />
                <UI.ListItemIcon sx={{ ml: 2 }}>
                  <UI.IconButton onClick={() => showSignupModal()}>
                    <Icons.ArrowForward />
                  </UI.IconButton>
                </UI.ListItemIcon>
              </UI.ListItem>
            )}

            {session.groupFitnessActivities.map((gfa) => {
              const Icon = u.session.groupFitnessActivityIcon(gfa);
              return (
                <UI.ListItem key={gfa.id} disableGutters>
                  <UI.ListItemIcon sx={{ mr: 2 }}>
                    <UI.Avatar style={{ fontSize: "28px" }}>
                      <Icon />
                    </UI.Avatar>
                  </UI.ListItemIcon>
                  <UI.ListItemText
                    primary={
                      <>
                        <UI.Link
                          sx={{ color: "black.main", cursor: "pointer" }}
                          onClick={() => showSignupModal(gfa)}
                        >
                          {gfa.type} {gfa.distance}km from{" "}
                          {gfa.address.postcode}
                        </UI.Link>
                        {(gfa.ledBy.id === runner?.id ||
                          u.roles.hasRoleOrAdmin(runner, "TRAINER")) && (
                          <>
                            <UI.IconButton
                              size="small"
                              href={u.links.editGroupFitnessActivity(
                                session,
                                gfa
                              )}
                              sx={{ ml: 1 }}
                            >
                              <Icons.Edit />
                            </UI.IconButton>
                            <UI.IconButton
                              size="small"
                              onClick={deleteGroupFitnessActivity({
                                session,
                                gfa,
                              })}
                            >
                              <Icons.Delete />
                            </UI.IconButton>
                          </>
                        )}
                      </>
                    }
                    secondary={
                      <>
                        <UI.RunnerLinks runners={gfa.signups} />{" "}
                        {gfa.signups.length === 1 ? "is" : "are"} going to{" "}
                        {gfa.type.toLocaleLowerCase()} from{" "}
                        {session.address ? (
                          <UI.Link
                            href={u.geo.directionsLink(
                              gfa.address,
                              session.address
                            )}
                          >
                            {gfa.address.line1}
                          </UI.Link>
                        ) : (
                          gfa.address.line1
                        )}
                      </>
                    }
                  />

                  <UI.ListItemIcon sx={{ ml: 2 }}>
                    <UI.IconButton onClick={() => showSignupModal(gfa)}>
                      <Icons.ArrowForward />
                    </UI.IconButton>
                  </UI.ListItemIcon>
                </UI.ListItem>
              );
            })}
          </UI.List>
        </>
      )}
    </UI.Box>
  );
};

export default GettingThere;
