import React from "react";
import * as UI from "@goodgym/components";
import { daysOfWeek, timeIntervals, convertTo12HourFormat } from "./helper";

export type AvailabilityFormProps = {
  index: number;
  availability: { day: string; from: string; to: string }[];
  setAvailability: Function;
};

const AvailabilityForm: React.FC<AvailabilityFormProps> = ({
  index,
  availability,
  setAvailability,
}) => {
  const [fromTime, setFromTime] = React.useState(String);
  const findIndex = timeIntervals.findIndex((time) => time === fromTime);

  return (
    <>
      <UI.Grid columnSpacing={5} container sx={{ mt: 2 }}>
        <UI.Grid item xs={12}>
          <UI.Grid container spacing={3}>
            <UI.Grid item xs={12} md={4}>
              <UI.FormControl fullWidth>
                <UI.InputLabel id="day-select-label">Day</UI.InputLabel>

                <UI.Select
                  required
                  id="day-select"
                  label="day"
                  onChange={(event) => {
                    availability[index].day = event.target.value;
                    const available = [...availability];

                    setAvailability(available);
                  }}
                  value={availability[index].day}
                >
                  {daysOfWeek.slice(0, 7).map((day) => (
                    <UI.MenuItem key={day} value={day} aria-label={day}>
                      {day}
                    </UI.MenuItem>
                  ))}
                  <UI.Divider />
                  {daysOfWeek.slice(7, 9).map((day) => (
                    <UI.MenuItem key={day} value={day} aria-label={day}>
                      {day}
                    </UI.MenuItem>
                  ))}
                </UI.Select>
              </UI.FormControl>
            </UI.Grid>
            <UI.Grid item xs={12} md={4}>
              <UI.FormControl fullWidth>
                <UI.InputLabel id="time-select-label">From</UI.InputLabel>

                <UI.Select
                  id="time-select"
                  label="time"
                  onChange={(event) => {
                    availability[index].from = event.target.value;
                    const available = [...availability];
                    setAvailability(available);
                    setFromTime(event.target.value);
                  }}
                  value={availability[index].from}
                >
                  {timeIntervals.map((time) => (
                    <UI.MenuItem key={time} value={time} aria-label={time}>
                      {convertTo12HourFormat(time)}
                    </UI.MenuItem>
                  ))}
                </UI.Select>
              </UI.FormControl>
            </UI.Grid>
            <UI.Grid item xs={12} md={4}>
              <UI.FormControl fullWidth>
                <UI.InputLabel id="time-select-label">To</UI.InputLabel>

                <UI.Select
                  id="time-select"
                  label="Time"
                  onChange={(event) => {
                    availability[index].to = event.target.value;
                    const available = [...availability];
                    setAvailability(available);
                  }}
                  value={availability[index].to}
                >
                  {fromTime
                    ? timeIntervals.slice(findIndex + 1).map((time) => (
                        <UI.MenuItem key={time} value={time} aria-label={time}>
                          {convertTo12HourFormat(time)}
                        </UI.MenuItem>
                      ))
                    : timeIntervals.map((time) => (
                        <UI.MenuItem key={time} value={time} aria-label={time}>
                          {convertTo12HourFormat(time)}
                        </UI.MenuItem>
                      ))}
                </UI.Select>
              </UI.FormControl>
            </UI.Grid>
          </UI.Grid>
        </UI.Grid>
      </UI.Grid>
    </>
  );
};

export default AvailabilityForm;
