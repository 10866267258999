/** @jsxImportSource @emotion/react */
import React from "react";
import * as Icons from "@goodgym/icons";
import * as UI from "@goodgym/components";
import * as T from "@goodgym/graphql/types";
import * as u from "@goodgym/util";
import _ from "lodash";

export type BadgesProps = UI.GridProps & {
  runner: T.ProfilePageQuery["runner"];
};

const Badges: React.FC<React.PropsWithChildren<BadgesProps>> = ({
  runner,
  ...props
}) => {
  return (
    <UI.Grid
      container
      {...props}
      columnSpacing={1}
      rowSpacing={2}
      justifyContent="space-between"
    >
      {runner.awards.map((award) => (
        <UI.Grid key={award.id} item>
          <UI.Badge badge={award.badge} />
        </UI.Grid>
      ))}
    </UI.Grid>
  );
};

export default Badges;
