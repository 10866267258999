/** @jsxImportSource @emotion/react */
import React from "react";
import * as Icons from "@goodgym/icons";
import * as UI from "@goodgym/components";
import * as T from "@goodgym/graphql/types";
import * as u from "@goodgym/util";
import _ from "lodash";
import * as C from "./components";

export type BeneficiarySearchProps = UI.TextFieldProps & {
  onBeneficiarySelected: (beneficiary: T.RestrictedBeneficiaryFragment) => any;
};

const BeneficiarySearch: React.FC<
  React.PropsWithChildren<BeneficiarySearchProps>
> = ({ onBeneficiarySelected, ...props }) => {
  const [search, { loading, data }] = T.useBeneficiarySearchLazyQuery({});
  const throttledSearch = React.useMemo(
    () => _.debounce(search, 200),
    [search]
  );
  // for resetting the component on select
  const [key, setKey] = React.useState(_.uniqueId());
  return (
    <UI.Autocomplete<T.RestrictedBeneficiaryFragment>
      key={key}
      placeholder="Start typing a beneficiary's name or phone number"
      isOptionEqualToValue={(option, value) => option.id === value.id}
      getOptionLabel={(option) => option.name}
      options={data?.results || []}
      loading={loading}
      onChange={(_e, beneficiary) => {
        if (beneficiary) {
          onBeneficiarySelected(beneficiary);
          setKey(_.uniqueId());
        }
      }}
      clearOnBlur
      filterOptions={(x) => x}
      renderOption={(props, option) => (
        <li {...props}>
          <C.SearchResult beneficiary={option} />
        </li>
      )}
      renderInput={(params) => (
        <UI.TextField
          {...params}
          {...props}
          onChange={(e) => {
            throttledSearch({ variables: { query: e.target.value } });
          }}
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <React.Fragment>
                {loading ? <UI.CircularProgress size={20} /> : null}
                {params.InputProps.endAdornment}
              </React.Fragment>
            ),
          }}
        />
      )}
    />
  );
};

export default BeneficiarySearch;
