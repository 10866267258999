/** @jsxImportSource @emotion/react */
import React from "react";
import * as Icons from "@goodgym/icons";
import * as UI from "@goodgym/components";
import * as T from "@goodgym/graphql/types";
import * as u from "@goodgym/util";
import _ from "lodash";

export type ReloadProps = { href: string };

const Reload: React.FC<ReloadProps> = ({ href }) => {
  React.useEffect(() => {
    if (!u.isServer) window.location.href = href;
  });
  return <></>;
};

export default Reload;
