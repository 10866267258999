import * as Images from "../../images";
import * as Icons from "../images";
import Looks3RoundedIcon from "@mui/icons-material/Looks3Rounded";
import LooksOneRoundedIcon from "@mui/icons-material/LooksOneRounded";
import LooksTwoRoundedIcon from "@mui/icons-material/LooksTwoRounded";
import Looks4RoundedIcon from "@mui/icons-material/Looks4Rounded";

export const taskIconData = [
  {
    image: Images.PlantIcon,
    tagline: "Walk to plant trees for the local park",
  },
  {
    image: Images.DiggingIcon,
    tagline: "Run to shift earth for a community gardening project",
  },
  {
    image: Images.HoldingBannerIcon,
    tagline: "Walk to sort cans for the food bank",
  },
];

export const groupRunTasks = [
  {
    title: "Run or walk",
    image: Icons.MarathonIcon,
    tagline:
      "Meet the group at the area start point, drop your bag and run or walk to a local community org",
    icon: LooksOneRoundedIcon,
  },
  {
    title: "Work out",
    image: Icons.CuttingWeedIcon,
    tagline:
      "Work hard with the group digging, lifting, scraping and clearing for a good cause",
    icon: LooksTwoRoundedIcon,
  },
  {
    title: "Run or walk back",
    image: Icons.MarathonIcon,
    tagline:
      "Connect with great people and Push yourself on the way back with some intervals or a fitness session",
    icon: Looks3RoundedIcon,
  },
  {
    title: "Stretch",
    image: Icons.WorkoutIcon,
    tagline:
      "Connect with great people and Our qualified area activator will help you stretch and celebrate what we've achieved",
    icon: Looks4RoundedIcon,
  },
];
