/** @jsxImportSource @emotion/react */
import React from "react";
import * as Icons from "@goodgym/icons";
import * as UI from "@goodgym/components";
import * as T from "@goodgym/graphql/types";
import * as u from "@goodgym/util";
import _ from "lodash";
import * as C from "./components";

export type AddressSearchProps = UI.TextFieldProps & {
  onAddressSelected: (address: T.AddressSearchResultFragment) => any;
};

const AddressSearch: React.FC<React.PropsWithChildren<AddressSearchProps>> = ({
  onAddressSelected,
  ...props
}) => {
  const [search, { loading, data }] = T.useAddressSearchLazyQuery({});
  const throttledSearch = React.useMemo(
    () => _.debounce(search, 200),
    [search]
  );
  // for resetting the component on select
  const [key, setKey] = React.useState(_.uniqueId());
  return (
    <UI.Autocomplete<T.AddressSearchResultFragment>
      key={key}
      placeholder="Start typing an address"
      isOptionEqualToValue={(option, value) => option.id === value.id}
      getOptionLabel={(option) => u.text.compositeAddress(option) || ""}
      options={data?.results || []}
      loading={loading}
      onChange={(_e, address) => {
        if (address) {
          onAddressSelected(address);
          setKey(_.uniqueId());
        }
      }}
      clearOnBlur
      filterOptions={(x) => x}
      renderOption={(props, option) => (
        <li {...props}>
          <C.SearchResult address={option} />
        </li>
      )}
      renderInput={(params) => (
        <UI.TextField
          {...params}
          {...props}
          onChange={(e) => {
            throttledSearch({ variables: { query: e.target.value } });
          }}
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <React.Fragment>
                {loading ? <UI.CircularProgress size={20} /> : null}
                {params.InputProps.endAdornment}
              </React.Fragment>
            ),
          }}
        />
      )}
    />
  );
};

export default AddressSearch;
