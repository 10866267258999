/** @jsxImportSource @emotion/react */
import { useAuth } from "@goodgym/auth";
import * as UI from "@goodgym/components";
import {
  useAvailableCoachPageQuery,
  useTestPairingsQuery,
} from "@goodgym/graphql/hooks";
import * as u from "@goodgym/util";
import CancelIcon from "@mui/icons-material/Cancel";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import React from "react";
import { useParams } from "react-router-dom";
import AskHelp from "./components/AskHelp";
import Header from "./components/Header";
import RequestPairing from "./components/RequestPairing";

export type SocialVisitPageProps = UI.BoxProps & {
  panel?: boolean;
  slug?: string;
};

const SocialVisitPage: React.FC<SocialVisitPageProps> = (props) => {
  const { runner } = useAuth();
  const { id } = useParams();

  const { data, error } = useAvailableCoachPageQuery({
    variables: { id: props.id || id! },
  });

  const { data: pairing } = useTestPairingsQuery({});

  const testPairingEnabled = !pairing?.testPairings.results.find(
    (x) => x.coach.id === data?.availableCoach.id && !x.cancelledAt && !x.completedAt
  );

  const previousHadTestPairing = !!pairing?.testPairings.results.find(
    (x) =>
      x.coach.id === data?.availableCoach.id && x.scheduledAt && x.completedAt
  );

  const runnerVerified = runner?.needsToGetCoachVerified !== true;

  const requestPairingRef = React.useRef<HTMLSpanElement | null>(null);
 
  const scrollToRequest = () => {
    requestPairingRef.current?.scrollIntoView({
      behavior: "smooth",
      block: "start",
    });
  };

  if (error || !(props.id || id)) return <UI.Error error={error} />;

  if (!data) return <UI.Loading />;

  const coachReferral = data.availableCoach;

  return (
    <>
      <UI.Seo
        title={`${coachReferral.name} - GoodGym ${coachReferral.area?.name}`}
        url={u.links.socialVisit(coachReferral)}
      />

      <UI.Box
        sx={{
          pb: { xs: "120px", sm: 0 },
          minHeight: "100vh",
          overflowX: "hidden",
          ...props.sx,
        }}
      >
        <UI.Box sx={{ backgroundColor: "neutral.light" }}>
          <UI.Container>
            <UI.Box
              sx={{
                flexDirection: "column",
                pt: 2,
                mb: 2,
              }}
            >
              {!props.panel && (
                <UI.Breadcrumbs
                  crumbs={[
                    ["Home", u.links.home()],
                    ["Social Visits", u.links.socialVisits()],
                    [coachReferral.name, u.links.socialVisit(coachReferral)],
                  ]}
                />
              )}
              <Header
                socialVisit={coachReferral}
                runner={runner}
                sx={{ mt: 3, display: { xs: "block", md: "none" } }}
              />
            </UI.Box>
            <UI.Grid columnSpacing={5} container sx={{}}>
              <UI.Grid item xs={12} sm={6} lg={7}>
                <Header
                  socialVisit={coachReferral}
                  runner={runner}
                  sx={{ display: { xs: "none", md: "block" }, mt: 2, mb: 2 }}
                />
                <UI.Box
                  sx={{
                    display: "flex",
                    mb: 2,
                    flexDirection: "column",
                  }}
                >
                  <UI.Box sx={{ display: "flex" }}>
                    <UI.Box
                      sx={{
                        background: "black",
                        width: "fit-content",
                        mb: 2,
                        boxShadow: "-5px 5px 0 -1px #E11018",
                      }}
                    >
                      <UI.Typography
                        sx={{
                          mb: 0.5,
                          px: 1,
                          fontStyle: "normal",
                          fontFamily: "'Barlow Condensed', sans-serif",
                          textTransform: "uppercase",
                        }}
                        color="common.white"
                      >
                        {coachReferral.area.name}
                      </UI.Typography>
                    </UI.Box>
                  </UI.Box>
                  {runner && (
                    <UI.Typography variant="subtitle1" sx={{ my: 2 }}>
                      <UI.Emoji emoji="mapPin" sx={{ mr: 0 }} />
                      {coachReferral.distanceFromRunner}
                    </UI.Typography>
                  )}
                </UI.Box>
                {runner && (
                  <UI.Box
                    sx={{
                      mb: 3,
                      display: "flex",
                      flexDirection: "column",
                    }}
                  >
                    <UI.Box
                      sx={{
                        display: { xs: "none", sm: "block" },
                        flexShrink: 0,
                      }}
                    >
                      <UI.Button
                        disabled={testPairingEnabled ? false : true}
                        onClick={scrollToRequest}
                      >
                        {runnerVerified
                          ? testPairingEnabled
                            ? "Request trial visit"
                            : "Trial visit already requested"
                          : "Get verified to request trial visit"}
                      </UI.Button>
                    </UI.Box>
                  </UI.Box>
                )}
              </UI.Grid>
              <UI.Grid item xs={12} sm={6} lg={5}>
                <UI.Box
                  sx={{
                    height: {
                      xs: "100%",
                    },
                    minHeight: "300px",
                    width: { xs: "100%" },
                    position: "relative",
                  }}
                >
                  <UI.TaskRequestMap taskRequest={coachReferral} />
                </UI.Box>
              </UI.Grid>
            </UI.Grid>
          </UI.Container>
        </UI.Box>
        <UI.Box sx={{ backgroundColor: "white.main" }}>
          <UI.Container>
            <UI.Grid columnSpacing={5} container sx={{ pt: 3, mb: 2 }}>
              <UI.Grid xs={12} md={6} lg={8} item>
                <UI.Typography variant="h5" sx={{ mt: 3, mb: 2 }}>
                  About this social visit
                </UI.Typography>
                <UI.Box
                  sx={{ mb: 2 }}
                  className="gg-markdown"
                  dangerouslySetInnerHTML={{
                    __html: coachReferral.reasonForReferral,
                  }}
                />
                <UI.Box
                  sx={{ mb: 2 }}
                  className="gg-markdown"
                  dangerouslySetInnerHTML={{
                    __html: coachReferral.interests,
                  }}
                />
                <UI.Typography variant="body1" color="" my={1} sx={{ mb: 2 }}>
                  <UI.Emoji emoji="calendar" sx={{ mr: 1 }} />
                  {coachReferral.availableForVisit}
                </UI.Typography>
              </UI.Grid>
              <UI.Grid xs={12} md={6} lg={8} item>
                <UI.Typography variant="h5" sx={{ mt: 3, mb: 2 }}>
                  Additional information
                </UI.Typography>
                <UI.Typography>{coachReferral.additionalInfo}</UI.Typography>
                {coachReferral.homeRiskAssesed ? (
                  <UI.Typography>
                    <CheckCircleIcon /> This property was assessed by the
                    referrer.
                  </UI.Typography>
                ) : (
                  <UI.Typography>
                    <CancelIcon /> This property has not been assessed by the
                    referrer.
                  </UI.Typography>
                )}
              </UI.Grid>
            </UI.Grid>
            <UI.Divider sx={{ mx: { xs: -2, sm: 0 } }} />
            <UI.Grid container spacing={2} sx={{ my: 2, mb: 4 }}>
              <UI.Grid item xs={12} md={6} xl={8}>
                <UI.Box>
                  <UI.Typography variant="h5" sx={{ mt: 3, mb: 2 }}>
                    What are social visits?
                  </UI.Typography>
                  <UI.Typography>
                    Social visits are{" "}
                    <span style={{ color: "red" }}>30-60 minute</span> friendly
                    visits. They are informal weekly in person chats that help
                    combat loneliness and isolation. You are there to provide
                    company to help brighten up that person’s day.
                  </UI.Typography>
                  <UI.Typography variant="h5" sx={{ mt: 3, mb: 2 }}>
                    How does it work?
                  </UI.Typography>
                  <UI.Typography>
                    Choose an older person and sign up to a test visit to meet
                    them. If the first visit goes well, you can then decide if
                    you want to turn this into a longer term pairing and keep
                    visiting every week. If you would like to find out more
                    about social visits, please visit our{" "}
                    <UI.Link
                      href={
                        "https://goodgym.zendesk.com/hc/en-gb/categories/16929787801618-Social-Visits"
                      }
                    >
                      FAQs
                    </UI.Link>{" "}
                    or email{" "}
                    <UI.Link href={`mailto:${"coachteam@goodgym.org"}`}>
                      coachteam@goodgym.org.
                    </UI.Link>
                  </UI.Typography>
                </UI.Box>
              </UI.Grid>
              <UI.Grid item xs={12} md={6} xl={4}>
                <UI.Box>
                  <UI.Box
                    sx={{
                      borderStyle: "solid",
                      borderWidth: "1px",
                      borderColor: "neutral.light",
                    }}
                  >
                    <UI.Typography
                      variant="h5"
                      sx={{
                        mb: 2,
                        p: 1,
                        borderBottomStyle: "solid",
                        borderWidth: "0.3px",
                        backgroundColor: "neutral.light",
                      }}
                    >
                      Age Restriction
                    </UI.Typography>
                    <UI.Typography sx={{ p: 1 }}>
                      GoodGym welcomes anyone aged 18 or over. If you are under
                      18 then you will be unable to participate in any GoodGym
                      activity
                    </UI.Typography>
                  </UI.Box>
                  <UI.Box
                    sx={{
                      borderStyle: "solid",
                      borderWidth: "1px",
                      mt: 2,
                      p: 1,
                    }}
                  >
                    <UI.Typography sx={{ mb: 2 }}>
                      This social visit was from a referrer with little or no
                      knowledge of the older person. This means that their
                      behaviour or environment may post signicant risks to you.
                    </UI.Typography>
                    <UI.Typography sx={{ mb: 1 }}>
                      To make sure you are safe, please ensure you have read
                      <UI.Link
                        href={"https://www.goodgym.org/general_group_run_risks"}
                      >
                        {" "}
                        general guidelines for safety{" "}
                      </UI.Link>
                      when running, walking or cycling the information below and that you are
                      confident in your social visits training.
                    </UI.Typography>
                  </UI.Box>
                </UI.Box>
              </UI.Grid>
            </UI.Grid>{" "}
            <UI.Divider sx={{ my: 2 }} />
            <UI.Grid container spacing={4} sx={{ my: 1 }}>
              {" "}
              <UI.Grid item xs={12} md={6} lg={9}>
                {runner && (
                  <UI.Box ref={requestPairingRef}>
                    <RequestPairing
                      runner={runner}
                      coachReferral={coachReferral}
                      testPairingEnabled={testPairingEnabled}
                      runnerVerified={runnerVerified}
                      previousHadTestPairing={previousHadTestPairing}
                    />
                  </UI.Box>
                )}
              </UI.Grid>
              <UI.Grid item xs={12} md={6} lg={3}>
                <UI.Box>
                  <AskHelp coachReferral={coachReferral} />
                </UI.Box>
              </UI.Grid>
            </UI.Grid>
          </UI.Container>
        </UI.Box>
      </UI.Box>
    </>
  );
};

export default SocialVisitPage;
