import * as UI from "@goodgym/components";
import * as T from "@goodgym/graphql/types";
import React from "react";
import * as u from "@goodgym/util"

export type SocialVisitCardProps = UI.CardProps & {
  dashboard?: boolean;
  coachReferral: T.CoachReferralFragment;
};
const SocialVisitCard: React.FC<SocialVisitCardProps> = ({
  dashboard,
  coachReferral,
}) => {
  return (
    <>
      <UI.Card>
        <UI.Box
          sx={{
            display: "flex",
            flexDirection: dashboard
              ? "column-reverse"
              : { xs: "column-reverse", md: "row" },
          }}
        >
          <UI.Box sx={{ px: 2, my: 1, width: { sm: "100%", md: "50%" } }}>
            <UI.Box>
              <UI.Box>
                <UI.Box
                  sx={{
                    background: "black",
                    width: "fit-content",
                    mb: 2,
                    boxShadow: "-5px 5px 0 -1px #E11018",
                  }}
                >
                  <UI.Typography
                    sx={{
                      mb: 0.5,
                      px: 1,
                      fontStyle: "normal",
                      fontFamily: "'Barlow Condensed', sans-serif",
                      textTransform: "uppercase",
                    }}
                    color="common.white"
                  >
                    {coachReferral.area.name}
                  </UI.Typography>
                </UI.Box>
                <UI.Link
                  href={u.links.socialVisit(coachReferral)}
                  variant="h4"
                  sx={{ color: "main", flexWrap: "wrap" }}
                >
                  {coachReferral.name}
                </UI.Link>

                <UI.Typography
                  variant="body1"
                  color="neutral.dark"
                  my={1}
                  sx={{ mb: 3 }}
                >
                  {coachReferral.reasonForReferral}
                </UI.Typography>
                <UI.Typography variant="subtitle1" sx={{ mb: 2 }}>
                  <UI.Emoji emoji="mapPin" sx={{ mr: 0 }} />
                  {coachReferral.distanceFromRunner}
                </UI.Typography>
                <UI.Typography variant="body2" color="" my={1} sx={{ mb: 2 }}>
                  <UI.Emoji emoji="calendar" sx={{ mr: 1 }} />
                  {coachReferral.availableForVisit}
                </UI.Typography>
              </UI.Box>
            </UI.Box>
          </UI.Box>
          <UI.Box
            sx={{
              width: { sm: "100%", md: "50%" },
            }}
          >
            <UI.Box
              sx={{
                height: {
                  xs: "100%",
                },
                minHeight: "300px",
                width: { xs: "100%" },
                position: "relative",
              }}
            >
              <UI.TaskRequestMap taskRequest={coachReferral} />
            </UI.Box>
          </UI.Box>
        </UI.Box>
      </UI.Card>
    </>
  );
};

export default SocialVisitCard;
