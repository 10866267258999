import * as UI from "@goodgym/components";

const useBreakpoints = () => {
  const theme = UI.useTheme();
  const sm = UI.useMediaQuery(theme.breakpoints.up("sm"));
  const md = UI.useMediaQuery(theme.breakpoints.up("md"));
  const lg = UI.useMediaQuery(theme.breakpoints.up("lg"));
  const xl = UI.useMediaQuery(theme.breakpoints.up("xl"));
  return xl ? "xl" : lg ? "lg" : md ? "md" : sm ? "sm" : "xs";
};
export default useBreakpoints;
