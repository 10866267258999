/** @jsxImportSource @emotion/react */
import React from "react";
import * as Icons from "@goodgym/icons";
import * as UI from "@goodgym/components";
import * as T from "@goodgym/graphql/types";
import * as u from "@goodgym/util";
import _ from "lodash";

export type ControlButtonProps = UI.IconButtonProps & {};

const ControlButton: React.FC<ControlButtonProps> = ({ sx, ...props }) => {
  const theme = UI.useTheme();
  return (
    <UI.IconButton
      {...props}
      sx={{
        backgroundColor: "white.main",
        "&:hover": {
          backgroundColor: "neutral.light",
        },
        "&:disabled": {
          color: "#acacac",
          backgroundColor: "neutral.light",
        },
        boxShadow: theme.shadows[1],
        ...sx,
      }}
    />
  );
};

// @ts-ignore
ControlButton.muiName = UI.IconButton.muiName;
export default ControlButton;
