/** @jsxImportSource @emotion/react */
import React, { PropsWithChildren } from "react";
import * as T from "@goodgym/graphql/types";
import * as UI from "@goodgym/components";
import { useCSRFToken } from "@goodgym/csrf";
import axios from "axios";
import { useLocation } from "react-router-dom";

type AuthData = T.AuthQuery & {
  state: "LOADING" | "LOGGED_IN" | "LOGGED_OUT";
  logout: () => any;
};

export const Context = React.createContext<AuthData>({
  state: "LOADING",
  logout: () => null,
});

export const useAuth = () => {
  const authData = React.useContext(Context);
  return {
    ...authData,
    isLoggedIn: authData.state === "LOGGED_IN",
    isLoggedOut: authData.state === "LOGGED_OUT",
    ready: authData.state !== "LOADING",
  };
};

export const AuthenticationProvider: React.FC<PropsWithChildren<{}>> = ({
  children,
}) => {
  const [query, { error, data, client }] = T.useAuthLazyQuery();
  const location = useLocation();

  const [state, setState] = React.useState<AuthData["state"]>("LOADING");

  const csrfToken = useCSRFToken();

  const logout = async () => {
    await axios.delete("/users/sign_out.json", {
      withCredentials: true,
      headers: {
        "X-CSRF-Token": csrfToken,
      },
    });

    if (
      location.pathname === "/v3/sessions" ||
      location.pathname === "/v3/reports" ||
      location.pathname === "/v3/stories"
    ) {
      window.location.href = location.pathname;
    } else window.location.href = "/";
  };

  React.useEffect(() => {
    query();
  }, [query]);

  React.useEffect(() => {
    if (data?.runner) {
      client?.resetStore();
    }
  }, [client, data?.runner]);

  React.useEffect(() => {
    if (data?.referrer && !data?.runner) {
      window.location.href = "/referrers";
    }

    if (data) {
      data.runner ? setState("LOGGED_IN") : setState("LOGGED_OUT");
    }
  }, [data]);

  return error ? (
    <UI.Error error={error} />
  ) : (
    <Context.Provider value={{ ...data, state, logout }}>
      {children}
    </Context.Provider>
  );
};
