/** @jsxImportSource @emotion/react */
import { useUnSignUpFromSessionMutation } from "@goodgym/graphql/hooks";
import * as T from "@goodgym/graphql/types";
import * as UI from "@goodgym/components";
import * as u from "@goodgym/util";
import { useConfirm } from "@goodgym/hooks";

export type UnSignUpFromSessionMutationHook = () => (
  session: T.SessionCardFragment
) => React.MouseEventHandler;

export const useUnSignUpFromSession: UnSignUpFromSessionMutationHook = () => {
  const { confirm } = useConfirm();
  const [unSignUpFromSession] = useUnSignUpFromSessionMutation({
    onError: console.log,
  });

  return (session) => (e) => {
    confirm({
      title: "Cancel registration",
      text: (
        <>
          <UI.Typography sx={{ mb: 2 }}>
            Are you sure you want to cancel your registration for{" "}
            <strong>{session.title}</strong>?
          </UI.Typography>
        </>
      ),
      confirmText: "Cancel registration",
      cancelText: "Don't cancel",
      onConfirm: () =>
        unSignUpFromSession({
          variables: { sessionId: session.id },
          optimisticResponse: {
            response: {
              __typename: "UnSignUpFromSessionMutationPayload",
              session: { ...session, signedUp: false },
            },
          },
        }),
    });
    e?.preventDefault();
  };
};
