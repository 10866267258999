/** @jsxImportSource @emotion/react */
import React from "react";
import * as Icons from "@goodgym/icons";
import * as UI from "@goodgym/components";
import * as T from "@goodgym/graphql/types";
import * as u from "@goodgym/util";
import { useDropdown } from "@goodgym/hooks";
import _ from "lodash";
import * as menus from "@goodgym/menus";
import { useAuth } from "@goodgym/auth";

export type AccountProps = {};

const Account: React.FC<AccountProps> = () => {
  const { runner, referrer, logout } = useAuth();
  const { open, onClick, onClose, anchor } = useDropdown();
  const menu = menus.account({ runner, referrer });

  return (
    <>
      <UI.Avatar
        src={runner?.avatarUrl}
        component={UI.ButtonBase}
        sx={{ ml: "auto" }}
        onClick={onClick}
      />
      {runner && (
        <UI.Menu
          id="account-menu"
          anchorEl={anchor}
          open={open}
          onClose={onClose}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "right",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
        >
          {menu.map(({ key, links }, index) => (
            <UI.Box key={key}>
              {links.map(({ key, icon, label, ...props }) => (
                <UI.MenuItem
                  key={key}
                  component={UI.Link}
                  {...props}
                  onClick={onClose}
                >
                  <UI.ListItemIcon>{icon}</UI.ListItemIcon>
                  <UI.ListItemText>{label}</UI.ListItemText>
                </UI.MenuItem>
              ))}
              {index === menu.length - 1 ? null : <UI.Divider />}
            </UI.Box>
          ))}

          <UI.MenuItem onClick={logout}>
            <UI.ListItemIcon>
              <Icons.ExitToApp />
            </UI.ListItemIcon>
            <UI.ListItemText>Sign out</UI.ListItemText>
          </UI.MenuItem>
        </UI.Menu>
      )}
    </>
  );
};

export default Account;
