import React from "react";

export type Drawer = {
  open: boolean;
  onClick: React.MouseEventHandler<Element>;
  onClose: () => void;
};

const useDrawer = () => {
  const [open, setOpen] = React.useState(false);

  const onClick = (e: React.MouseEvent<Element>) => {
    setOpen(true);
    e.preventDefault();
  };

  const onClose = () => {
    setOpen(false);
    return false;
  };

  return { open, onClick, onClose };
};

export default useDrawer;
