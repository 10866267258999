/** @jsxImportSource @emotion/react */
import * as UI from "@goodgym/components";
import * as T from "@goodgym/graphql/types";
import * as Icons from "@goodgym/icons";
import React from "react";

export type FollowDialogProps = UI.DialogProps & {
  runner: T.RunnerFragment;
  onCancel: () => any;
  onConfirm: (message: string) => any;
};

const FollowDialog: React.FC<React.PropsWithChildren<FollowDialogProps>> = ({
  runner,
  onCancel,
  onConfirm,
  ...props
}) => {
  const [message, setMessage] = React.useState(
    `Hey ${runner.name}, you're doing great -  keep up the good work!`
  );
  const onKeyDown: React.KeyboardEventHandler = (e) => {
    if (e.key === "Enter" && e.ctrlKey) {
      onConfirm(message);
    }
  };

  return (
    <UI.Dialog maxWidth="sm" fullWidth {...props}>
      <UI.DialogTitle>Follow {runner.name}?</UI.DialogTitle>
      <UI.Box position="absolute" top={8} right={8}>
        <UI.IconButton onClick={onCancel}>
          <Icons.Close />
        </UI.IconButton>
      </UI.Box>
      <UI.DialogContent>
        <UI.Typography sx={{ mb: 2 }}>
          Follow {runner.name} to keep up to date with their progress.
        </UI.Typography>
        <UI.Typography sx={{ mb: 2 }}>
          Write them a personal message of support:
        </UI.Typography>
        <UI.TextField
          value={message}
          multiline
          fullWidth
          autoFocus
          onChange={(e) => setMessage(e.target.value)}
          onKeyDown={onKeyDown}
        />
      </UI.DialogContent>
      <UI.DialogActions sx={{ mb: 1, mr: 1 }}>
        <UI.Button color="neutral" variant="text" onClick={onCancel}>
          Cancel
        </UI.Button>
        <UI.Button
          color="primary"
          variant="contained"
          onClick={() => onConfirm(message)}
        >
          Follow
        </UI.Button>
      </UI.DialogActions>
    </UI.Dialog>
  );
};

export default FollowDialog;
