import { useAuth } from "@goodgym/auth";
import { useAvailableCoachesLazyQuery } from "@goodgym/graphql/hooks";
import { useDrawer } from "@goodgym/hooks";
import React from "react";

export type Filters = {
  postcode?: string;
  maxDistance?: number;
  areaIds?: Array<string>;
};

export const useSocialVisits = (
  props: {
    filters?: { postcode: string; maxDistance: number, areaIds: any };
  } = {}
): any => {
  const { runner } = useAuth();
  const areaIds = runner ? [runner?.homeArea?.id] : [];

  const [fetchSocialVisits, { error, data }] = useAvailableCoachesLazyQuery({
    fetchPolicy: "cache-and-network",
  });

  const filterDrawer = useDrawer();

  const defaultFilters = {
    postcode: "",
    maxDistance: 5,
    areaIds
  };

  const [filters, setFilters] = React.useState({
    ...defaultFilters,
    ...props.filters,
  });

  const updateFilters = (update: Partial<Filters>) => {
    setFilters({ ...filters, ...update });
  };

  const resetFilters = () => {
    setFilters(defaultFilters);
  };

  const more = data?.availableCoaches.more;
  const results = data?.availableCoaches.results;

  const fetchMore = React.useCallback(() => {
    if (more) {
      fetchSocialVisits({
        variables: filters,
      });
    }
  }, [fetchSocialVisits, more, filters]);

  React.useEffect(() => {
    fetchSocialVisits({
      variables: filters,
    });
  }, [filters, fetchSocialVisits]);

  return {
    runner,
    error,
    loading: !data,
    items: results,
    more,
    fetchMore,
    drawers: { filters: filterDrawer },
    filters: { values: filters, update: updateFilters, result: resetFilters },
  };
};
