import React from "react";
import * as UI from "@goodgym/components";

export const ConfirmContext = React.createContext<{
  confirm: (props: Omit<UI.ConfirmDialogProps, "open">) => any;
}>({ confirm: () => null });

export const ConfirmProvider: React.FC<React.PropsWithChildren<{}>> = ({
  children,
}) => {
  const [state, setState] = React.useState<Omit<
    UI.ConfirmDialogProps,
    "open"
  > | null>(null);

  const confirm = (props: Omit<UI.ConfirmDialogProps, "open">) => {
    setState(props);
  };

  const onConfirm = () => {
    state?.onConfirm && state.onConfirm();
    setState(null);
  };

  const onCancel = () => {
    state?.onCancel && state.onCancel();
    setState(null);
  };

  return (
    <ConfirmContext.Provider value={{ confirm }}>
      {state && (
        <UI.ConfirmDialog
          {...state}
          onConfirm={onConfirm}
          onCancel={onCancel}
          open
        />
      )}
      {children}
    </ConfirmContext.Provider>
  );
};

export const useConfirm = () => React.useContext(ConfirmContext);
export default useConfirm;
