/** @jsxImportSource @emotion/react */
import React from "react";
import * as Icons from "@goodgym/icons";
import * as UI from "@goodgym/components";
import * as T from "@goodgym/graphql/types";
import * as u from "@goodgym/util";
import _ from "lodash";

import Attendance from "../Attendance";
import Award from "../Award";
import CoachVisit from "../CoachVisit";
import Registration from "../Registration";
import Workout from "../Workout";

export type NewsfeedItemProps = {
  item: T.NewsfeedQuery["newsfeed"]["items"][0];
};

const NewsfeedItem: React.FC<NewsfeedItemProps> = ({ item }) => {
  switch (item.__typename) {
    case "NewsfeedAttendance":
      return <Attendance attendance={item} />;
    case "NewsfeedAward":
      return <Award award={item} />;
    case "NewsfeedCoachVisit":
      return <CoachVisit visit={item} />;
    case "NewsfeedRegistration":
      return <Registration registration={item} />;
    case "NewsfeedWorkout":
      return <Workout workout={item} />;
  }
};

export default NewsfeedItem;
