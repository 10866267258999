import * as UI from "@goodgym/components";
import IconCards from "@goodgym/components/IconCards";
import * as data from "../../data";
import *as Images from "../../images"
import PostcodeSearch from "@goodgym/pages/AboutPage/components/PostcodeSearch";

const AboutMission = () => {
  return (
    <>
      <UI.Container>
        <UI.Typography
          variant="h5"
          sx={{
            lineHeight: 1.8,
            fontWeight: "normal",
            marginTop: 1,
            marginBottom: 3,
            textAlign: "center",
          }}
        >
          We run, walk or cycle to help out older people with practical tasks
          that they are no longer able to do on their own. Missions give us a
          reason to exercise and make a big impact on someone's life too.
        </UI.Typography>
      </UI.Container>
      <UI.Container>
        <UI.Grid
          container
          spacing={2}
          sx={{
            display: "flex",
            justifyContent: "flex-start",
            alignItems: "flex-start",
          }}
        >
          {data.taskIconData.map((element, index) => (
            <IconCards
              key={index}
              image={element.image}
              tagline={element.tagline}
              bold={true}
            />
          ))}
        </UI.Grid>
      </UI.Container>
      <UI.Container>
        <UI.Typography
          variant="h2"
          sx={{ my: 2, textAlign: "center", fontStyle: "normal" }}
        >
          Find your local group
        </UI.Typography>
      </UI.Container>
      <UI.Container sx={{ textAlign: "center" }}>
        <UI.Container sx={{ textAlign: "center" }}>
          <PostcodeSearch missionPage={true} />
        </UI.Container>
      </UI.Container>

      <UI.Box
        sx={{
          backgroundImage: `url(${Images.MissionBanner})`,
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
          height: "390px",
          width: "100vw",
          display: "flex",
          justifyContent: "flex-start",
          alignItems: "flex-end",
          aspectRatio: "5/3",
        }}
      >
        <UI.Typography
          variant="h1"
          color="neutral.light"
          sx={{ textShadow: 7, m: 6 }}
        >
          How it works
        </UI.Typography>
      </UI.Box>
      <UI.Container>
        <UI.Typography
          variant="h5"
          sx={{
            lineHeight: 1.8,
            fontWeight: "normal",
            marginTop: 1,
            marginBottom: 3,
            textAlign: "center",
          }}
        >
          Run, walk or cycle to an older person’s home and help them with
          something they are struggling to do themselves.
        </UI.Typography>
      </UI.Container>
      <UI.Container>
        <UI.Grid
          container
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "flex-start",
            marginTop: "30px",
            mb: 4,
          }}
        >
          {data.missionTasks.map((element, index) => (
            <UI.Grid
              item
              xs={12}
              md={5}
              lg={3}
              key={index}
              sx={{
                textAlign: "center",
                mb: 2,
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <UI.Box sx={{ height: "135px" }}>
                <img
                  style={{ width: "130px", height: "auto" }}
                  src={element.image}
                  alt="icon"
                />
              </UI.Box>

              <UI.Box
                sx={{
                  width: { xs: "60%", sm: "50%", md: "100%" },

                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  borderTop: "4px solid #E11018",
                }}
              />
              <UI.Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  mx: { xs: 2, md: 0 },
                }}
              >
                <element.icon sx={{ mx: 1 }} fontSize="medium" />
                <UI.Typography
                  sx={{
                    textAlign: "center",
                    p: 1,
                  }}
                  variant="h3"
                >
                  {element.title}
                </UI.Typography>
              </UI.Box>

              <UI.Typography
                sx={{
                  textAlign: "center",
                  p: 1,
                }}
                variant="body1"
              >
                {element.tagline}
              </UI.Typography>
            </UI.Grid>
          ))}
        </UI.Grid>
      </UI.Container>
    </>
  );
};

export default AboutMission;
