/** @jsxImportSource @emotion/react */
import React from "react";
import * as Icons from "@goodgym/icons";
import * as UI from "@goodgym/components";
import * as T from "@goodgym/graphql/types";
import * as u from "@goodgym/util";
import * as NP from "@goodgym/noun-project";
import _ from "lodash";

export type WhatWeDoProps = {};

const thingsWeDo = [
  { Icon: NP.PlantingTree, text: "Plant trees at the local park" },
  { Icon: NP.CarryingBoxes, text: "Sort cans for a foodbank" },
  { Icon: NP.Cleaning, text: "Clean up a community centre" },
  {
    Icon: NP.ChangingLightbulb,
    text: "Help an older person with maintenance around their house",
  },
  { Icon: NP.Gardening, text: "Do some gardening" },
  { Icon: NP.MovingFurniture, text: "Move furniture for an older person" },
];

const WhatWeDo: React.FC<WhatWeDoProps> = ({}) => {
  return (
    <UI.Grid container>
      {thingsWeDo.map(({ text, Icon }, index) => (
        <UI.Grid key={index} item xs={12} sm={6}>
          <UI.Box sx={{ display: "flex", my: 2 }}>
            <UI.Box>
              <Icon css={{ height: "60px", width: "60px" }} />
            </UI.Box>
            <UI.Box sx={{ flexGrow: 1, ml: 2, alignSelf: "center" }}>
              <UI.Typography variant="h5" component="p">
                {text}
              </UI.Typography>
            </UI.Box>
          </UI.Box>
        </UI.Grid>
      ))}
    </UI.Grid>
  );
};

export default WhatWeDo;
