import React, { useState } from "react";
import * as UI from "@goodgym/components";
import * as T from "@goodgym/graphql/types";
import * as Icons from "@goodgym/icons";
import * as u from "@goodgym/util";
import * as C from "../../components";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import { Alert, Snackbar } from "@mui/material";

export type ProfileHeaderProps = {
  runner: T.Runner | any;
  currentRunner?: T.CurrentRunner | any;
};

function ProfileHeader({ runner, currentRunner }: ProfileHeaderProps) {
  const [open, setOpen] = useState(false);

  const checkAdminRoles =
    (currentRunner !== null) &&
    u.roles.hasAnyRole(currentRunner, [
      "TRAINER",
      "RUN_LEADER",
      "CENTRAL_ADMIN",
    ]);

  const runnerEmail = runner?.user?.email;


  const handleClick = (text: string) => {
    setOpen(true);
    navigator.clipboard.writeText(text);
  };

  return (
    <UI.Box sx={{ textAlign: "center", position: "relative" }}>
      <UI.Avatar
        aria-label="ProfileHeader"
        src={runner.avatarUrl}
        component={UI.Link}
        to={u.links.profile(runner)}
        sx={{
          width: "144px",
          height: "144px",
          border: "2px solid",
          borderColor: "black.main",
          display: "inline-block",
        }}
      />
      {runner.blockedByYou && (
        <Icons.Block
          sx={{
            position: "absolute",
            top: 0,
            left: "calc(50% - 72px)",
            height: "144px",
            width: "144px",
            color: "neutral.light",
            opacity: 0.5,
          }}
        />
      )}
      <UI.Typography variant="h2" sx={{ my: 1 }}>
        {runner.name}
        <C.AdminMenu
          runner={runner}
          sx={{ ml: "4px", verticalAlign: "middle" }}
        />
      </UI.Typography>
      {checkAdminRoles && (
        <UI.Box aria-label="Email&Phone">
          {runnerEmail && (
            <UI.Container sx={{ display: "flex", justifyContent: "center" }}>
              <UI.Chip
                component={UI.Link}
                label={runnerEmail}
                clickable
                href={`mailto:${runnerEmail}`}
                sx={{
                  borderRadius: 1,
                  height: 24,
                  verticalAlign: "baseline",
                  fontWeight: "bold",
                  mb: 2,
                }}
              />
              <UI.Box onClick={() => handleClick(runnerEmail)}>
                <ContentCopyIcon
                  style={{ height: "25px", width: "auto" }}
                />
              </UI.Box>
              <Snackbar
                anchorOrigin={{ vertical: "top", horizontal: "center" }}
                open={open}
                onClose={() => setOpen(false)}
                autoHideDuration={2000}
              >
                <Alert
                  onClose={() => setOpen(false)}
                  color="info"
                  sx={{ width: "100%" }}
                >
                  Copied to Clipboard
                </Alert>
              </Snackbar>
            </UI.Container>
          )}

        </UI.Box>
      )}
    </UI.Box>
  );
}

export default ProfileHeader;
