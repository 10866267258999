/** @jsxImportSource @emotion/react */
import React from "react";
import { Link as MUILink } from "@mui/material";
import * as Icons from "@goodgym/icons";
import * as UI from "@goodgym/components";
import * as T from "@goodgym/graphql/types";
import * as u from "@goodgym/util";
import _ from "lodash";
import { Link as RouterLink } from "react-router-dom";

export type LinkProps = UI.LinkProps<typeof RouterLink | "a">;

const Link: React.FC<LinkProps> = React.forwardRef((props, ref) => {
  if ("to" in props) {
    return (
      <MUILink
        {...(props as any)}
        to={(props as any).to}
        ref={ref}
        component={RouterLink}
      />
    );
  } else if (props.href?.startsWith("data:")) {
    return <MUILink rel="noreferrer" {...props} href={props.href} ref={ref} />;
  } else if (props.href?.startsWith("mailto:")) {
    return <MUILink rel="noreferrer" {...props} href={props.href} ref={ref} />;
  } else {
    const isExternal =
      props.href?.startsWith("http") &&
      !props.href?.startsWith("https://www.goodgym.org");

    // redirect between react and rails app as
    // needed in development
    const href = !props.href
      ? undefined
      : props.href?.startsWith("http")
      ? props.href
      : !u.isServer && window.location.host === "localhost:3002"
      ? `http://localhost:3000${props.href}`
      : props.href;

    return (
      <MUILink
        {...(isExternal
          ? { rel: "noreferrer noopener nofollow", target: "_blank" }
          : {})}
        {...props}
        href={href}
        ref={ref}
      />
    );
  }
});

// @ts-ignore
Link.muiName = MUILink.muiName;
export default Link;
