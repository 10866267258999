import React from "react";
import * as UI from "@goodgym/components";
import { useRequestTestPairing } from "@goodgym/graphql/mutations/RequestTestPairing";
import * as T from "@goodgym/graphql/types";

export type ButtonProps = {
  coachReferral: T.CoachReferralFragment;
  runner: any;
  runnerSuggestedTimes: any;
  testPairingEnabled?: boolean;
  previousHadTestPairing?: boolean;
};
const Button: React.FC<ButtonProps> = ({
  coachReferral,
  runner,
  runnerSuggestedTimes,
  testPairingEnabled,
  previousHadTestPairing,
}) => {
  const requestPairing = useRequestTestPairing({
    coachReferral,
    runner,
    runnerSuggestedTime: runnerSuggestedTimes,
    previousHadTestPairing
  });
  return (
    <UI.Button
      disabled={testPairingEnabled ? false : true}
      sx={{ my: 2 }}
      onClick={requestPairing}
    >
      {!testPairingEnabled
        ? "Pairing already requested"
        : `Meet ${coachReferral.name}`}
    </UI.Button>
  );
};

export default Button;
