/** @jsxImportSource @emotion/react */
import React from "react";
import * as Icons from "@goodgym/icons";
import * as UI from "@goodgym/components";
import * as T from "@goodgym/graphql/types";
import * as u from "@goodgym/util";
import _ from "lodash";
import { css } from "@emotion/react";

export type EmploymentCardProps = UI.CardProps & {
  employment: T.EmploymentFragment;
};

const EmploymentCard: React.FC<EmploymentCardProps> = ({
  employment,
  ...props
}) => {
  return (
    <UI.Card
      {...props}
      sx={{
        textAlign: "center",
        p: 2,
        mt: 1,
        ...props.sx,
      }}
    >
      <UI.Link href={u.links.employer(employment.employer)}>
        <img
          src={employment.employer.imageUrl}
          alt={employment.employer.name}
          css={css`
            margin: 0 auto;
            width: calc(100% - 100px);
            height: calc(100% - 100px);
          `}
        />
      </UI.Link>
      <UI.Typography variant="body1" sx={{ my: 1 }}>
        Doing good with
      </UI.Typography>
      <UI.Link href={u.links.employer(employment.employer)}>
        <UI.Typography variant="h4">{employment.employer.name}</UI.Typography>
      </UI.Link>
      <UI.Typography variant="body1" sx={{ my: 1 }}>
        Since {u.time.formatDate(employment.startedAt)}
      </UI.Typography>
    </UI.Card>
  );
};

export default EmploymentCard;
