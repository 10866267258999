/** @jsxImportSource @emotion/react */
import { useUpdateOrganisationMutation } from "@goodgym/graphql/hooks";
import * as T from "@goodgym/graphql/types";

export const useUpdateOrganisation = ({
  organisation,
}: {
  organisation: Optional<T.OrganisationFragment>;
}) => {
  const [updateOrganisationMutation] = useUpdateOrganisationMutation({
    onError: console.log,
  });

  return ({ description }: { description: string }) => {
    if (!organisation) return;
    updateOrganisationMutation({
      variables: { organisationId: organisation.id, description },
      optimisticResponse: {
        response: {
          organisation: {
            ...organisation,
            description,
          },
          __typename: "UpdateOrganisationMutationPayload",
        },
      },
    });
  };
};
