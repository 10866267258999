/** @jsxImportSource @emotion/react */
import React from "react";
import * as Icons from "@goodgym/icons";
import * as UI from "@goodgym/components";
import * as T from "@goodgym/graphql/types";
import * as u from "@goodgym/util";
import * as C from "..";
import _ from "lodash";
import { useDrawers } from "@goodgym/drawers";

export type RegistrationProps = {
  registration: T.NewsfeedRegistrationFragment;
};

const Registration: React.FC<RegistrationProps> = ({ registration }) => {
  const { showSession } = useDrawers();
  const { runners, session } = registration;
  return (
    <UI.Card>
      <UI.CardHeader
        title={
          <UI.Typography variant="subtitle1">
            <UI.RunnerLinks runners={runners} /> signed up to a {session.type}.
          </UI.Typography>
        }
        subheader={
          <UI.Typography variant="body2">
            {u.time.formatDateTime(session.startedAt)}
          </UI.Typography>
        }
        avatar={
          <UI.AvatarGroup max={2}>
            {runners.map((runner) => (
              <UI.RunnerAvatar
                key={runner.id}
                size="medium"
                runner={runner}
                noStatus
              />
            ))}
          </UI.AvatarGroup>
        }
      />
      <UI.CardActionArea
        href={u.links.session(session)}
        onClick={(e) => showSession(e, session)}
      >
        <UI.CardMedia title={session.title} image={session.coverImageUrl} />
      </UI.CardActionArea>
      <UI.CardContent sx={{ pb: 0 }}>
        <UI.Link
          variant="h4"
          color="black.main"
          href={u.links.session(session)}
          onClick={(e) => showSession(e, session)}
        >
          {session.title}
        </UI.Link>

        <UI.Box>
          <UI.AreaChip area={session.area} sx={{ mt: 1 }} />
        </UI.Box>
        <UI.Typography variant="body1" color="neutral.main" sx={{ mt: 1 }}>
          {session.strapline}
        </UI.Typography>
        <UI.Button
          href={u.links.session(session)}
          onClick={(e) => showSession(e, session)}
          variant="text"
          sx={{ ml: -1, color: "secondary.dark" }}
        >
          Read more
        </UI.Button>
      </UI.CardContent>
      <C.CheersAndComments
        commentable={registration.session}
        cheerable={registration}
      />
    </UI.Card>
  );
};

export default Registration;
