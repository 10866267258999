/** @jsxImportSource @emotion/react */
import React from "react";
import * as Icons from "@goodgym/icons";
import * as UI from "@goodgym/components";
import * as T from "@goodgym/graphql/types";
import * as u from "@goodgym/util";
import _ from "lodash";

export type ShareButtonProps = UI.BoxProps & {
  session: T.SessionFragment;
};

const ShareButton: React.FC<ShareButtonProps> = ({ session, ...props }) => {
  const [showCopiedLabel, setShowCopiedLabel] = React.useState(false);
  const [showShareLabel, setShowShareLabel] = React.useState(true);

  React.useEffect(() => {
    if (showCopiedLabel) setTimeout(() => setShowCopiedLabel(false), 2500);
  }, [showCopiedLabel, setShowCopiedLabel]);

  const share: React.MouseEventHandler = (e) => {
    const title = session.title;
    const text = session.strapline;
    const url = window.location.origin + u.links.session(session);
    if (u.detect.shareApi) {
      navigator.share({ text: title + " - " + text, url });
    } else {
      // truncated to fit in a tweet
      const truncated = u.text.ellipsify(title, 216) + " " + url;
      navigator.clipboard.writeText(truncated);
      setShowCopiedLabel(true);
      setShowShareLabel(false);
    }
    e.preventDefault();
  };

  return (
    <UI.Box
      sx={{
        display: "flex",
        alignContent: "center",
        color: "neutral.dark",
        mb: { xs: 0, sm: 3 },
      }}
    >
      <UI.Box sx={{ width: "32px", flexShrink: 0 }}>
        {u.detect.shareApi ? (
          <Icons.Share
            sx={{ verticalAlign: "text-bottom", fontSize: "22px" }}
          />
        ) : (
          <Icons.ContentCopy
            sx={{ verticalAlign: "text-bottom", fontSize: "22px" }}
          />
        )}
      </UI.Box>
      {!u.session.isOlderPeopleSession(session) && (
        <UI.Box>
          <UI.Typography variant="body1">
            Invite someone to join you
          </UI.Typography>
          <UI.Link
            variant="subtitle1"
            onClick={share}
            sx={{ color: showCopiedLabel ? "primary.main" : "secondary.dark" }}
          >
            {showShareLabel && (
              <UI.Box component="span">Share this session</UI.Box>
            )}
            <UI.Fade
              in={showCopiedLabel}
              addEndListener={() =>
                !showCopiedLabel &&
                setTimeout(() => setShowShareLabel(true), 200)
              }
            >
              <UI.Box component="span">Copied to clipboard</UI.Box>
            </UI.Fade>
          </UI.Link>
        </UI.Box>
      )}
    </UI.Box>
  );
};

export default ShareButton;
