/** @jsxImportSource @emotion/react */
import React from "react";
import * as Icons from "@goodgym/icons";
import * as UI from "@goodgym/components";
import * as T from "@goodgym/graphql/types";
import * as u from "@goodgym/util";
import _ from "lodash";

export type StatsProps = { stats: Optional<T.HomePageQuery["stats"]> };

const Stats: React.FC<StatsProps> = ({ stats }) => {
  const theme = UI.useTheme();
  const md = UI.useMediaQuery(theme.breakpoints.up("sm"));
  const variant = md ? "h1" : "h3";
  return (
    <>
      <UI.Typography variant={variant} color="#D3DCE9" sx={{ my: 2 }}>
        There are{" "}
        <UI.Typography variant={variant} component="span" color="white.main">
          {stats?.goodgymers.toLocaleString() || "thousands of"}
        </UI.Typography>{" "}
        GoodGymers in{" "}
        <UI.Typography variant={variant} component="span" color="white.main">
          {stats?.areas || "59"}
        </UI.Typography>{" "}
        areas.
      </UI.Typography>
      <UI.Typography variant={variant} color="#D3DCE9">
        We've helped{" "}
        <UI.Typography variant={variant} component="span" color="white.main">
          {(stats?.beneficiaries || 30000).toLocaleString()}
        </UI.Typography>{" "}
        people with{" "}
        <UI.Typography variant={variant} component="span" color="white.main">
          {(stats?.tasks || 250000).toLocaleString()}
        </UI.Typography>{" "}
        tasks.
      </UI.Typography>
    </>
  );
};

export default Stats;
