/** @jsxImportSource @emotion/react */
import * as UI from "@goodgym/components";
import * as T from "@goodgym/graphql/types";
import VerifiedUserIcon from "@mui/icons-material/VerifiedUser";
import React from "react";

const NotVerified = () => {
  return (
    <UI.Box
      sx={{
        borderStyle: "solid",
        borderWidth: "2px",
        p: 2,
        borderColor: "black",
        borderRadius: "20px",
      }}
    >
      <UI.Typography variant="h4" sx={{ my: 1 }}>
        You are not Social Visits Verified
      </UI.Typography>

      <UI.Typography sx={{ my: 1 }}>
        Getting Social Visits Verified involves providing references, getting a background check, 
        and doing a short online training. You will then have a pairing call with a member of our
        coach team to go through the training. 
      </UI.Typography>

      <UI.Typography sx={{ my: 1 }}>
        <VerifiedUserIcon fontSize="small" sx={{ mr: 1 }} />
        <UI.Link
          sx={{ mt: 1, color: "primary.dark" }}
          href="https://www.goodgym.org/my/coach/verification"
        >
          Get verified to request a trial visit
        </UI.Link>
      </UI.Typography>
    </UI.Box>
  );
};

export default NotVerified;
