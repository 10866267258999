/** @jsxImportSource @emotion/react */
import * as UI from "@goodgym/components";
import * as T from "@goodgym/graphql/types";
import * as u from "@goodgym/util";
import React from "react";
import { useParams } from "react-router-dom";
import { useCancelTestPairing } from "../hooks/CancelTestPairing";
import { useCompleteTestPairing } from "../hooks/CompleteTestPairing";
import { useRescheduleTestPairing } from "../hooks/RescheduleTestPairing";
export type TestPairingScheduledProps = UI.BoxProps & {
  testPairing: T.TestPairingFragment;
};

const TestPairingScheduled: React.FC<
  React.PropsWithChildren<TestPairingScheduledProps>
> = ({ ...props }) => {
  const { scheduledAt, notesForRunner, coach } = props.testPairing;
  const canComplete = new Date().getTime() > new Date(scheduledAt).getTime();
  const { id } = useParams();

  const [cancelNotes, setCancelNotes] = React.useState("");
  const [visitFeedback, setVisitFeedback] = React.useState("");
  const [visitNotes, setVisitNotes] = React.useState("");
    const [wantsPairing, setwantsPairing] = React.useState(false);


  const reschedulePairing = useRescheduleTestPairing({
    coach,
    testPairingId: props.id || id!,
  });

  const cancelTestPairing = useCancelTestPairing({
    coach,
    testPairingId: props.id || id!,
    cancellationNotes: cancelNotes,
  });
    const completeTestPairing = useCompleteTestPairing({
      coach,
      testPairingId: props.id || id!,
      runnerFeedback: visitFeedback,
      runnerVisitNotes: visitNotes,
      runnerWantsPairing: wantsPairing
    });

  

  return (
    <UI.Box
      {...props}
      sx={{
        borderStyle: "solid",
        borderWidth: "0.5px",
        p: 2,
        borderColor: "#E11018",
        borderRadius: "20px",
      }}
    >
      <UI.Typography variant="h5" sx={{ my: 2 }}>
        Trial visit is scheduled for: {u.time.formatDateTime(scheduledAt)}
      </UI.Typography>

      {!canComplete && (
        <UI.Typography sx={{ my: 2 }}>
          Notes from the Goodgym coach team: {notesForRunner}
        </UI.Typography>
      )}

      {canComplete ? (
        <>
          <UI.Typography variant="subtitle1">
            Complete your trial visit with {coach.name}
          </UI.Typography>

          <UI.Box>
            <UI.TextField
              sx={{ my: 2 }}
              required
              fullWidth
              id="outlined-multiline-flexible"
              label="How did your test visit go?"
              multiline
              maxRows={4}
              onChange={(event) => {
                setVisitFeedback(event.target.value);
              }}
            />
          </UI.Box>

          <UI.Box>
            <label>
              Would you like to keep visiting {coach.name} on a weekly basis?
            </label>
            <UI.Checkbox onChange={() => setwantsPairing(!wantsPairing)} />
          </UI.Box>

          <UI.Box>
            <UI.TextField
              sx={{ my: 2 }}
              fullWidth
              id="outlined-multiline-flexible"
              label="Is there anything you would like to let us know?"
              multiline
              maxRows={4}
              onChange={(event) => {
                setVisitNotes(event.target.value);
              }}
            />
          </UI.Box>

          <UI.Button
            sx={{ width: { md: "50%" }, my: 1 }}
            onClick={completeTestPairing}
          >
            Submit your feedback
          </UI.Button>
        </>
      ) : (
        <>
          <UI.Box
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              mb: 1,
            }}
          >
            <UI.Button onClick={reschedulePairing}>
              Re-schedule this trial visit
            </UI.Button>{" "}
            <UI.TextField
              sx={{ my: 2 }}
              fullWidth
              id="outlined-multiline-flexible"
              label="Reasons for cancellations"
              multiline
              maxRows={4}
              onChange={(event) => {
                setCancelNotes(event.target.value);
              }}
            />
            <UI.Button
              sx={{ width: { md: "50%" } }}
              onClick={cancelTestPairing}
            >
              Cancel this trial visit
            </UI.Button>
          </UI.Box>
        </>
      )}
    </UI.Box>
  );
};

export default TestPairingScheduled;
