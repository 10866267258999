/** @jsxImportSource @emotion/react */
import React from "react";
import * as Icons from "@goodgym/icons";
import * as UI from "@goodgym/components";
import * as T from "@goodgym/graphql/types";
import * as u from "@goodgym/util";
import _ from "lodash";

export type SignupStatusProps = UI.TypographyProps & {
  session: T.SessionPageQuery["session"];
};

const SignupStatus: React.FC<SignupStatusProps> = ({ session, ...props }) => {
  if (!!session.cancelledAt) {
    return (
      <UI.Typography
        variant="subtitle2"
        sx={{
          display: "flex",
          alignItems: "center",
          flexDirection: "row",
        }}
        color="error.main"
        {...props}
      >
        <Icons.Cancel sx={{ mr: "4px" }} /> Cancelled
      </UI.Typography>
    );
  } else if (session.signedUp) {
    return (
      <UI.Typography
        variant="subtitle2"
        sx={{
          display: "flex",
          alignItems: "center",
          flexDirection: "row",
        }}
        color="success.main"
        {...props}
      >
        <Icons.CheckCircle sx={{ mr: "4px" }} /> You're going
      </UI.Typography>
    );
  } else if (u.session.isOlderPeopleSession(session)) {
    // you can't sign up to an already confirmed mission
    return null;
  } else if (
    u.time.isBefore(session.startedAt, session.registerDeadline || new Date())
  ) {
    return (
      <UI.Typography
        variant="subtitle2"
        sx={{
          display: "flex",
          alignItems: "center",
          flexDirection: "row",
        }}
        color="error.main"
        {...props}
      >
        <Icons.Cancel sx={{ mr: "4px" }} />{" "}
        {session.registerDeadline
          ? "Too late to register"
          : "Session is in the past"}
      </UI.Typography>
    );
  } else if (
    session.registerMax &&
    session.signups.length >= session.registerMax
  ) {
    return (
      <UI.Typography
        variant="subtitle2"
        sx={{
          display: "flex",
          alignItems: "center",
          flexDirection: "row",
        }}
        color="error.main"
        {...props}
      >
        <Icons.Cancel sx={{ mr: "4px" }} /> No spaces left
      </UI.Typography>
    );
  } else {
    return null;
  }
};

export default SignupStatus;
