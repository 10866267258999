/** @jsxImportSource @emotion/react */
import * as UI from "@goodgym/components";
import * as Icons from "@goodgym/icons";
import * as u from "@goodgym/util";
import React from "react";
import { useLocation } from "react-router-dom";

export type HomeProps = {};

const Home: React.FC<HomeProps> = ({}) => {
  const { pathname } = useLocation();
  return (
    <UI.NavbarButton
      icon={Icons.Home}
      label="Home"
      active={pathname === "/v3"}
      to={u.links.home()}
    />
  );
};

export default Home;
