/** @jsxImportSource @emotion/react */
import { useMarkAllNotificationsAsReadMutation } from "@goodgym/graphql/hooks";
import * as T from "@goodgym/graphql/types";
import * as UI from "@goodgym/components";
import * as u from "@goodgym/util";

export type MarkAllNotificationsAsReadMutationHook =
  (params?: {}) => React.MouseEventHandler;

export const useMarkAllNotificationsAsRead: MarkAllNotificationsAsReadMutationHook =
  () => {
    const [markAllNotificationsAsRead] = useMarkAllNotificationsAsReadMutation({
      onError: console.log,
      refetchQueries: [T.NotificationsDocument],
      awaitRefetchQueries: true,
    });

    return (e) => {
      e?.preventDefault();
      markAllNotificationsAsRead();
    };
  };
