import React, { PropsWithChildren } from "react";
import * as T from "@goodgym/graphql/types";
import { useDrawer } from "@goodgym/hooks";
import * as UI from "@goodgym/components";
import { Navigate, useNavigate } from "react-router-dom";
import SessionPage from "@goodgym/pages/SessionPage";
import ReportPage from "@goodgym/pages/ReportPage";
import StoryPage from "@goodgym/pages/StoryPage";
import * as u from "@goodgym/util";
import { useAuth } from "@goodgym/auth";

type Drawers = {
  session: Optional<T.SessionFragment>;
  showSession: (
    e: React.MouseEvent<Element>,
    s: T.SessionFragment | T.SessionCardFragment
  ) => any;
  report: Optional<T.ReportFragment>;
  showReport: (e: React.MouseEvent<Element>, r: T.ReportFragment) => any;
  story: Optional<T.StoryFragment>;
  showStory: (e: React.MouseEvent<Element>, r: T.StoryFragment, drafted?:boolean, deleted?:boolean) => any;
};

export const Context = React.createContext<Drawers>({
  session: null,
  showSession: () => null,
  report: null,
  showReport: () => null,
  story: null,
  showStory: () => null,
});

export const useDrawers = () => {
  return React.useContext(Context);
};

export const DrawersProvider: React.FC<PropsWithChildren<{}>> = ({
  children,
}) => {
  const { runner } = useAuth();
  const theme = UI.useTheme();
  const md = UI.useMediaQuery(theme.breakpoints.up("md"));
  const navigate = useNavigate();

  const reportDrawer = useDrawer();
  const [report, setReport] = React.useState<Optional<T.ReportFragment>>(null);
  const showReport = (
    e: React.MouseEvent<Element>,
    report: T.ReportFragment
  ) => {
    if (u.isDevOrBeta && md) {
      navigate(u.links.report(report));
    } else if (u.isDevOrBeta) {
      setReport(report);
      reportDrawer.onClick(e);
    } else {
      window.location.href = u.links.report(report);
    }
    e.preventDefault();
  };

  const storyDrawer = useDrawer();
  const [story, setStory] = React.useState<Optional<T.StoryFragment>>(null);
  const showStory = (e: React.MouseEvent<Element>, story: T.StoryFragment, drafted?:boolean) => {
    if (md) {
      navigate(u.links.story(story));
    } else if (!md) {
      setStory(story);
      storyDrawer.onClick(e);
      if (drafted) {
        navigate(u.links.draftedStory(story));
      }
    } else {
      window.location.href = u.links.story(story);
    }
    e.preventDefault();
  };

  const sessionDrawer = useDrawer();
  const [session, setSession] =
    React.useState<Optional<T.SessionFragment>>(null);

  const showSession = (
    e: React.MouseEvent<Element>,
    session: T.SessionFragment | T.SessionCardFragment
  ) => {
    if ("report" in session && !!session.report?.publishedAt) {
      // if there's a published report, let's show that instead
      return showReport(e, session.report);
    }
    const showNewPage = runner?.featureFlags.includes("session_page");
    if ((u.isDevOrBeta || showNewPage) && md) {
      navigate(u.links.newSession(session));
    } else if ((u.isDevOrBeta || showNewPage) && !md) {
      setSession(session);
      sessionDrawer.onClick(e);
    } else {
      window.location.href = u.links.session(session);
    }
    e.preventDefault();
  };

  if (md && sessionDrawer.open && session) {
    // if we've open the session drawer with the screen at sm or below,
    // then enlarged the screen, we should redirect to the sessions page
    return <Navigate to={u.links.newSession(session)} />;
  }

  if (md && reportDrawer.open && report) {
    // if we've open the report drawer with the screen at sm or below,
    // then enlarged the screen, we should redirect to the reports page
    return <Navigate to={u.links.report(report)} />;
  }

  if (md && storyDrawer.open && story) {
    // if we've open the story drawer with the screen at sm or below,
    // then enlarged the screen, we should redirect to the stories page
    return <Navigate to={u.links.story(story)} />;
  }

  return (
    <Context.Provider
      value={{ session, showSession, report, showReport, showStory, story }}
    >
      {children}

      <UI.Drawer
        anchor="left"
        open={sessionDrawer.open}
        onClose={sessionDrawer.onClose}
        sx={{ display: { xs: "block", md: "none" } }}
      >
        {session && (
          <>
            <UI.DrawerHeader
              title={session.title}
              anchor="left"
              onClose={sessionDrawer.onClose}
              open={sessionDrawer.open}
            />
            <SessionPage slug={session.slug} panel sx={{ pt: "34px" }} />
          </>
        )}
      </UI.Drawer>

      <UI.Drawer
        anchor="left"
        open={reportDrawer.open}
        onClose={reportDrawer.onClose}
        sx={{ display: { xs: "block", md: "none" } }}
      >
        {report && (
          <>
            <UI.DrawerHeader
              title={report.title}
              anchor="left"
              onClose={reportDrawer.onClose}
              open={reportDrawer.open}
            />
            <ReportPage slug={report.slug} panel sx={{ pt: "34px" }} />
          </>
        )}
      </UI.Drawer>
      <UI.Drawer
        anchor="left"
        open={storyDrawer.open}
        onClose={storyDrawer.onClose}
        sx={{ display: { xs: "block", md: "none" } }}
      >
        {story && (
          <>
            <UI.DrawerHeader
              title={story.title}
              anchor="left"
              onClose={storyDrawer.onClose}
              open={storyDrawer.open}
            />
            <StoryPage slug={story.slug} panel sx={{ pt: "34px" }} />
          </>
        )}
      </UI.Drawer>
    </Context.Provider>
  );
};
