export const daysOfWeek = [
  "Monday",
  "Tuesday",
  "Wednesday",
  "Thursday",
  "Friday",
  "Saturday",
  "Sunday",
  "Weekends",
  "Weekdays",
];

export const timeIntervals = [
  "0800",
  "0830",
  "0900",
  "0930",
  "1000",
  "1030",
  "1100",
  "1130",
  "1200",
  "1230",
  "1300",
  "1330",
  "1400",
  "1430",
  "1500",
  "1530",
  "1600",
  "1630",
  "1700",
  "1730",
  "1800",
  "1830",
  "1900",
  "1930",
  "2000",
];

//Given 24 hr time format, this function will return 12 hr time format
export const convertTo12HourFormat = (time24: string) => {
  const hours = parseInt(time24.substring(0, 2));
  const minutes = time24.substring(2);

  const period = hours >= 12 ? "PM" : "AM";

  // Convert hours to 12-hour format
  const hours12 = hours % 12 || 12;
  const time12 = `${hours12.toString()}:${minutes
    .toString()
    .padStart(2, "0")} ${period}`;

  return time12;
};
