import React from "react";
import axios from "axios";

export const useCSRFToken = () => {
  const [token, setToken] = React.useState<string>("");
  React.useEffect(() => {
    (async () => {
      const { data } = await axios.get("/csrf");
      setToken(data);
    })();
  }, [setToken]);

  return token;
};
