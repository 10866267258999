/** @jsxImportSource @emotion/react */
import React from "react";
import * as Icons from "@goodgym/icons";
import * as UI from "@goodgym/components";
import * as T from "@goodgym/graphql/types";
import * as u from "@goodgym/util";
import _ from "lodash";

export type CoverImageProps = UI.BoxProps & {
  image: { jpg: string; webp: string };
};

const CoverImage: React.FC<CoverImageProps> = ({ image, ...props }) => {
  const [format, setFormat] = React.useState<"webp" | "jpg">("webp");
  React.useEffect(() => {
    (async () => {
      if (!(await u.detect.webp)) setFormat("jpg");
    })();
  }, [setFormat]);

  return (
    <UI.Box sx={{ position: "relative" }} {...props}>
      <UI.Box
        sx={{
          height: 0,
          position: "relative",
          pt: "66.66%",
          background: `left center / cover no-repeat url(${image[format]})`,
        }}
      />
    </UI.Box>
  );
};

export default CoverImage;
