/** @jsxImportSource @emotion/react */
import { useDeleteStoryMutation } from "@goodgym/graphql/hooks";
import * as T from "@goodgym/graphql/types";
import { useConfirm } from "@goodgym/hooks";
import * as u from "@goodgym/util";
import { useNavigate } from "react-router-dom";

export type DeleteStoryMutationHook = (params: {
  story: T.StoryFragment;
  runner: Optional<T.CurrentRunnerFragment>;
}) => React.MouseEventHandler;

export const useDeleteStory: DeleteStoryMutationHook = ({ story, runner }) => {
  const { confirm } = useConfirm();

  const navigate = useNavigate();

  const [deleteStory] = useDeleteStoryMutation({
    onError: console.log,
    variables: {
      storyId: story.id,
    },
    optimisticResponse: {
      response: {
        __typename: "DeleteStoryPayload",
        story: {
          ...story,
        },
      },
    },
  });

  return (e) => {
    e?.preventDefault();
    if (u.roles.hasRole(runner, "CENTRAL_ADMIN")) {
      confirm({
        title: story.discardedAt ? "Restore Story?" : "Delete Story",
        text: story.discardedAt
          ? "Are you sure you want to restore this story?"
          : "Are you sure you want to delete this story?",
        onConfirm: () => {
          deleteStory();
          navigate(u.links.stories());
        },
      });
    }
  };
};
