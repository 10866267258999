/** @jsxImportSource @emotion/react */
import { useUndeclineTaskRequestMutation } from "@goodgym/graphql/hooks";
import * as T from "@goodgym/graphql/types";
import * as UI from "@goodgym/components";
import * as u from "@goodgym/util";

export type UndeclineTaskRequestMutationHook = (params: {
  taskRequest: T.TaskRequestCardFragment;
}) => React.MouseEventHandler;

export const useUndeclineTaskRequest: UndeclineTaskRequestMutationHook = ({
  taskRequest,
}) => {
  const [undeclineTaskRequest] = useUndeclineTaskRequestMutation({
    onError: console.log,
    optimisticResponse: {
      response: {
        taskRequest: { ...taskRequest, declined: false },
        __typename: "UndeclineTaskRequestMutationPayload",
      },
    },
  });

  return (e) => {
    e?.preventDefault();
    undeclineTaskRequest({
      variables: { taskRequestId: taskRequest.id },
    });
  };
};
