import * as UI from "@goodgym/components";
import theme from "@goodgym/theme";
import GoodGym from  "./GoodGymPolicy.pdf";

export type IntroProps = {};

const Intro: React.FC<IntroProps> = () => {
  return (
    <>
      <UI.Container
        sx={{
          [theme.breakpoints.down("sm")]: {
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            textAlign: "center",
          },

          padding: 5,
        }}
      >
        <UI.Typography
          sx={{
            typography: { xs: "subtitle1", md: "h3" },
            fontStyle: { md: "normal" },
            display: "flex",
            justifyContent: "flex-start",
            alignItems: "center",
          }}
        >
          Introduction
        </UI.Typography>
        <br />
        <UI.Typography
          sx={{
            typography: { sm: "body1", md: "h5" },
            fontWeight: { md: "normal" },
          }}
        >
         <p>Welcome to the GoodGym's privacy policy.</p> 
          
          <p>GoodGym respects your privacy and is committed to protecting your
          personal data. This privacy policy will inform you as to how we look
          after your personal data when you visit our Website (regardless of
          where you visit it from) and tell you about your privacy rights and
          how the law protects you.</p>
        </UI.Typography>
        <a
          href={GoodGym}
          download="GoodGym"
          style={{
            textDecoration: "none",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          {" "}
          <UI.Button sx={{marginTop:2}}>
            Download Privacy Policy
          </UI.Button>
        </a>
      </UI.Container>
    </>
  );
};

export default Intro;
