/** @jsxImportSource @emotion/react */
import React from "react";
import * as Icons from "@goodgym/icons";
import * as UI from "@goodgym/components";
import * as T from "@goodgym/graphql/types";
import * as u from "@goodgym/util";
import _ from "lodash";
import {
  useJoinWaitingList,
  useUnSignUpFromSession,
} from "@goodgym/graphql/mutations";
import { useAuth } from "@goodgym/auth";

export type SecondaryActionsProps = UI.BoxProps & {
  session: T.SessionPageQuery["session"];
};

const SecondaryActions: React.FC<
  React.PropsWithChildren<SecondaryActionsProps>
> = ({ session, ...props }) => {
  const { runner } = useAuth();
  const cancelSignup = useUnSignUpFromSession();
  const joinWaitingList = useJoinWaitingList();

  if (!runner) {
    return null;
  } else if (u.session.isOlderPeopleSession(session)) {
    return null;
  } else if (session.signedUp) {
    return (
      <UI.Box {...props}>
        <UI.Typography variant="body2" sx={{ color: "neutral.main", mb: 1 }}>
          Can't make it?{" "}
          <UI.Link
            color="inherit"
            sx={{ textDecoration: "underline" }}
            onClick={cancelSignup(session)}
          >
            Let us know.
          </UI.Link>
        </UI.Typography>
      </UI.Box>
    );
  } else if (
    session.registerMax &&
    session.registerMax <= session.signups.length &&
    !session.onWaitingList
  ) {
    return (
      <UI.Box {...props}>
        <UI.Typography variant="body2" sx={{ color: "neutral.main", mb: 1 }}>
          No spaces left.{" "}
          <UI.Link
            color="inherit"
            sx={{ textDecoration: "underline" }}
            onClick={joinWaitingList(session)}
          >
            Get an email if a space opens up.
          </UI.Link>
        </UI.Typography>
      </UI.Box>
    );
  } else {
    return null;
  }
};

export default SecondaryActions;
