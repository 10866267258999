import React from "react";
import { useAuth } from "@goodgym/auth";
import * as T from "@goodgym/graphql/types";
import { useLocalStorage } from "usehooks-ts";

import _ from "lodash";

export const useReportsPage = () => {
  const [area, setArea] = useLocalStorage<Optional<T.AreaFragment>>(
    "area",
    null
  );
  const { runner } = useAuth();
  const [sessionType, setSessionType] = React.useState<Optional<string>>("All");
  const [includeRunner, setIncludeRunner] = React.useState(false);
  const [showFilter, setShowFilter] = React.useState(false);
  
  React.useEffect(() => {
    if (!_.isEqual(area?.id, area?.id)) {
      setArea(area);
    }
  }, [area, setArea, runner]);


  const toggleFilter = () => setShowFilter(!showFilter);

  return {
    toggleFilter,
    showFilter,
    area,
    setArea,
    sessionType: sessionType === "All" ? null : sessionType,
    setSessionType,
    includeRunner,
    setIncludeRunner,
    runner: includeRunner ? runner : null,
  };
};
