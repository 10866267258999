/** @jsxImportSource @emotion/react */
import React from "react";
import * as Icons from "@goodgym/icons";
import * as UI from "@goodgym/components";
import * as T from "@goodgym/graphql/types";
import * as u from "@goodgym/util";
import _ from "lodash";
import * as menus from "@goodgym/menus";

const MorePage = () => {
  return (
    <UI.Container sx={{ py: 3 }}>
      <UI.Seo title="More" url="/v3/more" />
      <UI.Grid container spacing={1}>
        {menus.more.map(({ key, label, links }) => (
          <UI.Grid key={key} item xs={12} md={4}>
            <UI.Typography variant="subtitle1" color="neutral.dark">
              {label}
            </UI.Typography>
            <UI.List>
              {links.map(({ key, label, icon, ...props }) => (
                <UI.ListItem
                  key={key}
                  sx={{
                    px: 0,
                    borderBottom: "1px solid #efefef",
                  }}
                >
                  <UI.ListItemButton
                    disableGutters
                    component={UI.Link}
                    {...props}
                    sx={{ pr: 1 }}
                  >
                    <UI.ListItemIcon>{icon}</UI.ListItemIcon>
                    <UI.ListItemText
                      sx={{ color: "neutral.dark" }}
                      primary={label}
                    />
                  </UI.ListItemButton>
                </UI.ListItem>
              ))}
            </UI.List>
          </UI.Grid>
        ))}
      </UI.Grid>
    </UI.Container>
  );
};

export default MorePage;
