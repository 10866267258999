/** @jsxImportSource @emotion/react */
import React from "react";
import * as UI from "@goodgym/components";

export type LoadingProps = { text?: Optional<string>; fullScreen?: boolean };

const Loading: React.FC<LoadingProps> = ({ text, fullScreen = false }) =>
  fullScreen ? (
    <UI.Backdrop open={true}>
      {text && (
        <UI.Typography variant="h5" sx={{ color: "neutral.main", mb: 3 }}>
          {text}
        </UI.Typography>
      )}
      <UI.CircularProgress />
    </UI.Backdrop>
  ) : (
    <UI.Box sx={{ textAlign: "center", my: 2 }}>
      {text && (
        <UI.Typography variant="h5" sx={{ color: "neutral.main", mb: 3 }}>
          {text}
        </UI.Typography>
      )}
      <UI.CircularProgress />
    </UI.Box>
  );

export default Loading;
