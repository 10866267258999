import {
  ApolloClient,
  ApolloLink,
  createHttpLink,
  InMemoryCache,
} from "@apollo/client";
import { setContext } from "@apollo/client/link/context";
import _ from "lodash";
import { onError } from "@apollo/client/link/error";
import * as Sentry from "@sentry/react";
import { Severity } from "@sentry/react";
import fetch from "cross-fetch";
import * as u from "@goodgym/util";
import fragmentMatcher from "./fragmentMatcher";
declare global {
  interface Window {
    __APOLLO_STATE__: any;
  }
}

const state = u.isServer ? {} : window.__APOLLO_STATE__;

const http = (host: string, cookie?: string) =>
  createHttpLink({
    uri: `${host}/graphql`,
    fetch,
    headers: cookie ? { cookie } : {},
  });

const error = onError(({ graphQLErrors, networkError }) => {
  if (graphQLErrors)
    graphQLErrors.forEach(({ message, locations, path }) =>
      Sentry.captureMessage(message, {
        level: Severity.Error,
        extra: { locations, path },
      })
    );

  if (networkError) Sentry.captureException(networkError);
});

const csrf = (csrfToken: string = "") =>
  setContext((_, { headers }) => {
    return {
      headers: {
        ...headers,
        "X-CSRF-Token": csrfToken,
      },
    };
  });

export const client = ({
  ssrMode = false,
  cookie = undefined,
  csrfToken = undefined,
  host = "",
} = {}) =>
  new ApolloClient({
    ssrMode,
    link: ApolloLink.from([error, csrf(csrfToken), http(host, cookie)]),
    cache: new InMemoryCache({
      possibleTypes: fragmentMatcher.possibleTypes,
      typePolicies: {
        Query: {
          fields: {
            newsfeed: {
              keyArgs: ["type", "runnerId"],
            },
            reports: {
              keyArgs: ["areaId", "organisationId", "sessionType", "runnerId"],
            },
            sessionsV2: {
              keyArgs: ["types", "areaIds", "postcode", "maxDistance", "from"],
            },
            taskRequests: {
              keyArgs: ["types", "areaIds", "postcode", "maxDistance", "from"],
            },
            stories: {
              keyArgs: [],
            },
            deletedStories: {
              keyArgs: [],
            },
            draftedStories: {
              keyArgs: [],
            },
          },
        },
        Newsfeed: {
          fields: {
            items: {
              merge(existing = [], incoming) {
                return _.unionBy(existing, incoming, "__ref");
              },
            },
          },
        },
        SessionsResultV2: {
          fields: {
            results: {
              merge(existing = [], incoming) {
                return _.unionBy(existing, incoming, "__ref");
              },
            },
          },
        },
        TaskRequestsResult: {
          fields: {
            results: {
              merge(existing = [], incoming) {
                return _.unionBy(existing, incoming, "__ref");
              },
            },
          },
        },
        ReportsResult: {
          fields: {
            reports: {
              merge(existing = [], incoming) {
                return _.unionBy(existing, incoming, "__ref");
              },
            },
          },
        },
        StoriesResult: {
          fields: {
            stories: {
              merge(existing = [], incoming) {
                return _.unionBy(existing, incoming, "__ref");
              },
            },
          },
        },
      },
    }).restore(state),
    credentials: "same-origin",
  });
