/** @jsxImportSource @emotion/react */
import React from "react";
import * as Icons from "@goodgym/icons";
import * as UI from "@goodgym/components";
import * as T from "@goodgym/graphql/types";
import * as u from "@goodgym/util";
import _ from "lodash";

export type StreaksProps = UI.GridProps & {
  runner: T.ProfilePageQuery["runner"];
};

const Streaks: React.FC<React.PropsWithChildren<StreaksProps>> = ({
  runner,
  ...props
}) => {
  return (
    <UI.Grid {...props} container sx={{ ...props.sx, mt: 3, mb: 3 }}>
      {runner.streaks.map((streak) => {
        return (
          <UI.Grid
            item
            key={streak.id}
            xs={(12 / runner.streaks.length) as any}
          >
            <UI.RunnerStreak runnerStreak={streak} />
          </UI.Grid>
        );
      })}
    </UI.Grid>
  );
};

export default Streaks;
