/** @jsxImportSource @emotion/react */
import { useDeclineTaskRequestMutation } from "@goodgym/graphql/hooks";
import * as T from "@goodgym/graphql/types";
import * as UI from "@goodgym/components";
import * as u from "@goodgym/util";
import { useConfirm } from "@goodgym/hooks";

export type DeclineTaskRequestMutationHook = (params: {
  taskRequest: T.TaskRequestCardFragment;
}) => React.MouseEventHandler;

export const useDeclineTaskRequest: DeclineTaskRequestMutationHook = ({
  taskRequest,
}) => {
  const { confirm } = useConfirm();
  const [declineTaskRequest] = useDeclineTaskRequestMutation({
    onError: console.log,
    variables: { taskRequestId: taskRequest.id },
    optimisticResponse: {
      response: {
        taskRequest: { ...taskRequest, declined: true },
        __typename: "DeclineTaskRequestMutationPayload",
      },
    },
  });

  return (e) => {
    e?.preventDefault();
    confirm({
      title: "Decline request?",
      text: "Are you sure you want to decline this task request?",
      onConfirm: () => declineTaskRequest(),
    });
  };
};
