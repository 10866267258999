import * as I from "./images";

export const BannerData = {
  title: "Request practical support for an older person",
  tagline:
    "Organisations working with older people aged over 50 can get support from GoodGym volunteers with practical household tasks.",
  buttonText: "Make a request",
  image: I.MissionRunBannerImage,
  linkForButton:"/referrers"
};

export const IconData = [
  {
    image: I.lightBulbIcon,
    tagline: "We can change lightbulb or smoke alarm batteries",
  },
  {
    image: I.phoneIcon,
    tagline: "We can support with technology",
  },
  {
    image: I.liftingIcon,
    tagline: "We can move furniture and other heavy household items",
  },
];

export const workIconData = [
  {
    image: I.moneyIcon,
    title: "It's free",
    tagline:
      "Our tasks are carried out by volunteers so you don't have to pay.",
  },
  {
    image: I.lockIcon,
    title: "It's safe",
    tagline: "GoodGym volunteers are background checked and trained.",
  },
  {
    image: I.timeIcon,
    title: "It's flexible",
    tagline: "You can choose the time and date.",
  },
];

export const checkListItemsData = [
  {
    listItem:
      "Gardening only for safety, access, or significant impact on health or wellbeing",
    checkOrCancelIcon: true,
  },
  {
    listItem: "Food shopping, foodbank and prescription deliveries",
    checkOrCancelIcon: true,
  },
  {
    listItem: "Tasks to facilitate hospital discharge",
    checkOrCancelIcon: true,
  },
  {
    listItem: "Light bulb/smoke alarm battery changing",
    checkOrCancelIcon: true,
  },
  {
    listItem: "Face to face welfare checks",
    checkOrCancelIcon: true,
  },
  {
    listItem: "One-off dog walking",
    checkOrCancelIcon: true,
  },
  {
    listItem: "Furniture assembly, hanging curtains etc",
    checkOrCancelIcon: true,
  },
];

export const cancelListItemsData = [
  {
    listItem: "Skilled tasks (e.g. plumbing)",
    checkOrCancelIcon: false,
  },
  {
    listItem: "Cleaning or painting indoors",
    checkOrCancelIcon: false,
  },
  {
    listItem: "Handling people’s financial or personal affairs",
    checkOrCancelIcon: false,
  },
  {
    listItem: "Caring for older people",
    checkOrCancelIcon: false,
  },
  {
    listItem: "Befriending",
    checkOrCancelIcon: false,
  },
  {
    listItem: "Any tasks for people under the age of 50",
    checkOrCancelIcon: false,
  },
];