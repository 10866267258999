import * as T from "@goodgym/graphql/types";
import * as Icons from "@goodgym/icons";

export const isCommunitySession = (session: T.SessionFragment) => {
  return [
    "group run",
    "community mission",
    "GroupRun",
    "CommunityMission",
  ].includes(session.type);
};

export const isOlderPeopleSession = (session: T.SessionFragment) => {
  return ["mission", "Mission"].includes(session.type);
};

export const isGoodDeed = (session: T.SessionFragment) => {
  return isCommunitySession(session) || isOlderPeopleSession(session);
};

export const isCommunityMission = (session: T.SessionFragment) => {
  return ["community mission", "CommunityMission"].includes(session.type);
};

export const groupFitnessActivityIcon = (
  gfa: T.GroupFitnessActivityFragment
): React.FC => {
  switch (gfa.type) {
    case "Run":
      return Icons.DirectionsRun;
    case "Walk":
      return Icons.DirectionsWalk;
    case "Ride":
      return Icons.DirectionsBike;
    default:
      return Icons.DirectionsRun;
  }
};
