import * as Images from "../images";
import * as Icons from "../../images";
import Looks3RoundedIcon from "@mui/icons-material/Looks3Rounded";
import LooksOneRoundedIcon from "@mui/icons-material/LooksOneRounded";
import LooksTwoRoundedIcon from "@mui/icons-material/LooksTwoRounded";

export const taskIconData = [
  {
    image: Icons.ShoeIcon,
    tagline: "Increase your motivation to exercise",
  },
  {
    image: Icons.IconHeart,
    tagline: "Make a difference to someone's life",
  },
  {
    image: Icons.ThumbsupIcon,
    tagline: "Make a connection and feel great",
  },
];

export const travelThereData = [
  {
    image: Icons.RunningIcon,
    tagline: "Travel there: run, walk or cycle",
    icon: LooksOneRoundedIcon,
  },
  {
    image: Icons.IconHeart,
    tagline: "Hang out",
    icon: LooksTwoRoundedIcon,
  },
  {
    image: Icons.RunningIcon,
    tagline: "Travel back",
    icon: Looks3RoundedIcon,
  },
];

export const ImpactTestimonials = [
  {
    avatarUrl: Images.Coach1,
    quote:
      "I get very lonely and don't see many people, so it's wonderful to have something to look forward to. The world can get very dark, these days, and it's nice to know there are good people out there still.",
    by: "Mrs S from W&M",
  },
  {
    avatarUrl: Images.Coach2,
    quote:
      "It has made a tremendous difference. We have a little chat, a cold drink and a game of table tennis. I get very lonely and the visits are very important to me.",
    by: "Mr C from W&M",
  },
  {
    avatarUrl: Images.Coach3,
    quote:
      "There are no words that can convey how blessed and lucky I feel to have met you and all the wonderful people that you have introduced me to.",
    by: "Ms K from W&M",
  },
];

export const CoachTestimonials = [
  {
    avatarUrl: Images.Sheila,
    quote:
      "I was very lonely. You've opened up a whole new world to me. Being a runner's coach is wonderful. They've given me the confidence to get out there again.",
    by: "Sheila - GoodGym Coach",
  },
  {
    avatarUrl: Images.Kevin,
    quote:
      "I’ve never been a morning exerciser. Being paired up with Connie has given me that motivation to start the day with a run. Getting to know her has been a breeze compared to the run there!",
    by: "Kevin - GoodGymer",
  },
  {
    avatarUrl: Images.John,
    quote:
      "The first question Flo asks me every Friday morning when I turn up at her flat is, 'Do you know me?' This is then normally followed by “How did you know I was here?",
    by: "John - GoodGymer",
  },
];

export const FaqData = [
  {
    heading: "What's expected of me?",
    content:
      "30-60 minutes once a week. You can still miss visits if you’re on holiday or ill but you should be there more often than not.",
  },
  {
    heading: "  How much does it cost?",
    content:
      "  GoodGym is free. Most of us make a monthly donation to help cover the cost of running our charity, but no one has to.",
  },
  {
    heading: " What is involved?",
    content:
      "Online training, DBS check, online training call and 2 references. This should take approximately four hours of your time.",
  },
];
