/** @jsxImportSource @emotion/react */
import * as UI from "@goodgym/components";
import { useReportsPageQuery } from "@goodgym/graphql/hooks";
import * as Icons from "@goodgym/icons";
import * as u from "@goodgym/util";
import * as C from "./components";
import { useReportsPage } from "./hooks";

const ReportsPage = () => {
  const { error, data } = useReportsPageQuery({
    variables: {},
  });
  const theme = UI.useTheme();
  const md = UI.useMediaQuery(theme.breakpoints.up("md"));
  const state = useReportsPage();
  const { toggleFilter, showFilter, area, sessionType, runner } = state;
  if (error) return <UI.Error error={error} />;
  if (!data) return <UI.Loading />;


  
  return (
    <>
      <UI.Seo title="Reports" url="/v3/reports" />
      <UI.Box
        sx={{
          display: { xs: "none", md: "block" },
          backgroundColor: "primary.main",
          position: "fixed",
          top: "64px",
          left: 0,
          right: 0,
          py: 2,
          zIndex: 3,
        }}
      >
        <UI.Container>
          <UI.Breadcrumbs
            crumbs={[
              ["Home", u.links.home()],
              ["Reports", u.links.reports()],
            ]}
            sx={{ mb: 1 }}
          />
          <UI.Typography variant="h1">Reports</UI.Typography>
        </UI.Container>
      </UI.Box>

      {/* Mobile header */}
      <UI.Container
        sx={{
          display: { xs: "block", md: "none" },
          pt: 2,
          pb: 2,
          position: "fixed",
          top: { xs: "56px", sm: "64px" },
          background: "white",
          zIndex: 2,
          borderBottom: "1px solid #d7d7d7",
        }}
      >
        <UI.Box display="flex" justifyContent={"space-between"}>
          <UI.Typography
            variant="h3"
            sx={{ whiteSpace: "nowrap", alignSelf: "center" }}
          >
            Reports
          </UI.Typography>
          <UI.Button variant="outlined" color="neutral" onClick={toggleFilter}>
            <Icons.FilterList sx={{ mr: "4px" }} />
            Filters
          </UI.Button>
        </UI.Box>
      </UI.Container>

      {/* Body with reports */}
      <UI.Container sx={{ mt: md ? 15 : 7 }}>
        {/* Mobile filters */}
        <UI.Drawer
          anchor="right"
          open={showFilter}
          sx={{ display: { xs: "block", md: "none" } }}
        >
          <C.ReportsFilter state={state} />
          <UI.Button
            variant="outlined"
            color="neutral"
            onClick={toggleFilter}
            sx={{ mx: 2 }}
          >
            Apply
          </UI.Button>
        </UI.Drawer>
        {/* Desktop filters */}
        <UI.Box sx={{ display: { xs: "none", md: "inline" } }}>
          <C.ReportsFilter state={state} />
        </UI.Box>
        <UI.ReportsFeed
          sx={{ pt: 7 }}
          area={area}
          sessionType={sessionType}
          runner={runner}
        />
      </UI.Container>
    </>
  );
};

export default ReportsPage;
