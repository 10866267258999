/** @jsxImportSource @emotion/react */
import { useRescheduleTestPairingMutation } from "@goodgym/graphql/hooks";
import * as T from "@goodgym/graphql/types";
import { useConfirm } from "@goodgym/hooks";
import React from "react";
import { useNavigate } from "react-router-dom";

export type RescheduleProps = (params: {
  coach: Pick<T.CoachReferralFragment, "id" | "name">;
  testPairingId: string;
}) => React.MouseEventHandler;

export const useRescheduleTestPairing: RescheduleProps = ({testPairingId, coach }) => {
 
  const { confirm } = useConfirm();
  
  const navigate = useNavigate();
  
  const [RescheduleTestPairing, { data }] =
    useRescheduleTestPairingMutation({
      onError: console.log,
      variables: {
        id: testPairingId,
      },
      optimisticResponse: {
        response: {
          __typename: "CancelTestPairingMutationPayload",
          testPairing: {
            __typename: "TestPairing",
            acceptedAt: null,
            completedAt: null,
            id: coach.id,
            notesForRunner: null,
            rejectedAt: null,
            rejectionNotes: null,
            scheduledAt: null,
            cancelledAt: new Date(),
            cancellationNotes: "Reschedule requested by Goodgymer",
            coach: {
              __typename: "Coach",
              id: coach.id,
              name: coach.name,
            },
          },
        },
      },
    });

  return (e) => {
    e?.preventDefault();
    confirm({
      title: "Request to reschedule this trial visit",
      text: "Are you sure you want to reschedule this trial visit?",
      alert: false,
      onConfirm: async () => {
        await RescheduleTestPairing();
        if (data) {
          navigate(`/v3/trial-visits/${data?.response.testPairing?.id}`);
        }
      },
    });
  };
};
