/** @jsxImportSource @emotion/react */
import React from "react";
import * as Icons from "@goodgym/icons";
import * as UI from "@goodgym/components";
import * as T from "@goodgym/graphql/types";
import * as u from "@goodgym/util";
import _ from "lodash";
import * as C from "..";

export type CommentProps = UI.BoxProps & {
  comment: T.CommentFragment;
  commentable: T.CommentableItem;
  runner?: Optional<T.CurrentRunnerFragment>;
};

const Comment: React.FC<CommentProps> = ({
  comment,
  commentable,
  runner,
  ...props
}) => {
  const isDeleted = comment.content === "[This comment has been deleted]";
  const currentRunnerIsCommenter = comment.runner.id === runner?.id;
  const showMenu =
    currentRunnerIsCommenter || u.roles.hasRole(runner, "CENTRAL_ADMIN");
  return (
    <UI.Box
      {...props}
      sx={{
        my: 2,
        display: "flex",
      }}
    >
      <UI.Box sx={{ alignSelf: "top", flexShrink: 0, mr: 1 }}>
        <UI.RunnerAvatar runner={comment.runner} size="medium" />
      </UI.Box>
      <UI.Box
        sx={{
          ml: 1,
          flexGrow: 1,
          borderRadius: 1,
          px: 2,
          py: 1,
          backgroundColor: currentRunnerIsCommenter
            ? "secondary.light"
            : "neutral.light",
        }}
      >
        <UI.Box sx={{ display: "flex", flexWrap: "wrap", mb: 1 }}>
          <UI.Box sx={{ flexGrow: 1 }}>
            <UI.RunnerLink runner={comment.runner} />
          </UI.Box>
          <UI.Box sx={{ display: "flex", flexShrink: 0 }}>
            <UI.Typography variant="body2" sx={{ color: "neutral.main" }}>
              {u.time.formatDateTime(comment.createdAt)}
            </UI.Typography>
            {showMenu && !isDeleted && (
              <C.CommentMenu
                sx={{ ml: 2 }}
                runner={runner}
                commentable={commentable}
                comment={comment}
              />
            )}
          </UI.Box>
        </UI.Box>
        <UI.Typography
          sx={{
            color: isDeleted ? "neutral.dark" : "black.main",
            whiteSpace: "pre-wrap",
          }}
        >
          {comment.content}
        </UI.Typography>
      </UI.Box>
    </UI.Box>
  );
};

export default Comment;
