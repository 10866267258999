/** @jsxImportSource @emotion/react */
import { useDeleteCommentMutation } from "@goodgym/graphql/hooks";
import * as T from "@goodgym/graphql/types";
import * as UI from "@goodgym/components";
import * as u from "@goodgym/util";
import { useConfirm } from "@goodgym/hooks";

export type DeleteCommentMutationHook = (params: {
  runner: Optional<T.CurrentRunnerFragment>;
  commentable: T.CommentableItem;
  comment: T.CommentFragment;
}) => React.MouseEventHandler;

export const useDeleteComment: DeleteCommentMutationHook = ({
  runner,
  commentable,
  comment,
}) => {
  const { confirm } = useConfirm();

  const [deleteComment] = useDeleteCommentMutation({
    onError: console.log,
    variables: {
      commentId: comment.id,
    },
    optimisticResponse: {
      response: {
        __typename: "DeleteCommentMutationPayload",
        commentable: {
          __typename: commentable.__typename,
          id: commentable.id,
          comments: commentable.comments.map((c) =>
            comment.id === c.id
              ? { ...comment, content: "[This comment has been deleted]" }
              : c
          ),
        },
      },
    },
  });

  return (e) => {
    e?.preventDefault();
    if (
      comment.runner.id === runner?.id ||
      u.roles.hasRole(runner, "CENTRAL_ADMIN")
    ) {
      confirm({
        title: "Delete comment?",
        text: "Are you sure you want to delete this comment?",
        onConfirm: () => deleteComment(),
      });
    }
  };
};
