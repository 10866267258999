/** @jsxImportSource @emotion/react */
import { useAuth } from "@goodgym/auth";
import * as UI from "@goodgym/components";
import {
  useTestPairingPageQuery
} from "@goodgym/graphql/hooks";
import * as u from "@goodgym/util";
import CancelIcon from "@mui/icons-material/Cancel";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { useParams } from "react-router-dom";
import AskHelp from "../SocialVisitPage/components/AskHelp";
import Header from "../SocialVisitPage/components/Header";
import {
  TestPairingCancelled,
  TestPairingCompleted,
  TestPairingPending,
  TestPairingRejected,
  TestPairingScheduled,
} from "./components";
const TestPairingPage = () => {
  const params = useParams();
  const id = params.id!;
  const { runner } = useAuth();
  const { error, data } = useTestPairingPageQuery({
    variables: {
      id: id!,
    },
  });

  if (error) return <UI.Error />;
  if (!data) return <UI.Loading />;

  const { testPairing } = data;

  const { cancelledAt, coach, completedAt, rejectedAt, scheduledAt } =
    testPairing;

  const Component = () => {
    if (completedAt) return <TestPairingCompleted testPairing={testPairing} />;
    if (cancelledAt) return <TestPairingCancelled testPairing={testPairing} />;
    if (rejectedAt) return <TestPairingRejected testPairing={testPairing} />;
    if (scheduledAt && !cancelledAt)
      return <TestPairingScheduled testPairing={testPairing} />;
    return <TestPairingPending testPairing={testPairing} />;
  };

  return (
    <>
      <UI.Container sx={{ py: 3 }}>
        <UI.Seo title="Trial visit" url="/v3/trial-visit-page" />
        <UI.Grid container>
          <UI.Typography variant="h1">{`Trial visit with ${coach.name}`}</UI.Typography>
        </UI.Grid>

        <>
          <UI.Box
            sx={{
              pb: { xs: "120px", sm: 0 },
              minHeight: "100vh",
              overflowX: "hidden",
            }}
          >
            <UI.Box sx={{ backgroundColor: "neutral.light" }}>
              <UI.Container>
                <UI.Box
                  sx={{
                    flexDirection: "column",
                    pt: 2,
                    mb: 2,
                  }}
                >
                  <UI.Breadcrumbs
                    crumbs={[
                      ["Home", u.links.home()],
                      ["Social Visits", u.links.socialVisits()],
                      [coach.name, u.links.socialVisit(coach)],
                    ]}
                  />

                  <Header
                    socialVisit={coach}
                    runner={runner}
                    sx={{ mt: 3, display: { xs: "block", md: "none" } }}
                  />
                </UI.Box>
                <UI.Grid columnSpacing={5} container sx={{}}>
                  <UI.Grid item xs={12} sm={6} lg={7}>
                    <Header
                      socialVisit={coach}
                      runner={runner}
                      sx={{
                        display: { xs: "none", md: "block" },
                        mt: 2,
                        mb: 2,
                      }}
                    />
                    <UI.Box
                      sx={{
                        display: "flex",
                        mb: 2,
                        flexDirection: "column",
                      }}
                    >
                      <UI.Box sx={{ display: "flex" }}>
                        <UI.Box
                          sx={{
                            background: "black",
                            width: "fit-content",
                            mb: 2,
                            boxShadow: "-5px 5px 0 -1px #E11018",
                          }}
                        >
                          <UI.Typography
                            sx={{
                              mb: 0.5,
                              px: 1,
                              fontStyle: "normal",
                              fontFamily: "'Barlow Condensed', sans-serif",
                              textTransform: "uppercase",
                            }}
                            color="common.white"
                          >
                            {coach.area.name}
                          </UI.Typography>
                        </UI.Box>
                      </UI.Box>
                      {runner && (
                        <UI.Typography variant="subtitle1" sx={{ my: 2 }}>
                          <UI.Emoji emoji="mapPin" sx={{ mr: 0 }} />
                          {coach.distanceFromRunner}
                        </UI.Typography>
                      )}
                    </UI.Box>
                  </UI.Grid>
                  <UI.Grid item xs={12} sm={6} lg={5}>
                    <UI.Box
                      sx={{
                        height: {
                          xs: "100%",
                        },
                        minHeight: "300px",
                        width: { xs: "100%" },
                        position: "relative",
                      }}
                    >
                      <UI.TaskRequestMap taskRequest={coach} />
                    </UI.Box>
                  </UI.Grid>
                </UI.Grid>
              </UI.Container>
            </UI.Box>
            <UI.Box sx={{ backgroundColor: "white.main" }}>
              <UI.Container>
                <UI.Grid columnSpacing={5} container sx={{ pt: 3, mb: 2 }}>
                  <UI.Grid xs={12} md={6} lg={8} item>
                    <UI.Typography variant="h5" sx={{ mt: 3, mb: 2 }}>
                      About this social visit
                    </UI.Typography>
                    <UI.Box
                      sx={{ mb: 2 }}
                      className="gg-markdown"
                      dangerouslySetInnerHTML={{
                        __html: coach.reasonForReferral,
                      }}
                    />
                    <UI.Box
                      sx={{ mb: 2 }}
                      className="gg-markdown"
                      dangerouslySetInnerHTML={{
                        __html: coach.interests,
                      }}
                    />
                  </UI.Grid>
                  <UI.Grid xs={12} md={6} lg={8} item>
                    <UI.Typography variant="h5" sx={{ mt: 3, mb: 2 }}>
                      Additional information
                    </UI.Typography>
                    <UI.Typography>{coach.additionalInfo}</UI.Typography>
                    {coach.homeRiskAssesed ? (
                      <UI.Typography>
                        <CheckCircleIcon /> This property was assessed by the
                        referrer.
                      </UI.Typography>
                    ) : (
                      <UI.Typography>
                        <CancelIcon /> This property has not been assessed by
                        the referrer.
                      </UI.Typography>
                    )}
                  </UI.Grid>
                </UI.Grid>
                <UI.Divider sx={{ mx: { xs: -2, sm: 0 } }} />
                <UI.Grid container spacing={4} sx={{ my: 1 }}>
                  {" "}
                  <UI.Grid item xs={12} md={6} lg={6}>
                    <Component />
                  </UI.Grid>
                  <UI.Grid item xs={12} md={6} lg={6}>
                    <UI.Box>
                      <AskHelp coachReferral={coach} />
                    </UI.Box>
                  </UI.Grid>
                </UI.Grid>
                <UI.Divider sx={{ mx: { xs: -2, sm: 0 } }} />
                <UI.Grid container spacing={2} sx={{ my: 2, mb: 4 }}>
                  <UI.Grid item xs={12} md={6} xl={8}>
                    <UI.Box>
                      <UI.Typography variant="h5" sx={{ mt: 3, mb: 2 }}>
                        What are social visits?
                      </UI.Typography>
                      <UI.Typography>
                        Social visits are{" "}
                        <span style={{ color: "red" }}>30-60 minute</span>{" "}
                        friendly visits. They are informal weekly in person
                        chats that help combat loneliness and isolation. You are
                        there to provide company to help brighten up that
                        person’s day.
                      </UI.Typography>
                      <UI.Typography variant="h5" sx={{ mt: 3, mb: 2 }}>
                        How does it work?
                      </UI.Typography>
                      <UI.Typography>
                        Choose an older person and sign up to a test visit to
                        meet them. If the first visit goes well, you can then
                        decide if you want to turn this into a longer term
                        pairing and keep visiting every week. If you would like
                        to find out more about social visits, please visit our{" "}
                        <UI.Link
                          href={
                            "https://goodgym.zendesk.com/hc/en-gb/categories/16929787801618-Social-Visits"
                          }
                        >
                          FAQs
                        </UI.Link>{" "}
                        or email{" "}
                        <UI.Link href={`mailto:${"coachteam@goodgym.org"}`}>
                          coachteam@goodgym.org.
                        </UI.Link>
                      </UI.Typography>
                    </UI.Box>
                  </UI.Grid>
                  <UI.Grid item xs={12} md={6} xl={4}>
                    <UI.Box>
                      <UI.Box
                        sx={{
                          borderStyle: "solid",
                          borderWidth: "1px",
                          borderColor: "neutral.light",
                        }}
                      >
                        <UI.Typography
                          variant="h5"
                          sx={{
                            mb: 2,
                            p: 1,
                            borderBottomStyle: "solid",
                            borderWidth: "0.3px",
                            backgroundColor: "neutral.light",
                          }}
                        >
                          Age Restriction
                        </UI.Typography>
                        <UI.Typography sx={{ p: 1 }}>
                          GoodGym welcomes anyone aged 18 or over. If you are
                          under 18 then you will be unable to participate in any
                          GoodGym activity
                        </UI.Typography>
                      </UI.Box>
                      <UI.Box
                        sx={{
                          borderStyle: "solid",
                          borderWidth: "1px",
                          mt: 2,
                          p: 1,
                        }}
                      >
                        <UI.Typography sx={{ mb: 2 }}>
                          This social visit was from a referrer with little or
                          no knowledge of the older person. This means that
                          their behaviour or environment may post signiicant
                          risks to you.
                        </UI.Typography>
                        <UI.Typography sx={{ mb: 1 }}>
                          To make sure you are safe, please ensure you have read
                          <UI.Link
                            href={
                              "https://www.goodgym.org/general_group_run_risks"
                            }
                          >
                            {" "}
                            general guidelines for safety{" "}
                          </UI.Link>
                          when running, the information below and that you are
                          confident in your social visits training.
                        </UI.Typography>
                      </UI.Box>
                    </UI.Box>
                  </UI.Grid>
                </UI.Grid>{" "}
                <UI.Divider sx={{ my: 2 }} />
              </UI.Container>
            </UI.Box>
          </UI.Box>
        </>
      </UI.Container>
    </>
  );
};

export default TestPairingPage;
