/** @jsxImportSource @emotion/react */
import React from "react";
import * as Icons from "@goodgym/icons";
import * as UI from "@goodgym/components";
import * as T from "@goodgym/graphql/types";
import * as u from "@goodgym/util";
import _ from "lodash";

export type AreaSearchProps = UI.TextFieldProps & {
  onAreaSelected: (area: T.AreaFragment) => any;
};

const AreaSearch: React.FC<AreaSearchProps> = ({
  onAreaSelected,
  ...props
}) => {
  const { loading, data } = T.useAreaSearchQuery();
  // for resetting the component on select
  const [key, setKey] = React.useState(_.uniqueId());

  return (
    <UI.Autocomplete<T.AreaFragment>
      key={key}
      placeholder="Enter the name of a city or borough"
      isOptionEqualToValue={(option, value) => option.id === value.id}
      getOptionLabel={(option) => option.name}
      options={data?.areas || []}
      loading={loading}
      onChange={(_e, area) => {
        if (area) {
          onAreaSelected(area);
          setKey(_.uniqueId());
        }
      }}
      clearOnBlur
      renderInput={(params) => (
        <UI.TextField
          {...params}
          {...props}
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <React.Fragment>
                {loading ? <UI.CircularProgress size={20} /> : null}
                {params.InputProps.endAdornment}
              </React.Fragment>
            ),
          }}
        />
      )}
    />
  );
};

export default AreaSearch;
