/** @jsxImportSource @emotion/react */
import React from "react";
import * as Icons from "@goodgym/icons";
import * as UI from "@goodgym/components";
import * as T from "@goodgym/graphql/types";
import * as u from "@goodgym/util";
import _ from "lodash";
import { useNavigate } from "react-router-dom";


export type PostcodeSearchProps = UI.BoxProps & { fullWidth?: boolean };

const PostcodeSearch: React.FC<PostcodeSearchProps> = ({
  fullWidth = false,
  ...props
}) => {

   const navigate = useNavigate();
  const [postcode, setPostcode] = React.useState("");

   const handleOnClick = () => {
     const code = u.format.postcode(postcode);
     if (code) {
       navigate(`/v3/sessions?postcode=${code}`);
     }
   };
  return (
    <UI.Box {...props}>
      <UI.Typography
        variant="h5"
        component="h2"
        color="white.main"
        sx={{ my: 2 }}
      >
        Find sessions in your local area and start doing good today.
      </UI.Typography>

      <UI.TextField
        name="postcode"
        placeholder="Enter your postcode"
        variant="outlined"
        onChange={(e) => setPostcode(e.target.value?.toUpperCase())}
        value={postcode}
        fullWidth={fullWidth}
        onKeyDown={(e) => {
          if (e.key === "Enter") {
            handleOnClick();
          }
        }}
        sx={{ mb: 2 }}
        InputProps={{
          endAdornment: (
            <UI.InputAdornment position="end">
              <UI.IconButton
                edge="end"
                color="primary"
                type="submit"
                aria-label="Search for sessions"
                onClick={handleOnClick}
              >
                <Icons.ArrowForward />
              </UI.IconButton>
            </UI.InputAdornment>
          ),
        }}
      />
    </UI.Box>
  );
};

export default PostcodeSearch;
