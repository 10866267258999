import * as T from "@goodgym/graphql/types";
import _ from "lodash";

export const toSentence = (words: string[], joiner: "and" | "or" = "and") => {
  if (words.length === 1) {
    return words[0];
  } else {
    return _.initial(words).join(", ") + ` ${joiner} ` + _.last(words);
  }
};

export const compositeAddress = (
  address: Optional<T.AddressFragment | T.AddressSearchResultFragment>,
  options = { multiline: false }
) => {
  if (!address) return null;
  return _.reject(
    [address.line1, address.line2, address.city, address.postcode],
    (s) => _.isEmpty(s) || _.isNil(s)
  ).join(options.multiline ? "\n" : ", ");
};

export const ellipsify = (text: string, maxLength: number) => {
  try {
    if (text.length <= maxLength) {
      return text;
    } else {
      // cut off at the last space if there is one
      const truncatedNaively = text.substring(0, maxLength - 3);
      const truncatedToSpace = truncatedNaively.includes(" ")
        ? truncatedNaively.substring(0, truncatedNaively.lastIndexOf(" "))
        : truncatedNaively;
      return truncatedToSpace + "...";
    }
  } catch (e) {
    // being lazy about edge cases cos they're very unlikely
    return text.substring(0, maxLength);
  }
};
