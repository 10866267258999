import * as UI from "@goodgym/components"
import * as data from "../../data"
import IconCards from "@goodgym/components/IconCards";




const AboutSocialVisit = () => {
  return (
    <>
      <UI.Container>
        <UI.Typography
          variant="h5"
          sx={{
            lineHeight: 1.8,
            fontWeight: "normal",
            marginTop: 1,
            marginBottom: 3,
          }}
        >
          Over a million older people in the UK are always or often lonely, some
          go for months without seeing friends or family. Visiting an older
          person as part of your weekly exercise make a huge difference to their
          life.
        </UI.Typography>
      </UI.Container>
      <UI.Container>
        <UI.Grid
          container
          spacing={2}
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          {data.taskIconData.map((element, index) => (
            <IconCards
              key={index}
              image={element.image}
              tagline={element.tagline}
              bold={true}
            />
          ))}
        </UI.Grid>
      </UI.Container>
      <UI.Container>
        <UI.Typography
          variant="h3"
          sx={{ padding: 2, textAlign: "center", fontStyle: "normal" }}
        >
          How it works
        </UI.Typography>
        <UI.Typography
          variant="h5"
          sx={{
            lineHeight: 1.8,
            fontWeight: "normal",
            marginTop: 1,
            marginBottom: 3,
          }}
        >
          The purpose of a social visit is a friendly chat to help combat
          loneliness and isolation. You are there to provide company to help
          brighten up that person’s day. The visits don’t have to be long, we
          recommend 30-60 mins. The person you visit will become your{" "}
          <strong>coach</strong> as they will encourage you to exercise each
          week by going to visit them, and they will gain some company and a new
          friendly face. More information can be found in our{" "}
          <UI.Link
            href={
              "https://goodgym.zendesk.com/hc/en-gb/categories/16929787801618-Social-Visits"
            }
          >
            FAQs
          </UI.Link>
          .
        </UI.Typography>
      </UI.Container>
      <UI.Container>
        <UI.Grid
          container
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            marginTop: "30px",
            mb: 4,
          }}
        >
          {data.travelThereData.map((element, index) => (
            <UI.Grid
              item
              xs={12}
              md={5}
              lg={4}
              key={index}
              sx={{ textAlign: "center", mb: 5 }}
            >
              <img
                style={{ height: "120px", width: "auto" }}
                src={element.image}
                alt="icon"
              />
              <UI.Box
                sx={{
                  width: { xs: "60%", md: "0%", lg:"100%" },

                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  borderTop: "4px solid #E11018",
                  position: { xs: "absolute", md: "static" },
                  left: "20%",
                }}
              />
              <UI.Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  mx: { xs: 2, md: 0 },
                }}
              >
                <element.icon sx={{ mx: 1 }} fontSize="medium" />
                <UI.Typography
                  sx={{
                    textTransform: "uppercase",
                    textAlign: "center",
                  }}
                  variant="h5"
                >
                  {element.tagline}
                </UI.Typography>
              </UI.Box>
            </UI.Grid>
          ))}
        </UI.Grid>
      </UI.Container>
    </>
  );
}

export default AboutSocialVisit
