import React from "react";
import * as UI from "@goodgym/components";
import Banner from "@goodgym/components/Banner";
import MailOutlineIcon from "@mui/icons-material/MailOutline";
import Card from "./components/Cards";
import { bannerData, cardData, smallCardData } from "./components/data";
import { useAuth } from "@goodgym/auth";
import { useContactPageQuery } from "@goodgym/graphql/hooks";
import { twitter, facebook, instagram } from "./components/data/Images";

const ContactPage: React.FC = () => {
  const { runner } = useAuth();
  const slug = runner?.homeArea?.slug;

  const { data } = useContactPageQuery({
    variables: { slug: slug! },
  });

  const areaOfRunner = data?.area;

  const areaActivator = areaOfRunner?.areaActivator;
  const socialsOfArea = [
    { icon: "facebook", url: areaOfRunner?.facebook, image: facebook },
    { icon: "twitter", url: areaOfRunner?.twitter, image: twitter },
    { icon: "instagram", url: areaOfRunner?.instagram, image: instagram },
  ].filter((element) => element.url !== null);

  const socialMediaIcons = socialsOfArea.map((element, index) => {
    return (
      <a href={element.url!} key={index}>
        <img
          alt={element.icon}
          src={element.image}
          style={{ maxWidth: "100%", maxHeight: "100%" }}
        />
      </a>
    );
  });

  const theme = UI.useTheme();

  return (
    <>
      <Banner
        title={bannerData.title}
        tagline={bannerData.tagline}
        image={bannerData.image}
      />
      <UI.Container
        aria-label="ContactPage"
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          my: 8,
        }}
      >
        <MailOutlineIcon
          sx={{
            fontSize: "100px",
            justifyContent: "center",
            alignItems: "center",
          }}
        />
        <UI.Typography
          variant="h2"
          sx={{ fontStyle: "italic", fontWeight: "bold" }}
        >
          Email
        </UI.Typography>

        <UI.Typography variant="h5" sx={{ paddingBottom: 6 }}>
          <a
            style={{ color: "#E11018", textDecoration: "none" }}
            href="mailto:getinvolved@goodgym.org"
          >
            getinvolved@goodgym.org
          </a>
        </UI.Typography>

        {areaActivator && (
          <UI.Card
            aria-label="AreaActivatorCard"
            sx={{
              width: { lg: "40%", md: "55%", sm: "65%", xs: "85%" },
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              padding: 3,
              boxShadow: theme.shadows[2],
              mb: 4,
            }}
          >
            <UI.Typography
              variant="h4"
              sx={{ paddingBottom: 2, textAlign: "center" }}
            >
              Your Area Activator
            </UI.Typography>
            <UI.Box
              sx={{
                borderRadius: "50%",
                height: "100px",
                width: "100px",
                overflow: "hidden",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                mb: 3,
              }}
            >
              <img
                alt="avatar"
                src={areaActivator.avatarUrl}
                style={{ width: "100px", paddingBottom: 3 }}
              />
            </UI.Box>

            <UI.Typography component={"span"}>
              <span style={{ color: "#E11018" }}> {areaActivator.name}</span> is
              the area activator for Birmingham. {areaActivator.name} helps
              coordinate GoodGym tasks and supports the GoodGym community in the
              area. If you have any questions, you can reach them on:
              <UI.Typography sx={{ textAlign: "center", my: 2 }}>
                {" "}
                Email:{" "}
                <a
                  href={`mailto:${areaOfRunner.email}`}
                  style={{
                    color: "#E11018",
                    textDecoration: "none",
                    paddingBottom: 2,
                  }}
                >
                  {areaOfRunner.email}
                </a>
              </UI.Typography>
            </UI.Typography>
            {socialsOfArea && (
              <UI.Box
                sx={{
                  mx: 2,
                  height: "40px",
                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                {socialMediaIcons}
              </UI.Box>
            )}
          </UI.Card>
        )}

        <UI.Container>
          <UI.Grid container>
            {cardData.map((element, index) => (
              <UI.Grid
                key={index}
                item
                xs={12}
                md={4}
                sx={{ padding: 2, display: "flex", justifyContent: "center" }}
              >
                <Card
                  title={element.title}
                  tagline={element.tagline}
                  image={element.image}
                  link={element.link}
                  buttonText={element.buttonText}
                />
              </UI.Grid>
            ))}
          </UI.Grid>
        </UI.Container>
        <UI.Container sx={{ paddingTop: 4, paddingBottom: 3 }}>
          <UI.Grid
            container
            spacing={1}
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            {smallCardData.map((element, index) => (
              <UI.Grid item xs={12} md={5} lg={3} key={index}>
                <UI.Container
                  sx={{
                    width: "100%",
                    height: "170px",
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                  }}
                >
                  <UI.Typography
                    variant="h3"
                    sx={{
                      paddingBottom: 2,
                      textTransform: "uppercase",
                      textAlign: "center",
                    }}
                  >
                    {element.title}
                  </UI.Typography>
                  <UI.Typography sx={{ textAlign: "center", padding: 1 }}>
                    {element.tagline}
                  </UI.Typography>
                  <a
                    href={`mailto:${element.email}`}
                    style={{
                      color: "#E11018",
                      textDecoration: "none",
                      paddingBottom: 2,
                    }}
                  >
                    {element.email}
                  </a>
                </UI.Container>
              </UI.Grid>
            ))}
          </UI.Grid>
        </UI.Container>

        <UI.Typography sx={{ padding: 3, paddingBottom: 6 }}>
          See also our{" "}
          <a
            style={{ color: "#E11018", textDecoration: "none" }}
            href="https://goodgym.kayako.com/"
          >
            frequently asked questions.
          </a>
        </UI.Typography>
      </UI.Container>
    </>
  );
};

export default ContactPage;
