import * as UI from "@goodgym/components";

export type ImageIconProps = { link: string; icon: string; tagline: string };

const ImageIcon: React.FC<ImageIconProps> = ({ link, icon, tagline }) => {
  return (
    <UI.Container
      sx={{
        display: "flex",
        flexDirection: "column",
        gap: "10px",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <a href={link} style={{ cursor: "pointer" }}>
        {" "}
        <img
          src={icon}
          alt="logo"
          style={{ height: "auto", width: "90px" }}
        ></img>
      </a>
      <UI.Typography sx={{ width: { xs: "300px", md: "200px" } }}>
        <a
          href={link}
          style={{ textDecoration: "none", color: "black", cursor: "pointer" }}
        >
          {tagline}
        </a>
      </UI.Typography>
    </UI.Container>
  );
};
export default ImageIcon;
