import * as UI from "@goodgym/components";
import * as T from "@goodgym/graphql/types";
import * as u from "@goodgym/util";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import React from "react";
import Card from "./components/Cards";
import HomePage from "../HomePage";

const MyTeamPage = () => {
  const [tabValue, setTabValue] = React.useState("1");
  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    setTabValue(newValue);
  };
  const theme = UI.useTheme();
  const md = UI.useMediaQuery(theme.breakpoints.up("md"));

  const { data,error, refetch } = T.useMyTeamsPageQuery({});
  const followers = data?.team.supporters;
  const following = data?.team.supportings;
  const suggestions = data?.team.suggestions;

  React.useEffect(() => {
    refetch({})
  }, [data, refetch])

   if (error) return <HomePage />;
  return (
    <UI.Box>
      <UI.Box
        sx={{
          display: { xs: "none", md: "block" },
          backgroundColor: "primary.main",
          position: "fixed",
          top: "64px",
          left: 0,
          right: 0,
          py: 2,
          zIndex: 3,
        }}
      >
        <UI.Container>
          <UI.Breadcrumbs crumbs={[["Home", u.links.home()]]} sx={{ mb: 1 }} />
          <UI.Typography variant="h1">My Team</UI.Typography>
        </UI.Container>
      </UI.Box>
      {/* Mobile header */}
      <UI.Container
        sx={{
          display: { xs: "block", md: "none" },
          pt: 2,
          pb: 2,
          position: "fixed",
          top: { xs: "56px", sm: "64px" },
          background: "white",
          zIndex: 2,
          borderBottom: "1px solid #d7d7d7",
        }}
      >
        <UI.Box display="flex" justifyContent={"space-between"}>
          <UI.Typography
            variant="h3"
            sx={{ whiteSpace: "nowrap", alignSelf: "center" }}
          >
            My Team
          </UI.Typography>
        </UI.Box>
      </UI.Container>
      <UI.Container sx={{ mt: md ? 18 : 7 }}>
        <TabContext value={tabValue}>
          <UI.Container
            sx={{
              display: "flex",
              justifyContent: "center",
            }}
          >
            <TabList
              variant={md ? "standard" : "fullWidth"}
              allowScrollButtonsMobile
              onChange={handleChange}
            >
              <UI.Tab
                label={
                  <UI.Typography
                    variant="subtitle1"
                    color="neutral.dark"
                    sx={{}}
                  >
                    <UI.Box
                      component="span"
                      sx={{
                        alignSelf: "center",
                        display: "flex",
                        flexDirection: "column",
                        my: 1,
                      }}
                    >
                      Your followers
                    </UI.Box>
                  </UI.Typography>
                }
                value="1"
              />
              <UI.Tab
                label={
                  <UI.Typography variant="subtitle1" color="neutral.dark">
                    <UI.Box component="span" sx={{ alignSelf: "center" }}>
                      Goodgymers you follow
                    </UI.Box>
                  </UI.Typography>
                }
                value="2"
              />
              <UI.Tab
                label={
                  <UI.Typography variant="subtitle1" color="neutral.dark">
                    <UI.Box
                      component="span"
                      sx={{
                        alignSelf: "center",
                        display: "flex",
                        flexDirection: "column",
                        my: 1,
                      }}
                    >
                      People you've run with
                    </UI.Box>
                  </UI.Typography>
                }
                value="3"
              />
            </TabList>
          </UI.Container>
          <TabPanel value="1">
            <UI.Container sx={{ mt: 1 }}>
              <UI.Typography sx={{ mb: 2 }}>
                These people have got your back:
              </UI.Typography>
              <UI.Grid container spacing={4}>
                {followers?.map((follower) => (
                  <UI.Grid item xs={12} md={4} key={follower.id}>
                    <Card
                      key={follower.id}
                      title={follower.name}
                      image={follower.avatarUrl}
                      runner={follower}
                      link={u.links.runnerProfile(follower.slug)}
                    />
                  </UI.Grid>
                ))}
              </UI.Grid>
            </UI.Container>
          </TabPanel>
          <TabPanel value="2">
            <UI.Container>
              <UI.Typography sx={{ mb: 2 }}>
                Follow Goodgymers you've run most with:
              </UI.Typography>
              <UI.Grid container spacing={4}>
                {following?.map((following) => (
                  <UI.Grid item xs={12} md={4} key={following.id}>
                    <Card
                      key={following.id}
                      title={following.name}
                      image={following.avatarUrl}
                      runner={following}
                      link={u.links.runnerProfile(following.slug)}
                    />
                  </UI.Grid>
                ))}
              </UI.Grid>
            </UI.Container>
          </TabPanel>
          <TabPanel value="3">
            <UI.Container>
              <UI.Grid container spacing={4}>
                {suggestions?.map((suggestion) => (
                  <UI.Grid item xs={12} md={4} key={suggestion.id}>
                    <Card
                      key={suggestion.id}
                      title={suggestion.name}
                      image={suggestion.avatarUrl}
                      runner={suggestion}
                      link={u.links.runnerProfile(suggestion.slug)}
                    />
                  </UI.Grid>
                ))}
              </UI.Grid>
            </UI.Container>
          </TabPanel>
        </TabContext>
      </UI.Container>
    </UI.Box>
  );
};

export default MyTeamPage;
