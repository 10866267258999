/** @jsxImportSource @emotion/react */
import React from "react";
import * as Icons from "@goodgym/icons";
import * as UI from "@goodgym/components";
import * as T from "@goodgym/graphql/types";
import * as u from "@goodgym/util";
import _ from "lodash";
import GoogleMap from "google-map-react";

export type TaskRequestMapProps = { taskRequest: T.TaskRequestFragment | T.CoachReferralFragment};

const TaskRequestMap: React.FC<TaskRequestMapProps> = ({
  taskRequest,
  ...props
}) => {
  if (!taskRequest.lat || !taskRequest.lng) return null;
  return (
    <GoogleMap
      bootstrapURLKeys={{
        key: process.env.REACT_APP_GOOGLE_MAPS_API_KEY!,
      }}
      defaultCenter={{
        lat: taskRequest.lat,
        lng: taskRequest.lng,
      }}
      defaultZoom={15}
      style={{ height: "100%", width: "100%" }}
    >
      <UI.Box
        {...props}
        sx={{
          backgroundColor: "secondary.main",
          border: "1px solid",
          borderColor: "secondary.dark",
          opacity: 0.4,
          height: "128px",
          width: "128px",
          ml: "-64px",
          mt: "-64px",
          borderRadius: "50%",
          objectFit: "cover",
        }}
      />
    </GoogleMap>
  );
};

export default TaskRequestMap;
