/** @jsxImportSource @emotion/react */
import React from "react";
import * as UI from "@goodgym/components";
import * as T from "@goodgym/graphql/types";
import * as u from "@goodgym/util";
import _ from "lodash";
import * as C from "../../components";

export type HeaderProps = UI.BoxProps & {
  runner: Optional<T.CurrentRunnerFragment>;
  session: T.SessionPageQuery["session"];
};

const Header: React.FC<React.PropsWithChildren<HeaderProps>> = ({
  session,
  runner,
  ...props
}) => {
  const taskforceInCMArea =  (
     u.roles.hasAnyRole(runner, ["TASK_FORCE"]) &&
          runner?.homeArea?.id === session.area?.id &&
          session.type === "community mission"
    )
   
  return (
    <UI.Box {...props}>
      <UI.Typography aria-label="title of the session" variant="h4">
        {session.title}{" "}
        {(taskforceInCMArea ||  u.roles.hasAnyRole(runner, ["CENTRAL_ADMIN", "RUN_LEADER", "TRAINER"])) && <C.AdminMenu session={session} />}
      </UI.Typography>
      <UI.Typography
        aria-label="strapline of session"
        variant="subtitle1"
        color="neutral"
        sx={{ color: "neutral.main", mt: 1 }}
      >
        {session.strapline}
      </UI.Typography>
    </UI.Box>
  );
};

export default Header;
