/** @jsxImportSource @emotion/react */
import React from "react";
import * as Icons from "@goodgym/icons";
import * as UI from "@goodgym/components";
import * as T from "@goodgym/graphql/types";
import * as u from "@goodgym/util";
import * as C from "./components";
import _ from "lodash";
import { useNavigate } from "react-router-dom";

const OpsSupportPage = () => {
  const { error, data } = T.useOpsSupportPageQuery({
    variables: {},
  });

  const navigate = useNavigate();

  const [address, setAddress] =
    React.useState<T.AddressSearchResultFragment | null>(null);

  if (error) return <UI.Error error={error} />;
  if (!data) return <UI.Loading />;

  return (
    <>
      {address && (
        <C.AddressSearchResultDialog
          address={address}
          onClose={() => setAddress(null)}
          open={true}
        />
      )}

      {/* Tablet->desktop header */}
      <UI.Box
        sx={{
          display: { xs: "none", md: "block" },
          backgroundColor: "secondary.main",
          position: "fixed",
          top: "64px",
          left: 0,
          right: 0,
          py: 2,
          zIndex: 3,
        }}
      >
        <UI.Container>
          <UI.Breadcrumbs
            crumbs={[
              ["Home", u.links.home()],
              ["Ops Support", u.links.opsSupport()],
            ]}
            sx={{ mb: 1 }}
          />
          <UI.Typography variant="h1">Ops Support</UI.Typography>
        </UI.Container>
      </UI.Box>

      {/* Mobile header */}
      <UI.Container
        sx={{
          display: { xs: "block", md: "none" },
          pt: 2,
          pb: 2,
          position: "fixed",
          top: { xs: "56px", sm: "64px" },
          background: "white",
          zIndex: 2,
          borderBottom: "1px solid #d7d7d7",
        }}
      >
        <UI.Typography variant="h3" sx={{ whiteSpace: "nowrap" }}>
          Ops Support
        </UI.Typography>
      </UI.Container>
      <UI.Container sx={{ mt: { xs: "90px", sm: "150px" } }}>
        <UI.Typography variant="h4" sx={{ mb: 2 }}>
          Address search
        </UI.Typography>
        <UI.AddressSearch
          onAddressSelected={(address) => {
            setAddress(address);
          }}
        />

        <UI.Typography variant="h4" sx={{ mt: 5, mb: 2 }}>
          Area search
        </UI.Typography>
        <UI.AreaSearch
          onAreaSelected={(area) => {
            navigate(u.links.area(area));
          }}
        />

        <UI.Typography variant="h4" sx={{ mt: 5, mb: 2 }}>
          Beneficiary search
        </UI.Typography>
        <UI.BeneficiarySearch
          onBeneficiarySelected={(beneficiary) => {
            window.location.href = u.links.beneficiary(beneficiary);
          }}
        />
      </UI.Container>
    </>
  );
};

export default OpsSupportPage;
