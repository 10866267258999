/** @jsxImportSource @emotion/react */
import React from "react";
import * as Icons from "@goodgym/icons";
import * as UI from "@goodgym/components";
import * as T from "@goodgym/graphql/types";
import * as u from "@goodgym/util";
import _ from "lodash";
import { ReactComponent as Cheered } from "@goodgym/images/icons/cheer-filled.svg";
import { ReactComponent as Uncheered } from "@goodgym/images/icons/cheer-outline.svg";
import { css } from "@emotion/react";
import useCheers from "./hooks";

export type CheerButtonProps = UI.IconButtonProps & {
  cheerable: T.CheerableItem;
};

const CheerButton: React.FC<CheerButtonProps> = ({ cheerable, ...props }) => {
  const runners = (() => {
    switch (cheerable.__typename) {
      case "NewsfeedAttendance":
      case "NewsfeedRegistration":
        return cheerable.runners;
      case "NewsfeedCoachVisit":
      case "NewsfeedAward":
      case "NewsfeedWorkout":
        return [cheerable.runner];
      default:
        return [];
    }
  })();

  const theme = UI.useTheme();
  const cheerer = useCheers({ cheerable, runners });

  if (!cheerer) return null;
  return (
    <UI.IconButton
      {...props}
      sx={{
        backgroundColor: "#eaeaea",
        height: "45px",
        width: "45px",
        border: "2px solid #fff",
        ...props.sx,
      }}
      onClick={cheerer.onClick}
    >
      {cheerer.isCheered ? (
        <Cheered
          css={css`
            fill: ${theme.palette.primary.main};
            height: 22px;
          `}
        />
      ) : (
        <Uncheered
          css={css`
            fill: ${theme.palette.neutral.dark};
            height: 22px;
          `}
        />
      )}
    </UI.IconButton>
  );
};

export default CheerButton;
