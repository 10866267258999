/** @jsxImportSource @emotion/react */
import React from "react";
import * as Icons from "@goodgym/icons";
import * as UI from "@goodgym/components";
import * as T from "@goodgym/graphql/types";
import * as u from "@goodgym/util";
import _ from "lodash";
import { useAuth } from "@goodgym/auth";

export type AdminMenuProps = UI.IconButtonProps & {
  runner: T.RunnerFragment;
};

const AdminMenu: React.FC<AdminMenuProps> = ({ runner, ...props }) => {
  const { runner: currentRunner } = useAuth();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    setAnchorEl(e.currentTarget);
  };
  const onClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <UI.IconButton
        aria-controls={open ? "session-admin-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        onClick={handleClick}
        size="small"
        {...props}
      >
        <Icons.Settings />
      </UI.IconButton>
      <UI.Menu anchorEl={anchorEl} open={open} onClose={onClose}>
        <UI.MenuItem component={UI.Link} href={u.links.runnerIdChecks(runner)}>
          ID checks
        </UI.MenuItem>

        <UI.MenuItem component={UI.Link} href={u.links.legacyProfile(runner)}>
          Legacy profile
        </UI.MenuItem>

        {u.roles.hasRole(currentRunner, "CENTRAL_ADMIN") && (
          <UI.MenuItem component={UI.Link} href={u.links.adminProfile(runner)}>
            Admin profile
          </UI.MenuItem>
        )}
      </UI.Menu>
    </>
  );
};

export default AdminMenu;
