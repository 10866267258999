/** @jsxImportSource @emotion/react */
import { useJoinWaitingListMutation } from "@goodgym/graphql/hooks";
import * as T from "@goodgym/graphql/types";
import * as UI from "@goodgym/components";
import * as u from "@goodgym/util";
import { useConfirm } from "@goodgym/hooks";

export type JoinWaitingListMutationHook = () => (
  session: T.SessionFragment
) => React.MouseEventHandler;

export const useJoinWaitingList: JoinWaitingListMutationHook = () => {
  const { confirm } = useConfirm();
  const [joinWaitingList] = useJoinWaitingListMutation({
    onError: console.log,
  });

  return (session) => (e) => {
    e?.preventDefault();
    confirm({
      title: "Join notification list",
      text: (
        <>
          <UI.Typography sx={{ mb: 2 }}>
            Join notification list for <strong>{session.title}</strong>?
          </UI.Typography>
          <UI.Typography sx={{ mb: 2 }}>
            We will email you to let you know if space on this session opens up.
          </UI.Typography>
        </>
      ),
      onConfirm: () => {
        joinWaitingList({
          variables: { sessionId: session.id },
          optimisticResponse: {
            response: {
              __typename: "JoinWaitingListMutationPayload",
              session: { ...session, onWaitingList: true },
            },
          },
        });
      },
    });
  };
};
