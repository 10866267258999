/** @jsxImportSource @emotion/react */
import React from "react";
import * as Icons from "@goodgym/icons";
import * as UI from "@goodgym/components";
import * as T from "@goodgym/graphql/types";
import * as u from "@goodgym/util";
import _ from "lodash";

export type AreaChipProps = UI.ChipProps<"a"> & {
  area: Optional<T.AreaFragment>;
};

const AreaChip: React.FC<AreaChipProps> = ({ area, ...props }) => {
  if (!area) return null;
  return (
    <UI.Chip
      {...props}
      label={area.name}
      to={u.links.area(area)}
      component={UI.Link}
      clickable
      sx={{
        borderRadius: 1,
        height: 24,
        ...props.sx,
        verticalAlign: "baseline",
        backgroundColor: "#E5EDFE",
        fontWeight: "bold",
      }}
    />
  );
};

// @ts-ignore
AreaChip.muiName = UI.Chip.muiName;

export default AreaChip;
