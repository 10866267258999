import React from "react";
import * as T from "@goodgym/graphql/types";
import * as u from "@goodgym/util";
import _ from "lodash";
import { useAuth } from "@goodgym/auth";
import { useCheer, useUncheer } from "@goodgym/graphql/mutations";

const useCheers = (props: {
  cheerable: T.CheerableItem;
  runners: T.RunnerFragment[];
}) => {
  const { cheerable, runners } = props;
  const { runner } = useAuth();
  const { cheers } = cheerable;

  // the cheer button should be in it's "off" state if any of the people we
  // could be cheering haven't yet been cheered
  const cheered = runner
    ? cheers.filter((c) => c.runner.id === runner?.id).map((c) => c.recipient)
    : [];

  const uncheered = _.differenceBy(runners, cheered, "id").filter(
    (r) => r.id !== runner?.id
  );

  const cheer = useCheer({ runner, cheerable, uncheered });
  const uncheer = useUncheer({ runner, cheerable, cheers });

  const onClick: React.MouseEventHandler = (e) => {
    if (uncheered.length > 0) {
      cheer(e);
      u.analytics.track("cheer", {
        cheerable_id: u.cheers.cheerableActivityId(cheerable),
        cheerable_type: u.cheers.cheerableType(cheerable),
      });
    } else {
      uncheer(e);
      u.analytics.track("cheer", {
        cheerable_id: u.cheers.cheerableActivityId(cheerable),
        cheerable_type: u.cheers.cheerableType(cheerable),
      });
    }
  };

  if (!runner) return null;
  if (runners.length === 1 && runners[0].id === runner.id) return null;

  return {
    onClick,
    isCheered: uncheered.length === 0,
  };
};

export default useCheers;
