/** @jsxImportSource @emotion/react */
import React from "react";
import * as Icons from "@goodgym/icons";
import * as UI from "@goodgym/components";
import * as T from "@goodgym/graphql/types";
import * as u from "@goodgym/util";
import _ from "lodash";

export type TaskForceAchievementProps = UI.GridProps & {
  Icon: React.FC<{ style?: any }>;
  label: string;
  stat: number;
};

const TaskForceAchievement: React.FC<
  React.PropsWithChildren<TaskForceAchievementProps>
> = ({ Icon, label, stat, ...props }) => {
  return (
    <UI.Grid {...props} item>
      <UI.MuiBadge
        color="primary"
        overlap="circular"
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        badgeContent={
          <UI.Typography variant="subtitle2">{stat.toString()}</UI.Typography>
        }
      >
        <UI.Avatar
          sx={{
            width: u.avatarSize("huge"),
            height: u.avatarSize("huge"),
            backgroundColor: "secondary.light",
          }}
        >
          <Icon style={{ width: "100%", height: "100%" }} />
        </UI.Avatar>
      </UI.MuiBadge>
      <UI.Typography
        variant="body2"
        sx={{
          mt: 1,
          width: u.avatarSize("huge"),
          textAlign: "center",
          whiteSpace: "pre-wrap",
        }}
      >
        {label.split(" ").join("\n")}
      </UI.Typography>
    </UI.Grid>
  );
};

export default TaskForceAchievement;
