import * as t from "./time";

export const celebratory = ["🎉", "🥇", "😎", "🤩", "🥳"];

export const random = (id: string) => {
  const index = parseInt(id) % celebratory.length;
  return celebratory[index];
};

/*
 * There are emoji clocks for each hour on the hour
 * and each hour half past the hour. This function
 * takes a date and returns the closest emoji
 * clock for the given time.
 * Anything from 0-15 mins past the hour we return the
 * clock for that hour on the hour;
 * anything from 15-45 mins past the hour we return the clock
 * for that hour half past the hour;
 * anything from 45-60 minutes past the hour we return the clock for
 * the next hour on the hour
 */
export const clock = (date: Date | string) => {
  const hour = t.getHours(date);
  const minutes = t.getMinutes(date);
  const onTheHourEmojisStart = 128336;
  const halfPastTheHourEmojisStart = 128348;

  // the emojis start at 1 o'clock and end at 12 o'clock,
  // so an hour of 0 needs to resolve to an index of 12
  // and an hour of 1 needs to resolve to an index of 0
  // also - if the minutes are 45 or more we show the clock
  // for the _next_ hour, so we need to take that into account
  // here.
  const index = (hour + (minutes >= 45 ? 12 : 11)) % 12;
  const emojiStart =
    minutes <= 15 || minutes >= 45
      ? onTheHourEmojisStart
      : halfPastTheHourEmojisStart;

  return String.fromCodePoint(emojiStart + index);
};

export const calendar = String.fromCodePoint(128467);

export const mapPin = String.fromCodePoint(128205);
