import styled from "@emotion/styled";
import * as UI from "@goodgym/components";
import theme from "@goodgym/theme";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import Link from "@mui/material/Link";
import { styled as Styled } from "@mui/system";

const Image = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center top;
  z-index: -1;
`;

const ImageContainer = Styled(UI.Box)`
width:100%;
height:450px;
 display:block;
  position: relative;
  /* &::after {
    position: absolute;
    bottom: 0px;
    left: 0px;
    right: 0px;
    content: "";
    width: 100%;
    height: 4.5rem;
    background-image: linear-gradient(
      to left bottom,
      transparent,
      transparent 50%,
      rgb(51, 51, 51) 51%,
      rgb(51, 51, 51)
    );
  } */
`;

const TitleBox = Styled(UI.Box)`
  width:100%;
  height:200px; 
  background: rgb(51, 51, 51);
  
`;

export type BannerProps = {
  image?: string;
};


const Banner: React.FC<BannerProps>= ({image}) => {
  return (
    <>
      <ImageContainer>
        <Image src={image} alt="about" />
      </ImageContainer>
      <TitleBox>
        <UI.Container>
          <UI.Typography
            variant="h5"
            sx={{
              typography: {
                md: "h2",
                sm: "h5",
                color: "#FFF",
                paddingTop: "20px",
              },
            }}
          >
            {" "}
            GoodGym helps you get fit by doing good. We’re a group of runners,
            walkers, and cyclists who combine regular exercise with helping our
            communities.
          </UI.Typography>{" "}
        </UI.Container>
      </TitleBox>
      <UI.Container
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          minHeight: "50px",
          padding: 3,
        }}
      >
        <Breadcrumbs
          separator="|"
          sx={{  padding: {xs:0, md:3}}}
          aria-label="breadcrumb"
        >
          <Link
            variant="h5"
            underline="hover"
            color={theme.palette.black.main}
            href="/team"
            sx={{ mx: 2 }}
          >
            Meet the team
          </Link>
          <Link
            variant="h5"
            underline="hover"
            color={theme.palette.black.main}
            href="https://goodgym.kayako.com/"
            sx={{ mx: 2 }}
          >
            Frequently asked questions
          </Link>
          <Link
            variant="h5"
            underline="hover"
            color={theme.palette.black.main}
            href="/v3/contact"
            sx={{ mx: 2 }}
          >
            Contact us
          </Link>
        </Breadcrumbs>
      </UI.Container>
    </>
  );
};

export default Banner;
