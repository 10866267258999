import * as UI from "@goodgym/components";
import theme from "@goodgym/theme";
import { styled } from "@mui/material";
import { tableCellClasses } from "@mui/material/TableCell";
import React from "react";

export type TableProps = {};

const ListItem = styled(UI.ListItem)`
  list-style-type: disc;
  display: list-item;
  margin-top: -1;
  list-style-position: inside;
`;

const Table: React.FC<TableProps> = () => {
  function createData(
    name: string,
    Purpose: string,
    PurposeDetails: string[],
    TypeOfData: string[],
    LawfulBasis: string[]
  ) {
    return { name, Purpose, PurposeDetails, TypeOfData, LawfulBasis };
  }

  const rows = [
    createData(
      "first row",
      "To register you as a Runner, Referrer or Coach",
      [],
      ["Identity", "Contact", "Profile", "Coach"],
      ["Performance of a contract with you"]
    ),
    createData(
      "second row",
      "To process any subscription or donation including",
      [
        "Manage payments, fees and charges",
        "Collect and recover money owed to us",
      ],
      [
        "Identity",
        "Contact",
        "Financial",
        "Transaction",
        "Marketing and Communication",
      ],
      [
        "Performance of a contract with you",
        "Necessary for our legitimate interests (to recover debts due to us)",
      ]
    ),
    createData(
      "third row",
      "To manage our relationship with you which will include:",
      [
        "Notifying you about changes to our terms or privacy policy",
        "Asking you to leave a review or take a survey",
        "Sending you communications in respect of any runs in which you may wish to participate and other service related communications",
      ],
      ["Identity", "Contact", "Profile", "Marketing and Communication"],
      [
        "Performance of a contract with you",
        "Necessary to comply with a legal obligation",
        "Necessary for our legitimate interests (to keep our records updated and to study how Runners and Coaches use our services)",
      ]
    ),
    createData(
      "fourth row",
      "To enable you to partake in a prize draw, competition or complete a survey",
      [],
      [
        "Identity",
        "Contact",
        "Profile",
        "Usage",
        "Marketing and Communication",
      ],
      [
        "Performance of a contract with you",
        "Necessary for our legitimate interests (to study how Runners and Coaches use our services)",
      ]
    ),
    createData(
      "sixth row",
      "To administer and protect our business and the Website (including troubleshooting, data analysis, testing, system maintenance, support, reporting and hosting of data)",
      [],
      ["Identity", "Contact", "Technical"],
      [
        "Performance of a contract with youNecessary for our legitimate interests (for running our business, provision of administration and IT services, network security, to prevent fraud and in the context of a business reorganisation or group restructuring exercise)Necessary for our legitimate interests (to study how Runners and Coaches use our services)",
        "Necessary to comply with a legal obligation",
      ]
    ),
    createData(
      "seventh row",
      "To deliver relevant Website content and advertisements to you and measure or understand the effectiveness of the advertising we serve to you",
      [],
      [
        "Identity",
        "Contact",
        "Profile",
        "Usage",
        "Marketing and Communication",
        "Technical",
      ],
      [
        "Necessary for our legitimate interests (to study how Runners and Coaches use our services, to develop them, to grow our business and to inform our marketing strategy)",
      ]
    ),
    createData(
      "eigth row",
      "To use data analytics to improve the Website, products/services, marketing, customer relationships and experiences",
      [],
      ["Usage", "Technical"],
      [
        "Necessary for our legitimate interests (to keep our Website updated and relevant, to develop our business and to inform our marketing strategy)",
      ]
    ),
    createData(
      "ninth row",
      "To make suggestions and recommendations to you about goods or services that may be of interest to you",
      [],
      ["Identity", "Contact", "Profile", "Usage", "Technical"],
      [
        "Necessary for our legitimate interests (to develop our products/services and grow our business)",
      ]
    ),
    createData(
      "tenth row",
      "To monitor your welfare",
      [],
      ["Identity", "Contact", "Profile", "Coach"],
      ["Necessary for our legitimate interests (to ensure that you are safe)"]
    ),
    createData(
      "eleventh row",
      "To report any unlawful activity or activity that poses a risk to Coaches and other Runners",
      [],
      ["Identity", "Contact", "Profile"],
      [
        "Necessary for our legitimate interests (to ensure that you are safe)",
        "Necessary to comply with a legal obligation",
      ]
    ),
  ];

  const StyledTableCell = styled(UI.TableCell)(() => ({
    border: "0.5px solid rgba(0,0,0,0.5)",
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: theme.palette.black.main,
      color: theme.palette.white.main,
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
    },
  }));

  const StyledTableRow = styled(UI.TableRow)(() => ({
    "&:nth-of-type(odd)": {
      backgroundColor: theme.palette.neutral.light,
    },
  }));

  return (
    <UI.Container
      sx={{
        maxHeight: "500px",
        maxWidth: { xs: "343px", sm: "600px", md: "100%" },
        overflow: "scroll",
      }}
    >
      <UI.TableContainer
        sx={{
          marginTop: 2,
        }}
        component={UI.Paper}
      >
        <UI.Table
          stickyHeader
          sx={{ tableLayout: "auto", overflow: "scroll" }}
          aria-label="customized table"
        >
          <UI.TableHead sx={{ position: "sticky" }}>
            <StyledTableRow>
              <StyledTableCell align="left">Purpose/Activity</StyledTableCell>
              <StyledTableCell align="left">Type of Data</StyledTableCell>
              <StyledTableCell align="left">
                Lawful basis for processing
              </StyledTableCell>
            </StyledTableRow>
          </UI.TableHead>
          <UI.TableBody sx={{ border: "1px solid black", overflow: "auto" }}>
            {rows.map((row) => (
              <StyledTableRow key={row.name}>
                <StyledTableCell align="left">
                  {row.Purpose}
                  {row.PurposeDetails.map((details) => (
                    <ol type="a">
                      <li>{details}</li>
                    </ol>
                  ))}
                </StyledTableCell>
                <StyledTableCell align="left">
                  {row.TypeOfData.map((text) => (
                    <ListItem>{text}</ListItem>
                  ))}
                </StyledTableCell>
                <StyledTableCell align="left">
                  {row.LawfulBasis.map((text) => {
                    if (row.LawfulBasis.length > 1) {
                      return (
                        <ol type="a">
                          <li>{text}</li>
                        </ol>
                      );
                    }
                    return (
                      <UI.List style={{ listStyleType: "none" }}>
                        <UI.List>{text}</UI.List>
                      </UI.List>
                    );
                  })}
                </StyledTableCell>
              </StyledTableRow>
            ))}
          </UI.TableBody>
        </UI.Table>
      </UI.TableContainer>
    </UI.Container>
  );
};

export default Table;
