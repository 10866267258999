import * as Images from "../images";
import * as Icons from "../../images";

export const taskIconData = [
  {
    image: Icons.PlantIcon,
    tagline: "Plant trees for the local park",
  },
  {
    image: Icons.DiggingIcon,
    tagline: "Shift earth for a community gardening project",
  },
  {
    image: Icons.HoldingBannerIcon,
    tagline: "Sort cans for the food bank",
  },
];

export const whyHelp = [
  {
    image: Icons.ShoeIcon,
    tagline: "Increase your motivation to exercise",
  },
  {
    image: Icons.IconHeart,
    tagline: "Help make your local area a better place to live",
  },
  {
    image: Icons.HighFiveIcon,
    tagline: "Connect with great people and projects",
  },
];

export const RunnersTestimonials = [
  {
    avatarUrl: Images.Hazel,
    quote:
      "Before GoodGym I always lost motivation to stick at running or the gym but now I run two or three times a week.",
    by: "Hazel, GoodGym Barnet",
  },
  {
    avatarUrl: Images.Mark,
    quote:
      "Running with GoodGym is great because I get fit, do good and get to hang with a load of awesome people.",
    by: "Mark, GoodGym Islington",
  },
  {
    avatarUrl: Images.Judy,
    quote:
      "GoodGym combines doing good with running, which helps me forget I'm even exercising. It's great!",
    by: "Judy, GoodGym LambethM",
  },
];

export const FaqData = [
  {
    heading: "How much does it cost?",
    content:
      "GoodGym is free. Most of us make a monthly donation to help cover the cost of running our charity, but no one has to.",
  },
  {
    heading: "Am I fit enough?",
    content: "Yes. GoodGym welcomes anybody no matter what your fitness level.",
  },
  {
    heading: "What should I bring?",
    content:
      "All you need is a pair of trainers - just don't wear your snazziest gear. Tools will be provided if necessary.",
  },
];
