import * as UI from "@goodgym/components";
import * as Icons from "../../images";

export type GetInvolvedCardProps = {
  groupRunPage?: boolean;
  missionPage?: boolean;
};
const GetInvolvedCard: React.FC<GetInvolvedCardProps> = ({
  groupRunPage,
}) => {
  return (
    <UI.Box
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "flex-end",
        mt: "-200px",
      }}
    >
      <UI.Card
        sx={{
          width: { xs: "80%", sm: "55%", md: "45%" },
          borderRadius: "10px",
        }}
      >
        <UI.CardHeader
          title={
            <UI.Typography
              variant="h2"
              sx={{
                fontStyle: "italic",
                textTransform: "uppercase",
                textAlign: "center",
              }}
            >
              How to get involved
            </UI.Typography>
          }
        />
        <UI.CardContent sx={{ mt: -1 }}>
          <UI.Container sx={{ textAlign: "center" }}>
            <UI.Button
              href={
                groupRunPage
                  ? "/v3/sessions?type=GroupRun"
                  : "/v3/sessions?type=CommunityMission"
              }
              sx={{ mb: 3 }}
            >
            {  groupRunPage ? "Find Group Runs" : "Find Community Mission"}
            </UI.Button>
          </UI.Container>

          <UI.Typography sx={{ mb: 2 }} variant="body1">
            Just sign up to your local group for free to get involved or if
            you’re keen to take the plunge and get involved on a regular basis
            you can become a member.
          </UI.Typography>
          <UI.Box
            sx={{
              width: "110%",
              borderTop: "10px solid #E11018",
              ml: -2,
            }}
          />
          <UI.Box
            sx={{
              display: "flex",
              justifyContent: "center",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <img
              src={Icons.TShirt}
              alt="t-shirt"
              style={{ height: "120px", width: "auto", marginTop: 8 }}
            />
            <UI.Typography variant="h4" sx={{ mb: 1, textAlign: "center" }}>
              Becoming a member
            </UI.Typography>
            <UI.Typography variant="body1" sx={{ textAlign: "center", my: 1 }}>
              If you become a member we'll help you get fit by doing good.
              You'll get the support of the GoodGym community and help to set
              and achieve fitness goals, and a new GoodGym technical running
              top.
            </UI.Typography>
            <UI.Typography variant="body2" sx={{ textAlign: "center", my: 1 }}>
              All aspects of GoodGym are <strong>free </strong>to participate
              in. Most GoodGym members make an optional monthly donation which
              goes to the GoodGym Charity to help us reach more older people.
              We’ll ask you if you’d like to donate when you join.
            </UI.Typography>
            <UI.Button
              href={"https://www.goodgym.org/users/sign_up?flow=become_member"}
            >
              Become a member
            </UI.Button>
          </UI.Box>
        </UI.CardContent>
      </UI.Card>
    </UI.Box>
  );
};

export default GetInvolvedCard;
