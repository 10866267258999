/** @jsxImportSource @emotion/react */
import React from "react";
import * as Icons from "@goodgym/icons";
import * as UI from "@goodgym/components";
import * as T from "@goodgym/graphql/types";
import * as u from "@goodgym/util";
import * as C from "..";
import * as h from "../../helpers";
import _ from "lodash";
import { useDrawers } from "@goodgym/drawers";

export type AttendanceProps = {
  attendance: T.NewsfeedAttendanceFragment;
};

const Attendance: React.FC<AttendanceProps> = ({ attendance }) => {
  const { showSession } = useDrawers();
  const { session } = attendance;
  const report = attendance.session.report;
  const { runners } = attendance;
  return (
    <UI.Card>
      <UI.CardHeader
        title={
          <UI.Typography variant="subtitle1">
            <UI.RunnerLinks runners={runners} />{" "}
            {h.activityDescription(session)}
          </UI.Typography>
        }
        subheader={
          <UI.Typography variant="body2">
            {u.time.formatDateTime(session.startedAt)}
          </UI.Typography>
        }
        avatar={
          <UI.AvatarGroup max={2}>
            {runners.map((runner) => (
              <UI.RunnerAvatar
                key={runner.id}
                size="medium"
                runner={runner}
                noStatus
              />
            ))}
          </UI.AvatarGroup>
        }
      />
      <UI.CardActionArea
        href={report ? u.links.report(report) : u.links.session(session)}
        onClick={(e) => showSession(e, session)}
      >
        <UI.CardMedia
          title={report?.title || session.title}
          image={report?.coverImageUrl || session.coverImageUrl}
          sx={{ height: 0, pt: "56.25% // 16:9", position: "relative" }}
        />
      </UI.CardActionArea>
      <UI.CardContent sx={{ pb: 0 }}>
        <UI.Link
          variant="h4"
          color="black.main"
          href={report ? u.links.report(report) : u.links.session(session)}
          onClick={report ? undefined : (e) => showSession(e, session)}
        >
          {report?.title || session.title}
        </UI.Link>
        {report?.writtenBy && (
          <UI.Typography
            variant="body2"
            sx={{ mt: 2, mb: 1, color: "neutral.main" }}
          >
            <UI.AreaChip area={session.area} sx={{ mr: 1 }} />
            <Icons.AssignmentOutlined
              sx={{ height: "20px", width: "20px", verticalAlign: "middle" }}
            />{" "}
            Report written by <UI.RunnerLink runner={report.writtenBy} />
          </UI.Typography>
        )}
        {report && (
          <UI.Snippet
            content={report.description}
            href={report ? u.links.report(report) : u.links.session(session)}
            onClick={report ? undefined : (e) => showSession(e, session)}
          />
        )}
      </UI.CardContent>
      <C.CheersAndComments
        cheerable={attendance}
        commentable={attendance.session.report || attendance.session}
        defaultOpen
      />
    </UI.Card>
  );
};

export default Attendance;
