/** @jsxImportSource @emotion/react */
import React from "react";
import * as Icons from "@goodgym/icons";
import * as UI from "@goodgym/components";
import * as T from "@goodgym/graphql/types";
import * as u from "@goodgym/util";
import _ from "lodash";

export type DateTimeProps = UI.BoxProps & {
  session: T.SessionFragment & { address?: Optional<T.AddressFragment> };
};

const DateTime: React.FC<DateTimeProps> = ({
  session,
  ...props
}) => {
  return (
    <UI.Box
      sx={{
        display: "flex",
        alignContent: "center",
        color: "neutral.dark",
        mb: 3,
      }}
    >
      <UI.Box sx={{ width: "32px", flexShrink: 0 }}>
        <Icons.Event sx={{ verticalAlign: "middle", fontSize: "22px" }} />
      </UI.Box>
      <UI.Box>
        <UI.Typography variant="body1">
          {u.time.format(session.startedAt, "EEEE do MMMM yyyy")}
        </UI.Typography>
        <UI.Typography variant="body1">
          {u.time.formatTimeRange(session.startedAt, session.duration)}
        </UI.Typography>
        <UI.Typography variant="subtitle1" sx={{ mt: "4px" }}>
          <UI.AddToCalendarButton
            event={{
              title: session.title,
              description: session.strapline,
              start: u.time.parse(session.startedAt),
              duration: session.duration
                ? [session.duration, "minutes"]
                : undefined,
              location: session.address
                ? u.geo.compositeAddress(session.address)
                : "",
              url: u.links.session(session),
            }}
          />
        </UI.Typography>
      </UI.Box>
    </UI.Box>
  );
};

export default DateTime;
