/** @jsxImportSource @emotion/react */
import React from "react";
import * as Icons from "@goodgym/icons";
import * as UI from "@goodgym/components";
import * as T from "@goodgym/graphql/types";
import * as u from "@goodgym/util";
import * as NP from "@goodgym/noun-project";
import _ from "lodash";

export type BenefitsProps = {};

const benefits = [
  {
    Icon: NP.Community,
    text: "Help make your local area a better place to live",
  },
  {
    Icon: NP.Goal,
    text: "Increase your motivation by exercising with a group",
  },
  { Icon: NP.HighFive, text: "Connect with great people and projects" },
  {
    Icon: NP.CarryingHeart,
    text: "Make a difference to someone's life",
  },
];

const Benefits: React.FC<BenefitsProps> = ({}) => {
  return (
    <UI.Box>
      {benefits.map(({ text, Icon }, index) => (
        <UI.Box key={index} sx={{ display: "flex", my: 2 }}>
          <UI.Box>
            <Icon css={{ height: "60px", width: "60px" }} />
          </UI.Box>
          <UI.Box sx={{ flexGrow: 1, ml: 2, alignSelf: "center" }}>
            <UI.Typography variant="h5" component="p">
              {text}
            </UI.Typography>
          </UI.Box>
        </UI.Box>
      ))}
    </UI.Box>
  );
};

export default Benefits;
