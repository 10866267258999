/** @jsxImportSource @emotion/react */
import React from "react";
import * as Icons from "@goodgym/icons";
import * as UI from "@goodgym/components";
import * as T from "@goodgym/graphql/types";
import * as u from "@goodgym/util";
import _ from "lodash";
import { useBlock, useUnblock } from "@goodgym/graphql/mutations";

export type BlockButtonProps = UI.BoxProps & {
  runner: T.RunnerFragment & T.FollowStatusFragment;
};

const BlockButton: React.FC<React.PropsWithChildren<BlockButtonProps>> = ({
  runner,
  ...props
}) => {
  const block = useBlock({ runner });
  const unblock = useUnblock({ runner });

  return runner.blockedByYou ? (
    <UI.Typography color="neutral.main">
      You have blocked {runner.name}. You can{" "}
      <UI.Link
        color="neutral.main"
        onClick={unblock}
        sx={{ textDecoration: "underline" }}
      >
        unblock
      </UI.Link>{" "}
      or{" "}
      <UI.Link
        color="neutral.main"
        href="mailto:support@goodgym.org?subject=Code of Conduct violation"
        sx={{ textDecoration: "underline" }}
      >
        report
      </UI.Link>{" "}
      them.
    </UI.Typography>
  ) : (
    <UI.Typography color="neutral.main">
      <UI.Link
        color="neutral.main"
        onClick={block}
        sx={{ textDecoration: "underline" }}
      >
        Block
      </UI.Link>{" "}
      or{" "}
      <UI.Link
        color="neutral.main"
        href="mailto:support@goodgym.org?subject=Code of Conduct violation"
        sx={{ textDecoration: "underline" }}
      >
        report
      </UI.Link>{" "}
      {runner.name}
    </UI.Typography>
  );
};

export default BlockButton;
