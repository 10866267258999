import * as T from "@goodgym/graphql/types";
import * as Icons from "@goodgym/icons";
import _ from "lodash";
import * as u from "@goodgym/util";
import { fixtures as f } from "@goodgym/test";

type MenuItem = {
  key: string;
  icon: React.ReactNode;
  label: string;
  href?: string;
  to?: string;
};

type MenuSection = { key: string; links: MenuItem[] };

type Menu = MenuSection[];

const account = ({
  runner,
  referrer,
}: Pick<T.AuthQuery, "runner" | "referrer">): Menu =>
  [
    runner && {
      key: "profile",
      links: [
        {
          key: "profile",
          icon: <Icons.Person />,
          label: "My profile",
          to: u.links.profile(runner),
        },
        runner.pairing && {
          key: "coach",
          icon: <Icons.Sports />,
          label: "My coach",
          href: `/my/coach`,
        },
        {
          key: "team",
          icon: <Icons.People />,
          label: "My team",
          href: u.links.profile(runner, "my-team", true),
        },
        runner.needsToGetCoachVerified ? {
          key: "coach-verified",
          icon: <Icons.Lock />,
          label: "Get social visits verified",
          href: `/my/coach/verification`,
        } : {
          key: "pairings",
          icon: <Icons.House />,
          label: "My trial visits",
          to: u.links.testPairings(),
        },
        runner.needsToGetMissionVerified && {
          key: "mission-verified",
          icon: <Icons.Lock />,
          label: "Get mission verified",
          href: `/my/missions/verification`,
        },
        (!runner.donation || !runner.donation.giftaid) && {
          key: "donation",
          icon: <Icons.CardGiftcard />,
          label: "My donation",
          href: `/my/settings/donations`,
        },
        {
          key: "self-log",
          icon: <Icons.Edit />,
          label: "Self-log a good deed",
          href: u.links.selfLog(),
        },
        runner.employment && {
          key: "employer",
          icon: <Icons.People />,
          label: `${runner.employment.employer.name} leaderboard`,
          href: u.links.employer(runner.employment.employer),
        },
      ].filter(u.identity),
    },
    (u.roles.hasAnyRole(runner, [
      "CENTRAL_ADMIN",
      "TRAINER",
      "BEFRIENDING_COORDINATOR",
      "MISSION_COORDINATOR",
      "RACE_TEAM_ADMIN",
      "RUN_LEADER",
      "TASK_FORCE",
      "DOCUMENT_CHECKER",
    ]) ||
      referrer) && {
      key: "roles",
      links: [
        u.roles.hasRole(runner, "CENTRAL_ADMIN") && {
          key: "central-admin",
          icon: <Icons.Business />,
          label: "Central admin",
          href: "/central_admin",
        },
        u.roles.hasRole(runner, "CENTRAL_ADMIN") && {
          key: "opops",
          icon: <Icons.Grade />,
          label: "Ops Support Toolbox",
          to: "/v3/ops-support",
        },
        u.roles.hasRoleOrAdmin(runner, "TRAINER") && {
          key: "aa",
          icon: <Icons.Build />,
          label: "Area activator admin",
          href: "/admin",
        },
        u.roles.hasRoleOrAdmin(runner, "BEFRIENDING_COORDINATOR") && {
          key: "befriending-coordinator",
          icon: <Icons.People />,
          label: "Befriending coordinator admin",
          href: "/befriending_coordinator",
        },
        u.roles.hasRoleOrAdmin(runner, "MISSION_COORDINATOR") && {
          key: "mission-coordinator",
          icon: <Icons.ChatBubbleOutline />,
          label: "Mission coordinator admin",
          href: "/admin/mission_coordinator",
        },
        u.roles.hasRoleOrAdmin(runner, "RACE_TEAM_ADMIN") && {
          key: "race-team",
          icon: <Icons.Flag />,
          label: "Race team admin",
          href: "/race_team_admin",
        },
        u.roles.hasRoleOrAdmin(runner, "RUN_LEADER") && {
          key: "run-leader",
          icon: <Icons.Timer />,
          label: "Run leader admin",
          href: "/run_leader",
        },
        u.roles.hasRoleOrAdmin(runner, "TASK_FORCE") && {
          key: "tf",
          icon: <Icons.TrackChanges />,
          label: "TaskForce admin",
          href: "/taskforce/dashboard",
        },
        u.roles.hasRoleOrAdmin(runner, "DOCUMENT_CHECKER") && {
          key: "document-checker",
          icon: <Icons.Fingerprint />,
          label: "Document checks",
          href: "/document-checker",
        },
        referrer && {
          key: "referrers",
          icon: <Icons.Book />,
          label: "Referrer dashboard",
          href: "/referrers",
        },
      ].filter(u.identity),
    },
    {
      key: "account",
      links: [
        {
          key: "settings",
          icon: <Icons.Settings />,
          label: "Account settings",
          href: "/my/settings",
        },
      ],
    },
  ].filter(u.identity);

export default account;
