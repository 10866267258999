/** @jsxImportSource @emotion/react */
import React from "react";
import * as Icons from "@goodgym/icons";
import * as UI from "@goodgym/components";
import * as T from "@goodgym/graphql/types";
import * as u from "@goodgym/util";
import _ from "lodash";
import { useDropdown } from "@goodgym/hooks";

export type NotificationsProps = {};

const Notifications: React.FC<NotificationsProps> = ({}) => {
  const { open, onClick, onClose, anchor } = useDropdown();

  const { data } = T.useNotificationsQuery();
  const [markAllAsRead] = T.useMarkAllNotificationsAsReadMutation({
    refetchQueries: [T.NotificationsDocument],
    awaitRefetchQueries: true,
  });

  const notifications = data?.runner?.notifications || [];
  const read = notifications.filter((n) => n.read);
  const unread = notifications.filter((n) => !n.read);

  return (
    <>
      <UI.NavbarButton
        id="notifications-button"
        icon={Icons.Notifications}
        label="Notifications"
        onClick={onClick}
        aria-controls={open ? "notifications-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        counter={unread.length}
      />
      <UI.Menu
        id="notifications-menu"
        anchorEl={anchor}
        open={open}
        onClose={onClose}
        MenuListProps={{
          "aria-labelledby": "notifications-button",
        }}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
      >
        {unread.map((notification) => (
          <UI.MenuItem
            key={notification.id}
            component={UI.Link}
            href={notification.url}
          >
            <UI.ListItemIcon sx={{ pr: 2 }}>
              <UI.Avatar src={notification.imageUrl} />
            </UI.ListItemIcon>
            <UI.Box>
              <UI.Typography>{notification.content}</UI.Typography>
              <UI.Typography>
                {u.time.formatDateTime(notification.createdAt)}
              </UI.Typography>
            </UI.Box>
          </UI.MenuItem>
        ))}

        {read.map((notification) => (
          <UI.MenuItem
            key={notification.id}
            component={UI.Link}
            href={notification.url}
          >
            <UI.ListItemIcon sx={{ pr: 2 }}>
              <UI.Avatar src={notification.imageUrl} />
            </UI.ListItemIcon>
            <UI.Box>
              <UI.Typography color="neutral.main">
                {notification.content}
              </UI.Typography>
              <UI.Typography color="neutral.main">
                {u.time.formatDateTime(notification.createdAt)}
              </UI.Typography>
            </UI.Box>
          </UI.MenuItem>
        ))}

        <UI.MenuItem>
          <UI.Button
            variant="text"
            onClick={() => markAllAsRead()}
            fullWidth
            disabled={unread.length === 0}
          >
            Mark all as read
          </UI.Button>
        </UI.MenuItem>
      </UI.Menu>
    </>
  );
};

export default Notifications;
