/** @jsxImportSource @emotion/react */
import React from "react";
import * as Icons from "@goodgym/icons";
import * as UI from "@goodgym/components";
import * as T from "@goodgym/graphql/types";
import * as u from "@goodgym/util";
import _ from "lodash";

export type ReportsProps = UI.BoxProps & {
  area: NonNullable<T.AreaPageQuery["area"]>;
};

const Reports: React.FC<ReportsProps> = ({ area, ...props }) => {
  return <UI.ReportsFeed area={area} sx={{ mt: 3 }} />;
};

export default Reports;
