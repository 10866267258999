/** @jsxImportSource @emotion/react */
import React from "react";
import * as Icons from "@goodgym/icons";
import * as UI from "@goodgym/components";
import * as T from "@goodgym/graphql/types";
import * as u from "@goodgym/util";
import _ from "lodash";

export type ProfileInfoItemProps = UI.BoxProps & {
  text: string;
  icon: React.ReactNode;
  tooltip?: string | undefined;
  href?: string;
};

const ProfileInfoItem: React.FC<
  React.PropsWithChildren<ProfileInfoItemProps>
> = ({ text, tooltip, href, icon, ...props }) => {
  return (
    <UI.Box
      title={tooltip}
      {...props}
      sx={{
        ...props.sx,
        my: 2,
        color: "neutral.dark",
        display: "flex",
        alignItems: "center",
      }}
    >
      <UI.Avatar
        sx={{
          color: "neutral.main",
          width: "20px",
          height: "20px",
          mr: 1,
          backgroundColor: "unset",
        }}
      >
        {icon}
      </UI.Avatar>{" "}
      {href ? (
        <UI.Link variant="subtitle2" href={href} color="neutral.dark">
          {text}
        </UI.Link>
      ) : (
        <UI.Typography variant="subtitle2">{text}</UI.Typography>
      )}
    </UI.Box>
  );
};

export default ProfileInfoItem;
