/** @jsxImportSource @emotion/react */
import React from "react";
import * as Icons from "@goodgym/icons";
import * as UI from "@goodgym/components";
import * as T from "@goodgym/graphql/types";
import * as u from "@goodgym/util";
import _ from "lodash";
import { css } from "@emotion/react";

export type RunnerStreakProps = UI.BoxProps & {
  runnerStreak: T.RunnerStreakFragment;
};

const RunnerStreak: React.FC<RunnerStreakProps> = ({
  runnerStreak,
  ...props
}) => {
  return (
    <UI.Box
      sx={{
        opacity: runnerStreak.verified ? 1 : 0.4,
        textAlign: "center",
        px: 1,
      }}
    >
      <UI.Box sx={{ height: 80, alignItems: "top" }}>
        <img
          src={runnerStreak.imageUrl}
          alt={runnerStreak.description}
          css={css`
            width: 60px;
          `}
        />
      </UI.Box>
      <UI.Typography variant="body2">
        {runnerStreak.count}
        {" Month Streak"}
      </UI.Typography>
    </UI.Box>
  );
};

export default RunnerStreak;
