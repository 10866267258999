import * as UI from "@goodgym/components";
import { useDeleteStory, usePublishStory } from "@goodgym/graphql/mutations";
import * as T from "@goodgym/graphql/types";
import React from "react";

export type AdminMenuProp = UI.BoxProps & {
  story: T.StoryFragment;
  runner?: Optional<T.CurrentRunnerFragment>;
};

const AdminMenu: React.FC<AdminMenuProp> = ({ story, runner }) => {
  const deleteStory = useDeleteStory({ story, runner });
  const publishStory = usePublishStory({ story, runner });

  return (
    <UI.Box sx={{ mt: 2, mb: 4 }}>
      <UI.Typography variant="h5" sx={{ mb: 2 }}>
        Edit Story
      </UI.Typography>
      <UI.Box sx={{ display: "flex", gap: "5px" }}>
        <UI.Button href={`/stories/${story.slug}/edit`} size="small">
          Edit
        </UI.Button>
        {(story.publishedAt && !story.discardedAt) && (
          <UI.Button onClick={publishStory} size="small">
            Draft Story
          </UI.Button>
        )}

        {!story.discardedAt && (
          <UI.Button onClick={deleteStory} size="small">
            Delete Story
          </UI.Button>
        )}

        {(!story.publishedAt &&
          !story.discardedAt) && (
            <UI.Button onClick={publishStory} size="small">
              Publish Story
            </UI.Button>
          )}

        {story.discardedAt && (
          <UI.Button onClick={deleteStory} size="small">
            Restore Story
          </UI.Button>
        )}
      </UI.Box>
    </UI.Box>
  );
};

export default AdminMenu;
