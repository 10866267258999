import * as T from "@goodgym/graphql/types";
import * as Icons from "@goodgym/icons";
import _ from "lodash";

const roles = [
  "CENTRAL_ADMIN",
  "TECH_TEAM",
  "TASK_FORCE",
  "RUN_LEADER",
  "TRAINER",
  "BEFRIENDING_COORDINATOR",
  "RACE_TEAM_ADMIN",
  "MISSION_COORDINATOR",
  "DOCUMENT_CHECKER",
] as const;

export type Role = typeof roles[number];

export const hasRoleOrAdmin = (
  runner: { roles: string[] } | undefined | null,
  role: Role
) => hasRole(runner, role) || hasRole(runner, "CENTRAL_ADMIN");

export const hasRole = (
  runner: { roles: string[] } | undefined | null,
  role: Role
): boolean => {
  return !!runner?.roles?.includes(role);
};

export const hasAnyRole = (
  runner: Optional<{ roles: string[] }>,
  roles: Role[]
): boolean => {
  return _.some(roles, (role) => runner?.roles?.includes(role)) || false;
};

export const hasSessionAdmin = (
  runner: Optional<T.CurrentRunnerFragment>,
  session: T.SessionFragment & {
    area?: Optional<T.AreaFragment>;
    ledBy?: Optional<T.RunnerFragment>;
  }
) => {
  if (!runner) {
    return false;
  } else if (hasAnyRole(runner, ["CENTRAL_ADMIN", "TRAINER", "RUN_LEADER"])) {
    return true;
  } else if (runner.id === session.ledBy?.id) {
    return true;
  } else if (!runner.areas.find((area) => area.id === session.area?.id)) {
    return false;
  } else {
    return hasAnyRole(runner, ["TASK_FORCE"]);
  }
};

export const hasAreaAdmin = (
  runner: Optional<T.CurrentRunnerFragment>,
  area: Optional<
    T.AreaFragment & { areaActivator?: Optional<T.RunnerFragment> }
  >
) => {
  if (!runner) {
    return false;
  } else if (!area) {
    return false;
  } else if (hasRole(runner, "CENTRAL_ADMIN")) {
    return true;
  } else if (area.areaActivator?.id === runner.id) {
    return true;
  } else {
    return false;
  }
};

export const colors = {
  trainer: {
    color: "white.main",
    backgroundColor: "primary.main",
  },
  runLeader: {
    color: "white.main",
    backgroundColor: "secondary.dark",
  },
  taskForce: {
    color: "white.main",
    backgroundColor: "warning.main",
  },
};

export const icon = (role: string) => {
  switch (role) {
    case "TRAINER":
      return <Icons.Star sx={colors.trainer} />;
    case "RUN_LEADER":
      return <Icons.DirectionsRun sx={colors.runLeader} />;
    case "TASK_FORCE":
      return <Icons.Star sx={colors.taskForce} />;
    default:
      return null;
  }
};

export const label = (role: string) => {
  switch (role) {
    case "TRAINER":
      return "Area activator";
    case "RUN_LEADER":
      return "Run leader";
    case "TASK_FORCE":
      return "TaskForce";
    default:
      return null;
  }
};

export const description = (role: string) => {
  switch (role) {
    case "TRAINER":
      return "Area activators support the growth of GoodGym, are passionate about fitness and community, and are here to help us do more.";
    case "RUN_LEADER":
      return "Volunteer run leaders who have completed the England Athletics Leadership in Running Fitness (LiRF) training.";
    case "TASK_FORCE":
      return "TaskForce supports the operation of GoodGym in their area. They manage social media accounts, support new GoodGymers and organise sessions.";
    default:
      return undefined;
  }
};
