import * as UI from "@goodgym/components";
import * as Images from "../../images";

const GetInvolvedCard = () => {
  return (
    <UI.Box
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "flex-end",
        mt: "-200px",
      }}
    >
      <UI.Card
        sx={{
          width: { xs: "80%", sm: "55%", md: "45%" },
          borderRadius: "10px",
        }}
      >
        <UI.CardHeader
          title={
            <UI.Typography
              variant="h2"
              sx={{
                fontStyle: "italic",
                textTransform: "uppercase",
                textAlign: "center",
              }}
            >
              Get involved
            </UI.Typography>
          }
        />
        <UI.CardContent sx={{ mt: -1 }}>
          <UI.Typography sx={{ mb: 2, textAlign: "center" }} variant="body1">
            Join GoodGym and become social visit verified so you can start
            making a difference to an older person’s life. It’s simple and we’ll
            guide you through every step.
          </UI.Typography>
          <UI.Box
            sx={{
              width: "110%",
              borderTop: "10px solid #E11018",
              ml: -2,
            }}
          />
          <UI.Box
            sx={{
              display: "flex",
              justifyContent: "center",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <img
              src={Images.Badge}
              alt="badge"
              style={{ height: "120px", width: "auto", marginTop: 8 }}
            />
            <UI.Typography variant="h4" sx={{ mb: 1, textAlign: "center" }}>
              Becoming social visit verified
            </UI.Typography>
            <UI.Typography variant="body1" sx={{ textAlign: "center", my: 1 }}>
              We work to make GoodGym as safe as possible. Part of this involves
              you providing references, doing a short online training module and
              getting a background check.
            </UI.Typography>
            <UI.Typography variant="body2" sx={{ textAlign: "center", my: 1 }}>
              Getting verified only takes approximately four hours of your time.
            </UI.Typography>
            <UI.Button href={"https://www.goodgym.org/my/coach/verification"}>
              Get Social Visit Verified
            </UI.Button>
          </UI.Box>
        </UI.CardContent>
      </UI.Card>
    </UI.Box>
  );
};

export default GetInvolvedCard;
