/** @jsxImportSource @emotion/react */
import React from "react";
import * as Icons from "@goodgym/icons";
import * as UI from "@goodgym/components";
import * as T from "@goodgym/graphql/types";
import * as u from "@goodgym/util";
import _ from "lodash";
import { css } from "@emotion/react";

export type AreaNameProps = UI.LinkProps & {
  area: T.AreaFragment;
};
const AreaName: React.FC<AreaNameProps> = ({ area, ...props }) => {
  const theme = UI.useTheme();

  return (
    <UI.Link
      {...props}
      to={u.links.area(area)}
      variant="h3"
      sx={{
        display: "inline-block",
        py: 1,
        px: 2,
        mb: 1,
        bgcolor: "black.main",
        color: "white.main",
        "&:hover": { textDecoration: "none" },
        ...props.sx,
      }}
      css={css`
        box-shadow: -4px 4px ${theme.palette.primary.main};
      `}
    >
      {area.name}
    </UI.Link>
  );
};
// @ts-ignore
AreaName.muiName = UI.Link.muiName;
export default AreaName;
