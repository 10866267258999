/** @jsxImportSource @emotion/react */
import React from "react";
import * as Icons from "@goodgym/icons";
import * as UI from "@goodgym/components";
import * as T from "@goodgym/graphql/types";
import * as u from "@goodgym/util";
import _ from "lodash";
import { useDeleteComment } from "@goodgym/graphql/mutations";

export type CommentMenuProps = UI.BoxProps & {
  comment: T.CommentFragment;
  commentable: T.CommentableItem;
  runner?: Optional<T.CurrentRunnerFragment>;
};

const CommentMenu: React.FC<CommentMenuProps> = ({
  comment,
  commentable,
  runner,
  ...props
}) => {
  const [toggle, setToggle] = React.useState<null | HTMLElement>(null);

  const deleteComment = useDeleteComment({ comment, commentable, runner });

  const open: React.MouseEventHandler<HTMLButtonElement & HTMLAnchorElement> = (
    e
  ) => {
    e.preventDefault();
    setToggle(e.currentTarget);
  };

  const close = () => setToggle(null);
  return (
    <>
      <UI.Link
        onClick={open}
        sx={{
          ...props.sx,
          color: "neutral.dark",
          "&:hover": { textDecoration: "none" },
        }}
      >
        •••
      </UI.Link>
      <UI.Menu
        id="user-menu"
        anchorEl={toggle}
        anchorOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        keepMounted
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        open={!!toggle}
        onClick={close}
        onClose={close}
      >
        <UI.MenuItem onClick={deleteComment}>
          <UI.ListItemIcon>
            <Icons.Delete />
          </UI.ListItemIcon>
          Delete comment
        </UI.MenuItem>
      </UI.Menu>
    </>
  );
};

export default CommentMenu;
