import * as UI from "@goodgym/components";
import Header from "@goodgym/components/Header";
import * as images from "@goodgym/pages/HomePage/images";
import * as u from "@goodgym/util";
import Guidelines from "./components/Guidelines";
import TableContents from "./components/TableContents";

const CodeOfConductPage = () => {
  return (
    <>
      <UI.Seo
        title={`Code of Conduct`}
        description={
          "All participants on any GoodGym activity to adhere to the following code of conduct "
        }
        image={images.landing.jpg}
        url="/"
      />
      <Header title={"The GoodGym Code of Conduct"} />
      <Guidelines />
      <TableContents />
    </>
  );
};

export default CodeOfConductPage;
