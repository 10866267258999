
import * as UI from "@goodgym/components";

export type TestimonialProps = {
  testimonials?: {
    avatarUrl: string;
    quote: string;
    by: string;
  }[];
};

const TestimonialRunners: React.FC<React.PropsWithChildren<TestimonialProps>> = ({
  testimonials,
}) => {
  return (
    <UI.Container>
      <UI.Grid
        container
        spacing={7}
        sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}
      >
        {testimonials?.map((testimonial, index) => (
          
            <UI.Grid
              item
              key={index}
              xs={12}
              md={3}
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <UI.Avatar
                src={testimonial.avatarUrl}
                sx={{ height: "150px", width: "150px" }}
              />
              <UI.Box sx={{ display: "flex" }}>
                <UI.Typography variant="h5" sx={{ mr: 1, mt: 1 }}>
                  ||
                </UI.Typography>
                <UI.Typography variant="body1" sx={{ mt: 2 }}>
                  {testimonial.quote}
                </UI.Typography>
              </UI.Box>

              <UI.Typography
                variant="body1"
                color={"primary.main"}
                sx={{ mt: 2, textAlign: "right" }}
              >
                {testimonial.by}
              </UI.Typography>
            </UI.Grid>
          
        ))}
      </UI.Grid>
    </UI.Container>
  );
};

export default TestimonialRunners;
