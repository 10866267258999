/** @jsxImportSource @emotion/react */
import React from "react";
import * as Icons from "@goodgym/icons";
import * as UI from "@goodgym/components";
import * as T from "@goodgym/graphql/types";
import * as u from "@goodgym/util";
import _ from "lodash";
import { css } from "@emotion/react";

export type SnippetProps = UI.BoxProps & {
  content: string;
  onClick?: React.MouseEventHandler;
  href: string;
};

const Snippet: React.FC<React.PropsWithChildren<SnippetProps>> = ({
  href,
  content,
  ...props
}) => {
  const theme = UI.useTheme();
  return (
    <>
      <UI.Box
        css={css`
          position: relative;
          max-height: 8em;
          overflow: hidden;
          &:after {
            content: "";
            position: absolute;
            z-index: 1;
            bottom: 0;
            left: 0;
            pointer-events: none;
            background-image: linear-gradient(
              to bottom,
              rgba(255, 255, 255, 0),
              rgba(255, 255, 255, 1) 90%
            );
            width: 100%;
            height: 3em;
          }
          a,
          a:visited {
            color: ${theme.palette.primary.main};
          }
          p:first-of-type {
            margin-top: ${theme.spacing(1)};
          }
        `}
      >
        <UI.Box dangerouslySetInnerHTML={{ __html: content }} />
      </UI.Box>
      <UI.Button
        href={href}
        variant="text"
        sx={{ ml: -1, color: "secondary.dark" }}
      >
        Read more
      </UI.Button>
    </>
  );
};

export default Snippet;
