/** @jsxImportSource @emotion/react */
import React from "react";
import * as Icons from "@goodgym/icons";
import * as UI from "@goodgym/components";
import * as T from "@goodgym/graphql/types";
import * as u from "@goodgym/util";
import _ from "lodash";

export type RunnerLinksProps = { runners: T.RunnerFragment[] };

const RunnerLinks: React.FC<RunnerLinksProps> = ({ runners }) => {
  if (runners.length === 0) {
    return null;
  } else if (runners.length === 1) {
    return (
      <>
        <UI.RunnerLink runner={runners[0]} />
      </>
    );
  } else if (runners.length === 2) {
    return (
      <>
        <UI.RunnerLink runner={runners[0]} /> and{" "}
        <UI.RunnerLink runner={runners[1]} />
      </>
    );
  } else {
    return (
      <>
        <UI.RunnerLink runner={runners[0]} /> and{" "}
        <UI.Tooltip
          title={
            <UI.Box sx={{ textAlign: "center" }}>
              {runners.slice(1).map((runner) => (
                <UI.Typography key={runner.id} variant="body2">
                  {runner.name}
                </UI.Typography>
              ))}
            </UI.Box>
          }
        >
          <UI.Box component="span">{runners.length - 1} more</UI.Box>
        </UI.Tooltip>
      </>
    );
  }
};

export default RunnerLinks;
