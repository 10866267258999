import { useAuth } from "@goodgym/auth";
import * as T from "@goodgym/graphql/types";
import { useDrawer } from "@goodgym/hooks";
import * as u from "@goodgym/util";
import _ from "lodash";
import React from "react";
import * as h from "./helpers";
import { EnabledSections, Filters } from "./types";
import { useSearchParams } from "react-router-dom";

/*
 * The useSessionsFeed hook allows us to pass in an area, filters, or enabled
 * sections to override the defaults applied to the filter. This enables the
 * component to be reused on different pages with different needs. For example,
 * on the main sessions listings page /v3/sessions, we store the user's last
 * used filters in localStorage, so we retrieve and pass those in. On the
 * other hand, on the area page we want to override the user's area to show
 * only sessions in the page the area is for, regardless of the area of the
 * user looking at it.
 */
export const useSessionsFeed = (props?: {
  areaIds: string[];
  types: string[];
  sections?: Partial<EnabledSections>;
  postcode?: string;
  maxDistance: number;
  from: Date;
}) => {
  const { runner } = useAuth();

  const [searchParams] = useSearchParams();
  const postcodeUrl = u.format.postcode(searchParams.get("postcode"));
  const typeUrl = searchParams.get("type");
  console.log("typeurl", typeUrl)


  const [fetchSessions, { error, data }] = T.useSessionsFeedLazyQuery({
    fetchPolicy: "cache-and-network",
  });

  // State for managing the currently selected filters and the drawer
  // for showing them.
  const filtersDrawer = useDrawer();

  console.log("postcodefilter", postcodeUrl);
  const defaultFilters = {
    from: new Date(),
    types: !typeUrl || typeUrl === "all"
        ? [
            "GroupRun",
            "CommunityMission",
            "Mission",
            "DocumentCheck",
            "Party",
            "TrainingSession",
            "Race",
          ]
        : typeUrl === "Group Sessions"
        ? ["CommunityMission", "GroupRun"]
        : [typeUrl],
    areaIds: props?.areaIds
      ? props.areaIds
      : postcodeUrl
      ? []
      : runner?.areas.map((a) => a.id) || [],
    postcode: props?.postcode
      ? props.postcode
      : postcodeUrl
      ? postcodeUrl
      : runner?.postcode || null,
    maxDistance: 5,
  };

  const [filters, setFilters] = React.useState(defaultFilters);
  console.log("filterDefault", defaultFilters);
  console.log("filtersreal", filters)

  const defaultSections = {
    areas: true,
    postcode: !!filters.areaIds,
  };


  const resetFilters = () => {
    setFilters(defaultFilters);
  };

  const updateFilters = (updatedFilters: Partial<Filters>) => {
    setFilters((currentFilters) => ({
      ...currentFilters,
      ...updatedFilters,
    }));
  };

  const [sections, setSections] = React.useState<EnabledSections>({
    ...defaultSections,
    ...props?.sections,
  });

  const toggleSection = (section: keyof EnabledSections) => {
    // setSections({ ...sections, [section]: !sections[section] });
    if (section === "areas") {
      setSections({ ...sections, areas: true, postcode: false });
    } else {
      setSections({ ...sections, areas: false, postcode: true });
    }
  };

  const [areas, setAreas] = React.useState<T.AreaFragment[]>(
    runner?.areas || []
  );

  const addArea = (area: T.AreaFragment) => {
    setAreas(_.union(areas, [area]));
  };

  // handle the horrors of pagination
  const { items, cursors, more } = h.collate(data);

  // function for the InfiniteScroll component
  const fetchMore = React.useCallback(() => {
    if (more) {
      fetchSessions({
        variables: {
          ...h.getQueryVariables(sections, filters, cursors),
        },
      });
    }
  }, [fetchSessions, cursors, filters, more, sections]);

  // refetch sessions every time one of the filters is changed or a filter
  // section is enabled or disabled
  React.useEffect(() => {
    fetchSessions({
      variables: h.getQueryVariables(sections, filters, {}),
    });
  }, [filters, sections, fetchSessions]);

  return {
    runner,
    error,
    loading: !data,
    items: _.filter(items, (item) => h.applyFilters(filters, item)),
    more,
    fetchMore,
    drawers: { filters: filtersDrawer },
    filters: { values: filters, update: updateFilters, reset: resetFilters },
    sections: {
      enabled: sections,
      toggle: toggleSection,
    },
    areas: { options: areas, add: addArea },
  };
};
