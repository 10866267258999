/** @jsxImportSource @emotion/react */
import React from "react";
import * as Icons from "@goodgym/icons";
import * as UI from "@goodgym/components";
import * as T from "@goodgym/graphql/types";
import * as u from "@goodgym/util";
import _ from "lodash";

export type Microsoft365IconProps = UI.BoxProps & {};

const Microsoft365Icon: React.FC<Microsoft365IconProps> = ({ ...props }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 278050 333334"
      shapeRendering="geometricPrecision"
      imageRendering="optimizeQuality"
      fillRule="evenodd"
    >
      <path
        fill="#ea3e23"
        d="M278050 305556l-29-16V28627L178807 0 448 66971l-448 87 22 200227 60865-23821V80555l117920-28193-17 239519L122 267285l178668 65976v73l99231-27462v-316z"
      />
    </svg>
  );
};

export default Microsoft365Icon;
