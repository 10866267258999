/** @jsxImportSource @emotion/react */
import * as UI from "@goodgym/components";
import * as T from "@goodgym/graphql/types";
import React from "react";
import PendingIcon from "@mui/icons-material/Pending";
import { useCancelTestPairing } from "../hooks/CancelTestPairing";
import { useParams } from "react-router-dom";

export type TestPairingPendingProps = UI.BoxProps & {
  testPairing: T.TestPairingFragment;
};

const TestPairingPending: React.FC<
  React.PropsWithChildren<TestPairingPendingProps>
> = ({ ...props }) => {
  const { id } = useParams();
  const { coach } = props.testPairing;

  const [cancelNotes, setCancelNotes] = React.useState("");

  const cancelTestPairing = useCancelTestPairing({
    coach,
    testPairingId: props.id || id!,
    cancellationNotes: cancelNotes,
  });
  return (
    <UI.Box
      {...props}
      sx={{
        borderStyle: "solid",
        borderWidth: "0.5px",
        p: 2,
        borderColor: "#E11018",
        borderRadius: "20px",
      }}
    >
      <UI.Typography>
        <PendingIcon fontSize="small" sx={{ mr: 1, mb:3 }} />
        Your trial visit request is being processed.
      </UI.Typography>

      <UI.Typography>

      If you wish to cancel the trial visit, please complete the section below
      </UI.Typography>

      <UI.TextField
        sx={{ my: 2 }}
        fullWidth
        id="outlined-multiline-flexible"
        label="Reasons for cancellations"
        multiline
        maxRows={4}
        onChange={(event) => {
          setCancelNotes(event.target.value);
        }}
      />
      <UI.Button sx={{ width: { md: "50%" } }} onClick={cancelTestPairing}>
        Cancel this trial visit
      </UI.Button>
    </UI.Box>
  );
};

export default TestPairingPending;
