import React from "react";
import * as UI from "@goodgym/components";
import LoginIcon from "@mui/icons-material/Login";

const RunnerNotLoggedIn = () => {
  return (
    <>
      <UI.Container
        sx={{
          height: "100vh",
          flexGrow: 1,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <UI.Card
          sx={{
            width: "80%",
            textAlign: "center",
            height: "250px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <UI.Grid container>
            <UI.Grid item xs={12}>
              <LoginIcon sx={{fontSize:"44px", my:1, color:"red"}} />
            </UI.Grid>
            <UI.Grid item xs={12}>
              <UI.Typography variant="h3">
                Please log in to view this page
              </UI.Typography>
            </UI.Grid>
            <UI.Grid item xs={12} sx={{ my: 2 }}>
              <UI.SignInButton variant="text" sx={{fontSize:"24px"}}>Log in</UI.SignInButton>
            </UI.Grid>
          </UI.Grid>
        </UI.Card>
      </UI.Container>
    </>
  );
};

export default RunnerNotLoggedIn;
