/** @jsxImportSource @emotion/react */
import React from "react";
import * as Icons from "@goodgym/icons";
import * as UI from "@goodgym/components";
import * as T from "@goodgym/graphql/types";
import * as u from "@goodgym/util";
import _ from "lodash";
import { State } from "../../../types";

export type DistanceFilterProps = UI.BoxProps & {
  state: Pick<State, "sections" | "filters">;
};

const DistanceFilter: React.FC<DistanceFilterProps> = ({ state, ...props }) => {
  const { sections, filters } = state;

  const enabled = sections.enabled.postcode && filters.values.postcode;

  return (
    <UI.Box {...props}>
      <UI.Typography variant="subtitle1" sx={{ mt: 2, mb: 1 }}>
        <UI.Grid container>
          <UI.Grid item sx={{ alignSelf: "center", flexGrow: 1 }}>
            Distance
          </UI.Grid>
        </UI.Grid>
      </UI.Typography>
      <UI.Select
        value={filters.values.maxDistance}
        fullWidth
        disabled={!enabled}
        onChange={(e) => {
          const maxDistance = parseInt(e.target.value as string);
          if (!isNaN(maxDistance)) {
            filters.update({ maxDistance });
          }
        }}
      >
        {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 12, 15, 20].map((n) => (
          <UI.MenuItem key={n} value={n}>
            Within {n}km
          </UI.MenuItem>
        ))}
      </UI.Select>
    </UI.Box>
  );
};

export default DistanceFilter;
