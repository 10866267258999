/** @jsxImportSource @emotion/react */
import React from "react";
import * as Icons from "@goodgym/icons";
import * as UI from "@goodgym/components";
import * as T from "@goodgym/graphql/types";
import * as u from "@goodgym/util";
import _ from "lodash";

const StyleGuidePage = () => {
  const variants = [
    "h1",
    "h2",
    "h3",
    "h4",
    "h5",
    "h6",
    "subtitle1",
    "subtitle2",
    "body1",
    "body2",
    "button",
    "caption",
    "overline",
  ];

  return (
    <UI.Container sx={{ py: 3 }}>
      {variants.map((v) => (
        <UI.Box
          key={v}
          sx={{ my: 3, borderBottom: "1px solid", borderColor: "neutral.main" }}
        >
          <UI.Typography variant={v as any} sx={{ mb: 1 }}>
            {v}. The quick brown fox...
          </UI.Typography>
        </UI.Box>
      ))}
      <UI.Divider sx={{ my: 2 }} />

      <UI.Grid container spacing={1}>
        <UI.Grid item>
          <UI.Button variant="contained" color="primary">
            Primary
          </UI.Button>
        </UI.Grid>
        <UI.Grid item>
          <UI.Button variant="contained" color="secondary">
            Secondary
          </UI.Button>
        </UI.Grid>
        <UI.Grid item>
          <UI.Button variant="text">Text</UI.Button>
        </UI.Grid>
      </UI.Grid>

      <UI.Divider sx={{ my: 2 }} />

      <UI.Grid container spacing={1}>
        <UI.Grid item>
          <UI.Alert severity="info">Info</UI.Alert>
        </UI.Grid>
        <UI.Grid item>
          <UI.Alert severity="success">Success</UI.Alert>
        </UI.Grid>
        <UI.Grid item>
          <UI.Alert severity="warning">Warning</UI.Alert>
        </UI.Grid>
        <UI.Grid item>
          <UI.Alert severity="error">Error</UI.Alert>
        </UI.Grid>
      </UI.Grid>
    </UI.Container>
  );
};

export default StyleGuidePage;
