/** @jsxImportSource @emotion/react */
import * as UI from "@goodgym/components";
import * as u from "@goodgym/util";
import * as images from "./images";

const PowerTheGoodPage = () => {
  return (
    <>
      <UI.Seo title="Power the good" url={u.links.powerTheGood()} />
      <UI.Container>
        <UI.Grid container justifyContent="center">
          <UI.Grid item xs={12} md={8}>
            <UI.Box
              component="img"
              src={images.powerTheGood}
              sx={{ maxWidth: "100%" }}
            />
            <UI.Box id="firstParagraph" sx={{ my: 3 }}>
              <UI.Typography variant="h1">
                Join our autumn campaign
              </UI.Typography>
              <UI.Typography variant="h4" sx={{ my: 3 }}>
                The Power the Good campaign is a drive to help 2000 community
                organisations and older people this Autumn.{" "}
              </UI.Typography>
              <UI.Typography variant="body1">
                The campaign is running from{" "}
                <UI.Typography variant="subtitle1" sx={{ display: "inline" }}>
                  20th September to 30th November 2022{" "}
                </UI.Typography>
                when we’ll be coming together to support as many people as we
                can through GoodGym sessions across the country.
              </UI.Typography>
              <br />
              <UI.Typography variant="body1">
                Join us on this Power the Good campaign journey as we’ll track
                our way to the{" "}
                <UI.Typography variant="subtitle1" sx={{ display: "inline" }}>
                  2000 target, pledge individual goals, have competitions and
                  play games
                </UI.Typography>{" "}
                (as well as resources to looking after your own wellbeing too).
              </UI.Typography>
              <br />
              <UI.Typography variant="body1">Will you join us?</UI.Typography>
            </UI.Box>
            <UI.Button
              component={UI.Link}
              href="https://bit.ly/InviteToPowerTheGood"
              color="primary"
              variant="contained"
              sx={{ mb: 3 }}
            >
              Find out more
            </UI.Button>
            <UI.Box
              component="img"
              src={images.newFitnessGoal}
              sx={{ maxWidth: "100%" }}
            />

            <UI.Typography variant="h4" sx={{ my: 3 }}>
              GoodGymers: Find your new goal
            </UI.Typography>
            <UI.Box id="paragraph2" sx={{ my: 3 }}>
              <UI.Typography variant="body1">
                Whether you joined GoodGym for the do good or the get fit (or
                both),{" "}
                <UI.Typography variant="subtitle1" sx={{ display: "inline" }}>
                  remember that feeling that pushed you to join?
                </UI.Typography>{" "}
                Now is a great time to revitalise your ‘why’ and set a new goal…
              </UI.Typography>
              <br />
              <UI.Typography variant="body1">
                We have a great season of activities to look forward to. So
                let’s beat that target, pledge our individual fitness and
                wellbeing goals, enjoy competitions and play some games.
              </UI.Typography>
              <br />
              <UI.Typography variant="body1">
                <UI.Typography variant="subtitle1" sx={{ display: "inline" }}>
                  We’ll stay motivated together
                </UI.Typography>
                , keep fit, prioritise our wellbeing and help even more people.
                Ready?
              </UI.Typography>
            </UI.Box>
            <UI.Button
              component={UI.Link}
              href="https://bit.ly/GoodGymerPowerTheGood"
              color="primary"
              variant="contained"
              sx={{ mb: 3 }}
            >
              Take me to my invite
            </UI.Button>
            <UI.Box
              component="img"
              src={images.taskOwner}
              sx={{ maxWidth: "100%" }}
            />

            <UI.Typography variant="h4" sx={{ my: 3 }}>
              Community organisation looking for support?
            </UI.Typography>
            <UI.Box id="paragraph3" sx={{ my: 3 }}>
              <UI.Typography variant="body1">
                Our Power the Good campaign is looking for more projects,
                initiatives and charities to support during these difficult
                times.
              </UI.Typography>
              <br />
              <UI.Typography variant="body1">
                Whether it’s restocking food banks, gardening tasks for
                community spaces, supporting community kitchens, sorting charity
                stock, painting community centres or marshalling events, we have
                an amazing community of GoodGymers ready to help. We’re always
                happy to hear new ideas of ways we can support.
              </UI.Typography>
              <br />
              <UI.Typography variant="body1">
                Want to know more? We have an info guide for you, simply click
                below.
              </UI.Typography>
            </UI.Box>
            <UI.Button
              component={UI.Link}
              href="https://bit.ly/PowerTheGoodTaskOwner"
              color="primary"
              variant="contained"
              sx={{ mb: 8 }}
            >
              Take me to the info guide
            </UI.Button>
          </UI.Grid>
        </UI.Grid>
      </UI.Container>
    </>
  );
};

export default PowerTheGoodPage;
