/** @jsxImportSource @emotion/react */
import React from "react";
import * as Icons from "@goodgym/icons";
import * as UI from "@goodgym/components";
import * as T from "@goodgym/graphql/types";
import * as u from "@goodgym/util";
import _ from "lodash";
import * as C from "..";
import * as menus from "@goodgym/menus";

export type MegaMenuProps = UI.BoxProps & {};

const MegaMenu: React.FC<MegaMenuProps> = ({ ...props }) => {
  const [open, setOpen] = React.useState(false);
  const handleClick = (e: React.MouseEvent<HTMLAnchorElement>) => {
    e.preventDefault();
    setOpen(true);
  };
  const onClose = () => {
    setOpen(false);
  };

  return (
    <UI.Box {...props}>
      <UI.NavbarButton icon={Icons.Menu} label="Menu" onClick={handleClick} />
      <UI.Box sx={{ position: "absolute" }}>
        <UI.Popover
          id="megamenu"
          marginThreshold={0}
          anchorReference="anchorPosition"
          anchorPosition={{ top: 60, left: 0 }}
          anchorOrigin={{
            vertical: "top",
            horizontal: "center",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "center",
          }}
          open={open}
          onClose={onClose}
          elevation={2}
          PaperProps={{
            style: {
              borderTopRightRadius: 0,
              borderTopLeftRadius: 0,
              width: "100%",
              maxWidth: "100%",
              maxHeight: "calc(100vh - 60px)",
              left: 0,
              right: 0,
            },
          }}
        >
          <UI.Container sx={{ p: 3 }}>
            <UI.Grid container spacing={1}>
              {menus.more.map(({ key, label, links }) => (
                <UI.Grid key={key} item xs={12} md={4}>
                  <UI.Typography variant="subtitle1" color="neutral.dark">
                    {label}
                  </UI.Typography>
                  <UI.List onClick={onClose}>
                    {links.map(({ key, label, icon, href }) => (
                      <C.MegaMenuItem
                        key={key}
                        icon={icon}
                        label={label}
                        href={href}
                      />
                    ))}
                  </UI.List>
                </UI.Grid>
              ))}
            </UI.Grid>
          </UI.Container>
        </UI.Popover>
      </UI.Box>
    </UI.Box>
  );
};

export default MegaMenu;
