/** @jsxImportSource @emotion/react */
import React from "react";
import * as Icons from "@goodgym/icons";
import * as UI from "@goodgym/components";
import {
  Breadcrumbs as MUIBreadcrumbs,
  BreadcrumbsProps as MUIBreadcrumbsProps,
} from "@mui/material";
import * as T from "@goodgym/graphql/types";
import * as u from "@goodgym/util";
import _ from "lodash";

type Crumb = [string, string];

export type BreadcrumbsProps = MUIBreadcrumbsProps & {
  crumbs: Crumb[];
};

const Breadcrumbs: React.FC<BreadcrumbsProps> = ({ crumbs, ...props }) => {
  return (
    <MUIBreadcrumbs
      aria-label="breadcrumb"
      separator={<Icons.NavigateNext fontSize="small" />}
      {...props}
      sx={{ mb: 2, ...props.sx }}
    >
      {_.initial(crumbs).map(([name, to], index) => (
        <UI.Link key={index} to={to} variant="body2" color="black.main">
          {name}
        </UI.Link>
      ))}
      <UI.Typography variant="body2" component="span">
        {_.first(_.last(crumbs))}
      </UI.Typography>
    </MUIBreadcrumbs>
  );
};

export default Breadcrumbs;
