/** @jsxImportSource @emotion/react */
import React from "react";
import * as Icons from "@goodgym/icons";
import * as UI from "@goodgym/components";
import * as T from "@goodgym/graphql/types";
import * as u from "@goodgym/util";
import _ from "lodash";
import {
  useJoinWaitingList,
  useLeaveWaitingList,
  useUnSignUpFromSession,
} from "@goodgym/graphql/mutations";
import { useHover } from "usehooks-ts";
import { useAuth } from "@goodgym/auth";

export type SignupButtonProps = UI.ButtonProps & {
  session: T.SessionPageQuery["session"];
  onClick: React.MouseEventHandler;
};

const SignupButton: React.FC<React.PropsWithChildren<SignupButtonProps>> = ({
  session,
  onClick,
  ...props
}) => {
  const { runner } = useAuth();
  const cancelSignup = useUnSignUpFromSession();
  const joinWaitingList = useJoinWaitingList();
  const leaveWaitingList = useLeaveWaitingList();
  const ref = React.useRef(null);
  const hover = useHover(ref);


  const TooLate = () => (
    <UI.Button
      variant="contained"
      color="error"
      disabled
      fullWidth
      ref={ref}
      {...props}
    >
      {session.registerDeadline
        ? "Too late to register"
        : "Session is in the past"}
    </UI.Button>
  );

  const Cancelled = () => (
    <UI.Button
      variant="contained"
      color="error"
      disabled
      fullWidth
      ref={ref}
      {...props}
    >
      Cancelled
    </UI.Button>
  );

  const AlreadySignedUp = () => (
    <UI.Button
      variant="outlined"
      color={hover ? "error" : "success"}
      onClick={cancelSignup(session)}
      fullWidth
      ref={ref}
      {...props}
    >
      {hover ? "Cancel" : "You're going!"}
    </UI.Button>
  );

  const JoinWaitingList = () => (
    <UI.Button
      variant={hover ? "contained" : "outlined"}
      color="error"
      onClick={leaveWaitingList(session)}
      fullWidth
      ref={ref}
      {...props}
    >
      <Icons.Notifications sx={{ mr: 1 }} />
      {hover ? "Leave notification list" : "On notification list"}
    </UI.Button>
  );

  const LeaveWaitingList = () => (
    <UI.Button
      variant="outlined"
      color="error"
      onClick={joinWaitingList(session)}
      fullWidth
      ref={ref}
      {...props}
    >
      <Icons.Notifications sx={{ mr: 1 }} />
      Join notification list
    </UI.Button>
  );

  const SignIn = () => (
    <UI.SignInButton fullWidth variant="outlined">
      Sign in to register
    </UI.SignInButton>
  );

  const SignUpToSession = () => (
    <UI.Button
      color="secondary"
      fullWidth
      onClick={onClick}
      ref={ref}
      {...props}
      aria-label="sign up button"
    >
      Sign up now
    </UI.Button>
  );
  /*
   * If a session is cancelled, it shows as cancelled
   * even if you're signed up or logged out
   *
   * If you've already signed up, you show as signed up even if the deadline
   * has passed.
   *
   * If it's a mission and it's not cancelled and you're not already signed up,
   * we don't show a button as you can't sign up to a confirmed mission.
   *
   * If you're not logged in and none of the above is true, we ask you to log in
   * and don't present any other options until you have.
   *
   * If the session is full and you're logged in then we let you join the waiting
   * list.
   *
   * If you're on the waiting list you can leave it.
   *
   * If none of the above are true, you can sign up to the session.
   */
  if (!!session.cancelledAt) {
    return <Cancelled />;
  } else if (session.signups.find((s) => s.id === runner?.id)) {
    return <AlreadySignedUp />;
  } else if (u.session.isOlderPeopleSession(session)) {
    // you can't sign up to an already confirmed mission
    return null;
  } else if (
    u.time.isBefore(session.startedAt, session.registerDeadline) ||
    u.time.isAfter(new Date(), session.registerDeadline) ||
    u.time.isAfter(new Date(), session.startedAt)
  ) {
    return <TooLate />;
  } else if (!runner) {
    return <SignIn />;
  } else if (
    session.registerMax &&
    session.registerMax <= session.signups.length &&
    session.onWaitingList
  ) {
    return <JoinWaitingList />;
  } else if (
    session.registerMax &&
    session.registerMax <= session.signups.length &&
    !session.onWaitingList
  ) {
    return <LeaveWaitingList />;
  } else {
    return <SignUpToSession />;
  }
};

export default SignupButton;
