import { useAuth } from "@goodgym/auth";
import * as UI from "@goodgym/components";
import { useTestPairingsQuery } from "@goodgym/graphql/hooks";
import React from "react";
import NotVerified from "./components/NotVerified";
import TestPairingTable from "./components/TestPairingTable";

export type TestPairingDashboardProps = UI.BoxProps & {
  panel?: boolean;
  slug?: string;
};

const TestPairingDashboard: React.FC<TestPairingDashboardProps> = () => {
  const { runner } = useAuth();

  const runnerVerified = runner?.needsToGetCoachVerified;

  const { data, error } = useTestPairingsQuery({});

  const testPairings = data?.testPairings.results;

  if (error) return <UI.Error />;
  if (!data) return <UI.Loading />;
  
  const Component = () => {
    if (runnerVerified) return <NotVerified />;
    else return <TestPairingTable testPairings={testPairings!} />;
  };

  return (
    <UI.Container sx={{ mt: "150px" }}>
      <Component />
    </UI.Container>
  );
};

export default TestPairingDashboard;
