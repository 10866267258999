/** @jsxImportSource @emotion/react */
import * as UI from "@goodgym/components";
import { useStoriesPageQuery } from "@goodgym/graphql/hooks";
import * as u from "@goodgym/util";
import _ from "lodash";
import StoriesFeed from "../../components/StoriesFeed";
import { useAuth } from "@goodgym/auth";
import { hasAnyRole } from "@goodgym/util/roles";

const StoriesPage = () => {
  const theme = UI.useTheme();
  const md = UI.useMediaQuery(theme.breakpoints.up("md"));

  const { fetchMore, data, error } = useStoriesPageQuery({
    variables: {},
  });

  const loadMore = () => {
    fetchMore({
      variables: {
        before: _.last(stories)?.publishedAt,
      },
    });
  };

  const { runner } = useAuth();
  const adminRunner = hasAnyRole(runner, ["CENTRAL_ADMIN"]);

  if (error) return <UI.Error />;
  if (!data) return <UI.Loading />;

  const stories = data.stories.stories;
  const { more } = data.stories;

  return (
    <>
      <UI.Seo title="Stories" url="/v3/stories" />
      <UI.Box
        sx={{
          display: { xs: "none", md: "block" },
          backgroundColor: "primary.main",
          position: "fixed",
          top: "64px",
          left: 0,
          right: 0,
          py: 2,
          zIndex: 3,
        }}
      >
        <UI.Container>
          <UI.Breadcrumbs
            crumbs={[
              ["Home", u.links.home()],
              ["Stories", u.links.stories()],
            ]}
            sx={{ mb: 1 }}
          />
          <UI.Typography variant="h1">Stories</UI.Typography>
        </UI.Container>
      </UI.Box>

      {/* Mobile header */}
      <UI.Container
        sx={{
          display: { xs: "block", md: "none" },
          pt: 2,
          pb: 2,
          position: "fixed",
          top: { xs: "56px", sm: "64px" },
          backgroundColor: "primary.main",
          zIndex: 2,
          borderBottom: "1px solid #d7d7d7",
        }}
      >
        <UI.Box display="flex" justifyContent={"space-between"}>
          <UI.Typography
            variant="h3"
            sx={{ whiteSpace: "nowrap", alignSelf: "center" }}
          >
            Stories
          </UI.Typography>
        </UI.Box>
      </UI.Container>

      <UI.Container sx={{ mt: md ? 18 : 9 }}>
        {adminRunner && (
          <UI.Container
            sx={{
              display: "flex",
              gap: { xs: "5px", sm: "10px" },
              justifyContent: { xs: "center", sm: "flex-end" },
              mr: { sm: -2 },
            }}
          >
            {[
              {
                title: "New Story",
                link: "/stories/new",
              },
              {
                title: "Draft stories",
                link: "/v3/stories/drafted",
              },
              {
                title: "Deleted Stories",
                link: "/v3/stories/deleted",
              },
            ].map((button, index) => (
              <UI.Button
                key={index}
                href={button.link}
                size={md ? "medium" : "small"}
                sx={{ my: 3 }}
              >
                {button.title}
              </UI.Button>
            ))}
          </UI.Container>
        )}
        <StoriesFeed
          stories={stories}
          fetchMore={loadMore}
          more={more}
          error={error}
        />
      </UI.Container>
    </>
  );
};

export default StoriesPage;
