import * as UI from "@goodgym/components";
import { NewsQuote } from "../../data";

const NewsQuotes = () => {
  return (
    <>
      <UI.Grid container spacing={4} sx={{ display:"center", justifyContent:"center", alignItems:"center"}}>
        {NewsQuote.map((quote, index) => (
          <UI.Grid item xs={12} md={4} key={index}>
            <UI.Card sx={{ height: "195px"}}>
              <UI.Box
                sx={{
                  width: "110%",
                  borderTop: "4px solid #E11018",
                }}
              />

              <UI.Box sx={{ width: "30%", height: "60px", mt: 1 }}>
                <img
                  src={quote.image}
                  alt="quote"
                  style={{
                    width: "100%",
                    height: "100%",
                    objectFit: "contain",
                    paddingLeft: 5,
                    marginLeft: 1,
                  }}
                />
              </UI.Box>

              <UI.Typography
                variant="subtitle1"
                sx={{
                  px: 1,
                  py: 0.5,
                  fontStyle: "italic",
                  fontWeight: "normal",
                }}
              >
                "{quote.quote}"
              </UI.Typography>
            </UI.Card>
          </UI.Grid>
        ))}
      </UI.Grid>
    </>
  );
};

export default NewsQuotes;
