import * as UI from "@goodgym/components";
import Image from "../goodgym.jpeg";

const NotVerifiedPage = () => {
  const theme = UI.useTheme();
  return (
    <UI.Box>
      <UI.Grid container spacing={3}>
        <UI.Grid item xs={12} md={6}>
          <UI.Typography variant="h4" sx={{ mb: 2 }}>
            Get social visits verified
          </UI.Typography>

          <UI.Typography
            variant="h5"
            sx={{ my: 1, color: theme.palette.primary.main }}
          >
            What are social visits?
          </UI.Typography>
          <UI.Typography variant="body1">
            Social visits are <span style={{ color: "red" }}>30-60 minute</span>{" "}
            friendly visits. They are informal weekly in person chats that help
            combat loneliness and isolation. You are there to provide company to
            help brighten up that person’s day. It gives you motivation to keep
            exercising and you’ll likely make a new friend too. We call the
            people that we visit regularly coaches because they provide
            motivation to exercise and share their wisdom.
          </UI.Typography>

          <UI.Typography
            variant="h5"
            sx={{ my: 1, color: theme.palette.primary.main }}
          >
            How can I get involved?
          </UI.Typography>
          <UI.Typography variant="body1">
            To make sure everyone is safe you’ll need to be social visit
            verified to take part. It takes about 4 hours to complete it. This
            includes:
          </UI.Typography>
          <ul>
            <li>A criminal record check </li>
            <li>Short online training</li>
            <li>2 references. </li>
            <li>A call with one of our team members </li>
          </ul>
          <UI.Typography
            variant="h5"
            sx={{ my: 1, color: theme.palette.primary.main }}
          >
            How long do I have to wait until I will meet someone?
          </UI.Typography>
          <UI.Typography variant="body1" sx={{ mb: 2 }}>
            We wait until 5 people are verified so we don’t waste referrers time
            and they have some options when they get to the website. Once we
            have 5 verified GoodGymers then we get in touch with local
            organisations who can refer older people. The waiting time can vary
            depending on how quickly we reach the five verified GoodGymers.
          </UI.Typography>

          <UI.Button
            sx={{ mt: 1 }}
            href="https://www.goodgym.org/my/coach/verification"
          >
            Get verified
          </UI.Button>
        </UI.Grid>
        <UI.Grid item xs={12} md={6}>
          <UI.Box
            sx={{
              borderStyle: "solid",
              p: 1,
              borderRadius:"0.8px",
              background: theme.palette.grey[300],
              textAlign: "center",
            }}
          >
            <UI.Box
              component="img"
              sx={{
                width: { xs: "250px", sm: "280px", md: "310px", lg: "400px" },
                height: "auto",
                m: 2,
                overflow: "hidden",
              }}
              src={Image}
            />

            <UI.Typography variant="body1">
              “This was my first social visit, and I went to see Mr R. I had
              been nervous about whether we would have enough to talk about, but
              I needn't have worried, he was a great story-teller and we
              actually found we had quite a bit in common, even though I
              couldn't compete with him having met the Queen!”
              <br /> <br />
              Sheila from W&M
            </UI.Typography>
          </UI.Box>
        </UI.Grid>
      </UI.Grid>
    </UI.Box>
  );
};

export default NotVerifiedPage;
