/** @jsxImportSource @emotion/react */
import React from "react";
import * as Icons from "@goodgym/icons";
import * as UI from "@goodgym/components";
import * as T from "@goodgym/graphql/types";
import * as u from "@goodgym/util";
import _ from "lodash";
import * as C from "./components";

export type NavbarLoggedOutProps = {};

const NavbarLoggedOut: React.FC<NavbarLoggedOutProps> = ({}) => {
  return (
    <>
      <UI.AppBar position="fixed" color="white">
        <UI.Container>
          <UI.Toolbar disableGutters>
            <UI.Box>
              <UI.Logo />
            </UI.Box>
            <UI.Box flexGrow={1} />
            <UI.SignInButton variant="text" sx={{ mr: 1 }} />
            <UI.Button variant="contained" href="/users/sign_up" sx={{ mr: 1 }}>
              <UI.Box
                component="span"
                sx={{ display: { xs: "inline", sm: "none" } }}
              >
                Join
              </UI.Box>
              <UI.Box
                component="span"
                sx={{ display: { xs: "none", sm: "inline" } }}
              >
                Join GoodGym
              </UI.Box>
            </UI.Button>
            <C.MegaMenu sx={{ display: { xs: "none", sm: "inline" } }} />
          </UI.Toolbar>
        </UI.Container>
      </UI.AppBar>
      <UI.Toolbar />
    </>
  );
};

export default NavbarLoggedOut;
