import * as UI from "@goodgym/components";
import Banner from "@goodgym/components/Banner";
import IconCards from "@goodgym/components/IconCards";
import { useGetRunnersQuery } from "@goodgym/graphql/hooks";
import * as T from "@goodgym/graphql/types";
import FAQ from "../components/FAQ";
import GetInvolvedCard from "../components/GetInvolvedCard";
import MidBanner from "../components/MidBanner";
import NewsQuotes from "../components/NewsQuotes";
import Testimonial from "../components/Testimonials";
import TestimonialRunners from "../components/TestimonialsRunners";
import { MissionBanner } from "../images";
import AboutCommunityMission from "./components/AboutCommunityMission";
import { FaqData, RunnersTestimonials, whyHelp } from "./data";
import * as Images from "./images";
import PostcodeSearch from "../components/PostcodeSearch";

const AboutCommunityMissionPage = () => {
  const { data } = useGetRunnersQuery({
    variables: {
      random: true,
      limit: 3,
    },
  });

  const areaActivators = data?.runners.map((runner) => ({
    avatarUrl: runner.avatarUrl,
    quote: runner.bio,
    by: runner.name,
    area: runner.homeArea?.name
  }));

  const { data: info } = T.useHomePageQuery();

  const numberOfCommunityMission = info?.stats.communitymissions;

  return (
    <UI.Box sx={{}}>
      <Banner
        title="Run, walk or cycle to help community projects with practical tasks"
        image={Images.CommunityMissionHeader}
        buttonText={"Sign up"}
        linkForButton="https://www.goodgym.org/users/sign_up"
      />
      <AboutCommunityMission />

      <Testimonial testimonials={areaActivators} />
      <UI.Container>
        <UI.Grid
          container
          spacing={2}
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          {whyHelp.map((element, index) => (
            <IconCards
              key={index}
              image={element.image}
              tagline={element.tagline}
              bold={true}
            />
          ))}
        </UI.Grid>
      </UI.Container>

      <MidBanner
        numberOfDeeds={numberOfCommunityMission}
        badge={Images.BadgeMission}
        subtitle={"Good deeds completed"}
      />
      <UI.Box
        sx={{
          backgroundImage: `url(${MissionBanner})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          backgroundRepeat: "no-repeat",
          height: "600px",
          width: "100vw",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          aspectRatio: "5/3",
        }}
      ></UI.Box>
      <GetInvolvedCard missionPage={true} />

      <UI.Container sx={{ mt: 10 }}>
        <TestimonialRunners testimonials={RunnersTestimonials} />
      </UI.Container>

      <UI.Box sx={{ mt: 6, background: "#E9E9E9", p: 2 }}>
        <UI.Container sx={{}}>
          <UI.Typography variant="h4" sx={{ my: 2 }}>
            What people say about our community
          </UI.Typography>
          <NewsQuotes />
        </UI.Container>
      </UI.Box>
      <FAQ info={FaqData} />
      <UI.Box
        sx={{
          height: "200px",
          p: 4,
          mt: 5,
          mb: { xs: 9, md: 0 },
        }}
      >
        <UI.Container sx={{ textAlign: "center" }}>
          <UI.Typography
            variant="h2"
            sx={{ my: 2, textAlign: "center", fontStyle: "normal" }}
          >
            Find your local group
          </UI.Typography>
          <UI.Container sx={{ textAlign: "center" }}>
<PostcodeSearch communityMissionPage={true} />
          </UI.Container>
        </UI.Container>
      </UI.Box>
    </UI.Box>
  );
};

export default AboutCommunityMissionPage;
