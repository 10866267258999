/** @jsxImportSource @emotion/react */
import { useCompleteTrainingMutation } from "@goodgym/graphql/hooks";
import * as T from "@goodgym/graphql/types";
import * as UI from "@goodgym/components";
import * as u from "@goodgym/util";

export type CompleteTrainingMutationParams = {
  training: T.TrainingFragment & {
    modules: (T.TrainingModule & { questions: T.TrainingModuleQuestion[] })[];
  };
};

export const useCompleteTraining = () => {
  const [completeTraining] = useCompleteTrainingMutation({
    onError: console.log,
  });

  return async ({ training }: CompleteTrainingMutationParams) => {
    // we assume all questions are "click to agree" and so
    // hardcode all answers to 1
    const answers = training.modules.flatMap((m) =>
      m.questions.map((q) => ({
        moduleId: m.id,
        questionId: q.id,
        answer: "1",
      }))
    );

    // let's not do an optimistic response for this one, we want to
    // be sure it's succeeded before closing the dialog
    return await completeTraining({
      variables: { trainingId: training.slug, answers },
    });
  };
};
