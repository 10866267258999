import React from "react";
import * as UI from "@goodgym/components";
import { FollowButton } from "@goodgym/components";
import * as T from "@goodgym/graphql/types";


export type CardProps = {
  title: string;
  tagline?: string;
  image?: string;
  link?: string;
  runner: T.RunnerFragment & T.FollowStatusFragment;
};
const Card: React.FC<CardProps> = ({ title, image, link, runner }) => {
  const theme = UI.useTheme();
  return (
    <>
      <UI.Card
        aria-label="ProfileCard"
        sx={{
          width: "100%",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          padding: 3,
          boxShadow: theme.shadows[2],
          mb: 4,
        }}
      >
        <UI.Typography
          variant="h4"
          sx={{ paddingBottom: 2, textAlign: "center" }}
        >
          {title}
        </UI.Typography>
        <UI.Link href={link}>
        <UI.Box
          sx={{
            borderRadius: "50%",
            height: "100px",
            width: "100px",
            overflow: "hidden",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            mb: 3,
            backgroundImage: `url(${image})`,
            backgroundSize: "cover",
            backgroundPosition: "center",
            backgroundRepeat: "no-repeat",
          }}
        >
          </UI.Box>
        </UI.Link>
        <FollowButton runner={runner} myTeam={true} />
      </UI.Card>
    </>
  );
};

export default Card;
