import * as UI from "@goodgym/components";
import * as T from "@goodgym/graphql/types";
import * as u from "@goodgym/util";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import React from "react";

export type TestPairingTableProps = {
  testPairings: T.TestPairingFragment[];
};
const TestPairingTable: React.FC<TestPairingTableProps> = ({
  testPairings,
}) => {
  const status = (pairing: T.TestPairingFragment) => {
    const { rejectedAt, scheduledAt, completedAt, cancelledAt } = pairing;

    if(rejectedAt) return "declined";
    if(cancelledAt) return "cancelled";
    if(completedAt) return "completed";
    if(scheduledAt) return "scheduled"
    return "pending";
  }

  return (
    <UI.Box>
      {testPairings.length === 0 && (
        <UI.Typography variant="h2" sx={{ my: 3 }}>
          You do not have any trial visits - to see social visits in your area
          please visit <UI.Link href="/v3/social-visits">Social Visits</UI.Link>
        </UI.Typography>
      )}
      {testPairings.length > 0 && (
        <>
          <UI.Typography variant="h3" sx={{ my: 3 }}>
            {" "}
            Your trial visits
          </UI.Typography>
          <TableContainer component={Paper}>
            <Table sx={{ minWidth: 450 }} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell
                    sx={{
                      backgroundColor: "#8EB0F9",
                      fontWeight: "bold",
                      fontSize: "18px",
                    }}
                  >
                    Name
                  </TableCell>
                  <TableCell
                    sx={{
                      backgroundColor: "#8EB0F9",
                      fontWeight: "bold",
                      fontSize: "18px",
                    }}
                    align="center"
                  >
                    Status
                  </TableCell>
                  <TableCell
                    sx={{
                      backgroundColor: "#8EB0F9",
                      fontWeight: "bold",
                      fontSize: "18px",
                    }}
                    align="center"
                  >
                    Scheduled
                  </TableCell>
                  <TableCell
                    sx={{
                      backgroundColor: "#8EB0F9",
                      fontWeight: "bold",
                      fontSize: "18px",
                    }}
                    align="center"
                  >
                    Completed
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {testPairings?.map((testpairing) => (
                  <TableRow
                    key={testpairing.id}
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  > 
                    <TableCell
                      component="th"
                      scope="row"
                      sx={{ fontSize: "15px" }}
                    >
                      <UI.Link href={`/v3/trial-visits/${testpairing.id}`}>{testpairing.coach.name}</UI.Link>
                    </TableCell> 
                    <TableCell align="center" sx={{ fontSize: "15px" }}>
                      {status(testpairing)}
                    </TableCell>
                    <TableCell align="center" sx={{ fontSize: "15px" }}>
                      {testpairing.scheduledAt
                        ? u.time.formatDateTime(testpairing.scheduledAt)
                        : "---"}
                    </TableCell>
                    <TableCell align="center" sx={{ fontSize: "15px" }}>
                      {testpairing.completedAt
                        ? u.time.formatDateTime(testpairing.completedAt)
                        : "---"}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </>
      )}
    </UI.Box>
  );
};

export default TestPairingTable;
