import * as Sentry from "@sentry/react";

export type CheerableType = "Attendance" | "Award" | "Registration" | "Workout";
export type ShareableType = "Attendance" | "Registration" | "Report";

export type SocialType =
  | "Email"
  | "Twitter"
  | "Facebook"
  | "Pinterest"
  | "WhatsApp"
  | "Messenger";

type Events = {
  cheer: {
    cheerable_id: string;
    cheerable_type: CheerableType;
  };
  uncheer: {
    cheerable_id: string;
    cheerable_type: CheerableType;
  };
  share: {
    shareable_id: string;
    shareable_type: ShareableType;
    social_type: SocialType;
  };
  cta_actioned: {
    cta_id: string;
  };
  cta_dismissed: {
    cta_id: string;
  };
};

export const track = <Event extends keyof Events>(
  event: Event,
  meta: Events[Event]
) => {
  if (window?.gtag) {
    window.gtag("event", event, meta);
  }
};

export const page = (_page: string, _meta: any = {}) => {};

export const identify = (userId: string, meta: any = {}) => {
  Sentry.setUser({ id: userId, ...meta });
};
