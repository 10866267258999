/** @jsxImportSource @emotion/react */
import React from "react";
import * as Icons from "@goodgym/icons";
import * as UI from "@goodgym/components";
import * as T from "@goodgym/graphql/types";
import * as u from "@goodgym/util";
import _ from "lodash";

export type JoinUsProps = UI.BoxProps;

const JoinUs: React.FC<JoinUsProps> = ({ ...props }) => {
  return (
    <UI.Box {...props}>
      <UI.Typography variant="h2" sx={{ mb: 1 }}>
        We're a community of people who get fit by doing good.{" "}
      </UI.Typography>
      <UI.Link variant="h2" href="/users/sign_up" color="primary">
        Join us.
      </UI.Link>
    </UI.Box>
  );
};

export default JoinUs;
