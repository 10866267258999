/** @jsxImportSource @emotion/react */
import React from "react";
import * as Icons from "@goodgym/icons";
import * as UI from "@goodgym/components";
import * as T from "@goodgym/graphql/types";
import * as u from "@goodgym/util";
import _ from "lodash";

export type SearchResultProps = UI.BoxProps & {
  beneficiary: T.RestrictedBeneficiaryFragment;
};

const SearchResult: React.FC<React.PropsWithChildren<SearchResultProps>> = ({
  beneficiary,
  ...props
}) => {
  return (
    <UI.Box
      {...props}
      sx={{
        display: "flex",
        width: "100%",
        borderBottom: "1px solid #dadada",
        pb: 1,
        ...props.sx,
      }}
    >
      <UI.Box sx={{ flexGrow: 1, whiteSpace: "pre-wrap" }}>
        <strong>{beneficiary.name}</strong>
        <br />

        {u.text.compositeAddress(beneficiary.address, { multiline: true })}
      </UI.Box>
      <UI.Box sx={{ flexShrink: 0 }}>
        <UI.AreaChip area={beneficiary.area} />
      </UI.Box>
    </UI.Box>
  );
};

export default SearchResult;
