import * as UI from "@goodgym/components";
import Header from "@goodgym/components/Header";
import * as images from "@goodgym/pages/HomePage/images";
import * as u from "@goodgym/util";
import Intro from "./components/Intro";
import TableContents from "./components/Tabs";

const PrivacyPolicyPage = () => {
  return (
    <>
      <UI.Seo
        title="Privacy Policy"
        description="GoodGym's privacy policy"
        image={images.landing.jpg}
        url="/"
      />
      <Header title={"The Good Gym Privacy Policy"} />
      <Intro />
      <TableContents />
    </>
  );
};

export default PrivacyPolicyPage;
