import React from 'react'
import * as Images from "../../images"
import * as UI from "@goodgym/components"

const Card = () => {
  return (
    <>
      <UI.Card
        sx={{
          width: { xs: "80%", sm: "55%", md: "55%" },
          borderRadius: "10px",
        }}
      >
        <UI.CardHeader
          sx={{
            backgroundImage: `url(${Images.CardImage})`,
            height: "340px",
            backgroundSize: "cover",
            backgroundPosition: "center",
            backgroundRepeat: "no-repeat",
            width: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            aspectRatio: "5/3",
          }}
        />
        <UI.Box
          sx={{
            width: "100%",
            borderTop: "10px solid #E11018",
          }}
        />
        <UI.CardContent>
          <UI.Box
            sx={{
              display: "flex",
              justifyContent: "center",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <UI.Typography
              variant="body1"
              sx={{ textAlign: "center", my: 1, display: "flex", px:2 }}
            >
              <UI.Typography variant="h5">
                ||
              </UI.Typography>
              I’ve got my garden back now, and without the help of GoodGym I
              wouldn’t have the spring to look forward to. I really don’t think
              the volunteers that I had help me realised the impact that they
              have had on our lives. It is more than just clearing the garden,
              it is giving me hope. I am now really looking forward and hoping
              to sit in the garden in springtime and enjoy the outside.
            </UI.Typography>
            <UI.Typography
              variant="body1"
              sx={{
                display: "flex",
                justifyContent: "flex-end",
                alignItems: "flex-end",
                my: 1,
              }}
            >
              Mr C, mission beneficiary
            </UI.Typography>
            <UI.Button
              href={"https://www.goodgym.org/users/sign_up?flow=become_member"}
            >
              Become a member
            </UI.Button>
          </UI.Box>
        </UI.CardContent>
      </UI.Card>
    </>
  );
}

export default Card