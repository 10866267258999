import * as I from "./images";

export const bannerData = {
  title: "Request free help for a community project",
  tagline:
    "Know a local charity or community project which needs assistance with a physical task? There are hundreds of GoodGym volunteers ready to help.",
  buttonText: "Request a task",
  image: I.MainBannerImage,
  linkForButton:"/referrers"
};

export const taskIconData = [
  {
    image: I.sortIcon,
    tagline: "We sort donations, clean areas and pack boxes",
  },
  {
    image: I.gardeningIcon,
    tagline: "We do gardening and most outdoor tasks",
  },
  {
    image: I.liftingIcon,
    tagline: "We move furniture or other heavy items",
  },
];

export const workIconData = [
  {
    image: I.moneyIcon,
    title: "It's free",
    tagline:
      "Our tasks are carried out by volunteers so you don't have to pay.",
  },

  {
    image: I.lockIcon,
    title: "It's safe",
    tagline: "GoodGym volunteers receive a full health and safety briefing.",
  },
  {
    image: I.clockIcon,
    title: "It's flexible",
    tagline: "GoodGym volunteers are available every week and turn up on time.",
  },
];
export const listItemTask = [
  "Gardening",
  "Moving heavy items",
  "Tidying and organising",
  "sorting donations",
];
 export const listItemNotTask = [
  "Tasks requiring power tools",
  "Tasks requiring specific training",
];