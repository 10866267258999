import * as T from "@goodgym/graphql/types";

export const activityDescription = (session: T.SessionFragment) => {
  switch (session.type) {
    case "party":
      return "went to a social";
    case "document check":
      return `went to a ${session.type}`;
    case "community mission":
    case "mission":
    case "group run":
    case "training session":
    case "race":
    default:
      return `went on a ${session.type}`;
  }
};
