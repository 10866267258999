import React from "react";

const useDropdown = () => {
  const [anchor, setAnchor] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchor);

  const onClick = (
    e: React.MouseEvent<HTMLButtonElement | HTMLAnchorElement>
  ) => {
    setAnchor(e.currentTarget);
    e.preventDefault();
  };

  const onClose = () => {
    setAnchor(null);
  };

  return { open, onClick, onClose, anchor };
};

export default useDropdown;
