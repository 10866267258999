/** @jsxImportSource @emotion/react */
import React from "react";
import * as Icons from "@goodgym/icons";
import * as UI from "@goodgym/components";
import * as T from "@goodgym/graphql/types";
import * as u from "@goodgym/util";
import _ from "lodash";
import {
  useDeclineTaskRequest,
  useUndeclineTaskRequest,
} from "@goodgym/graphql/mutations";

export type TaskRequestCardProps = UI.CardProps & {
  taskRequest: T.TaskRequestCardFragment;
  dashboard?: boolean;
};

const TaskRequestCard: React.FC<TaskRequestCardProps> = ({
  taskRequest,
  dashboard,
  ...props
}) => {
  const decline = useDeclineTaskRequest({ taskRequest });
  const undecline = useUndeclineTaskRequest({ taskRequest });

  // we show up to three potential times in full
  // if there are more than three times, we show the first two
  // and then in place of the third some explanatory text
  // which indicates how many other times there are available
  const countVisibleTimes = taskRequest.potentialTimes.length === 3 ? 3 : 2;
  const countHiddenTimes =
    taskRequest.potentialTimes.length - countVisibleTimes;
  const hasMaxSlot =
    taskRequest.reservations.filter(
      (reservation) => reservation.runners.length >= taskRequest.registerMax
    ).length > 0;
  const signups = _.uniqBy(
    _.flatMap(taskRequest.reservations, "runners"),
    "id"
  );

  return (
    <UI.Card {...props}>
      <UI.Box
        sx={{
          display: "flex",
          flexDirection: dashboard
            ? "column-reverse"
            : { xs: "column-reverse", md: "row" },
        }}
      >
        <UI.Box sx={{ px: 2, mt: 1, width: { sm: "100%", md: "65%" } }}>
          <UI.Box>
            <UI.Box>
              <UI.Box
                sx={{
                  background: "black",
                  width: "fit-content",
                  my: 1,
                  boxShadow: "-5px 5px 0 -1px #E11018",
                }}
              >
                <UI.Typography
                  sx={{
                    mb: 0.5,
                    px: 1,
                    fontStyle: "normal",
                    fontFamily: "'Barlow Condensed', sans-serif",
                    textTransform: "uppercase",
                  }}
                  color="common.white"
                >
                  {taskRequest.area.name}
                </UI.Typography>
              </UI.Box>
              <UI.Link
                href={u.links.taskRequest(taskRequest)}
                variant="h4"
                sx={{ color: "main", flexWrap: "wrap" }}
              >
                {taskRequest.title}
              </UI.Link>
              <UI.Box sx={{mt:1}}>
                <UI.Chip
                  label={_.upperFirst("Mission")}
                  component={UI.Link}
                  clickable
                  sx={{
                    borderRadius: 1,
                    height: 24,
                    verticalAlign: "baseline",
                    backgroundColor: "#dcdcdc",
                    fontWeight: "bold",
                  }}
                />
              </UI.Box>
              <UI.Typography variant="body2" sx={{ mt: 1 }}>
                {_.take(taskRequest.potentialTimes, countVisibleTimes).map(
                  (t) => {
                    return (
                      <UI.Box
                        key={t}
                        component="span"
                        sx={{
                          mr: 1,
                          whiteSpace: "nowrap",
                          display: "inline-block",
                        }}
                      >
                        <UI.Emoji emoji="calendar" sx={{ mr: 1 }} />
                        {u.time.formatDay(t)}{" "}
                        <UI.Box color="neutral.dark" component="span">
                          {u.time.formatTime(t)}
                        </UI.Box>
                      </UI.Box>
                    );
                  }
                )}
                {countHiddenTimes > 0 && (
                  <UI.Box color="neutral.dark" component="span">
                    and {countHiddenTimes} more{" "}
                    {u.inflect("times", countHiddenTimes)}
                  </UI.Box>
                )}
              </UI.Typography>
              <UI.Typography
                variant="body2"
                color="neutral.dark"
                my={1}
                sx={{ mb: 3 }}
              >
                {taskRequest.outcome}
              </UI.Typography>
            </UI.Box>
          </UI.Box>
          <UI.Box sx={{ mb: 1 }}>
            {(signups.length > 0 || taskRequest.signedUp) && (
              <UI.Grid container sx={{ mt: 1 }}>
                <UI.Grid item xs={12} md={6} display="flex">
                  <UI.AvatarGroup
                    max={8}
                    sx={{ justifyContent: "left", ml: "-2px" }}
                  >
                    {signups.map((runner) => (
                      <UI.RunnerAvatar
                        key={runner.id}
                        runner={runner}
                        noStatus
                      />
                    ))}
                  </UI.AvatarGroup>
                </UI.Grid>

                {!hasMaxSlot && !taskRequest.signedUp && (
                  <UI.Grid item xs={12}>
                    <UI.Typography variant="caption">
                      Spaces still left! 👀
                    </UI.Typography>
                  </UI.Grid>
                )}
                <UI.Grid
                  item
                  xs={12}
                  md={6}
                  sx={{
                    alignSelf: "flex-end",
                    textAlign: { xs: "left", md: "right" },
                  }}
                >
                  <UI.Box sx={{ pt: { xs: 1, md: 0 } }}>
                    {taskRequest.signedUp && (
                      <UI.Chip
                        variant="outlined"
                        color="success"
                        label="You've signed up! 🎉"
                      />
                    )}
                  </UI.Box>
                </UI.Grid>
              </UI.Grid>
            )}
            {taskRequest.declined ? (
              <UI.Typography variant="body2">
                You have declined this request.{" "}
                <UI.Link onClick={undecline}>Undo</UI.Link>.
              </UI.Typography>
            ) : (
              <UI.Typography variant="body2">
                Not interested?{" "}
                <UI.Link onClick={decline}>Decline this request</UI.Link>.
              </UI.Typography>
            )}
          </UI.Box>
        </UI.Box>
        <UI.Box
          sx={{
            width: { sm: "100%", md: "35%" },
          }}
        >
          <UI.Box
            sx={{
              height: {
                xs: "100%",
              },
              minHeight: "300px",
              width: { xs: "100%" },
              position: "relative",
            }}
          >
            <UI.TaskRequestMap taskRequest={taskRequest} />
          </UI.Box>
        </UI.Box>
      </UI.Box>
    </UI.Card>
  );
};

export default TaskRequestCard;
