/** @jsxImportSource @emotion/react */
import React from "react";
import * as Icons from "@goodgym/icons";
import * as UI from "@goodgym/components";
import * as T from "@goodgym/graphql/types";
import * as u from "@goodgym/util";
import _ from "lodash";

export type DrawerHeaderProps = {
  title: string;
  onClose: any;
  anchor: "left" | "right";
  open: boolean;
};

const DrawerHeader: React.FC<DrawerHeaderProps> = ({
  title,
  anchor,
  onClose,
}) => {
  return (
    <UI.Box sx={{ width: "100%", position: "relative" }}>
      <UI.Paper
        elevation={3}
        square
        sx={{
          height: "56px",
          position: "fixed",
          background: "white",
          width: "100vw",
          zIndex: 2,
        }}
      >
        <UI.Typography
          variant="body1"
          color="neutral.dark"
          sx={{ mt: 2, textAlign: "center", position: "relative" }}
        >
          <UI.Box
            component="span"
            sx={{
              display: "inline-block",
              maxWidth: "calc(100% - 120px)",
              overflow: "hidden",
              textOverflow: "ellipsis",
              whiteSpace: "nowrap",
            }}
          >
            {title}
          </UI.Box>
          {anchor === "right" ? (
            <UI.IconButton
              onClick={onClose}
              sx={{ position: "absolute", right: "0", top: "-8px" }}
            >
              <Icons.ArrowForwardIos />
            </UI.IconButton>
          ) : (
            <UI.IconButton
              onClick={onClose}
              sx={{ position: "absolute", left: "16px", top: "-8px" }}
            >
              <Icons.ArrowBackIos sx={{ ml: "4px", mr: "-4px" }} />
            </UI.IconButton>
          )}
        </UI.Typography>
      </UI.Paper>
    </UI.Box>
  );
};

export default DrawerHeader;
