/** @jsxImportSource @emotion/react */
import React from "react";
import * as Icons from "@goodgym/icons";
import * as UI from "@goodgym/components";
import * as T from "@goodgym/graphql/types";
import * as u from "@goodgym/util";
import _ from "lodash";
import { useAuth } from "@goodgym/auth";
import { useDrawer } from "@goodgym/hooks";
import * as menus from "@goodgym/menus";

export type AccountProps = {};

const Account: React.FC<AccountProps> = () => {
  const { runner, referrer, logout } = useAuth();
  const { open, onClick, onClose } = useDrawer();
  const menu = menus.account({ runner, referrer });

  return (
    <>
      <UI.Avatar
        id="account-button"
        onClick={onClick}
        src={runner?.avatarUrl}
        component={UI.ButtonBase}
        sx={{ ml: "auto" }}
        aria-controls={open ? "account-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
      />
      <UI.Drawer anchor="left" open={open} onClose={onClose}>
        <UI.Box>
          <UI.DrawerHeader
            title="Account"
            anchor="left"
            onClose={onClose}
            open={open}
          />
          <UI.List sx={{ pt: "68px", px: 2 }}>
            {menu.map(({ key, links }, index) => (
              <UI.Box key={key}>
                {links.map(({ key, icon, label, ...props }) => (
                  <UI.ListItem
                    key={key}
                    component={UI.Link}
                    onClick={onClose}
                    {...props}
                    sx={{ color: "black.main" }}
                  >
                    <UI.ListItemIcon>{icon}</UI.ListItemIcon>
                    <UI.ListItemText>{label}</UI.ListItemText>
                  </UI.ListItem>
                ))}
                {index === menu.length - 1 ? null : <UI.Divider />}
              </UI.Box>
            ))}

            <UI.ListItem onClick={logout}>
              <UI.ListItemIcon>
                <Icons.ExitToApp />
              </UI.ListItemIcon>
              <UI.ListItemText>Sign out</UI.ListItemText>
            </UI.ListItem>
          </UI.List>
        </UI.Box>
      </UI.Drawer>
    </>
  );
};

export default Account;
