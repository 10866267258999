/** @jsxImportSource @emotion/react */
import React from "react";
import * as Icons from "@goodgym/icons";
import * as UI from "@goodgym/components";
import * as T from "@goodgym/graphql/types";
import * as u from "@goodgym/util";
import _ from "lodash";

export type ResultCountProps = UI.TypographyProps & {
  more: boolean;
  count: number;
};

const ResultCount: React.FC<ResultCountProps> = ({ more, count, ...props }) => {
  const rounded = count < 5 ? count : Math.round(count / 5) * 5;
  return (
    <UI.Typography variant="h5" color="neutral.main">
      {more ? "More than" : ""} {rounded} {u.inflect("result", rounded)}
    </UI.Typography>
  );
};

export default ResultCount;
