import React from "react";
import * as T from "@goodgym/graphql/types";
import * as UI from "@goodgym/components";
import { useUnpublishReport } from "@goodgym/graphql/mutations/UnpublishReport";
import * as Icons from "@goodgym/icons";

export type UnpublishReportButtonProps = {
  report: T.ReportFragment;
  runner: Optional<T.CurrentRunnerFragment>;
};

const UnpublishReportButton: React.FC<UnpublishReportButtonProps> = ({
  report,
  runner,
}) => {
  const UnpublishReport = useUnpublishReport({ report, runner });

  return (
    <UI.Box>
      <UI.Link
        onClick={UnpublishReport}
        sx={{ display: "flex"}}
      >
        <Icons.Drafts
          sx={{
            mr: 1,
            verticalAlign: "middle",
            fontSize: "22px",
            color: "neutral.dark",
          }}
        />
        <UI.Typography variant="body1" sx={{ color: "neutral.dark" }}>
          {report.publishedAt ? "Unpublish report" : "Re-publish report"}
        </UI.Typography>
      </UI.Link>
    </UI.Box>
  );
};

export default UnpublishReportButton;
