/** @jsxImportSource @emotion/react */
import React from "react";
import * as Icons from "@goodgym/icons";
import * as UI from "@goodgym/components";
import * as T from "@goodgym/graphql/types";
import * as u from "@goodgym/util";
import * as C from "..";
import _ from "lodash";

export type CoachVisitProps = { visit: T.NewsfeedCoachVisitFragment };

const CoachVisit: React.FC<CoachVisitProps> = ({ visit }) => {
  return (
    <UI.Card>
      <UI.CardHeader
        title={
          <UI.Typography variant="subtitle1">
            <UI.RunnerLink runner={visit.runner} /> visited their coach
          </UI.Typography>
        }
        subheader={
          <UI.Typography variant="body2">
            {u.time.formatDate(visit.time)}
          </UI.Typography>
        }
        avatar={<UI.RunnerAvatar runner={visit.runner} noStatus />}
        sx={{ pb: 0 }}
      />
      <C.CheersAndComments cheerable={visit} commentable={visit} defaultOpen />
    </UI.Card>
  );
};

export default CoachVisit;
