import * as UI from "@goodgym/components";
import theme from "@goodgym/theme";

export type GuidelineProps = {};

const Guidelines: React.FC<GuidelineProps> = () => {
  return (
    <>
      <UI.Container
        sx={{
          [theme.breakpoints.down("sm")]: { height: "auto", paddingTop:2, paddingBottom:2 },
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          textAlign: "center",
          height: "140px",
        }}
      >
        <UI.Typography sx={{ typography: { sm: "body1", md: "h5" }, fontWeight:{md:"normal"} }}>
          Please read this carefully. We ask all participants on any GoodGym
          activity to adhere to the following code of conduct when dealing with
          other participants and representatives from external organisations.
        </UI.Typography>
      </UI.Container>
    </>
  );
};

export default Guidelines;
