/** @jsxImportSource @emotion/react */
import React from "react";
import * as Icons from "@goodgym/icons";
import * as UI from "@goodgym/components";
import * as T from "@goodgym/graphql/types";
import * as u from "@goodgym/util";
import _ from "lodash";

export type SessionTypeChipProps = UI.ChipProps<"a"> & {
  session: T.SessionFragment;
};

const SessionTypeChip: React.FC<
  React.PropsWithChildren<SessionTypeChipProps>
> = ({ session, ...props }) => {
  const article =
    {
      CommunityMission: "36-community-missions",
      Mission: "38-missions",
      GroupRun: "35-group-runs",
    }[u.pascalCase(session.type)] || "14-types-of-goodgym-sessions";

  return (
    <UI.Chip
      {...props}
      label={_.upperFirst(session.type)}
      href={u.links.faq(article)}
      component={UI.Link}
      clickable
      sx={{
        borderRadius: 1,
        height: 24,
        ...props.sx,
        verticalAlign: "baseline",
        backgroundColor: "#dcdcdc",
        fontWeight: "bold",
      }}
    />
  );
};

export default SessionTypeChip;
