/** @jsxImportSource @emotion/react */
import React from "react";
import * as Icons from "@goodgym/icons";
import * as UI from "@goodgym/components";
import * as T from "@goodgym/graphql/types";
import * as u from "@goodgym/util";
import _ from "lodash";
import { useDropdown } from "@goodgym/hooks";
import * as menus from "@goodgym/menus";

export type MoreProps = {};

const More: React.FC<MoreProps> = ({}) => {
  const { open, onClick, onClose, anchor } = useDropdown();

  return (
    <>
      <UI.NavbarButton icon={Icons.Menu} label="More" onClick={onClick} />
      <UI.Menu
        id="notifications-menu"
        anchorEl={anchor}
        open={open}
        onClose={onClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
      >
        {menus.more.map(({ key, label, links }, index) => (
          <UI.Box key={key}>
            <UI.ListItem sx={{ py: 0 }}>
              <UI.Typography variant="overline">{label}</UI.Typography>
            </UI.ListItem>
            {links.map(({ key, icon, label, ...props }) => (
              <UI.MenuItem
                key={key}
                component={UI.Link}
                onClick={onClose}
                {...props}
              >
                <UI.ListItemIcon>{icon}</UI.ListItemIcon>
                <UI.ListItemText>{label}</UI.ListItemText>
              </UI.MenuItem>
            ))}
            {index === menus.more.length - 1 ? null : <UI.Divider />}
          </UI.Box>
        ))}
      </UI.Menu>
    </>
  );
};

export default More;
