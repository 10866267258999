/** @jsxImportSource @emotion/react */
import React from "react";
import * as Icons from "@goodgym/icons";
import * as UI from "@goodgym/components";
import * as T from "@goodgym/graphql/types";
import * as u from "@goodgym/util";
import _ from "lodash";

export type SignInButtonProps = React.PropsWithChildren<UI.ButtonProps & {}>;

const SignInButton: React.FC<SignInButtonProps> = ({ ...props }) => {
  const [open, setOpen] = React.useState(false);

  const openDialog = () => {
    setOpen(true);
  };

  const closeDialog = () => {
    setOpen(false);
  };

  return (
    <>
      <UI.Button {...props} onClick={openDialog}>
        {props.children || "Sign in"}
      </UI.Button>
      <UI.SignInDialog open={open} onClose={closeDialog} />
    </>
  );
};

export default SignInButton;
