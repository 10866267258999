import React from 'react'
import * as UI from "@goodgym/components"
import * as T from "@goodgym/graphql/types"

export type AskHelpProps = {
  coachReferral: T.CoachReferralFragment;
}
const AskHelp:React.FC<AskHelpProps>= ({coachReferral})=> {
  return (
    <>
      <UI.Box sx={{ display: "flex", flexDirection: "column" }}>
        <UI.Typography variant="h5" sx={{ mt: 1, mb: 1 }}>
          Need help?
        </UI.Typography>
       
        <UI.Button
          variant="outlined"
          color="neutral"
          sx={{ my: 1 }}
          href="https://www.facebook.com/groups/406276916245285"
          target="_blank"
          rel="noopener noreferrer"
        >
          Visit the GoodGym Facebook group
        </UI.Button>
        <UI.Button
          variant="outlined"
          color="neutral"
          sx={{ my: 1 }}
          href="https://goodgym.zendesk.com/hc/en-gb/categories/16929787801618-Social-Visits "
          target="_blank"
          rel="noopener noreferrer"
        >
          Read frequently asked questions
        </UI.Button>
        <UI.Typography variant="body2" sx={{ mb: 2 }}>
          If you have a question you would like to ask privately, you can{" "}
          <UI.Link
            href={`mailto:${
              `${coachReferral.area?.name}@goodgym.org` ||
              "getinvolved@goodgym.org"
            }`}
          >
            email
          </UI.Link>{" "}
        </UI.Typography>
      </UI.Box>
    </>
  );
}

export default AskHelp