/** @jsxImportSource @emotion/react */
import React from "react";
import * as Icons from "@goodgym/icons";
import * as UI from "@goodgym/components";
import * as T from "@goodgym/graphql/types";
import * as u from "@goodgym/util";
import _ from "lodash";
import { useParams } from "react-router-dom";
import { useOrganisationPageQuery } from "@goodgym/graphql/hooks";
import { useAuth } from "@goodgym/auth";
import { useUpdateOrganisation } from "@goodgym/graphql/mutations";

const OrganisationPage = () => {
  const { runner } = useAuth();
  const theme = UI.useTheme();
  const { slug } = useParams();
  const { error, data } = useOrganisationPageQuery({
    variables: { slug: slug! },
  });

  const updateOrganisation = useUpdateOrganisation({
    organisation: data?.organisation,
  });

  if (error) return <UI.Error />;
  if (!data) return <UI.Loading />;

  const { organisation } = data;

  return (
    <UI.Container
      sx={{
        pt: 3,
        pb: { xs: 8, sm: 3 },
        mb: 5,
        minHeight: "100vh",
        backgroundColor: "white.main",
      }}
    >
      <UI.Seo
        title={organisation.name}
        description={organisation.strapline}
        url={`/v3/organisation/${slug}`}
      />
      <UI.Box
        sx={{
          mt: -3,
          mx: -3,
          background: `center center / cover no-repeat url(${organisation.imageUrl})`,
          height: "30vh",
          width: "calc(100% + 48px)",
        }}
      />

      <UI.Box
        sx={{
          display: "flex",
          alignItems: "flex-start",
          borderBottom: "1px solid",
          borderColor: "#dadada",
          mx: -3,
        }}
      >
        {organisation.logoUrl && (
          <UI.Box
            component="img"
            alt=""
            sx={{
              backgroundColor: "white.main",
              height: "88px",
              my: "-12px",
              mb: "-16px",
              ml: 3,
              p: "10px",
              borderRadius: "10px",
              flexShrink: 0,
              boxShadow: theme.shadows[2],
            }}
            src={organisation.logoUrl}
          />
        )}
        <UI.Box
          sx={{
            ml: 3,
            my: 2,
            flexGrow: 1,
          }}
        >
          <UI.Typography variant="h4">
            {" "}
            {organisation.name}
            {organisation.url && (
              <UI.IconButton
                size="small"
                href={organisation.url}
                sx={{ ml: "4px" }}
                component={UI.Link}
              >
                <Icons.Launch />
              </UI.IconButton>
            )}
          </UI.Typography>
        </UI.Box>
      </UI.Box>
      <UI.Typography
        variant="subtitle1"
        sx={{
          color: "neutral.main",
          mt: 3,
          mb: 2,
        }}
      >
        {organisation.strapline}
      </UI.Typography>
      <UI.EditableTextArea
        content={organisation.description || ""}
        placeholder="Enter a description for this organisation"
        editable={u.roles.hasAreaAdmin(runner, organisation.area)}
        handleSave={(description) => updateOrganisation({ description })}
      />
      <UI.Typography sx={{ mt: 1, whiteSpace: "pre-wrap" }}>
        {organisation.totalSessions > 0 && (
          <>
            {organisation.totalRunners}{" "}
            {u.inflect("GoodGymer", organisation.totalRunners)}{" "}
            {organisation.totalRunners > 1 ? "have" : "has"} supported{" "}
            {organisation.name} with {organisation.totalSessions}{" "}
            {u.inflect("task", organisation.totalSessions)}.
          </>
        )}
      </UI.Typography>
      {organisation.topSupporters.length > 0 && (
        <>
          <UI.Divider sx={{ my: 3, mx: { xs: -2, sm: -3 } }} />
          <UI.Typography variant="h5" sx={{ mb: 3 }}>
            Top supporters
          </UI.Typography>
          <UI.Grid container>
            {organisation.topSupporters.map((runner) => (
              <UI.Grid
                item
                xs={12}
                sm={6}
                lg={4}
                sx={{ mb: 2, display: "flex", alignItems: "center" }}
                key={runner.id}
              >
                <UI.Box sx={{ mr: 2 }}>
                  <UI.RunnerAvatar runner={runner} />
                </UI.Box>
                <UI.RunnerLink
                  variant="subtitle1"
                  color="black.main"
                  runner={runner}
                />
              </UI.Grid>
            ))}
          </UI.Grid>
        </>
      )}
      <UI.Divider sx={{ my: 3, mx: { xs: -2, sm: -3 } }} />
      {organisation.upcomingSessions.length > 0 && (
        <>
          <UI.Typography variant="h5" sx={{ mb: 2 }}>
            Upcoming sessions
          </UI.Typography>
          <UI.Grid container spacing={2}>
            {organisation.upcomingSessions.map((session) => (
              <UI.Grid item key={session.id} xs={12} md={6}>
                <UI.Link href={u.links.session(session)}>
                  {session.title}{" "}
                </UI.Link>
                <UI.Typography variant="body2" sx={{ color: "neutral.main" }}>
                  {u.time.format(session.startedAt, "EEEE do MMMM yyyy")}
                  {" "}
                  {u.time.formatTimeRange(session.startedAt, session.duration)}
                </UI.Typography>
              </UI.Grid>
            ))}
          </UI.Grid>
          <UI.Divider sx={{ my: 3, mx: { xs: -2, sm: -3 } }} />
        </>
      )}
      <UI.Typography variant="h5" sx={{ mb: 3 }}>
        Previous sessions
      </UI.Typography>
      <UI.ReportsFeed organisation={organisation} />
    </UI.Container>
  );
};

export default OrganisationPage;
