/** @jsxImportSource @emotion/react */
import React from "react";
import * as Icons from "@goodgym/icons";
import * as UI from "@goodgym/components";
import * as T from "@goodgym/graphql/types";
import * as u from "@goodgym/util";
import _ from "lodash";

export type WhatDoesItCostProps = {};

const WhatDoesItCost: React.FC<WhatDoesItCostProps> = ({}) => {
  return (
    <UI.Box>
      <UI.Typography variant="h1">GoodGym is free</UI.Typography>

      <UI.Typography
        variant="h5"
        component="p"
        sx={{ my: 2, fontWeight: "normal" }}
      >
        GoodGym is free to participate in and as a member you’ll get a free
        Saucony technical top.
      </UI.Typography>

      <UI.Typography
        variant="h5"
        component="p"
        sx={{ my: 2, fontWeight: "normal" }}
      >
        We're a registered charity and members have the option of making a
        monthly donation which goes directly towards achieving our aims of
        supporting isolated older people, helping local communities and
        improving physical and mental health.
      </UI.Typography>

      <UI.Typography
        variant="h5"
        component="p"
        sx={{ my: 2, fontWeight: "normal" }}
      >
        Donation plans start at <strong>£7 per month</strong>, there's no
        commitment and you can cancel at any time.
      </UI.Typography>

      <UI.Typography
        variant="h5"
        component="p"
        sx={{ my: 2, fontWeight: "normal" }}
      >
        We don’t want money to be a barrier so if you're not ready to donate, or
        can't afford to, you can still sign up.
      </UI.Typography>
    </UI.Box>
  );
};

export default WhatDoesItCost;
