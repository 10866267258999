/** @jsxImportSource @emotion/react */
import React from "react";
import * as Icons from "@goodgym/icons";
import * as UI from "@goodgym/components";
import * as T from "@goodgym/graphql/types";
import * as u from "@goodgym/util";
import * as C from "..";
import _ from "lodash";

export type WorkoutProps = {
  workout: T.NewsfeedWorkoutFragment;
};

const verb = (workout: T.NewsfeedWorkoutFragment) => {
  switch (workout.type) {
    case "training run":
      return "ran";
    case "ride":
      return "cycled";
    case "walk":
      return "walked";
    default:
      return "ran";
  }
};

const Workout: React.FC<WorkoutProps> = ({ workout }) => {
  return (
    <UI.Card>
      <UI.CardHeader
        avatar={<UI.RunnerAvatar runner={workout.runner} noStatus />}
        title={
          <UI.Typography variant="subtitle1">
            <UI.RunnerLink runner={workout.runner} /> {verb(workout)}{" "}
            {workout.distance}km {u.emoji.random(workout.id)}
          </UI.Typography>
        }
        subheader={<>{u.time.formatDateTime(workout.time)}</>}
      />

      <UI.CardContent sx={{ pt: 0, pb: 0 }}>
        <UI.Typography variant="h4">{workout.name}</UI.Typography>
        <UI.Typography variant="body1" color="neutral.main" sx={{ mt: 1 }}>
          {workout.averagePace} {" • "}{" "}
          {u.time.formatDuration(workout.duration)}
        </UI.Typography>
      </UI.CardContent>
      {workout.uri && workout.provider && (
        <UI.Link sx={{ ml: 2 }} href={workout.uri}>
          View on {`${workout.provider.charAt(0).toUpperCase()}${workout.provider.slice(1)}`}
        </UI.Link>
      )}
      <C.CheersAndComments cheerable={workout} commentable={workout} />
    </UI.Card>
  );
};

export default Workout;
