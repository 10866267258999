/** @jsxImportSource @emotion/react */
import * as UI from "@goodgym/components";
import { useAreaPageQuery } from "@goodgym/graphql/hooks";
import { useParams } from "react-router-dom";
import * as C from "./components";

const AreaPage = () => {
  const theme = UI.useTheme();
  const params = useParams();
  const slug = params.slug!;
  const section = params.section || "about";

  const sessionsByType = (() => {
     switch (params.type) {
       case "group-runs":
         return ["GroupRun"];
       case "community-missions":
         return ["CommunityMission"];
       case "missions":
         return ["Mission"];
       case "party":
         return ["Party"];
       case "training-sessions":
         return ["TrainingSession"];
       case "race":
         return ["Race"];
       case "group-sessions":
         return ["GroupRun", "CommunityMission"];
     }
   });


  const { error, data } = useAreaPageQuery({
    variables: { slug: slug! },
  });

  if (error) return <UI.Error />;
  if (!data) return <UI.Loading />;
  if (!data.area) return <UI.NotFound />;
  const { area } = data;
  if (!area.active) window.location.replace(`https://www.goodgym.org/proposals/${area.slug}`);

  return (
    <UI.Container
      sx={{
        pt: 3,
        pb: { xs: 11, sm: 8 },
        minHeight: "100vh",
        backgroundColor: "white.main",
      }}
    >
      <UI.Seo
        title={area.name}
        url={
          section === "about"
            ? `/v3/areas/${area.slug}`
            : `/v3/areas/${area.slug}/${section}`
        }
      />
      <UI.Box
        sx={{
          mt: -3,
          mx: -3,
          background: `center center / cover no-repeat url(${area.imageUrl})`,
          height: "30vh",
          width: "calc(100% + 48px)",
        }}
      />
      <UI.Box
        sx={{
          display: "flex",
          alignItems: "flex-start",
          borderBottom: "1px solid",
          borderColor: "#dadada",
          mx: -3,
        }}
      >
        {area.areaActivator && (
          <UI.Box
            sx={{
              my: "-5px",
              mb: "-6px",
              ml: 2,
              flexShrink: 0,
            }}
          >
            <UI.RunnerAvatar
              size="large"
              runner={area.areaActivator}
              sx={{
                boxShadow: theme.shadows[2],
                border: "1px solid #fff",
              }}
            />
          </UI.Box>
        )}
        <UI.Box
          sx={{
            ml: 2,
            my: 2,
            flexGrow: 1,
          }}
        >
          <UI.Typography variant="h4">
            <UI.Box
              component="span"
              sx={{ display: { xs: "none", sm: "inline" } }}
            >
              GoodGym
            </UI.Box>{" "}
            {area.name}
          </UI.Typography>
        </UI.Box>
      </UI.Box>
      <UI.Box sx={{ borderBottom: 1, borderColor: "divider", mx: -3 }}>
        <UI.Tabs
          value={section}
          aria-label="area page sections"
          variant="fullWidth"
        >
          <UI.Tab
            label="About"
            component={UI.Link}
            to={`/v3/areas/${area.slug}`}
            sx={{ "&:hover": { textDecoration: "none" } }}
            value="about"
          />
          <UI.Tab
            label="Sessions"
            component={UI.Link}
            to={`/v3/areas/${area.slug}/sessions`}
            sx={{ "&:hover": { textDecoration: "none" } }}
            value="sessions"
          />
          <UI.Tab
            label="Reports"
            component={UI.Link}
            to={`/v3/areas/${area.slug}/reports`}
            sx={{ "&:hover": { textDecoration: "none" } }}
            value="reports"
          />
        </UI.Tabs>
      </UI.Box>
      {(() => {
        switch (section) {
          case "about":
            return <C.About area={area} />;
          case "sessions":
            return (
              <C.Sessions
                area={area} type={sessionsByType()}
              />
            );
          case "reports":
            return <C.Reports area={area} />;
          
        }
      })()}
    
    </UI.Container>
  );
};

export default AreaPage;
