import _ from "lodash";
export { inflect, pluralize } from "inflection";
export * as analytics from "./analytics";
export * as cheers from "./cheers";
export * as comments from "./comments";
export * as detect from "./detect";
export * as emoji from "./emoji";
export * as format from "./format";
export * as geo from "./geo";
export * as links from "./links";
export * as roles from "./roles";
export * as seo from "./seo";
export * as status from "./status";
export * as session from "./session";
export * as text from "./text";
export * as time from "./time";
export * as training from "./training";
export * as types from "./types";
export * as validate from "./validate";

export const isServer = typeof window === "undefined";

export const isDev = !isServer && window.location.hostname === "localhost";

export const isBeta =
  !isServer && window.location.hostname === "beta.goodgym.org";

export const isDevOrBeta = isDev || isBeta;

export { camelCase } from "lodash";

export const identity = <T>(
  argument: T | null | undefined | false
): argument is T => {
  return !!argument;
};

export const frequencies = (values: string[]) => {
  return values.reduce(
    (acc, v) => ({
      ...acc,
      [v]: (acc[v] || 0) + 1,
    }),
    {} as { [key: string]: number }
  );
};

export const pascalCase = (s: string) =>
  s
    .split(/\s+/)
    .map((s) => _.upperFirst(_.camelCase(s)))
    .join("");

/*
 * Adapted from https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Array/from#sequence_generator_range
 * changed to work more like range in other languages,
 * where range(0, 5) => [0,1,2,3,4] NOT [0,1,2,3,4,5]
 */
export const range = (start: number, stop: number, step = 1) =>
  Array.from({ length: (stop - start) / step }, (_, i) => start + i * step);

export const partitionBy = <T>(xs: T[], fn: (v: T) => boolean): T[][] => {
  const result: T[][] = [];
  for (const x of xs) {
    const last = _.last(result);
    if (!_.isUndefined(last) && fn(x) === fn(last[0])) {
      last.push(x);
    } else {
      result.push([x]);
    }
  }
  return result;
};

export type AvatarSize = "small" | "medium" | "large" | "huge" | "ginormous";

export const avatarSize = (size: AvatarSize): number => {
  switch (size) {
    case "small":
      return 32;
    case "medium":
      return 40;
    case "large":
      return 72;
    case "huge":
      return 96;
    case "ginormous":
      return 144;
  }
};
