/** @jsxImportSource @emotion/react */
import React from "react";
import * as Icons from "@goodgym/icons";
import * as UI from "@goodgym/components";
import * as T from "@goodgym/graphql/types";
import * as u from "@goodgym/util";
import _ from "lodash";

export type TestPairingCancelledProps = UI.BoxProps & {
  testPairing: T.TestPairingFragment
};

const TestPairingCancelled: React.FC<
  React.PropsWithChildren<TestPairingCancelledProps>
> = ({ ...props }) => {
  const { cancellationNotes, cancelledAt } = props.testPairing;

  return (
    <UI.Box {...props} sx={{
        borderStyle: "solid",
        borderWidth: "0.5px",
        p: 2,
        borderColor: "#E11018",
        borderRadius:"20px"
      }}>
      <UI.Typography variant="h5" sx={{ my: 2 }}>
        Trial visit has been cancelled <UI.Typography variant="h5" color="primary" sx={{ my: 2}}>
        {u.time.formatDateTime(cancelledAt)}
      </UI.Typography>
      </UI.Typography>
      
      <UI.Typography variant="body1" sx={{ my: 1 }}>
        Cancellation reason: {cancellationNotes}
      </UI.Typography>
    </UI.Box>
  );
};

export default TestPairingCancelled;
