/** @jsxImportSource @emotion/react */
import React from "react";
import * as Icons from "@goodgym/icons";
import * as UI from "@goodgym/components";
import * as T from "@goodgym/graphql/types";
import * as u from "@goodgym/util";
import _ from "lodash";

export type WhyJoinProps = {};

const WhyJoin: React.FC<WhyJoinProps> = ({}) => {
  return (
    <UI.Box>
      <UI.Typography
        variant="h5"
        component="p"
        sx={{ my: 2, fontWeight: "normal" }}
      >
        We’re not an ordinary gym. We offer a way to volunteer that{" "}
        <strong>fits into your life</strong> easily, making it easy to commit
        for the long-term.
      </UI.Typography>
      <UI.Typography
        variant="h5"
        component="p"
        sx={{ my: 2, fontWeight: "normal" }}
      >
        <strong>Anybody can join</strong>, no matter your fitness level. All you
        need is a pair of trainers.
      </UI.Typography>
      <UI.Typography
        variant="h5"
        component="p"
        sx={{ my: 2, fontWeight: "normal" }}
      >
        You can <strong>attend as often or as little as you like</strong>. Once
        you’re a member we'll do our best to help you achieve your fitness
        goals.
      </UI.Typography>
      <UI.Typography
        variant="h5"
        component="p"
        sx={{ my: 2, fontWeight: "normal" }}
      >
        We also organise social events like parties and races so that you can
        get to know other members in our <strong>vibrant community</strong>.
      </UI.Typography>
    </UI.Box>
  );
};

export default WhyJoin;
