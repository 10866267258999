import * as T from "@goodgym/graphql/types";

export const cheerableType = (cheerable: T.CheerableItem) => {
  switch (cheerable.__typename) {
    case "NewsfeedAttendance":
      return "Attendance";
    case "NewsfeedAward":
      return "Award";
    case "NewsfeedCoachVisit":
      return "Workout";
    case "NewsfeedRegistration":
      return "Registration";
    case "NewsfeedWorkout":
      return "Workout";
  }
};

export const cheerableActivityId = (cheerable: T.CheerableItem) => {
  switch (cheerable.__typename) {
    case "NewsfeedAttendance":
      return cheerable.session.id;
    case "NewsfeedRegistration":
      return cheerable.session.id;
    default:
      return cheerable.id;
  }
};

export const cheerableRunners = (
  cheerable: T.CheerableItem
): T.RunnerFragment[] => {
  switch (cheerable.__typename) {
    case "NewsfeedAttendance":
    case "NewsfeedRegistration":
      return cheerable.runners;
    default:
      return [cheerable.runner];
  }
};
