/** @jsxImportSource @emotion/react */
import React from "react";
import * as Icons from "@goodgym/icons";
import * as UI from "@goodgym/components";
import * as T from "@goodgym/graphql/types";
import * as u from "@goodgym/util";
import _ from "lodash";
import { useDrawer } from "@goodgym/hooks";
import { useMarkAllNotificationsAsRead } from "@goodgym/graphql/mutations";

export type NotificationsProps = {};

const Notifications: React.FC<NotificationsProps> = ({}) => {
  const { open, onClick, onClose } = useDrawer();
  const { data } = T.useNotificationsQuery();
  const markAllAsRead = useMarkAllNotificationsAsRead();

  const notifications = data?.runner?.notifications || [];
  const read = notifications.filter((n) => n.read);
  const unread = notifications.filter((n) => !n.read);

  return (
    <>
      <UI.MuiBadge
        badgeContent={unread.length}
        color="primary"
        sx={{ mt: "4px" }}
      >
        <UI.IconButton
          id="notifications-button"
          onClick={onClick}
          sx={{ backgroundColor: "neutral.light" }}
          aria-controls={open ? "notifications-menu" : undefined}
          aria-haspopup="true"
          aria-expanded={open ? "true" : undefined}
        >
          <Icons.Notifications />
        </UI.IconButton>
      </UI.MuiBadge>
      <UI.Drawer anchor="right" open={open} onClose={onClose}>
        <UI.Box>
          <UI.DrawerHeader
            title="Notifications"
            anchor="right"
            onClose={onClose}
            open={open}
          />
          <UI.List sx={{ pt: "68px", px: 2 }}>
            {unread.map((notification) => (
              <UI.ListItem key={notification.id}>
                <UI.ListItemIcon sx={{ pr: 2 }}>
                  <UI.Avatar src={notification.imageUrl} />
                </UI.ListItemIcon>
                <UI.Box>
                  <UI.Link href={notification.url}>
                    {notification.content}{" "}
                  </UI.Link>
                  <UI.Typography>
                    {u.time.formatDateTime(notification.createdAt)}
                  </UI.Typography>
                </UI.Box>
              </UI.ListItem>
            ))}
            {read.map((notification) => (
              <UI.ListItem key={notification.id}>
                <UI.ListItemIcon sx={{ pr: 2 }}>
                  <UI.Avatar src={notification.imageUrl} />
                </UI.ListItemIcon>
                <UI.Box>
                  <UI.Link href={notification.url} color="neutral.main">
                    {notification.content}{" "}
                  </UI.Link>
                  <UI.Typography color="neutral.main">
                    {u.time.formatDateTime(notification.createdAt)}
                  </UI.Typography>
                </UI.Box>
              </UI.ListItem>
            ))}
          </UI.List>

          <UI.Box sx={{ px: 2, pb: 2 }}>
            <UI.Button
              variant="text"
              onClick={markAllAsRead}
              fullWidth
              sx={{ m: "auto" }}
              disabled={unread.length === 0}
            >
              Mark all as read
            </UI.Button>
          </UI.Box>
        </UI.Box>
      </UI.Drawer>
    </>
  );
};

export default Notifications;
