import * as UI from "@goodgym/components";
import React from "react";
import { styled as Styled } from "@mui/system";
import { default as styled } from "styled-components";

const CardContent = Styled(UI.Card)`
width:100%;
height:auto;
min-height:80px;
display:flex;
flex-Direction:column;
align-items:center;
`;

interface CardProps {
  title: string;
  tagline?: string;
  image?: string;
  link?: string;
  buttonText?: string;
}

const Image = styled.img`
  height: auto;
  max-height: 282px;
  width: 100%;
  padding-bottom: 5px;
  background-color: #e11018;
`;

const Card: React.FC<CardProps> = ({ title, tagline, image, link, buttonText }) => {
  return (
    <>
      <CardContent>
        <Image src={image} />
        <UI.Typography
          variant="h3"
          sx={{
            textAlign: "center",
            paddingBottom: 2,
            height: { sm: "auto", md: "60px" },
          }}
        >
          {title}
        </UI.Typography>
        <UI.Typography
          variant="body1"
          sx={{ textAlign: "center", padding: 3, height: "auto" }}
        >
          {tagline}
        </UI.Typography>
        <UI.Button href={link} sx={{ margin: 1, textAlign:"center" }}>{buttonText}</UI.Button>
      </CardContent>
    </>
  );
};

export default Card;
