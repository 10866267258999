import splitJpg from "./split.jpg";
import splitWebp from "./split.webp";
import socialisingJpg from "./socialising.jpg";
import socialisingWebp from "./socialising.webp";
import taskJpg from "./task.jpg";
import taskWebp from "./task.webp";
import landingJpg from "./landing-image.jpg";
import landingWebp from "./landing-image.webp";
import groupJpg from "./group.jpg";
import groupWebp from "./group.webp";

export const split = { jpg: splitJpg, webp: splitWebp };
export const socialising = { jpg: socialisingJpg, webp: socialisingWebp };
export const task = { jpg: taskJpg, webp: taskWebp };
export const landing = { jpg: landingJpg, webp: landingWebp };
export const group = { jpg: groupJpg, webp: groupWebp };

export { ReactComponent as Map } from "./map.svg";
