import * as UI from "@goodgym/components";
import { styled as Styled } from "@mui/system";
import styled from "styled-components";

const CardContainer = Styled(UI.Container)`
display:flex;
flex-direction:column;
justify-content:center;
align-items:center;
height:150px;
margin-top:30px;
margin-bottom: 40px;
`;
const CardImage = styled.img`
max-height:120px;
  padding-top: 25px;
`;

export type IconCardsProps = { title?: string; image: string; tagline: string, bold?: boolean; };

const IconCards: React.FC<IconCardsProps> = ({ title, image, tagline, bold }) => {
  return (
    <UI.Grid item lg={4} md={4} sm={12}>
      <CardContainer>
        <CardImage src={image} />
        {title && <UI.Typography sx={{fontWeight:"bold", textAlign:"center"}} variant="h4">{title}</UI.Typography>}
        <UI.Typography sx={{fontWeight:bold? "bold":"normal", textAlign:"center"}} variant="h5">{tagline}</UI.Typography>
      </CardContainer>
    </UI.Grid>
  );
};

export default IconCards;
