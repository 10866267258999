/** @jsxImportSource @emotion/react */
import { useDeleteGroupFitnessActivityMutation } from "@goodgym/graphql/hooks";
import * as T from "@goodgym/graphql/types";
import * as UI from "@goodgym/components";
import * as u from "@goodgym/util";
import { useConfirm } from "@goodgym/hooks";

export type DeleteGroupFitnessActivityMutationHookParams = {
  session: T.SessionFragment;
  gfa: T.GroupFitnessActivityFragment;
};

export type DeleteGroupFitnessActivityMutationHook = (
  defaults?: Partial<T.DeleteGroupFitnessActivityMutation>
) => (
  params: DeleteGroupFitnessActivityMutationHookParams
) => React.MouseEventHandler;

export const useDeleteGroupFitnessActivity: DeleteGroupFitnessActivityMutationHook =
  (defaults) => {
    const { confirm } = useConfirm();
    const [deleteGroupFitnessActivity] = useDeleteGroupFitnessActivityMutation({
      ...defaults,
      onError: console.log,
    });

    return ({ session, gfa }) =>
      (e) => {
        e?.preventDefault();
        confirm({
          title: `Cancel your ${gfa.type.toLocaleLowerCase()}?`,
          text: `Are you sure you want to cancel your ${gfa.type.toLocaleLowerCase()} from ${
            gfa.address.line1
          } to '${session.title}'?`,
          onConfirm: () => {
            deleteGroupFitnessActivity({
              variables: { groupFitnessActivityId: gfa.id },
            });
          },
        });
      };
  };
