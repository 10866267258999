import * as UI from "@goodgym/components";
import * as u from "@goodgym/util";
import HomeIcon from "@mui/icons-material/NearMe";

const ShowSessionsLink = () => {
  return (
    <>
      <UI.Box sx={{ flexGrow: 1 }}>
        <UI.Typography
          variant="subtitle1"
          color="black"
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <HomeIcon fontSize="large" sx={{mr:0.5}} />
          <UI.Link href={u.links.sessions()}>
            See group sessions
          </UI.Link>
        </UI.Typography>
      </UI.Box>
    </>
  );
};

export default ShowSessionsLink;
