import { useAuth } from "@goodgym/auth";
import * as UI from "@goodgym/components";
import { useStoryPageQuery } from "@goodgym/graphql/hooks";
import * as Icons from "@goodgym/icons";
import * as u from "@goodgym/util";
import { hasRole } from "@goodgym/util/roles";
import React from "react";
import { useParams } from "react-router-dom";
import AdminMenu from "./components/AdminMenu";

export type StoryPageProps = UI.BoxProps & {
  panel?: boolean;
  slug?: string;
};

const StoryPage: React.FC<StoryPageProps> = (props) => {
  const params = useParams();
  const slug = params.slug!;

  const { runner } = useAuth();
  const { error, data } = useStoryPageQuery({
    variables: { slug: props.slug || slug! },
  });
  const theme = UI.useTheme();
  const md = UI.useMediaQuery(theme.breakpoints.up("md"));

  if (error) return <UI.Error />;
  if (!data) return <UI.Loading />;

  const story = data.story;
  const authorOfStory = story.author.id;
  const adminRole =
    hasRole(runner, "CENTRAL_ADMIN") || runner?.id === authorOfStory;

  return (
    <>
      <UI.Seo
        title={`${story.title}`}
        description={`${story.strapline}`}
        url={u.links.story(story)}
        image={`${story.imageUrl}`}
      />

      <UI.Box
        sx={{
          pb: { xs: "120px", sm: 3 },
          minHeight: "100vh",
          overflowX: "hidden",
        }}
      >
        <UI.Box sx={{ backgroundColor: "neutral.light" }}>
          <UI.Container sx={{ py: 3 }}>
            <UI.Box sx={{ flexDirection: "column", pt: 2 }}>
              <UI.Breadcrumbs
                crumbs={[
                  ["Home", u.links.home()],
                  ["Stories", u.links.stories()],
                  [story.title, u.links.story(story)],
                ]}
              />
              <UI.Typography variant="h4" sx={{ mt: 2, mb: 1 }}>
                {story.title}
              </UI.Typography>
              <UI.Typography
                variant="subtitle1"
                sx={{ mb: 2, fontSize: "18px" }}
              >
                {story.strapline}
              </UI.Typography>

              <UI.LandscapeImage src={story.imageUrl} />
            </UI.Box>
            <UI.Box sx={{ backgroundColor: "white.main" }}></UI.Box>
          </UI.Container>
        </UI.Box>
        <UI.Box sx={{ backgroundColor: "white.main", pt: 3, mb: 4 }}>
          <UI.Container sx={{ backgroundColor: "white.main", width: "100hw" }}>
            <UI.Grid container columnSpacing={5} sx={{ pt: 2 }}>
              <UI.Grid item sm={12} md={7}>
                {!md && (
                  <>
                    <UI.AreaChip
                      area={story.area && story.area}
                      sx={{ mt: 2, mb: 1 }}
                    />
                    <UI.Box display="flex" sx={{ my: 2 }}>
                      {story.discardedAt ? (
                        <>
                          <Icons.Delete
                            sx={{
                              mr: 1,
                              verticalAlign: "middle",
                              fontSize: "22px",
                              color: "neutral.dark",
                            }}
                          ></Icons.Delete>
                          <UI.Typography
                            variant="body1"
                            sx={{ color: "neutral.dark" }}
                          >
                            {u.time.formatDate(story.discardedAt)}
                          </UI.Typography>
                        </>
                      ) : (
                        story.publishedAt && (
                          <>
                            <Icons.Event
                              sx={{
                                mr: 1,
                                verticalAlign: "middle",
                                fontSize: "22px",
                                color: "neutral.dark",
                              }}
                            ></Icons.Event>
                            <UI.Typography
                              variant="body1"
                              sx={{ color: "neutral.dark" }}
                            >
                              {u.time.formatDate(story.publishedAt)}
                            </UI.Typography>
                          </>
                        )
                      )}
                    </UI.Box>

                    <UI.Box display="flex" sx={{ my: 2 }}>
                      <Icons.Assignment
                        sx={{
                          mr: 1,
                          verticalAlign: "middle",
                          fontSize: "22px",
                          color: "neutral.dark",
                        }}
                      ></Icons.Assignment>

                      <UI.Typography
                        variant="body1"
                        sx={{ color: "neutral.dark" }}
                      >
                        Story written by{" "}
                        <UI.RunnerLink runner={story.author}></UI.RunnerLink>
                      </UI.Typography>
                    </UI.Box>
                    <UI.Box sx={{ mt: 2, mb: 4 }}>
                      <UI.Typography variant="h5" sx={{ mb: 2 }}>
                        Share the love
                      </UI.Typography>
                      <UI.SocialShareButtons
                        id={story.id}
                        type="Report"
                        title={`${story.title}`}
                        url={u.links.story(story)}
                        imageUrl={story.imageUrl}
                        cta="Have you seen this?"
                        sx={{ mx: 1 }}
                      />
                    </UI.Box>
                    {adminRole && <AdminMenu story={story} runner={runner} />}
                  </>
                )}
                <UI.Box
                  sx={{ img: { maxWidth: "100%" } }}
                  className="gg-markdown"
                  dangerouslySetInnerHTML={{ __html: story.body }}
                />
              </UI.Grid>
              <UI.Grid item sm={12} md={5} sx={{ px: 1 }}>
                {md && (
                  <>
                    <UI.AreaChip area={story.area} sx={{ mt: 2, mb: 1 }} />
                    <UI.Box display="flex" sx={{ my: 2 }}>
                      {story.discardedAt ? (
                        <>
                          <Icons.Delete
                            sx={{
                              mr: 1,
                              verticalAlign: "middle",
                              fontSize: "22px",
                              color: "neutral.dark",
                            }}
                          ></Icons.Delete>
                          <UI.Typography
                            variant="body1"
                            sx={{ color: "neutral.dark" }}
                          >
                            {u.time.formatDate(story.discardedAt)}
                          </UI.Typography>
                        </>
                      ) : (
                        story.publishedAt && (
                          <>
                            <Icons.Event
                              sx={{
                                mr: 1,
                                verticalAlign: "middle",
                                fontSize: "22px",
                                color: "neutral.dark",
                              }}
                            ></Icons.Event>
                            <UI.Typography
                              variant="body1"
                              sx={{ color: "neutral.dark" }}
                            >
                              {u.time.formatDate(story.publishedAt)}
                            </UI.Typography>
                          </>
                        )
                      )}
                    </UI.Box>

                    <UI.Box display="flex" sx={{ my: 2 }}>
                      <Icons.Assignment
                        sx={{
                          mr: 1,
                          verticalAlign: "middle",
                          fontSize: "22px",
                          color: "neutral.dark",
                        }}
                      ></Icons.Assignment>
                      {story.author && (
                        <UI.Typography
                          variant="body1"
                          sx={{ color: "neutral.dark" }}
                        >
                          Story written by{" "}
                          <UI.RunnerLink runner={story.author}></UI.RunnerLink>
                        </UI.Typography>
                      )}
                    </UI.Box>
                    <UI.Box sx={{ mt: 6 }}>
                      <UI.Typography variant="h5" sx={{ mb: 2 }}>
                        Share the love
                      </UI.Typography>
                      <UI.SocialShareButtons
                        id={story.id}
                        type="Report"
                        title={`${story.title}`}
                        url={u.links.story(story)}
                        imageUrl={story.imageUrl}
                        cta="Have you seen this?"
                        sx={{ mx: 1 }}
                      />
                    </UI.Box>
                    {adminRole && <AdminMenu story={story} runner={runner} />}
                  </>
                )}
              </UI.Grid>
            </UI.Grid>
          </UI.Container>
        </UI.Box>
      </UI.Box>
    </>
  );
};

export default StoryPage;
