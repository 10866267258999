/** @jsxImportSource @emotion/react */
import React from "react";
import * as Icons from "@goodgym/icons";
import * as UI from "@goodgym/components";
import * as T from "@goodgym/graphql/types";
import * as u from "@goodgym/util";
import _ from "lodash";

export type ListingMapMarkerProps = UI.BoxProps & {
  listings: (T.SessionCardFragment | T.TaskRequestCardFragment)[];
  lat: number;
  lng: number;
  selected?: boolean;
};

const ListingMapMarker: React.FC<ListingMapMarkerProps> = ({
  listings,
  selected = false,
  ...props
}) => {
  const types = _.uniq(_.map(listings, "__typename"));
  return (
    <>
      {_.includes(types, "TaskRequest") && (
        <UI.Box
          {...props}
          sx={{
            backgroundColor: "secondary.main",
            border: "1px solid",
            borderColor: "secondary.dark",
            opacity: selected ? 0.7 : 0.4,
            height: "48px",
            width: "48px",
            ml: "-24px",
            mt: "-24px",
            borderRadius: "50%",
          }}
        />
      )}
      {_.includes(types, "Session") && (
        <UI.Box
          {...props}
          sx={{
            fontSize: "24px",
            height: "24px",
            width: "24px",
            ml: "-12px",
            mt: "-12px",
          }}
        >
          <Icons.LocationOn
            sx={{
              color: selected ? "#333" : "primary.main",
            }}
          />
        </UI.Box>
      )}
    </>
  );
};

export default ListingMapMarker;
