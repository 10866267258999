/** @jsxImportSource @emotion/react */
import * as Icons from "@goodgym/icons";
import * as T from "@goodgym/graphql/types";
import * as UI from "@goodgym/components";
import * as u from "@goodgym/util";
import * as C from "./components";
import React, { useState } from "react";
import InfiniteScroll from "react-infinite-scroller";
import _ from "lodash";
import { useTheme } from "@goodgym/components";


export type NewsfeedProps =
  | { type: "Team"; runner?: undefined }
  | { type: "Runner"; runner: T.RunnerFragment };

// cheat for development when we're likely working with
// a stale staging database so won't have the latest newsfeed items
// - change 40 to whatever value you need
const pageStart = process.env.NODE_ENV === "production" ? 1 : 1;

const Newsfeed: React.FC<NewsfeedProps> = ({ runner, type }) => {
  const { loading, error, data, fetchMore } = T.useNewsfeedQuery({
    variables: { type, page: pageStart, runnerId: runner?.id },
  });

  const [expandedWorkouts, setExpandedWorkouts] = React.useState<{
    [key: string]: boolean;
  }>({});

  const [page, setPage] = useState(pageStart);

  const loadMore = () => {
    if (!loading) {
      const nextPage = page + 1; // Calculate the next page based on current page state
      setPage(nextPage); // Update the page state

      fetchMore({
        variables: { page: nextPage }, // Pass the nextPage variable here
      });
    }
  };

  const theme = useTheme();
  const md = UI.useMediaQuery(theme.breakpoints.up("md"));

  if (error) return <UI.Error error={error} />;
  if (!data) return <UI.Loading />;

  const itemGroups = u.partitionBy(
    data.newsfeed.items,
    (item) => item.__typename === "NewsfeedWorkout"
  );

  const expandWorkouts = (id: string) =>
    setExpandedWorkouts({ ...expandedWorkouts, [id]: true });

  const Feed = () => (
    <>
      {itemGroups.flatMap((items) => {
        if (
          items[0].__typename === "NewsfeedWorkout" &&
          !expandedWorkouts[items[0].id]
        ) {
          return [
            <UI.Grid item xs={12} key={`CollapsedWorkouts_${items[0].id}`}>
              <C.CollapsedWorkouts
                items={items}
                onClick={() => expandWorkouts(items[0].id)}
              />
            </UI.Grid>,
          ];
        } else {
          return items.map((item) => (
            <UI.Grid item xs={12} key={`${item.__typename}_${item.id}`}>
              <C.NewsfeedItem item={item} />
            </UI.Grid>
          ));
        }
      })}
    </>
  );

  return (
    <UI.Box>
      {!data.newsfeed.more && data.newsfeed.items.length === 0 && (
        <UI.Typography
          variant="body1"
          color="neutral.main"
          sx={{ fontStyle: "italic", mb: 3 }}
        >
          Nothing to see here yet.{" "}
          {type === "Team" ? (
            "Follow some other GoodGymers to see their activity in your feed."
          ) : type === "Runner" ? (
            <>
              Get started by finding a{" "}
              <UI.Link to={u.links.sessions()}>session near you</UI.Link>.
            </>
          ) : (
            ""
          )}
        </UI.Typography>
      )}

      {!md && (
        <InfiniteScroll
          hasMore={data.newsfeed.more}
          loadMore={loadMore}
          initialLoad={false}
          pageStart={pageStart}
          loader={
            <UI.InfiniteScrollLoader key="loader" more={data.newsfeed.more} />
          }
        >
          <UI.Grid container spacing={2}>
            <Feed />
          </UI.Grid>
        </InfiniteScroll>
      )}
      {md &&
      <UI.Grid container spacing={2}><Feed /></UI.Grid>}

      {/* Load More Button */}
      {data.newsfeed.more && md && (
        <UI.Box sx={{ textAlign: "center", mt: 2 }}>
          <UI.Button
            variant="text"
            onClick={()=>loadMore()}
            disabled={loading}
            sx={{
              fontSize: "30px",
              textTransform: "uppercase",
              fontStyle: "italic",
            }}
          >
            {loading ? "Loading..." : "Load More"}
          </UI.Button>
        </UI.Box>
      )}
    </UI.Box>
  );
};

export default Newsfeed;
