import * as UI from "@goodgym/components";
import theme from "@goodgym/theme/";
import * as Icon from "./Icons";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import { styled as Styled } from "@mui/system";
import { useState } from "react";
import Table from "./Table";

const TableContents: React.FC = () => {
  const [value, setValue] = useState("1");
  const breakpoint = theme.breakpoints.values["md"];
  const shouldBeHorizontal = UI.useMediaQuery(`(min-width:${breakpoint}px)`);

  const handleChangeTopTabs = (
    event: React.SyntheticEvent,
    newValue: string
  ) => {
    setValue(newValue);
    !shouldBeHorizontal && window.scrollTo({ top: 1000, behavior: "smooth" });
  };

  const handleChangeBottomTabs = (
    event: React.SyntheticEvent,
    newValue: string
  ) => {
    setValue(newValue);
    window.scrollTo({ top: 3, behavior: "smooth" });
    !shouldBeHorizontal && window.scrollTo({ top: 1000, behavior: "smooth" });
  };

  const TabContainer = Styled(UI.Container)`
    display: flex;
    justify-content: center;
    margin-bottom: 50px;
    margin-top: 4px;
  `;

  const ListItem = Styled(UI.ListItem)`   
  list-style-type: disc;
  display: list-item;
   margin-top: -1;
  list-style-position: inside;
                
  `;

  return (
    <UI.Container
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <UI.Box
        sx={{
          width: "100%",
          bgcolor: " #F5F5F5",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <TabContext value={value}>
          <UI.Container sx={{ borderBottom: 1, borderColor: "divider" }}>
            <TabList
              variant="scrollable"
              orientation={shouldBeHorizontal ? "horizontal" : "vertical"}
              onChange={handleChangeTopTabs}
              scrollButtons={true}
              allowScrollButtonsMobile={true}
              aria-label="table of content"
            >
              {" "}
              <UI.Tabs></UI.Tabs>
              <UI.Tab label="Important information and who we are" value="1" />
              <UI.Tab label="The data we collect about you" value="2" />
              <UI.Tab label="How is your personal data collected" value="3" />
              <UI.Tab label="How we use your personal data" value="4" />
              <UI.Tab label="Disclosures of your personal data" value="5" />
              <UI.Tab label="International transfers" value="6" />
              <UI.Tab label="Data security" value="7" />
              <UI.Tab label="Data retention" value="8" />
              <UI.Tab label="Your legal rights" value="9" />
            </TabList>
          </UI.Container>

          <TabPanel sx={{ typography: theme.typography.body1 }} value="1">
            <UI.Typography variant="h2" sx={{ marginBottom: 2 }}>
              Purpose of this privacy policy{" "}
            </UI.Typography>
            This privacy policy aims to give you information on how GoodGym
            collects and processes your personal data through your use of the
            website at https://www.goodgym.org and its associated sub-domains
            (the "Website"), including any personal data you may provide through
            the Website when you sign up to our newsletter, purchase any service
            or take part in a competition.
            <p>
              The Website is not intended for children and we do not knowingly
              collect data relating to children.
            </p>
            It is important that you read this privacy policy together with any
            other privacy policy or fair processing notice we may provide on
            specific occasions when we are collecting or processing personal
            data about you so that you are fully aware of how and why we are
            using your data. This privacy policy supplements the other notices
            and is not intended to override them.
            <UI.Typography variant="h2" sx={{ marginBottom: 2, marginTop: 2 }}>
              Controller
            </UI.Typography>
            The Good Gym (company number 06995775) (collectively referred to as
            "GoodGym", "we", "us" or "our" in this privacy policy) is a data
            controller in respect of personal data collected in respect of
            volunteers and persons we assist.
            <p>
              We have appointed a data protection officer who is responsible for
              overseeing questions in relation to this privacy policy. If you
              have any questions about this privacy policy, including any
              requests to exercise your legal rights, please contact the data
              privacy manager using the details set out below.
            </p>
            <UI.Typography variant="h2" sx={{ marginBottom: 2, marginTop: 2 }}>
              Contact details
            </UI.Typography>
            Our full details are:{" "}
            <UI.Grid
              container
              spacing={0}
              sx={{ paddingRight: 1, paddingBottom: 1 }}
            >
              <UI.Grid item md={4} xs={6}>
                <UI.Grid>
                  <strong>Full name of legal entity</strong>
                </UI.Grid>
              </UI.Grid>
              <UI.Grid item md={4} xs={6}>
                <UI.Grid>
                  The Good Gym{" "}
                  <a
                    style={{ color: theme.palette.primary.main }}
                    href="https://find-and-update.company-information.service.gov.uk/company/06995775"
                  >
                    (company number 06995775)
                  </a>
                </UI.Grid>
              </UI.Grid>
            </UI.Grid>
            <UI.Grid
              container
              spacing={0}
              sx={{ paddingRight: 1, paddingBottom: 1 }}
            >
              <UI.Grid item md={4} xs={6}>
                <UI.Grid>
                  <strong>Data protection manager</strong>
                </UI.Grid>
              </UI.Grid>
              <UI.Grid item md={4} xs={6}>
                <UI.Grid>
                  David Morley
                  <a
                    href={"mailto:"}
                    style={{ color: theme.palette.primary.main }}
                  >
                    {" "}
                    support@goodgym.org
                  </a>
                </UI.Grid>
              </UI.Grid>
            </UI.Grid>
            <UI.Grid
              container
              spacing={0}
              sx={{ paddingRight: 1, paddingBottom: 1 }}
            >
              <UI.Grid item md={4} xs={6}>
                <UI.Grid>
                  <strong>Full name of legal entity</strong>
                </UI.Grid>
              </UI.Grid>
              <UI.Grid item md={4} xs={6}>
                <UI.Grid>
                  The Good Gym{" "}
                  <a
                    style={{ color: theme.palette.primary.main }}
                    href="https://find-and-update.company-information.service.gov.uk/company/06995775"
                  >
                    (company number 06995775)
                  </a>
                </UI.Grid>
              </UI.Grid>
            </UI.Grid>
            <p>
              You have the right to make a complaint at any time to the
              Information Commissioner's Office (ICO), the UK supervisory
              authority for data protection issues{" "}
              <a
                style={{ color: theme.palette.primary.main }}
                href="www.ico.org.uk"
              >
                www.ico.org.uk
              </a>
              . We would, however, appreciate the chance to deal with your
              concerns before you approach the ICO so please contact us in the
              first instance.
            </p>
            <UI.Typography variant="h2" sx={{ marginBottom: 2, marginTop: 2 }}>
              Changes to the privacy policy and your duty to inform us of
              changes
            </UI.Typography>
            It is important that the personal data we hold about you is accurate
            and current. Please keep us informed if your personal data changes
            during your relationship with us.
            <UI.Typography variant="h2" sx={{ marginBottom: 2, marginTop: 2 }}>
              Third-party links
            </UI.Typography>
            The Website may include links to third-party websites, plug-ins and
            applications. Clicking on those links or enabling those connections
            may allow third parties to collect or share data about you. We do
            not control these third-party websites and are not responsible for
            their privacy statements. When you leave the Website, we encourage
            you to read the privacy policy of every website you visit.
            <UI.Typography variant="h2" sx={{ marginBottom: 2, marginTop: 2 }}>
              A bit about our data subjects
            </UI.Typography>
            We use some terms in this policy to refer to different types of data
            subjects:{" "}
            <UI.List>
              <UI.ListItem>
                <UI.Typography>
                  {" "}
                  <b>GoodGymers:</b> these are volunteers who carry out missions
                  with us which include a run, walk, or cycle to help older
                  people with one-off tasks (ii) run, walk, or cycle regularly
                  to see an isolated older person and (iii) group runs.
                </UI.Typography>
              </UI.ListItem>{" "}
              <UI.ListItem>
                <UI.Typography>
                  <b>Coaches:</b> these are older people visited by our
                  GoodGymers.
                </UI.Typography>
              </UI.ListItem>{" "}
              <UI.ListItem>
                <UI.Typography>
                  <b>Referrers:</b> these are people who provide us with
                  referrals to Coaches and in the process may provide us with
                  personal information about themselves.
                </UI.Typography>
              </UI.ListItem>{" "}
            </UI.List>
            References to "you" are to GoodGymers, Coaches and Referrers, as
            well as donors and other people with whom we interact.
            <TabContainer>
              <TabList
                variant="scrollable"
                orientation="horizontal"
                onChange={handleChangeBottomTabs}
                aria-label="table of content"
              >
                <UI.Tab
                  icon={<Icon.Computer />}
                  label="Next: The data we collect about you"
                  value="2"
                />
              </TabList>
            </TabContainer>
          </TabPanel>
          <TabPanel value="2">
            <UI.Typography variant="h2" sx={{ marginBottom: 2, marginTop: 2 }}>
              The data we collect about you
            </UI.Typography>
            Personal data, or personal information, means any information about
            an individual from which that person can be identified. It does not
            include data where the identity has been removed (anonymous data).
            We may collect, use, store and transfer different kinds of personal
            data about you which we have grouped together as follows:
            <UI.List style={{}}>
              <UI.ListItem>
                <UI.Typography>
                  {" "}
                  <b>Identity Data</b> includes name and username or similar
                  identifier, data of birth/age, profile picture and social
                  media links, Referrer organisation.
                </UI.Typography>
              </UI.ListItem>{" "}
              <UI.ListItem>
                <UI.Typography>
                  <b>Contact Data</b> includes address, email address and
                  telephone number.
                </UI.Typography>
              </UI.ListItem>{" "}
              <UI.ListItem>
                <UI.Typography>
                  {" "}
                  <b>Financial Data</b> includes bank account and payment card
                  details.
                </UI.Typography>
              </UI.ListItem>{" "}
              <UI.ListItem>
                <UI.Typography>
                  {" "}
                  <b>Transaction Data</b> includes details about payments to and
                  from you and other details of donations you have made to us.{" "}
                </UI.Typography>
              </UI.ListItem>
              <UI.ListItem>
                <UI.Typography>
                  {" "}
                  <b>Technical Data</b> includes internet protocol (IP) address,
                  your login data, browser type and version, time zone setting
                  and location, browser plug-in types and versions, operating
                  system and platform and other technology on the devices you
                  use to access the Website.
                </UI.Typography>
              </UI.ListItem>
              <UI.ListItem>
                <UI.Typography>
                  <b>Profile Data</b> includes your username and password,
                  purchases made by you, your interests, preferences, reasons
                  for working with us, information on experience, qualifications
                  and background, feedback and survey responses.
                </UI.Typography>
              </UI.ListItem>
              <UI.ListItem>
                <UI.Typography>
                  {" "}
                  <b>Coach Data</b> includes next of kind details, medical
                  details, support and environment information, any notes taken
                  by Runners or our staff and feedback given by the Coach or any
                  Runner.
                </UI.Typography>
              </UI.ListItem>
              <UI.ListItem>
                <UI.Typography>
                  {" "}
                  <b>Usage Data</b> includes information about how you use the
                  Website and our services.
                </UI.Typography>
              </UI.ListItem>
              <UI.ListItem>
                <UI.Typography>
                  {" "}
                  <b>Marketing and Communications Data</b> includes your
                  preferences in receiving marketing from us and our third
                  parties and your communication preferences.
                </UI.Typography>
              </UI.ListItem>
            </UI.List>
            We also collect, use and share <b>Aggregated Data </b>such as
            statistical or demographic data for any purpose. Aggregated Data may
            be derived from your personal data but is not considered personal
            data in law as this data does not directly or indirectly reveal your
            identity. For example, we may aggregate your Usage Data to calculate
            the percentage of users accessing a specific Website feature.
            However, if we combine or connect Aggregated Data with your personal
            data so that it can directly or indirectly identify you, we treat
            the combined data as personal data which will be used in accordance
            with this privacy policy.
            <p>
              Save for information on your ethnicity which is used to report to
              our funders, we do not collect any{" "}
              <b>Special Categories of Personal Data</b> about you (this
              includes details about your race or ethnicity, religious or
              philosophical beliefs, sex life, sexual orientation, political
              opinions, trade union membership, information about your health
              and genetic and biometric data). Nor do we collect any information
              about criminal convictions and offences.
            </p>
            <UI.Typography variant="h2" sx={{ marginBottom: 2, marginTop: 2 }}>
              If you fail to provide personal data
            </UI.Typography>
            Where we need to collect personal data by law, or under the terms of
            a contract we have with you and you fail to provide that data when
            requested, we may not be able to perform the contract we have or are
            trying to enter into with you (for example, to provide you with
            goods or services). In this case, we may have to cancel a product or
            service you have with us but we will notify you if this is the case
            at the time.
            <TabContainer>
              <TabList
                variant="scrollable"
                orientation={shouldBeHorizontal ? "horizontal" : "vertical"}
                onChange={handleChangeBottomTabs}
                aria-label="table of content"
              >
                <UI.Tab
                  icon={<Icon.DirectionsWalk />}
                  label="Previous: Important information and who we are "
                  value="1"
                />
                <UI.Tab
                  icon={<Icon.DisplaySettings />}
                  label="Next: How is your personal data collected"
                  value="3"
                />
              </TabList>
            </TabContainer>
          </TabPanel>
          <TabPanel value="3">
            We use different methods to collect data from and about you
            including through: {""}
            <p>
              <b>Direct interactions</b>
            </p>
            <p>
              You may give us your Identity, Contact, Profile, Coach and
              Financial Data by filling in forms or by corresponding with us by
              post, phone, email or otherwise. This includes personal data you
              provide when you:
            </p>
            <UI.List sx={{}}>
              <ListItem>apply to become a Runner or Coach;</ListItem>
              <ListItem>refer a Coach;</ListItem>
              <ListItem>
                provide us with feedback on a Coach or Runner;
              </ListItem>
              <ListItem>create an account on our Website;</ListItem>
              <ListItem>subscribe to publications or mailing list;</ListItem>
              <ListItem>request marketing to be sent to you;</ListItem>
              <ListItem>enter a competition, promotion or survey; or</ListItem>
              <ListItem>give us some feedback.</ListItem>
            </UI.List>
            <b>Automated technologies or interactions</b>
            <p>
              {" "}
              As you interact with our Website, we may automatically collect
              Technical Data about your equipment, browsing actions and
              patterns. We collect this personal data by using cookies, server
              logs and other similar technologies. We may also receive Technical
              Data about you if you visit other websites employing our cookies.
              Please see our{" "}
              <a
                style={{ color: theme.palette.primary.main }}
                href="https://www.goodgym.org/cookies-policy"
              >
                cookie policy
              </a>{" "}
              for further details.
            </p>
            <b>Third parties or publicly available sources</b>
            <p>
              We may receive personal data about you from various third parties
              and public sources as set out below:
            </p>
            <UI.List>
              <ListItem>
                Technical Data from the following parties:
                <UI.List
                  sx={{
                    listStylePosition: "inside",
                    marginLeft: 4,
                    listStyleType: "disc",
                  }}
                >
                  <ListItem>analytics providers; </ListItem>
                  <ListItem>advertising networks; and </ListItem>
                  <ListItem>search information providers. </ListItem>
                </UI.List>
              </ListItem>
              <ListItem>
                Contact, Financial and Transaction Data from providers of
                technical, payment and delivery services.
              </ListItem>
              <ListItem>
                Identity and Contact Data from data brokers, DBS search
                providers, or data aggregators.
              </ListItem>
              <ListItem>
                Identity, Contact and Profile Data from referees.{" "}
              </ListItem>
              <ListItem>
                Identity, Contact, Profile and Coach Data from Referrers.{" "}
              </ListItem>
              <ListItem>
                Identity and Contact Data from publicly availably sources such
                as Companies House.
              </ListItem>
            </UI.List>
            <TabContainer>
              <TabList
                variant="scrollable"
                orientation={shouldBeHorizontal ? "horizontal" : "vertical"}
                onChange={handleChangeBottomTabs}
                aria-label="table of content"
              >
                <UI.Tab
                  icon={<Icon.Computer />}
                  label="Previous: The data we collect about you "
                  value="2"
                />
                <UI.Tab
                  icon={<Icon.BorderColor />}
                  label="Next: How we use your personal data "
                  value="4"
                />
              </TabList>
            </TabContainer>
          </TabPanel>
          <TabPanel value="4">
            <p>
              {" "}
              We will only use your personal data when the law allows us to.
              Most commonly, we will use your personal data in the following
              circumstances:
            </p>
            <UI.List>
              <ListItem>
                Where we need to perform the contract we are about to enter into
                or have entered into with you.
              </ListItem>
              <ListItem>
                Where it is necessary for our legitimate interests (or those of
                a third party) and your interests and fundamental rights do not
                override those interests.
              </ListItem>
              <ListItem>
                Where we need to comply with a legal or regUI.Listatory
                obligation.
              </ListItem>
            </UI.List>
            Generally we do not rely on consent as a legal basis for processing
            your personal data other than in relation to (i) sending third party
            direct marketing communications to you via email (ii) processing
            certain Special Categories of Personal Data, namely Coach health
            information and Runner DBS information. You have the right to
            withdraw consent to marketing at any time by contacting us.
            <UI.Typography variant="h2" sx={{ marginBottom: 2, marginTop: 2 }}>
              Purposes for which we will use your personal data
            </UI.Typography>
            We have set out below, in a table format, a description of all the
            ways we plan to use your personal data, and which of the legal bases
            we rely on to do so. We have also identified what our legitimate
            interests are where appropriate.
            <p>
              Note that we may process your personal data for more than one
              lawful ground depending on the specific purpose for which we are
              using your data. Please contact us if you need details about the
              specific legal ground we are relying on to process your personal
              data where more than one ground has been set out in the table
              below.
            </p>
            <>
              <Table />
            </>
            <UI.Typography variant="h2" sx={{ marginBottom: 2, marginTop: 2 }}>
              Marketing{" "}
            </UI.Typography>
            We strive to provide you with choices regarding certain personal
            data uses, particularly around marketing and advertising. You may
            withdraw any consent given to receive direct marketing
            communications from us by contacting us.
            <UI.Typography variant="h2" sx={{ marginBottom: 2, marginTop: 2 }}>
              Promotional offers from us
            </UI.Typography>
            We may use your Identity, Contact, Technical, Usage and Profile Data
            to form a view on what we think you may want or need, or what may be
            of interest to you. This is how we decide which services and offers
            may be relevant for you. We strive to provide you with choices
            regarding certain personal data uses, particularly around marketing
            and advertising. You may withdraw any consent given to receive
            direct marketing communications from us by contacting us.
            <p>
              You will receive marketing communications from us if you have
              requested information from us or purchased services from us or if
              you provided us with your details when you entered a competition
              or registered for a promotion and, in each case, you have not
              opted out of receiving that marketing.
            </p>
            <UI.Typography variant="h2" sx={{ marginBottom: 2, marginTop: 2 }}>
              Third-party marketing
            </UI.Typography>
            We will get your express opt-in consent before we share your
            personal data with any company in order to allow them to market to
            you.
            <p>
              {" "}
              We do provide your obfuscated email address to Facebook to improve
              our advertising campaigns (e.g. so that we don't needlessly show
              you adverts to join GoodGym). Find out how your email address is
              obfuscated.
            </p>
            <UI.Typography variant="h2" sx={{ marginBottom: 2, marginTop: 2 }}>
              Opting out
            </UI.Typography>
            You can ask us or third parties to stop sending you marketing
            messages at any time by following the opt-out links on any marketing
            message sent to you or by contacting us at any time.
            <p>
              Where you opt out of receiving these marketing messages, this will
              not apply to personal data provided to us as a result of you
              purchasing services from us.{" "}
            </p>
            <UI.Typography variant="h2" sx={{ marginBottom: 2, marginTop: 2 }}>
              Cookies
            </UI.Typography>{" "}
            You can set your browser to refuse all or some browser cookies, or
            to alert you when websites set or access cookies. If you disable or
            refuse cookies, please note that some parts of the Website may
            become inaccessible or not function properly.{" "}
            <UI.Typography variant="h2" sx={{ marginBottom: 2, marginTop: 2 }}>
              Change of purpose
            </UI.Typography>{" "}
            We will only use your personal data for the purposes for which we
            collected it, unless we reasonably consider that we need to use it
            for another reason and that reason is compatible with the original
            purpose. If you wish to get an explanation as to how the processing
            for the new purpose is compatible with the original purpose, please
            contact us.
            <p>
              If we need to use your personal data for an unrelated purpose, we
              will notify you and we will explain the legal basis which allows
              us to do so.{" "}
            </p>
            Please note that we may process your personal data without your
            knowledge or consent, in compliance with the above rules, where this
            is required or permitted by law.
            <TabContainer>
              <TabList
                variant="scrollable"
                orientation={shouldBeHorizontal ? "horizontal" : "vertical"}
                onChange={handleChangeBottomTabs}
                aria-label="table of content"
              >
                <UI.Tab
                  icon={<Icon.DisplaySettings />}
                  label="Previous: How is your personal data collected"
                  value="3"
                />
                <UI.Tab
                  icon={<Icon.MenuBook />}
                  label="Next: Disclosure of your personal data"
                  value="5"
                />
              </TabList>
            </TabContainer>
          </TabPanel>
          <TabPanel value="5">
            <UI.Typography variant="h2" sx={{ marginBottom: 2, marginTop: 2 }}>
              Disclosures of your personal data
            </UI.Typography>
            We may have to share your personal data with the parties set out
            below for the purposes set out in the table in paragraph 4 above.
            <UI.List
              style={{
                listStylePosition: "inside",
                paddingLeft: "1rem",
                lineHeight: 1.8,
              }}
            >
              <ListItem>
                External Third Parties include our funders, payment providers,
                analytics and technical providers, applications to track your
                runs, messaging and customer service applications and checking
                software. You can see a more{" "}
                <a
                  style={{ color: theme.palette.primary.main }}
                  href="https://www.goodgym.org/privacy-policy/third-parties"
                >
                  detailed list of the third parties
                </a>
                with whom we work with.
              </ListItem>
              <ListItem>
                Third parties to whom we may choose to sell, transfer, or merge
                parts of our business or our assets. Alternatively, we may seek
                to acquire other businesses or merge with them. If a change
                happens to our business, then the new owners may use your
                personal data in the same way as set out in this privacy policy.
              </ListItem>
            </UI.List>
            We require all third parties to respect the security of your
            personal data and to treat it in accordance with the law. We do not
            allow our third-party service providers to use your personal data
            for their own purposes and only permit them to process your personal
            data for specified purposes and in accordance with our instructions.
            <TabContainer>
              <TabList
                variant="scrollable"
                orientation={shouldBeHorizontal ? "horizontal" : "vertical"}
                onChange={handleChangeBottomTabs}
                aria-label="table of content"
              >
                <UI.Tab
                  icon={<Icon.BorderColor />}
                  label="Previous: How we use your personal data"
                  value="4"
                />
                <UI.Tab
                  icon={<Icon.Public />}
                  label="Next: International transfer"
                  value="6"
                />
              </TabList>
            </TabContainer>
          </TabPanel>
          <TabPanel value="6">
            <UI.Typography variant="h2" sx={{ marginBottom: 2, marginTop: 2 }}>
              International transfers
            </UI.Typography>
            Some of our external third parties are based outside the European
            Economic Area <b> (EEA)</b> so their processing of your personal
            data will involve a transfer of data outside the EEA.
            <p>
              Whenever we transfer your personal data out of the EEA, we ensure
              a similar degree of protection is afforded to it by ensuring at
              least one of the following safeguards is implemented:
            </p>
            <UI.List
              style={{
                listStylePosition: "inside",
                paddingLeft: "1rem",
                lineHeight: 1.8,
              }}
            >
              <ListItem>
                Where we use certain service providers, we may use specific
                contracts approved by the European Commission which give
                personal data the same protection it has in Europe. For further
                details, see European Commission: Model contracts for the
                transfer of personal data to third countries.
              </ListItem>
              <ListItem>
                Where we use providers based in the US, we may transfer data to
                them if they are part of the Privacy Shield which requires them
                to provide similar protection to personal data shared between
                the Europe and the US. For further details, see European
                Commission: EU-US Privacy Shield.
              </ListItem>
            </UI.List>
            Please contact us if you want further information on the specific
            mechanism used by us when transferring your personal data out of the
            EEA.
            <TabContainer>
              <TabList
                variant="scrollable"
                orientation={shouldBeHorizontal ? "horizontal" : "vertical"}
                onChange={handleChangeBottomTabs}
                aria-label="table of content"
              >
                <UI.Tab
                  icon={<Icon.MenuBook />}
                  label="Previous: Disclosures of your personal data"
                  value="5"
                />
                <UI.Tab
                  icon={<Icon.Security />}
                  label="Next: Data Security"
                  value="7"
                />
              </TabList>
            </TabContainer>
          </TabPanel>
          <TabPanel value="7">
            <UI.Typography variant="h2" sx={{ marginBottom: 2, marginTop: 2 }}>
              Data security
            </UI.Typography>
            We have put in place appropriate security measures to prevent your
            personal data from being accidentally lost, used or accessed in an
            unauthorised way, altered or disclosed. In addition, we limit access
            to your personal data to those employees, agents, contractors and
            other third parties who have a business need to know. They will only
            process your personal data on our instructions and they are subject
            to a duty of confidentiality. We have put in place procedures to
            deal with any suspected personal data breach and will notify you and
            any applicable regulator of a breach where we are legally required
            to do so.
            <TabContainer>
              <TabList
                variant="scrollable"
                orientation={shouldBeHorizontal ? "horizontal" : "vertical"}
                onChange={handleChangeBottomTabs}
                aria-label="table of content"
              >
                <UI.Tab
                  icon={<Icon.Public />}
                  label="Previous: International transfers"
                  value="6"
                />
                <UI.Tab
                  icon={<Icon.SdStorage />}
                  label="Next: Data retention"
                  value="8"
                />
              </TabList>
            </TabContainer>
          </TabPanel>
          <TabPanel value="8">
            <UI.Typography variant="h2" sx={{ marginBottom: 2, marginTop: 2 }}>
              Data retention
            </UI.Typography>
            <UI.Typography variant="h4" sx={{ marginBottom: 2, marginTop: 2 }}>
              How long will you use my personal data for?
            </UI.Typography>
            We will only retain your personal data for as long as necessary to
            fulfil the purposes we collected it for, including for the purposes
            of satisfying any legal, accounting, or reporting requirements.
            <p>
              To determine the appropriate retention period for personal data,
              we consider the amount, nature, and sensitivity of the personal
              data, the potential risk of harm from unauthorised use or
              disclosure of your personal data, the purposes for which we
              process your personal data and whether we can achieve those
              purposes through other means, and the applicable legal
              requirements. Details of retention periods for different aspects
              of your personal data are available in our retention policy which
              you can request from us by contacting us. In some circumstances
              you can ask us to delete your data: see Erasure Request below for
              further information. In some circumstances we may anonymise your
              personal data (so that it can no longer be associated with you)
              for research or statistical purposes in which case we may use this
              information indefinitely without further notice to you.
            </p>
            <TabContainer>
              <TabList
                variant="scrollable"
                orientation={shouldBeHorizontal ? "horizontal" : "vertical"}
                onChange={handleChangeBottomTabs}
                aria-label="table of content"
              >
                <UI.Tab
                  icon={<Icon.Security />}
                  label="Previous: Data Security"
                  value="7"
                />
                <UI.Tab
                  icon={<Icon.Policy />}
                  label="Next: Your legal rights"
                  value="9"
                />
              </TabList>
            </TabContainer>
          </TabPanel>
          <TabPanel value="9">
            <UI.Typography variant="h2" sx={{ marginBottom: 2, marginTop: 2 }}>
              Your legal rights
            </UI.Typography>
            Under certain circumstances, you have rights under data protection
            laws in relation to your personal data.
            <p>You have the right to:</p>
            <UI.List>
              <ListItem>
                <strong>Request access</strong> to your personal data (commonly
                known as a <strong>"data subject access request" </strong>).
                This enables you to receive a copy of the personal data we hold
                about you and to check that we are lawfully processing it.
              </ListItem>
              <ListItem>
                <strong>Request correction</strong> of the personal data that we
                hold about you. This enables you to have any incomplete or
                inaccurate data we hold about you corrected, though we may need
                to verify the accuracy of the new data you provide to us.
              </ListItem>
              <ListItem>
                <strong>Request erasure</strong> of your personal data. This
                enables you to ask us to delete or remove personal data where
                there is no good reason for us continuing to process it. You
                also have the right to ask us to delete or remove your personal
                data where you have successfully exercised your right to object
                to processing (see below), where we may have processed your
                information unlawfully or where we are required to erase your
                personal data to comply with local law. Note, however, that we
                may not always be able to comply with your request of erasure
                for specific legal reasons which will be notified to you, if
                applicable, at the time of your request.
              </ListItem>
              <ListItem>
                <strong>Object to processing </strong>of your personal data
                where we are relying on a legitimate interest (or those of a
                third party) and there is something about your particular
                situation which makes you want to object to processing on this
                ground as you feel it impacts on your fundamental rights and
                freedoms. You also have the right to object where we are
                processing your personal data for direct marketing purposes. In
                some cases, we may demonstrate that we have compelling
                legitimate grounds to process your information which override
                your rights and freedoms.
              </ListItem>
              <ListItem>
                <strong>Request restriction of processing</strong> of your
                personal data. This enables you to ask us to suspend the
                processing of your personal data in the following scenarios:{" "}
                <UI.List sx={{ marginLeft: 4 }}>
                  <ListItem>
                    if you want us to establish the data's accuracy;
                  </ListItem>{" "}
                  <ListItem>
                    {" "}
                    where our use of the data is unlawful but you do not want us
                    to erase it;
                  </ListItem>{" "}
                  <ListItem>
                    where you need us to hold the data even if we no longer
                    require it as you need it to establish, exercise or defend
                    legal claims; or
                  </ListItem>{" "}
                  <ListItem>
                    you have objected to our use of your data but we need to
                    verify whether we have overriding legitimate grounds to use
                    it.
                  </ListItem>
                </UI.List>
              </ListItem>
              <ListItem>
                <strong>Request the transfer </strong>of your personal data to
                you or to a third party. We will provide to you, or a third
                party you have chosen, your personal data in a structured,
                commonly used, machine-readable format. Note that this right
                only applies to automated information which you initially
                provided consent for us to use or where we used the information
                to perform a contract with you.
              </ListItem>
              <ListItem>
                <b>Withdraw consent at any time</b> where we are relying on
                consent to process your personal data. However, this will not
                affect the lawfulness of any processing carried out before you
                withdraw your consent. If you withdraw your consent, we may not
                be able to provide certain products or services to you. We will
                advise you if this is the case at the time you withdraw your
                consent.
              </ListItem>
            </UI.List>
            If you wish to exercise any of the rights set out above, please
            contact us.
            <UI.Typography variant="h2" sx={{ marginBottom: 2, marginTop: 2 }}>
              No fee usually required
            </UI.Typography>
            You will not have to pay a fee to access your personal data (or to
            exercise any of the other rights). However, we may charge a
            reasonable fee if your request is clearly unfounded, repetitive or
            excessive. Alternatively, we may refuse to comply with your request
            in these circumstances.
            <UI.Typography variant="h2" sx={{ marginBottom: 2, marginTop: 2 }}>
              What we may need from you
            </UI.Typography>
            We may need to request specific information from you to help us
            confirm your identity and ensure your right to access your personal
            data (or to exercise any of your other rights). This is a security
            measure to ensure that personal data is not disclosed to any person
            who has no right to receive it. We may also contact you to ask you
            for further information in relation to your request to speed up our
            response.
            <UI.Typography variant="h2" sx={{ marginBottom: 2, marginTop: 2 }}>
              Time limit to respond
            </UI.Typography>
            We try to respond to all legitimate requests within one month.
            Occasionally it may take us longer than a month if your request is
            particularly complex or you have made a number of requests. In this
            case, we will notify you and keep you updated.
            <TabContainer>
              <TabList
                variant="scrollable"
                orientation={shouldBeHorizontal ? "horizontal" : "vertical"}
                onChange={handleChangeBottomTabs}
                aria-label="table of content"
              >
                <UI.Tab
                  icon={<Icon.SdStorage />}
                  label="Previous: Data Retention"
                  value="8"
                />
              </TabList>
            </TabContainer>
          </TabPanel>
        </TabContext>
      </UI.Box>
    </UI.Container>
  );
};

export default TableContents;
