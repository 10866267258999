import * as T from "@goodgym/graphql/types";
import * as u from "@goodgym/util";

export const signupStatus = (
  session: T.SessionCardFragment,
  runner: Optional<T.CurrentRunnerFragment>
) => {
  const isOrAreGoing =
    session.signups.length === 1
      ? "One GoodGymer is going"
      : `${session.signups.length} GoodGymers are going`;

  if (session.signups.find((s) => s.id === runner?.id)) {
    if (session.signups.length === 1) {
      return "You're going! 🎉";
    } else if (session.signups.length === 2) {
      return "You and one other GoodGymer are going! 🎉";
    } else {
      return `You and ${
        session.signups.length - 1
      } other GoodGymers are going! 🎉`;
    }
  } else if (!session.registerMax) {
    return isOrAreGoing;
  } else if (session.signups.length >= session.registerMax) {
    return `${isOrAreGoing} - no space left 😢`;
  } else {
    const spacesRemaining = session.registerMax - session.signups.length;
    return `${isOrAreGoing}  - ${spacesRemaining} ${u.inflect(
      "space",
      spacesRemaining
    )} left! 👀`;
  }
};
