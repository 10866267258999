/** @jsxImportSource @emotion/react */
import React from "react";
import * as Icons from "@goodgym/icons";
import * as UI from "@goodgym/components";
import * as T from "@goodgym/graphql/types";
import * as u from "@goodgym/util";
import _ from "lodash";
import GoogleMap from "google-map-react";

export type MapProps = UI.BoxProps & { session: T.SessionCardFragment };

const Map: React.FC<MapProps> = ({ session, ...props }) => {
  if (!session.address?.lat || !session.address?.lng) return null;
  return (
    <UI.Box
      {...props}
      sx={{
        position: "relative",
        width: "100%",
        height: "300px",
        ...props.sx,
      }}
    >
      <GoogleMap
        bootstrapURLKeys={{
          key: process.env.REACT_APP_GOOGLE_MAPS_API_KEY!,
        }}
        defaultCenter={{
          lat: session.address.lat,
          lng: session.address.lng,
        }}
        defaultZoom={15}
        style={{ height: "100%", width: "100%" }}
      >
        <UI.ListingMapMarker
          listings={[session]}
          lat={session.address.lat}
          lng={session.address.lng}
        />
      </GoogleMap>
    </UI.Box>
  );
};

export default Map;
