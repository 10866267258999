import * as UI from "@goodgym/components";
import * as menus from "@goodgym/menus";
import { SelectChangeEvent } from "@mui/material";
import FormControl from "@mui/material/FormControl";
import React from "react";
import { useSearchParams } from "react-router-dom";
import { State } from "../../../types";

export type SessionTypesFilterProps = UI.BoxProps & {
  state: Pick<State, "sections" | "filters" | "drawers">;
};

// Helper to check if localStorage is available
const isLocalStorageAvailable = (): boolean => {
  try {
    const test = "test";
    if (typeof window !== "undefined" && window.localStorage) {
      localStorage.setItem(test, test);
      localStorage.removeItem(test);
      return true;
    }
  } catch (e) {
    console.warn("localStorage is not available:", e);
  }
  return false;
};

const SessionTypesFilter: React.FC<SessionTypesFilterProps> = ({
  state,
  ...props
}) => {
  const { filters } = state;

  const [searchParams] = useSearchParams();
  const typeUrl = searchParams.get("type");

  // Safely access localStorage
  const getSessionTypeFromStorage = () => {
    if (isLocalStorageAvailable()) {
      return localStorage.getItem("sessionType");
    }
    return null;
  };

  const [typeSelected, setTypeSelected] = React.useState(() => {
    const sessionType = getSessionTypeFromStorage();
    return typeUrl ? typeUrl : sessionType ? sessionType : "all";
  });

  React.useEffect(() => {
    const url = new URL(window.location.href);
    url.searchParams.set("type", typeSelected);
    window.history.replaceState(null, "", url);
  }, [typeSelected]);

  const handleSessionTypeChange = (event: SelectChangeEvent) => {
    const value = (): Array<string> => {
      switch (event.target.value) {
        case "all":
          return [
            "GroupRun",
            "CommunityMission",
            "Mission",
            "DocumentCheck",
            "Party",
            "TrainingSession",
            "Race",
          ];
        case "Group Sessions":
          return ["GroupRun", "CommunityMission"];
        default:
          return [event.target.value];
      }
    };
    filters.update({
      types: value(),
    });
    const newType = event.target.value as string;
    if (isLocalStorageAvailable()) {
      try {
        localStorage.setItem("sessionType", newType);
      } catch (e) {
        console.warn("Error saving to localStorage:", e);
      }
    }
    setTypeSelected(newType);
  };

  return (
    <UI.Box {...props}>
      <UI.Typography component={"span"} variant="subtitle1">
        <UI.Grid container>
          <UI.Grid item sx={{ alignSelf: "center", flexGrow: 1, mb: 2 }}>
            Session types
          </UI.Grid>
        </UI.Grid>
      </UI.Typography>
      <UI.Box>
        <FormControl fullWidth>
          <UI.InputLabel> Sessions Type</UI.InputLabel>
          <UI.Select
            id="session-type-filter"
            label="Sessions Type"
            onChange={handleSessionTypeChange} // Correctly typed event handler
            value={typeSelected}
          >
            <UI.MenuItem value="all">All Session Types</UI.MenuItem>
            <UI.MenuItem value="Group Sessions">Group Sessions</UI.MenuItem>

            {menus.sessions.slice(2).map(({ type }) => (
              <UI.MenuItem key={type} value={type}>
                {type.replace(/([A-Z])/g, " $1").replace(/^\s/, "")}
              </UI.MenuItem>
            ))}
          </UI.Select>
        </FormControl>
      </UI.Box>
    </UI.Box>
  );
};

export default SessionTypesFilter;
