/** @jsxImportSource @emotion/react */
import React from "react";
import * as Icons from "@goodgym/icons";
import * as UI from "@goodgym/components";
import * as T from "@goodgym/graphql/types";
import * as u from "@goodgym/util";
import _ from "lodash";
import { useLocation } from "react-router-dom";

export type AaDashboardProps = UI.ButtonProps<typeof UI.Link> & {};

const AaDashboard: React.FC<React.PropsWithChildren<AaDashboardProps>> = ({
  ...props
}) => {
  const { pathname } = useLocation();
  const url = u.links.areaActivatorAdmin();
  return (
    <UI.NavbarButton
      icon={Icons.Build}
      label="AA Admin"
      active={pathname === url}
      {...(url.includes("/v3") ? { to: url } : { href: url })}
      {...props}
    />
  );
};

export default AaDashboard;
