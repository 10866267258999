import * as T from "@goodgym/graphql/types";

export const commentableType = (commentable: T.CommentableItem) => {
  switch (commentable.__typename) {
    case "Session":
      return "Session";
    case "NewsfeedCoachVisit":
      return "CoachVisit";
    case "Report":
      return "Report";
    case "NewsfeedWorkout":
      return "Workout";
    case "NewsfeedAward":
      return "Award";
  }
};
