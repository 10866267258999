import * as UI from "@goodgym/components";
import { styled as Styled } from "@mui/system";
import * as React from "react";

export type CardsProps = { title: string; image: string; strapline: string; linkForButton:string };

const Card: React.FC<CardsProps> = ({ title, image, strapline, linkForButton }) => {
  const CardHeader = Styled(UI.Box)`
    background: #e11018;
    height: 120px;
    width: 100%;
    display: flex;
  `;

  const CardTitleContainer = Styled(UI.Box)`
    width:50%;
    height: 100%;
    position: relative;
    &:after {
      background-image: linear-gradient(
        to left bottom,
        transparent,
        transparent 50%,
        #e11018 51%,
        #e11018
      );
      position: absolute;
      top: 0px;
      right: -32px;
      content: "";
      height: 100%;
      width: 2rem;
    
    }`;
  const ImageContainer = Styled(UI.Box)`
    width:50%;
  `;

  return (
    <>
      <UI.Grid
        item
        lg={6}
        md={6}
        sm={12}
        xs={12}
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          mb:1
        }}
      >
        <UI.Card
          sx={{
            width: { lg: "90%", md: "85%", sm: "60%", xs: "100%" },
            display: "block",
            minHeight: "250px",
            height: { lg: "200px", md: "295px", sm: "268px", xs: "280px" },
            borderRadius: "15px",
            position: "relative",
          }}
        >
          <CardHeader>
            <CardTitleContainer>
              <UI.Typography
                variant="h3"
                sx={{
                  margin: 3,
                  color: "#FFF",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  width: "80%",
                }}
              >
                {title}
              </UI.Typography>
            </CardTitleContainer>
            <ImageContainer>
              <img
                style={{
                  width: "100%",
                  height: "100%",
                  objectFit: "cover",
                  objectPosition: "top center",
                }}
                alt="goodgym missions"
                src={image}
              />
            </ImageContainer>
          </CardHeader>

          <UI.Container
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <UI.Typography
              color="black"
              sx={{
                textAlign: "center",
                my: 2,
              }}
            >
              {strapline}
            </UI.Typography>{" "}
            <UI.Button
              href={linkForButton}
              sx={{
                position: "absolute",
                bottom: "10px",
              }}
            >
              Find out more
            </UI.Button>{" "}
          </UI.Container>
        </UI.Card>
      </UI.Grid>
    </>
  );
};

export default Card;
