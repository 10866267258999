import * as UI from "@goodgym/components";
import IconCards from "@goodgym/components/IconCards";
import * as data from "../../data";
import PostcodeSearch from "@goodgym/pages/AboutPage/components/PostcodeSearch";

const AboutCommunityMission = () => {
  return (
    <>
      <UI.Container>
        <UI.Typography
          variant="h5"
          sx={{
            lineHeight: 1.8,
            fontWeight: "normal",
            marginTop: 1,
            marginBottom: 3,
            textAlign: "center",
          }}
        >
          We work out by helping local community projects.
        </UI.Typography>
      </UI.Container>
      <UI.Container>
        <UI.Grid
          container
          spacing={2}
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          {data.taskIconData.map((element, index) => (
            <IconCards
              key={index}
              image={element.image}
              tagline={element.tagline}
              bold={true}
            />
          ))}
        </UI.Grid>
      </UI.Container>
      <UI.Container>
        <UI.Typography
          variant="h2"
          sx={{ my: 2, textAlign: "center", fontStyle: "normal" }}
        >
          Find your local group
        </UI.Typography>
      </UI.Container>
      <UI.Container sx={{ textAlign: "center" }}>
        <UI.Container sx={{ textAlign: "center" }}>
          <PostcodeSearch communityMissionPage={true} />
        </UI.Container>
      </UI.Container>
    </>
  );
};

export default AboutCommunityMission;
