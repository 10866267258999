/** @jsxImportSource @emotion/react */
import * as UI from "@goodgym/components";
import * as T from "@goodgym/graphql/types";
import DoNotDisturbIcon from "@mui/icons-material/DoNotDisturb";
import React from "react";

export type TestPairingRejectedProps = UI.BoxProps & {
  testPairing: T.TestPairingFragment
};

const TestPairingRejected: React.FC<
  React.PropsWithChildren<TestPairingRejectedProps>
> = ({ ...props }) => {
  const { rejectionNotes } = props.testPairing;

  return (
    <UI.Box
      {...props}
      sx={{
        borderStyle: "solid",
        borderWidth: "0.5px",
        p: 2,
        borderColor: "#E11018",
        borderRadius: "20px",
      }}
    >
      <UI.Typography variant="h4">
        This trial visit request has been rejected
      </UI.Typography>
      <UI.Typography sx={{ my: 2 }}>
        <DoNotDisturbIcon fontSize="small" sx={{ mr: 1 }} />
        Rejection reason: {rejectionNotes}
      </UI.Typography>
    </UI.Box>
  );
};

export default TestPairingRejected;
