/** @jsxImportSource @emotion/react */
import React from "react";
import * as Icons from "@goodgym/icons";
import * as UI from "@goodgym/components";
import * as T from "@goodgym/graphql/types";
import * as u from "@goodgym/util";
import _ from "lodash";
import * as NP from "@goodgym/noun-project";
import * as C from "..";

export type TaskForceAchievementsProps = UI.BoxProps & {
  runner: T.ProfilePageQuery["runner"];
};

const TaskForceAchievements: React.FC<
  React.PropsWithChildren<TaskForceAchievementsProps>
> = ({ runner, ...props }) => {
  return (
    <UI.Box {...props}>
      <UI.Divider sx={{ my: 3 }} />
      <UI.Typography variant="subtitle1" sx={{ mb: 2 }}>
        TaskForce achievements
      </UI.Typography>
      <UI.Grid
        container
        columnSpacing={1}
        rowSpacing={2}
        justifyContent="space-between"
        alignItems="stretch"
      >
        <C.TaskForceAchievement
          label="Sessions listed"
          Icon={NP.List}
          stat={runner.stats.sessionsListed}
        />
        <C.TaskForceAchievement
          label="Sessions led"
          Icon={NP.HighFive}
          stat={runner.stats.sessionsLed}
        />
        <C.TaskForceAchievement
          label="Sessions backmarked"
          Icon={NP.Running}
          stat={runner.stats.sessionsBackmarked}
        />
        <C.TaskForceAchievement
          label="Walks led"
          Icon={NP.Walking}
          stat={runner.stats.walksLed}
        />
        <C.TaskForceAchievement
          label="Sessions photographed"
          Icon={NP.Camera}
          stat={runner.stats.sessionsPhotographed}
        />
        <C.TaskForceAchievement
          label="Reports written"
          Icon={NP.Report}
          stat={runner.stats.reportsWritten}
        />
      </UI.Grid>
    </UI.Box>
  );
};

export default TaskForceAchievements;
