/** @jsxImportSource @emotion/react */
import React from "react";
import { useCancelTestPairingMutation } from "@goodgym/graphql/hooks";
import * as T from "@goodgym/graphql/types";
import * as UI from "@goodgym/components";
import * as u from "@goodgym/util";
import { useConfirm } from "@goodgym/hooks";
import { useNavigate } from "react-router-dom";

export type RescheduleProps = (params: {
  coach: Pick<T.CoachReferralFragment, "id" | "name">;
  cancellationNotes: string;
  testPairingId: string;
}) => React.MouseEventHandler;

export const useCancelTestPairing: RescheduleProps = ({ coach, cancellationNotes, testPairingId }) => {
  const { confirm } = useConfirm();

  const navigate = useNavigate();

  const [CancelTestPairing, { data }] =
    useCancelTestPairingMutation({
      onError: console.log,
      variables: { id: testPairingId, cancellationNotes:cancellationNotes },
      optimisticResponse: {
        response: {
          __typename: "CancelTestPairingMutationPayload",
          testPairing: {
            __typename: "TestPairing",
            acceptedAt: null,
            completedAt: null,
            id: coach.id,
            notesForRunner: null,
            rejectedAt: null,
            rejectionNotes: null,
            cancellationNotes: cancellationNotes,
            runnerVisitFeedback: null,
            runnerVisitNotes: null,
            runnerWantsPairing:null,
            cancelledAt: new Date(),
            scheduledAt: null,
            coach: {
              __typename: "Coach",
              id: coach.id,
              name: coach.name,
            },
          },
        },
      },
    });

  return (e) => {
    e?.preventDefault();
    confirm({
      title: "Cancel this trial visit",
      text: "Are you sure you want to cancel this trial visit?",
      alert: false,
      onConfirm: async () => {
        await CancelTestPairing();
        if (data) {
          navigate(`/v3/trial-visits/${data?.response.testPairing?.id}`);
        }
      },
    });
  };
};
