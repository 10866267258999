/** @jsxImportSource @emotion/react */
import React from "react";
import * as Icons from "@goodgym/icons";
import * as UI from "@goodgym/components";
import * as T from "@goodgym/graphql/types";
import * as u from "@goodgym/util";
import _ from "lodash";

export type CommentButtonProps = UI.IconButtonProps & {
  commentable: T.CommentableFragment;
};

const CommentButton: React.FC<React.PropsWithChildren<CommentButtonProps>> = ({
  commentable,
  ...props
}) => {
  return (
    <UI.IconButton
      {...props}
      sx={{
        ...props.sx,
        backgroundColor: "#eaeaea",
        fontSize: "16px",
        color:
          commentable.comments.length > 0 ? "primary.main" : "neutral.dark",
        height: "45px",
        width: "45px",
        border: "2px solid #fff",
        ml: "-2px",
      }}
    >
      <Icons.ChatBubbleOutline />
    </UI.IconButton>
  );
};

export default CommentButton;
