import React from "react";
import _ from "lodash";

type Props<T> = { items: T[] };

/*
 * Hook to handle progressively expanding long lists (such as comment
 * threads) from the edges inwards. We start with 10 items expanded,
 * and every time we expand more we add 5 to each end, prioritizing the end,
 * until we've loaded the full list.
 */
const useExpandableComments = <T extends {}>({ items }: Props<T>) => {
  const [indexes, setIndexes] = React.useState({
    top: _.min([5, items.length])!,
    bottom: items.length - _.min([5, items.length])!,
  });

  const expand = () => {
    setIndexes({ top: indexes.top, bottom: indexes.bottom - 5 });
  };

  const fullyExpanded = indexes.bottom <= indexes.top;

  return {
    expand,
    top: fullyExpanded ? items.length : indexes.top,
    bottom: fullyExpanded ? items.length : indexes.bottom,
  };
};

export default useExpandableComments;
