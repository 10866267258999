import React from "react";
import * as UI from "@goodgym/components";
import * as u from "@goodgym/util";
import StoriesFeed from "../../components/StoriesFeed";
import { useDeletedStoryPageQuery } from "@goodgym/graphql/types";
import { useAuth } from "@goodgym/auth";
import { hasAnyRole } from "@goodgym/util/roles";
import { NotFound } from "@goodgym/components";
import _ from "lodash";

const DeletedStoriesPage = () => {

  const theme = UI.useTheme();
  const md = UI.useMediaQuery(theme.breakpoints.up("md"));
  const { fetchMore, data, error } = useDeletedStoryPageQuery({
    variables: {},
  });

  const { runner } = useAuth();
  const adminRole = hasAnyRole(runner, ["CENTRAL_ADMIN"]);

  if (!adminRole) return <NotFound notAuthorized={true} />;
  if (error) return <UI.Error />;
  if (!data) return <UI.Loading />;

  const stories = data.deletedStories.stories;
  const { more } = data.deletedStories;

  const loadMore = () => {
    fetchMore({
      variables: {
        before: _.last(stories)?.discardedAt,
      },
    });
  };

  return (
    <>
      <UI.Seo title=" Deleted Stories" url="/v3/stories/deleted" />
      <UI.Box
        sx={{
          display: { xs: "none", md: "block" },
          backgroundColor: "primary.main",
          position: "fixed",
          top: "64px",
          left: 0,
          right: 0,
          py: 2,
          zIndex: 3,
        }}
      >
        <UI.Container>
          <UI.Breadcrumbs
            crumbs={[
              ["Stories", u.links.stories()],
              ["Deleted Stories", u.links.deletedStories()],
            ]}
            sx={{ mb: 1 }}
          />
          <UI.Typography variant="h1"> Deleted Stories</UI.Typography>
        </UI.Container>
      </UI.Box>

      {/* Mobile header */}
      <UI.Container
        sx={{
          display: { xs: "block", md: "none" },
          pt: 2,
          pb: 2,
          position: "fixed",
          top: { xs: "56px", sm: "64px" },
          backgroundColor: "primary.main",
          zIndex: 2,
          borderBottom: "1px solid #d7d7d7",
        }}
      >
        <UI.Box display="flex" flexDirection="column">
          <UI.Breadcrumbs
            crumbs={[
              ["Stories", u.links.stories()],
              ["Deleted Stories", u.links.deletedStories()],
            ]}
            sx={{ mb: 1 }}
          />
          <UI.Typography
            variant="h3"
            sx={{ whiteSpace: "nowrap", alignSelf: "center" }}
          >
            Deleted Stories
          </UI.Typography>
        </UI.Box>
      </UI.Container>

      <UI.Container sx={{ mt: md ? 18 : 9 }}>
        <StoriesFeed
          stories={stories}
          fetchMore={loadMore}
          more={more}
          error={error}
          deleted={true}
          drafted={false}
        />
      </UI.Container>
    </>
  );
};

export default DeletedStoriesPage;
