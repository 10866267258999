/** @jsxImportSource @emotion/react */
import React from "react";
import * as Icons from "@goodgym/icons";
import * as UI from "@goodgym/components";
import * as T from "@goodgym/graphql/types";
import * as u from "@goodgym/util";
import _ from "lodash";
import { useLocation } from "react-router-dom";

export type StoriesProps = {};

const Stories: React.FC<StoriesProps> = ({}) => {
  const { pathname } = useLocation();
    const isActiveStories = pathname.startsWith("/v3/stories");

  return (
    <UI.NavbarButton
      icon={Icons.Feed}
      label="Stories"
      active={isActiveStories}
      to={u.links.stories()}
    />
  );
};

export default Stories;
