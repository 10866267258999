import * as T from "@goodgym/graphql/types";
import _ from "lodash";

export const center = (
  item: T.SessionCardFragment | T.TaskRequestCardFragment
) => {
  switch (item.__typename) {
    case "Session":
      return { lat: item.address?.lat, lng: item.address?.lng };
    case "TaskRequest":
      return { lat: item.lat, lng: item.lng };
    default:
      return {};
  }
};

export const defaultCenter = (
  listings: (T.SessionCardFragment | T.TaskRequestCardFragment)[]
) => {
  const centers = _.filter(_.map(listings, center), (c) => c.lat && c.lng);
  if (centers.length === 0) return null;
  const lat = _.map(centers, "lat").reduce((a, b) => a + b, 0) / centers.length;
  const lng = _.map(centers, "lng").reduce((a, b) => a + b, 0) / centers.length;
  return { lat, lng };
};

export const defaultBounds = (
  listings: (T.SessionCardFragment | T.TaskRequestCardFragment)[]
) => {
  const centers = _.filter(_.map(listings, center), (c) => c.lat && c.lng);
  const east = Math.max(..._.map(centers, "lng"));
  const west = Math.min(..._.map(centers, "lng"));
  const north = Math.max(..._.map(centers, "lat"));
  const south = Math.min(..._.map(centers, "lat"));
  return { east, west, north, south };
};

export const compositeAddress = (address: T.AddressFragment) => {
  return [address.line1, address.line2, address.city, address.postcode]
    .filter((x) => x)
    .join(", ");
};

export const runnerDirectionsLink = (
  runner: Optional<T.CurrentRunnerFragment>,
  address: T.AddressFragment
) => {
  return [
    "https://www.google.com/maps/dir/?api=1",
    runner?.postcode ? `origin=${runner.postcode}` : null,
    `destination=${address.lat},${address.lng}`,
    `travelmode=walking`,
  ]
    .filter((x) => x)
    .join("&");
};

export const directionsLink = (
  address1: T.AddressFragment,
  address2: T.AddressFragment
) => {
  return [
    "https://www.google.com/maps/dir/?api=1",
    `origin=${address1.lat},${address1.lng}`,
    `destination=${address2.lat},${address2.lng}`,
    `travelmode=walking`,
  ]
    .filter((x) => x)
    .join("&");
};
