/** @jsxImportSource @emotion/react */
import { ApolloProvider } from "@apollo/client";
import { CacheProvider, Global } from "@emotion/react";
import { AuthenticationProvider } from "@goodgym/auth";
import * as graphql from "@goodgym/graphql";
import { ConfirmProvider } from "@goodgym/hooks/useConfirm";
import * as theme from "@goodgym/theme";
import { ThemeProvider } from "@mui/material";
import CssBaseline from "@mui/material/CssBaseline";
import * as Sentry from "@sentry/react";
import { Integrations } from "@sentry/tracing";
import axios from "axios";
import React from "react";
import ReactDOM from "react-dom";
import { HelmetProvider } from "react-helmet-async";
import { BrowserRouter } from "react-router-dom";
import App from "./App";
import { DrawersProvider } from "./drawers";
import reportWebVitals from "./reportWebVitals";

(async () => {
  if (process.env.NODE_ENV !== "development") {
    Sentry.init({
      dsn: process.env.REACT_APP_SENTRY_DSN,
      integrations: [new Integrations.BrowserTracing({ traceFetch: false }) as any],
      tracesSampleRate: 0.5,
      allowUrls: [/https?:\/\/.+\/(v3|$)/],
      ignoreErrors: [
        "Network request failed",
        "The network connection was lost",
        "NetworkError",
        "Failed to fetch",
        "cancelled",
      ],
    });
  }

  const { data: csrfToken } = await axios.get("/csrf");

  const cache = theme.cache();
  const client = graphql.client({ ssrMode: false, csrfToken });

  // allow us to skip SSR in dev for faster workflow
  const render =
    process.env.NODE_ENV === "development" && window?.location.port === "3002"
      ? ReactDOM.render
      : ReactDOM.hydrate;

  render(
    <React.StrictMode>
      <ApolloProvider client={client}>
        <CacheProvider value={cache}>
          <ThemeProvider theme={theme.theme}>
            <CssBaseline />
            <Global styles={theme.global} />
            <BrowserRouter>
              <HelmetProvider>
                <AuthenticationProvider>
                  <ConfirmProvider>
                    <DrawersProvider>
                      <App />
                    </DrawersProvider>
                  </ConfirmProvider>
                </AuthenticationProvider>
              </HelmetProvider>
            </BrowserRouter>
          </ThemeProvider>
        </CacheProvider>
      </ApolloProvider>
    </React.StrictMode>,
    document.getElementById("root")
  );
})();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
