/** @jsxImportSource @emotion/react */
import React from "react";
import * as Icons from "@goodgym/icons";
import * as UI from "@goodgym/components";
import * as T from "@goodgym/graphql/types";
import * as u from "@goodgym/util";
import _ from "lodash";

export type AdminMenuProps = UI.IconButtonProps & {
  session: T.SessionFragment & { area?: Optional<T.AreaFragment> };
};

const AdminMenu: React.FC<AdminMenuProps> = ({session, ...props }) => {
  const { data } = T.useSessionPageAdminMenuQuery({
    variables: { sessionId: session.id },
  });
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    setAnchorEl(e.currentTarget);
  };
  const onClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <UI.IconButton
        aria-label="admin menu icon"
        aria-controls={open ? "session-admin-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        onClick={handleClick}
        size="small"
        {...props}
      >
        <Icons.Settings />
      </UI.IconButton>
      <UI.Menu anchorEl={anchorEl} open={open} onClose={onClose}>
        <UI.MenuItem component={UI.Link} href={u.links.legacySession(session)}>
          <UI.ListItemIcon>
            <Icons.Visibility />
          </UI.ListItemIcon>{" "}
          View old page
        </UI.MenuItem>
        {data?.options.map((option) => (
          <UI.MenuOption key={option.id} option={option} onClose={onClose} />
        ))}
        <UI.MenuItem component={UI.Link} href={u.links.sessionDigest(session)}>
          <UI.ListItemIcon>
            <Icons.Feed />
          </UI.ListItemIcon>{" "}
          Digest
        </UI.MenuItem>
      </UI.Menu>
    </>
  );
};

export default AdminMenu;
