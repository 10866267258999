import * as UI from "@goodgym/components";
import React from "react";
import { State } from "../../types";
import DistanceFilter from "../Filters/DistanceFilter";
import PostcodeFilter from "../Filters/PostcodeFilter";

export type FiltersProps = UI.BoxProps & {
  state: Pick<
    State,
    | "runner"
    | "error"
    | "loading"
    | "more"
    | "items"
    | "fetchMore"
    | "drawers"
    | "filters"
  >;
};

const Filters: React.FC<FiltersProps> = ({ state, ...props }) => {
  const { drawers } = state;

  const theme = UI.useTheme();

  const md = UI.useMediaQuery(theme.breakpoints.up("md"));

  return (
    <UI.Card
      sx={{ display: { xs: "block" }, mt: 1, mb: 3, width: "100%" }}
    >
      <UI.CardContent>
        <UI.Typography variant="subtitle1" color="neutral.dark" sx={{}}>
          <UI.Box
            component="span"
            sx={{
              alignSelf: "center",
              display: "flex",
              flexDirection: "column",
              my: 1,
            }}
          >
            Filters
          </UI.Box>
        </UI.Typography>

        <UI.Box {...props}>
          <UI.Box sx={{ display: "flex", flexDirection:"column" }}>
            <>
              <PostcodeFilter state={state} sx={{ mb: 4 }} />
              <DistanceFilter state={state} sx={{ mb: 4 }} />
            </>
          </UI.Box>
          {md ? (
            <UI.Box></UI.Box>
          ) : (
            <UI.Grid container spacing={2}>
              <UI.Grid item xs={12}>
                <UI.Button
                  color="secondary"
                  fullWidth
                  onClick={drawers.filters.onClose}
                >
                  Apply
                </UI.Button>
              </UI.Grid>
            </UI.Grid>
          )}
        </UI.Box>
      </UI.CardContent>
    </UI.Card>
  );
};

export default Filters;
