/** @jsxImportSource @emotion/react */
import React from "react";
import * as Icons from "@goodgym/icons";
import * as UI from "@goodgym/components";
import * as T from "@goodgym/graphql/types";
import * as u from "@goodgym/util";
import _ from "lodash";

export type UpcomingSessionsProps = UI.BoxProps & {
  title: string;
  sessions: T.SessionCardFragment[];
  md?: boolean;
};

const UpcomingSessions: React.FC<
  React.PropsWithChildren<UpcomingSessionsProps>
> = ({ title, sessions, md, ...props }) => {
  const [numberToDisplay, setNumberToDisplay] = React.useState(2);

  if (sessions.length === 0) return null;
  return (
    <UI.Box {...props}>
      <UI.Typography variant="h5" sx={{ mb: 2, color: "neutral.main" }}>
        {title}
      </UI.Typography>
      {_.take(sessions, numberToDisplay).map((session) => (
        <UI.SessionCard
          key={session.id}
          session={session}
          sx={{ mb: 3 }}
          dashboard={md ? true : false}
        />
      ))}
      {numberToDisplay < sessions.length && (
        <UI.Button
          fullWidth
          variant="outlined"
          color="neutral"
          onClick={() =>
            setNumberToDisplay(Math.min(numberToDisplay + 5, sessions.length))
          }
        >
          <Icons.ExpandMore />
          Show more
        </UI.Button>
      )}
    </UI.Box>
  );
};

export default UpcomingSessions;
