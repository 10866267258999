/** @jsxImportSource @emotion/react */
import React from "react";
import * as Icons from "@goodgym/icons";
import * as UI from "@goodgym/components";
import * as T from "@goodgym/graphql/types";
import * as u from "@goodgym/util";
import _ from "lodash";

export type OrganisationCardProps = UI.BoxProps & {
  organisation: T.OrganisationFragment & {
    url?: Optional<string>;
    logoUrl?: Optional<string>;
    area?: Optional<T.AreaFragment>;
    description?: Optional<string>;
  };
};

const OrganisationCard: React.FC<
  React.PropsWithChildren<OrganisationCardProps>
> = ({ organisation, ...props }) => {
  const theme = UI.useTheme();

  return (
    <UI.Box {...props}>
      <UI.Box>
        {organisation.logoUrl && (
          <UI.Box
            component="img"
            src={organisation.logoUrl}
            sx={{
              float: "left",
              backgroundColor: "white.main",
              height: "80px",
              mb: 2,
              mr: 2,
              p: "10px",
              borderRadius: "10px",
              flexShrink: 0,
              boxShadow: theme.shadows[2],
            }}
          />
        )}
        <UI.Link
          variant="subtitle1"
          color="black.main"
          to={u.links.organisation(organisation)}
        >
          {organisation.name}
        </UI.Link>
        <UI.Typography
          variant="subtitle2"
          color="neutral.main"
          sx={{ mt: 1, mb: 2 }}
        >
          {organisation.strapline}
        </UI.Typography>
        <UI.Box sx={{ clear: "both" }} />
      </UI.Box>
      {organisation.description && (
        <UI.Typography sx={{ my: 1, whiteSpace: "pre-wrap" }}>
          {organisation.description}{" "}
        </UI.Typography>
      )}
      <UI.Link to={u.links.organisation(organisation)}>
        See more
        <Icons.ArrowForward sx={{ verticalAlign: "middle" }} />
      </UI.Link>
    </UI.Box>
  );
};

export default OrganisationCard;
