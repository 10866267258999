import * as UI from "@goodgym/components";


export type TestimonialProps = {
  testimonials?: {
    avatarUrl: string;
    quote: string;
    by: string;
    area?: string;
  }[];
};

const Testimonial: React.FC<React.PropsWithChildren<TestimonialProps>> = ({
  testimonials,
}) => {
  return (
    <UI.Container>
      <UI.Typography
        variant="h2"
        sx={{ my: 2, textAlign: "center", fontStyle: "normal" }}
      >
        Area Activators
      </UI.Typography>
      <UI.Typography
        variant="body1"
        sx={{ my: 3, textAlign: "center", fontStyle: "normal" }}
      >
        Area activators support the growth of GoodGym in their area. They are
        passionate about fitness and community and are here to enable us to do
        more in each area.
      </UI.Typography>

      <UI.Grid
        container
        spacing={7}
        sx={{ display: "flex", justifyContent: "center", alignItems: "flex-start" }}
      >
        {testimonials?.map((testimonial, index) => (
          <UI.Grid
            item
            key={index}
            xs={12}
            md={3}
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <UI.Avatar
              src={testimonial.avatarUrl}
              sx={{ height: "100px", width: "100px" }}
            />
            <UI.Box
              sx={{
                background: "black",
                width: "fit-content",
                my: 1,
                boxShadow: "-5px 5px 0 -1px #E11018",
              }}
            >
              <UI.Typography
                sx={{
                  mb: 0.5,
                  px: 1,
                  fontStyle: "normal",
                  fontFamily: "'Barlow Condensed', sans-serif",
                  textTransform: "uppercase",
                }}
                color="common.white"
              >
                {testimonial.area}
              </UI.Typography>
            </UI.Box>

            <UI.Typography variant="body1" sx={{ mt: 2, textAlign: "center" }}>
              {testimonial.quote}
            </UI.Typography>
          </UI.Grid>
        ))}
      </UI.Grid>
    </UI.Container>
  );
};

export default Testimonial;
