/** @jsxImportSource @emotion/react */
import { useLeaveWaitingListMutation } from "@goodgym/graphql/hooks";
import * as T from "@goodgym/graphql/types";
import * as UI from "@goodgym/components";
import * as u from "@goodgym/util";
import { useConfirm } from "@goodgym/hooks";

export type LeaveWaitingListMutationHook = () => (
  session: T.SessionFragment
) => React.MouseEventHandler;

export const useLeaveWaitingList: LeaveWaitingListMutationHook = () => {
  const { confirm } = useConfirm();
  const [leaveWaitingList] = useLeaveWaitingListMutation({
    onError: console.log,
  });

  return (session) => (e) => {
    e?.preventDefault();
    confirm({
      title: "Leave notification list",
      text: (
        <>
          <UI.Typography sx={{ mb: 2 }}>
            Leave notification list for <strong>{session.title}</strong>?
          </UI.Typography>
          <UI.Typography sx={{ mb: 2 }}>
            You will no longer receive an email if space on this session opens
            up.
          </UI.Typography>
        </>
      ),
      onConfirm: () => {
        leaveWaitingList({
          variables: { sessionId: session.id },
          optimisticResponse: {
            response: {
              __typename: "LeaveWaitingListMutationPayload",
              session: { ...session, onWaitingList: false },
            },
          },
        });
      },
    });
  };
};
