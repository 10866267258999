const sessions = [
  {
    type: "GroupRun",
    label: "Group run",
    description:
      "Run in groups to do physical tasks for community organisations.",
  },
  {
    type: "CommunityMission",
    label: "Community mission",
    description:
      "Run, walk, or cycle to help community organisations with practical tasks.",
  },
  {
    type: "Mission",
    label: "Mission",
    description:
      "Run, walk, or cycle to help older people with one-off tasks they can no longer do alone.",
  },
  {
    type: "DocumentCheck",
    label: "Document check",
    description:
      "Get your identity documents checked as part of our DBS verification process.",
  },
  {
    type: "Party",
    label: "Social",
    description: "Meet people in the GoodGym community.",
  },
  {
    type: "TrainingSession",
    label: "Training session",
    description: "Improve your fitness with other GoodGym members.",
  },
  {
    type: "Race",
    label: "Race",
    description: "Run races with other GoodGym members.",
  },
];

export default sessions;
