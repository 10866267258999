/** @jsxImportSource @emotion/react */
import * as UI from "@goodgym/components";
import * as Icons from "@goodgym/icons";
import Markdown from "markdown-to-jsx";
import React from "react";
import { useHover } from "usehooks-ts";

export type EditableTextAreaProps = UI.TypographyProps & {
  content: string;
  editable: boolean;
  placeholder?: string;
  handleSave: (content: string) => any;
};

const EditableTextArea: React.FC<
  React.PropsWithChildren<EditableTextAreaProps>
> = ({ content, handleSave, editable, placeholder, ...props }) => {
  const ref = React.useRef(null);
  const hover = useHover(ref);
  const [editing, setEditing] = React.useState(false);

  const [value, setValue] = React.useState(content);

  const onSubmit = () => {
    handleSave(value);
    setEditing(false);
  };

  const onCancel = () => {
    setValue(content);
    setEditing(false);
  };

  const onKeyDown: React.KeyboardEventHandler = (e) => {
    if (e.key === "Enter" && e.ctrlKey) {
      onSubmit();
    }
  };

  return !editable ? (
    <Markdown>{content}</Markdown>
  ) : editing ? (
    <>
      <UI.Typography sx={{ pb: 1 }}>
        Did you know that you can use Markdown? Click
        <UI.Link
          sx={{ px: 0.5 }}
          href="https://www.markdownguide.org/cheat-sheet/"
        >
          here
        </UI.Link>
        to find out more
      </UI.Typography>
      <UI.TextField
        multiline
        fullWidth
        autoFocus
        value={value}
        onChange={(e) => setValue(e.target.value)}
        onKeyDown={onKeyDown}
        placeholder={placeholder}
      />
      <UI.Box sx={{ textAlign: "right", my: 1 }}>
        <UI.Button
          type="submit"
          size="small"
          color="neutral"
          variant="text"
          sx={{ mr: 1 }}
          onClick={onCancel}
        >
          Cancel
        </UI.Button>
        <UI.Button
          size="small"
          color="primary"
          variant="text"
          onClick={onSubmit}
        >
          Save changes
        </UI.Button>
      </UI.Box>{" "}
    </>
  ) : (
    <UI.Box sx={{ position: "relative" }}>
      <UI.IconButton
        size="small"
        ref={ref}
        onClick={() => setEditing(true)}
        sx={{
          position: "absolute",
          right: 0,
          top: "-30px",
          backgroundColor: "white.main",
          "&:hover": {
            backgroundColor: "neutral.light",
          },
        }}
      >
        <Icons.Edit />
      </UI.IconButton>
      <Markdown
        {...props}
        onDoubleClick={() => setEditing(true)}
        sx={{
          whiteSpace: "pre-wrap",
          color: hover || !content ? "neutral.main" : "black.main",
          p: 1,
          ...props.sx,
        }}
      >
        {content}
      </Markdown>
    </UI.Box>
  );
};

export default EditableTextArea;
