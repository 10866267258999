import {
  format as _format,
  intervalToDuration,
  parseISO,
  isToday as _isToday,
  isTomorrow,
  isYesterday,
  isThisWeek,
  isSameDay as _isSameDay,
  isSameWeek as _isSameWeek,
  isAfter as _isAfter,
  isBefore as _isBefore,
  isEqual as _isEqual,
  isFuture as _isFuture,
  startOfDay as _startOfDay,
  startOfMonth as _startOfMonth,
  startOfWeek as _startOfWeek,
  endOfWeek as _endOfWeek,
  endOfMonth as _endOfMonth,
  addDays as _addDays,
  addMonths as _addMonths,
  addMinutes as _addMinutes,
  getHours as _getHours,
  getMinutes as _getMinutes,
  isWeekend as _isWeekend,
} from "date-fns";

type UIDate = Date | string;

export const parse = (s: UIDate): Date => {
  try {
    return typeof s === "string" ? parseISO(s) : s;
  } catch (e) {
    console.log(e, `Invalid date: ${s}`);
    throw e;
  }
};

export const formatDate = (s: string | Date) => {
  const date = parse(s);
  return _format(
    date,
    `EEEE do MMMM${
      date.getFullYear() !== new Date().getFullYear() ? " yyyy" : ""
    }`
  );
};

export const formatDateTime = (s: string | Date) => {
  const date = parse(s);
  return _format(
    date,
    `EEE do MMM ${
      date.getFullYear() !== new Date().getFullYear() ? " yyyy " : ""
    }'at' h:mmaaa`
  );
};

export const formatTime = (s: string | Date) => {
  const date = parse(s);
  return _format(date, "h:mmaaa");
};

export const formatTimeRange = (
  s: string | Date,
  duration: Optional<number>
) => {
  if (!duration) return formatTime(s);
  const date = parse(s);
  return `${formatTime(date)} - ${formatTime(_addMinutes(date, duration))}`;
};

export const formatDayOfWeek = (s: string | Date) => {
  const date = parse(s);
  return _format(date, "EEEE");
};

export const formatDayOfMonth = (s: string | Date) => {
  const date = parse(s);
  return _format(date, "do");
};

export const formatDuration = (seconds: number) => {
  const duration = intervalToDuration({
    start: 0,
    end: seconds * 1000,
  });
  return `${duration.hours ? `${duration.hours}h` : ""}${
    duration.minutes ? `${duration.minutes}m` : ""
  }`;
};

export const formatDay = (s: string | Date) => {
  const date = parse(s);
  return isToday(date)
    ? "Today"
    : isTomorrow(date)
    ? "Tomorrow"
    : isYesterday(date)
    ? "Yesterday"
    : isThisWeek(date)
    ? format(date, "EEEE")
    : formatDate(date);
};

export const format = (s: string | Date, format: string) => {
  return _format(parse(s), format);
};

export const formatMonth = (s: string | Date) => {
  return format(parse(s), "MMMM");
};

export const isSameDay = (a: UIDate, b: UIDate) =>
  _isSameDay(parse(a), parse(b));

export const isAfter = (a: UIDate, b: UIDate) => {
  return _isAfter(parse(a), parse(b));
};

export const isBefore = (a: UIDate, b: UIDate) => {
  return _isBefore(parse(a), parse(b));
};

export const isEqual = (a: UIDate, b: UIDate) => {
  return _isEqual(parse(a), parse(b));
};

export const isFuture = (a: UIDate) => {
  return _isFuture(parse(a));
};

export const isToday = (a: UIDate) => {
  return _isToday(parse(a));
};

export const isSameWeek = (a: UIDate, b: UIDate, opts: any) => {
  return _isSameWeek(parse(a), parse(b), opts);
};

export const startOfDay = (a: UIDate) => {
  return _startOfDay(parse(a));
};

export const startOfMonth = (a: UIDate) => {
  return _startOfMonth(parse(a));
};

export const startOfWeek = (a: UIDate) => {
  return _startOfWeek(parse(a));
};

export const endOfWeek = (a: UIDate) => {
  return _endOfWeek(parse(a));
};

export const endOfMonth = (a: UIDate) => {
  return _endOfMonth(parse(a));
};

export const addMonths = (a: UIDate, n: number) => {
  return _addMonths(parse(a), n);
};

export const addDays = (a: UIDate, n: number) => {
  return _addDays(parse(a), n);
};

export const addMinutes = (a: UIDate, n: number) => {
  return _addMinutes(parse(a), n);
};

export const getHours = (a: UIDate) => {
  return _getHours(parse(a));
};

export const getMinutes = (a: UIDate) => {
  return _getMinutes(parse(a));
};

export const isWeekend = (a: UIDate) => {
  return _isWeekend(parse(a));
};

export const sort = (xs: UIDate[]) => {
  return xs.sort((a, b) => parse(a).getTime() - parse(b).getTime());
};
