import * as I from "./Images";

export const bannerData = {
  title: "Contact",
  tagline: " Do you have a question about GoodGym? Get in touch today.",
  buttonText: "",
  image: I.BannerImage,
  linkForButton: "",
};

export const cardData = [
  {
    title: " Need help with a community project?",
    tagline:
      " If you're a community organisation and have a physical task then we'd like to help.",
    image: I.CardOneImage,
    link: "/request-a-task",
    buttonText: "Request a task",
  },
  {
    title: "Work with an older person who needs help?",
    tagline:
      "  Organisations supporting older people can request help with practical tasks for people aged over 50.",
    image: I.CardTwoImage,
    link: "/request-a-mission",
    buttonText: "Request a task",
  },
  {
    title: "Have a question about coach runs?",
    tagline: " Get in touch with the GoodGym coach team.",
    image: I.CardThreeImage,
    link: "mailto:coachteam@goodgym.org",
    buttonText: "Email Coach Team",
  },
];

export const smallCardData = [
  {
    title: "T-shirt enquiries",
    tagline: "Have a question about your GoodGym t-shirt?",
    email: "tshirts@goodgym.org",
  },
  {
    title: "Employers",
    tagline: "Want to setup GoodGym runs at your company?",
    email: "employers@goodgym.org",
  },
  {
    title: "Media enquiries",
    tagline: "Do you have a media/press question?",
    email: "media-enquiries@goodgym.org",
  },
  {
    title: "Goodgym near me",
    tagline: "Have a question about setting up GoodGym near you?",
    email: "proposals@goodgym.org",
  },
];
