import { css } from "@emotion/react";

export default css`
  body {
    margin: 0;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  input:focus,
  textarea:focus,
  select:focus {
    outline: none;
  }

  .gg-markdown {
    a {
      color: #e11018;
    }
  }
`;
