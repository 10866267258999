/** @jsxImportSource @emotion/react */
import React from "react";
import { useCompleteTestPairingMutation } from "@goodgym/graphql/hooks";
import * as T from "@goodgym/graphql/types";
import * as UI from "@goodgym/components";
import * as u from "@goodgym/util";
import { useConfirm } from "@goodgym/hooks";
import { useNavigate } from "react-router-dom";

export type CompleteProps = (params: {
  coach: Pick<T.CoachReferralFragment, "id" | "name">;
  testPairingId: string;
  runnerFeedback: string;
  runnerVisitNotes: string;
  runnerWantsPairing: boolean;
}) => React.MouseEventHandler;

export const useCompleteTestPairing: CompleteProps = ({
  testPairingId,
  coach,
  runnerFeedback,
  runnerVisitNotes,
  runnerWantsPairing
}) => {
  const { confirm } = useConfirm();

  const navigate = useNavigate();

  const [CompleteTestPairing, { data }] =
    useCompleteTestPairingMutation({
      onError: console.log,
      variables: {
        id: testPairingId,
        runnerVisitFeedback: runnerFeedback,
        runnerVisitNotes: runnerVisitNotes,
        runnerWantsPairing: runnerWantsPairing

      },
      optimisticResponse: {
        response: {
          __typename: "CompleteTestPairingMutationPayload",
          testPairing: {
            __typename: "TestPairing",
            acceptedAt: null,
            completedAt: null,
            id: coach.id,
            notesForRunner: null,
            rejectedAt: null,
            rejectionNotes: null,
            scheduledAt: null,
            runnerVisitFeedback: runnerFeedback,
            runnerVisitNotes: runnerVisitNotes,
            runnerWantsPairing: runnerWantsPairing,
            cancellationNotes:null,
            cancelledAt: new Date(),
            coach: {
              __typename: "Coach",
              id: coach.id,
              name: coach.name,
            },
          },
        },
      },
    });

  return (e) => {
    e?.preventDefault();
    confirm({
      title: "Submitting your feedback",
      text: "Are you sure you want to proceed?",
      alert: false,
      onConfirm: async () => {
        await CompleteTestPairing();
        if (data) {
          navigate(`/v3/trial-visits/${data?.response.testPairing?.id}`);
        }
      },
    });
  };
};
