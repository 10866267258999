/** @jsxImportSource @emotion/react */
import React from "react";
import * as Icons from "@goodgym/icons";
import * as UI from "@goodgym/components";
import * as T from "@goodgym/graphql/types";
import * as u from "@goodgym/util";
import _ from "lodash";

export type PasswordFieldProps = UI.TextFieldProps & {};

const PasswordField: React.FC<PasswordFieldProps> = ({ ...props }) => {
  const [showPassword, setShowPassword] = React.useState(false);
  return (
    <UI.TextField
      {...props}
      type={showPassword ? "text" : "password"}
      InputProps={{
        endAdornment: (
          <UI.InputAdornment position="end">
            <UI.IconButton
              edge="end"
              onClick={() => setShowPassword(!showPassword)}
            >
              {showPassword ? <Icons.VisibilityOff /> : <Icons.Visibility />}
            </UI.IconButton>
          </UI.InputAdornment>
        ),
      }}
    />
  );
};

export default PasswordField;
