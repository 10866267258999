import * as UI from "@goodgym/components";

export type FAQProp = {
  info: { heading?: string; content?: string }[];
};

const FAQ: React.FC<FAQProp> = ({ info }) => {
  return (
    <UI.Container
      sx={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <UI.Typography variant="h3" sx={{ my: 2 }}>
        Frequently Asked Questions
      </UI.Typography>
      <UI.Typography>
        If you have any questions please get in touch and we’ll do our best to
        help.
      </UI.Typography>
      <UI.Typography sx={{ my: 1 }}>
        You can email{" "}
        <UI.Link href={`mailto: coachteam@goodgym.org`}>
          {" "}
          coachteam@goodgym.org{" "}
        </UI.Link>
        or call <UI.Link href="tel:02036420997">020 3642 0997</UI.Link>
      </UI.Typography>
      <UI.Grid container spacing={3} sx={{ mt: 1 }}>
        {info.map((element) => (
          <UI.Grid item xs={12} md={4}>
            <UI.Typography variant="subtitle1" sx={{ mb: 2 }}>
              {element.heading}
            </UI.Typography>
            <UI.Typography>{element.content}</UI.Typography>
          </UI.Grid>
        ))}
      </UI.Grid>
    </UI.Container>
  );
};

export default FAQ;
