import * as Icons from "@goodgym/icons";

const more = [
  {
    key: "community",
    label: "Community",
    links: [
      {
        key: "reports",
        icon: <Icons.Feed />,
        label: "Reports",
        href: "/v3/reports",
      },
      {
        key: "heroes",
        icon: <Icons.EmojiEvents />,
        label: "Heroes",
        href: "/heroes",
      },
      {
        key: "race_team",
        icon: <Icons.DirectionsRun />,
        label: "Race Team",
        href: "/race_team",
      },
      {
        key: "facebook",
        icon: <Icons.Facebook />,
        label: "Facebook Group",
        href: "https://www.facebook.com/groups/406276916245285",
        target: "_blank",
      },
    ],
  },
  {
    key: "request-support",
    label: "Request support",
    links: [
      {
        key: "mission",
        icon: <Icons.Elderly />,
        label: "For an older person",
        href: "/v3/request-a-mission",
      },
      {
        key: "community-mission",
        icon: <Icons.House />,
        label: "For a community project",
        href: "/v3/request-a-task",
      },
    ],
  },
  {
    key: "goodgym",
    label: "GoodGym",
    links: [
      {
        key: "stories",
        icon: <Icons.Feed />,
        label: "Stories",
        href: "/v3/stories",
      },
      {
        key: "about",
        icon: <Icons.Info />,
        label: "About",
        href: "/v3/about",
      },
      {
        key: "charity",
        icon: <Icons.Favorite />,
        label: "Charity",
        href: "/charity",
      },
      {
        key: "diversity",
        icon: <Icons.People />,
        label: "Diversity",
        href: "/diversity",
      },
      {
        key: "contact",
        icon: <Icons.People />,
        label: "Contact",
        href: "/v3/contact",
      },
      {
        key: "jobs",
        icon: <Icons.Work />,
        label: "Jobs",
        href: "/jobs",
      },
    ],
  },
];

export default more;
