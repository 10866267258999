/** @jsxImportSource @emotion/react */
import React from "react";
import * as Icons from "@goodgym/icons";
import * as UI from "@goodgym/components";
import * as T from "@goodgym/graphql/types";
import * as u from "@goodgym/util";
import _ from "lodash";
import { useAuth } from "@goodgym/auth";

export type NavbarProps = {};

const Navbar: React.FC<NavbarProps> = ({}) => {
  const theme = UI.useTheme();
  const desktop = UI.useMediaQuery(theme.breakpoints.up("sm"));
  const { isLoggedIn } = useAuth();
  if (isLoggedIn && desktop) {
    return <UI.NavbarDesktop />;
  } else if (isLoggedIn) {
    return <UI.NavbarMobile />;
  } else {
    return <UI.NavbarLoggedOut />;
  }
};

export default Navbar;
