/** @jsxImportSource @emotion/react */
import React from "react";
import * as Icons from "@goodgym/icons";
import * as UI from "@goodgym/components";
import * as u from "@goodgym/util";

export type ErrorProps = {
  error?: Error;
};

const Error: React.FC<ErrorProps> = ({ error }) => {
  if (error?.message.includes("not found")) return <UI.NotFound />;
  return (
    <UI.Backdrop
      open={true}
      sx={{ textAlign: "center", backgroundColor: "neutral.main" }}
    >
      <UI.Box>
        <Icons.ErrorOutline sx={{ height: "100px", width: "100px", mb: 2 }} />
        <UI.Typography variant="h1">Unexpected error</UI.Typography>
        <UI.Box>
          <UI.Typography variant="h5" sx={{ my: 2 }}>
            An unexpected error occurred.
          </UI.Typography>
          <UI.Typography variant="h5" sx={{ my: 2 }}>
            Please refresh the page or return to the homepage to try again.
          </UI.Typography>
        </UI.Box>
        <UI.Box>
          <UI.Typography variant="h5" sx={{ my: 2 }}>
            <UI.Link
              to={u.links.home()}
              color="black.main"
              sx={{ textDecoration: "underline" }}
            >
              Back to homepage
            </UI.Link>
          </UI.Typography>
        </UI.Box>
      </UI.Box>
    </UI.Backdrop>
  );
};

export default Error;
