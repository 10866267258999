/** @jsxImportSource @emotion/react */
import { useUncheerMutation } from "@goodgym/graphql/hooks";
import * as T from "@goodgym/graphql/types";
import * as UI from "@goodgym/components";
import * as u from "@goodgym/util";

export type UncheerMutationHook = (params: {
  runner: Optional<T.CurrentRunnerFragment>;
  cheerable: T.CheerableItem;
  cheers: T.CheerFragment[];
}) => React.MouseEventHandler;

export const useUncheer: UncheerMutationHook = ({
  runner,
  cheerable,
  cheers,
}) => {
  const [uncheer] = useUncheerMutation({
    onError: console.log,
    variables: {
      cheerableType: u.cheers.cheerableType(cheerable),
      cheerableActivityId: u.cheers.cheerableActivityId(cheerable),
      runnerIds: u.cheers.cheerableRunners(cheerable).map((r) => r.id),
    },
    optimisticResponse: {
      response: {
        __typename: "UncheerMutationPayload",
        cheerable: {
          __typename: cheerable.__typename,
          id: cheerable.id,
          cheers: cheers.filter((cheer) => runner?.id !== cheer.runner.id),
        },
      },
    },
  });
  return (e) => {
    e?.preventDefault();
    uncheer();
  };
};
