/** @jsxImportSource @emotion/react */
import * as UI from "@goodgym/components";
import * as Icons from "@goodgym/icons";
import React from "react";

export type ImageCarouselProps = UI.BoxProps & {
  reportImages: string[];
};

const ImageCarousel: React.FC<React.PropsWithChildren<ImageCarouselProps>> = ({
  reportImages,
  ...props
}) => {
  const ref = React.useRef();
  const [index, setIndex] = React.useState(0);

  // used to stop the image from sliding in on initial page load
  // after mount, we set it to and leave it as true
  const [appear, setAppear] = React.useState(false);
  React.useEffect(() => {
    setAppear(true);
  }, []);

  // used to change the direction of the slide depending on which button
  // was pressed
  const [direction, setDirection] = React.useState<"left" | "right">("right");


  const backReportImage = () => {
    setDirection("left");
    setIndex(Math.max(index - 1, 0));
  };

 

  const nextReportImage = () => {
    setDirection("right");
    setIndex(Math.min(index + 1, reportImages.length - 1));
  };

  if (reportImages?.length === 0) return null;

  return (
    <UI.Box
      ref={ref}
      {...props}
      sx={{ position: "relative", ...props.sx, overflow: "hidden" }}
    >
    
      {reportImages?.map(
        (image, i) =>
          i === index && (
            <UI.Slide
              key={i}
              direction={direction}
              in={i === index}
              appear={appear}
              container={ref.current}
            >
              <UI.LandscapeImage src={image} />
            </UI.Slide>
          )
      )}

      {reportImages.length > 1 && (
        <UI.Box
          sx={{
            mt: 1,
            display: "flex",
            alignItems: "center",
          }}
        >
          <UI.IconButton onClick={backReportImage} disabled={index === 0}>
            <Icons.ArrowBack />
          </UI.IconButton>
          <UI.Box sx={{ flexGrow: 1, textAlign: "center" }}>
            <UI.Typography variant="subtitle1">
              {index + 1} / {reportImages?.length}
            </UI.Typography>
          </UI.Box>
          <UI.IconButton
            onClick={nextReportImage}
            disabled={index === reportImages.length - 1}
          >
            <Icons.ArrowForward />
          </UI.IconButton>
        </UI.Box>
      )}
    </UI.Box>
  );
};

export default ImageCarousel;
