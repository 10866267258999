/** @jsxImportSource @emotion/react */
import React from "react";
import * as Icons from "@goodgym/icons";
import * as UI from "@goodgym/components";
import * as T from "@goodgym/graphql/types";
import * as u from "@goodgym/util";
import _ from "lodash";
import { css } from "@emotion/react";

export type BadgeProps = UI.AvatarProps & {
  badge: T.BadgeFragment;
};

const Badge: React.FC<BadgeProps> = ({ badge, ...props }) => {
  const theme = UI.useTheme();
  return (
    <UI.Tooltip title={`${badge.name}: ${badge.description}`}>
      <UI.Avatar
        {...props}
        sx={{
          backgroundColor: theme.palette.primary.main,
          height: u.avatarSize("huge"),
          width: u.avatarSize("huge"),
          ...props.sx,
        }}
      >
        <img
          css={css`
            border-radius: 50%;
            margin: 4px;
            background-color: ${theme.palette.primary.main};
            border: 2px dashed white;
            width: calc(100% - 10px);
            height: calc(100% - 10px);
          `}
          src={badge.imageUrl}
          alt={badge.name}
        />
      </UI.Avatar>
    </UI.Tooltip>
  );
};

// @ts-ignore
Badge.muiName = UI.Avatar.muiName;

export default Badge;
