/** @jsxImportSource @emotion/react */
import { useUpdateAreaMutation } from "@goodgym/graphql/hooks";
import * as T from "@goodgym/graphql/types";
import * as UI from "@goodgym/components";
import * as u from "@goodgym/util";

export const useUpdateArea = ({ area }: { area: T.AreaFragment }) => {
  const [updateAreaMutation] = useUpdateAreaMutation({
    onError: console.log,
  });

  return ({ description }: { description: string }) => {
    updateAreaMutation({
      variables: { areaId: area.id, description },
      optimisticResponse: {
        response: {
          area: {
            ...area,
            description,
          },
          __typename: "UpdateAreaMutationPayload",
        },
      },
    });
  };
};
