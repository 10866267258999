/** @jsxImportSource @emotion/react */
import React from "react";
import * as Icons from "@goodgym/icons";
import * as UI from "@goodgym/components";
import * as T from "@goodgym/graphql/types";
import * as u from "@goodgym/util";
import _ from "lodash";
import * as C from "./components";
import * as cal from "calendar-link";
import { css } from "@mui/styled-engine";

export type AddToCalendarButtonProps = UI.LinkProps & {
  event: cal.CalendarEvent;
};

const options = [
  { Icon: C.AppleIcon, label: "Apple", link: cal.ics },
  { Icon: C.GoogleIcon, label: "Google", link: cal.google },
  { Icon: C.ICalIcon, label: "iCal", link: cal.ics },
  { Icon: C.Microsoft365Icon, label: "Microsoft 365", link: cal.office365 },
  { Icon: C.OutlookIcon, label: "Outlook", link: cal.outlook },
  { Icon: C.YahooIcon, label: "Yahoo", link: cal.yahoo },
];

const AddToCalendarButton: React.FC<AddToCalendarButtonProps> = ({
  event,
  ...props
}) => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (e: React.MouseEvent<HTMLAnchorElement>) => {
    e.preventDefault();
    setAnchorEl(e.currentTarget);
  };
  const onClose = () => {
    setAnchorEl(null);
  };
  return (
    <>
      <UI.Link
        aria-controls={open ? "add-to-calendar" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        onClick={handleClick}
        variant="subtitle1"
        {...props}
        sx={{ color: "secondary.dark", ...props.sx }}
      >
        Add to calendar
      </UI.Link>
      <UI.Menu anchorEl={anchorEl} open={open} onClose={onClose}>
        {options.map(({ Icon, label, link }) => (
          <UI.MenuItem
            key={label}
            component={UI.Link}
            href={link(event)}
            onClick={onClose}
          >
            <UI.ListItemIcon
              css={css`
                & svg {
                  height: 20px;
                  color: rgb(51, 51, 51);
                  width: auto;
                }
              `}
            >
              <Icon />
            </UI.ListItemIcon>
            {label}
          </UI.MenuItem>
        ))}
      </UI.Menu>
    </>
  );
};

export default AddToCalendarButton;
