/** @jsxImportSource @emotion/react */
import React from "react";
import * as Icons from "@goodgym/icons";
import * as UI from "@goodgym/components";
import * as T from "@goodgym/graphql/types";
import * as u from "@goodgym/util";
import * as C from "..";
import _ from "lodash";

export type CheersAndCommentsProps = UI.BoxProps & {
  commentable: T.CommentableItem;
  cheerable: T.CheerableItem;
  defaultOpen?: boolean;
};

const CheersAndComments: React.FC<
  React.PropsWithChildren<CheersAndCommentsProps>
> = ({ commentable, cheerable, defaultOpen = false, ...props }) => {
  const [showComments, setShowComments] = React.useState(
    defaultOpen && commentable.comments.length > 0
  );

  return (
    <>
      <UI.Box sx={{ display: "flex" }}>
        <UI.Box sx={{ flexGrow: 1, mx: 2, my: 2 }}>
          <C.CommentButton
            commentable={commentable}
            onClick={() => setShowComments(!showComments)}
          />
        </UI.Box>
        <UI.Cheers cheerable={cheerable} sx={{ flexShrink: 0, mx: 2, my: 2 }} />
      </UI.Box>

      <UI.Box {...props}>
        {(commentable.comments.length > 0 || showComments) && <UI.Divider />}
        {commentable.comments.length > 0 && (
          <>
            <UI.Link
              variant="overline"
              sx={{ mx: 2 }}
              onClick={(e) => {
                e.preventDefault();
                setShowComments(!showComments);
              }}
            >
              {showComments ? "Hide comments" : "Show comments"} (
              {commentable.comments.length})
            </UI.Link>
          </>
        )}
        {showComments && (
          <UI.Comments sx={{ m: 2 }} commentable={commentable} />
        )}
      </UI.Box>
    </>
  );
};

export default CheersAndComments;
