import { useAuth } from "@goodgym/auth";
import * as UI from "@goodgym/components";
import { useProfilePageQuery } from "@goodgym/graphql/hooks";
import * as u from "@goodgym/util";
import { useParams } from "react-router-dom";
import * as C from "./components";
import ProfileHeader from "./components/ProfileHeader";
import HomePage from "../HomePage";
import { ReactComponent as StravaLogo } from "../images/pwrdBy_strava_logo_light.svg";

const ProfilePage = () => {
  const { slug } = useParams();

  const { runner: currentRunner } = useAuth();

  const checkAdminRoles =
    currentRunner !== null &&
    u.roles.hasAnyRole(currentRunner, [
      "TRAINER",
      "RUN_LEADER",
      "CENTRAL_ADMIN",
    ]);

  const { error, data } = useProfilePageQuery({
    variables: {
      slug: slug!,
      currentRunnerPresent: checkAdminRoles,
    },
  });


  if (error) return <HomePage  />;
  if (!data) return <UI.Loading />;

  const { runner } = data;

  return (
    <UI.Container sx={{ pt: 5, pb: { xs: 9, sm: 3 } }}>
      <UI.Seo
        title={`GoodGym ${runner.homeArea?.name} - ${runner.name}`}
        url={u.links.profile(runner)}
        description={runner.bio}
      />
      <UI.Grid container columnSpacing={5}>
        <UI.Grid item xs={12} md={5} lg={4}>
          <ProfileHeader runner={runner} currentRunner={currentRunner} />
          {runner.pronouns && (
            <UI.Typography variant="h5" sx={{ mb: 2, color: "neutral.main" }}>
              ({runner.pronouns})
            </UI.Typography>
          )}
          <UI.Box sx={{ textAlign: "center" }}>
            <UI.AreaChip area={runner.homeArea} sx={{ mb: 2 }} />
            <UI.Typography
              variant="body1"
              sx={{ mb: 1, whiteSpace: "pre-wrap" }}
            >
              {runner.bio}
            </UI.Typography>
          </UI.Box>
          {runner.followsYou && (
            <UI.Typography variant="body1" color="neutral.dark" sx={{ mb: 1 }}>
              {runner.followedByYou ? "You follow each other" : "Follows you"}
            </UI.Typography>
          )}
          {runner.id !== currentRunner?.id && (
            <UI.FollowButton runner={runner} />
          )}
          <UI.Divider sx={{ my: 3 }} />
          <C.ProfileStats runner={runner} />
          <UI.Divider sx={{ my: 3 }} />
          Expand Down
          <C.ProfileInfo runner={runner} />
          <UI.Divider sx={{ my: 3 }} />
          <C.Streaks runner={runner} />
          {runner.employment && (
            <>
              <UI.Divider sx={{ my: 3 }} />
              <UI.EmploymentCard employment={runner.employment} />
            </>
          )}
          {u.roles.hasRole(runner, "TASK_FORCE") && (
            <C.TaskForceAchievements runner={runner} />
          )}
          <UI.Divider sx={{ my: 3 }} />
          <C.Badges runner={runner} />
          <UI.Divider sx={{ my: 3 }} />
          <StravaLogo style={{ height: "60px", width: "auto"}} />
          {runner.id !== currentRunner?.id && (
            <>
              <UI.Divider sx={{ my: 3 }} />
              <UI.BlockButton runner={runner} />
            </>
          )}
          <UI.Divider sx={{ my: 3 }} />
        </UI.Grid>
        <UI.Grid item xs={12} md={7} lg={8}>
          {runner.upcomingSessions.length > 0 && (
            <UI.Box sx={{ mb: 5 }}>
              <UI.Typography variant="h5" sx={{ mb: 2, color: "neutral.main" }}>
                {runner.id === currentRunner?.id ? "Your" : `${runner.name}'s`}{" "}
                next session
              </UI.Typography>
              {runner.upcomingSessions.map((session) => (
                <UI.SessionCard
                  key={session.id}
                  session={session}
                  sx={{ mb: 2 }}
                />
              ))}
            </UI.Box>
          )}
          <UI.Typography variant="h5" sx={{ mb: 2, color: "neutral.main" }}>
            Latest activity
          </UI.Typography>
          <UI.Newsfeed type="Runner" runner={runner} />
        </UI.Grid>
      </UI.Grid>
    </UI.Container>
  );
};
export default ProfilePage;
