import * as UI from "@goodgym/components";
import CancelIcon from "@mui/icons-material/Cancel";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import Emoji from "a11y-react-emoji";
import React from "react";
import { ImQuotesLeft, ImQuotesRight } from "react-icons/im";
import Banner from "../../components/Banner";
import IconCards from "../../components/IconCards";
import * as I from "../RequestMissionPage/data/images";
import {
  BannerData,
  IconData,
  cancelListItemsData,
  checkListItemsData,
  workIconData,
} from "./data";

const RequestMissionPage: React.FC = () => {
  return (
    <>
      <Banner
        title={BannerData.title}
        image={BannerData.image}
        tagline={BannerData.tagline}
        buttonText={BannerData.buttonText}
        linkForButton={BannerData.linkForButton}
      />
      <UI.Container>
        <UI.Typography
          variant="h5"
          sx={{
            lineHeight: 1.8,
            fontWeight: "normal",
            mt: 1,
            mb: 3,
          }}
        >
          Background-checked GoodGym volunteers help out older people with tasks
          they cannot complete themselves, or have no one else to help with.
          They travel to the older person's house on foot or by bike and help
          with household and garden tasks.
        </UI.Typography>
      </UI.Container>
      <UI.Container>
        <UI.Grid
          container
          spacing={3}
          sx={{ display: "flex", justifyContent: "center" }}
        >
          {IconData.map((element) => (
            <IconCards image={element.image} tagline={element.tagline} />
          ))}
        </UI.Grid>
      </UI.Container>
      <UI.Container
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <UI.Typography
          variant="h5"
          sx={{
            lineHeight: 1.8,
            fontWeight: "normal",
            backgroundColor: "#fff3cd",
            textAlign: "center",
            mt: 3,
            mb: 4,
            py: 1,
            width: { lg: "55%", md: "65%", sm: "80%", xs: "100%" },
          }}
        >
          <Emoji symbol="⚠️" label="warning" /> Making a request for a community
          project?{" "}
          <a
            href="/request-a-task"
            style={{ textDecoration: "none", color: "red" }}
          >
            Find out more
          </a>
        </UI.Typography>
      </UI.Container>
      <UI.Typography
        color="black"
        variant="h1"
        sx={{
          textAlign: "center",
          typography: { lg: "h1", sm: "h2", xs: "h3" },
          textTransform: "uppercase",
          my: 2,
        }}
      >
        How it works
      </UI.Typography>
 
        <UI.Box
          component="img"
          src={I.midBannerImage}
          sx={{
            width: "100%",
            height: "50vh",
            objectFit: "cover",
            objectPosition: "15% 15%" 
          }}
        />
    

      <UI.Container>
        <UI.Typography
          variant="h5"
          sx={{
            lineHeight: 1.8,
            fontWeight: "normal",
            mt: 1,
            mb: 3,
          }}
        >
          GoodGym is a registered charity with lots of friendly members who like
          to keep fit and help people. We stop off on our runs, walks or rides
          to help people with practical tasks.
        </UI.Typography>
      </UI.Container>
      <UI.Container>
        <UI.Grid container spacing={1} sx={{ mb: 4, justifyContent: "center" }}>
          {workIconData.map((element) => (
            <IconCards
              image={element.image}
              tagline={element.tagline}
              title={element.title}
            />
          ))}
        </UI.Grid>
      </UI.Container>
      <UI.Container
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          mb: 7,
          mt: 3,
        }}
      >
        <UI.Card
          sx={{
            width: { lg: "56%", md: "70%", sm: "75%", xs: "100%" },
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
            borderRadius: "15px",
          }}
        >
          <UI.Typography
            variant="h1"
            sx={{ textAlign: "center", textTransform: "uppercase" }}
          >
            Request a task
          </UI.Typography>
          <UI.Box
            sx={{ backgroundColor: "red", height: "8px", width: "110%" }}
          ></UI.Box>
          <UI.Typography
            variant="h5"
            sx={{
              lineHeight: 1.8,
              fontWeight: "normal",
              mt: 1,
              mb: 3,
              textAlign: "center",
              padding: 2,
              paddingBottom: 0,
            }}
          >
            If you are part of an organisation that supports older people you
            request support for practical tasks.
          </UI.Typography>
          <UI.Button
            variant="contained"
            color="primary"
            sx={{
              padding: 1,
              mb: 1,
            }}
            href="/referrers"
          >
            Request a task
          </UI.Button>
          <UI.Box
            sx={{
              backgroundColor: "#e9e9e9",
            }}
          >
            <UI.Typography
              variant="subtitle1"
              sx={{
                textAlign: { md: "center", sm: "left" },
                fontWeight: "normal",
                padding: 2,
              }}
            >
              PLEASE NOTE:{" "}
              <b>We cannot accept requests from members of the public.</b> We
              only accept requests if you are part of an organisation that works
              with older people, such as a charity (e.g. AgeUK or British Red
              Cross), a local council team, an NHS team, or other health,
              housing and social care teams.
              <p>
                Please contact
                <a
                  href="mailto:missioncontrol@goodgym.org"
                  style={{ textDecoration: "none", color: "red" }}
                >
                  {" "}
                  missioncontrol@goodgym.org{" "}
                </a>
                for further details.
              </p>
            </UI.Typography>
          </UI.Box>
        </UI.Card>
      </UI.Container>
      <UI.Container>
        <UI.Grid
          container
          sx={{
            gap: "20px",
            mb: 5,
            justifyContent: "center",
          }}
        >
          <UI.Grid item xs={12} md={5}>
            <UI.Typography variant="h6" sx={{ textAlign: "center" }}>
              Our Volunteers can do{" "}
            </UI.Typography>
            {checkListItemsData.map((element, index) => (
              <UI.List key={index}>
                <UI.ListItem>
                  <UI.ListItemIcon>
                    {element.checkOrCancelIcon ? (
                      <CheckCircleIcon sx={{ color: "green" }} />
                    ) : (
                      <CancelIcon sx={{ color: "red" }} />
                    )}
                  </UI.ListItemIcon>
                  <UI.Typography variant="body1">
                    {element.listItem}
                  </UI.Typography>
                </UI.ListItem>
              </UI.List>
            ))}
          </UI.Grid>
          <UI.Grid item xs={12} md={5}>
            <UI.Typography variant="h6" sx={{ textAlign: "center" }}>
              Our Volunteers cannot do{" "}
            </UI.Typography>
            {cancelListItemsData.map((element, index) => (
              <UI.List key={index}>
                <UI.ListItem>
                  <UI.ListItemIcon>
                    {element.checkOrCancelIcon ? (
                      <CheckCircleIcon sx={{ color: "green" }} />
                    ) : (
                      <CancelIcon sx={{ color: "red" }} />
                    )}
                  </UI.ListItemIcon>
                  <UI.Typography variant="body1">
                    {element.listItem}
                  </UI.Typography>
                </UI.ListItem>
              </UI.List>
            ))}
          </UI.Grid>
        </UI.Grid>
      </UI.Container>
      <UI.Container sx={{ mt: 4, mb: 4 }}>
        <UI.Grid
          container
          spacing={1}
          sx={{
            gap: "30px",
            display: "flex",
            justifyContent: "center",
            padding: 2,
          }}
        >
          <UI.Grid xs={11} md={3}>
            <UI.Typography variant="h5" sx={{ fontWeight: "normal" }}>
              <p>
                <ImQuotesLeft style={{ fontSize: "15px" }} /> They were lovely,
                really helpful. It's a service I'd like to use again given the
                opportunity!
                <ImQuotesRight style={{ fontSize: "15px" }} />
              </p>
              -- Mrs S, Southwark
            </UI.Typography>
          </UI.Grid>
          <UI.Grid xs={11} md={3}>
            <UI.Typography variant="h5" sx={{ fontWeight: "normal" }}>
              <p>
                <ImQuotesLeft style={{ fontSize: "15px" }} /> The garden looks
                lovely. The ladies were very happy and smiley, it made me smile
                too.
                <ImQuotesRight style={{ fontSize: "15px" }} />
              </p>
              -- Mrs A, Bristol
            </UI.Typography>
          </UI.Grid>
          <UI.Grid xs={11} md={3}>
            <UI.Typography variant="h5" sx={{ fontWeight: "normal" }}>
              <p>
                <ImQuotesLeft style={{ fontSize: "15px" }} /> I cannot begin to
                tell you the difference your service has made to patients’
                lives. Please pass on our thanks to all the volunteers who have
                willingly and kindly given their time, to assist healthcare
                staff, patients and their families.{" "}
                <ImQuotesRight style={{ fontSize: "15px" }} />
              </p>
              --Mrs A, Islington
            </UI.Typography>
          </UI.Grid>
        </UI.Grid>
      </UI.Container>
      <UI.Container
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <UI.Typography
          variant="h3"
          sx={{
            textTransform: "uppercase",
            padding: 2,
            textAlign: "center",
          }}
        >
          Frequently asked questions
        </UI.Typography>
        <UI.Typography
          variant="h5"
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            padding: 2,

            fontWeight: "normal",
          }}
        >
          If you have any questions please get in touch and we’ll do our best to
          help.
        </UI.Typography>
        <UI.Typography
          variant="h5"
          sx={{
            display: "flex",
            flexDirection: { xs: "column", sm: "row" },
            justifyContent: "center",
            alignItems: "center",
            fontWeight: "normal",
          }}
        >
          <a
            href="mailto:tasks@goodgym.org"
            style={{ textDecoration: "none", color: "red" }}
          >
            tasks@goodgym.org&nbsp;&nbsp;
          </a>{" "}
          <a
            href="tel:0203 432 3920"
            style={{ textDecoration: "none", color: "red" }}
          >
            0203 432 3920
          </a>
        </UI.Typography>
      </UI.Container>
      <UI.Container>
        <UI.Grid
          container
          spacing={1}
          sx={{
            gap: "50px",
            padding: 2,
            display: "flex",
            justifyContent: "center",
            mt: 3,
            mb: 5,
          }}
        >
          <UI.Grid item xs={12} md={3}>
            <UI.Typography variant="h5" sx={{ mb: 2 }}>
              How much does it cost?
            </UI.Typography>
            <UI.Typography variant="subtitle1" sx={{ fontWeight: "normal" }}>
              It's free - GoodGym tasks are performed by our volunteers and do
              not expect payment for their time.
            </UI.Typography>
          </UI.Grid>
          <UI.Grid item xs={12} md={3}>
            <UI.Typography variant="h5" sx={{ mb: 2 }}>
              How do I get started?
            </UI.Typography>
            <UI.Typography variant="subtitle1" sx={{ fontWeight: "normal" }}>
              Contact{" "}
              <a
                href="mailto:missioncontrol@goodgym.org"
                style={{ textDecoration: "none", color: "red" }}
              >
                {" "}
                missioncontrol@goodgym.org{" "}
              </a>
              on your professional email giving your name, role and contact
              phone number and we can set you up to make referrals to GoodGym.
            </UI.Typography>
          </UI.Grid>
          <UI.Grid item xs={12} md={3}>
            <UI.Typography variant="h5" sx={{ mb: 2 }}>
              What sort of tasks are possible?
            </UI.Typography>
            <UI.Typography variant="subtitle1" sx={{ fontWeight: "normal" }}>
              Our volunteers are not professional tradesmen, so we're not able
              to do certain tasks such as: advanced painting, felling trees,
              putting up complex furniture.
            </UI.Typography>
          </UI.Grid>
        </UI.Grid>
      </UI.Container>
    </>
  );
};

export default RequestMissionPage;
