import * as UI from "@goodgym/components";
import Image from "../goodgym.jpeg";

export type NoSocialVisitPageProps = {
  area?: string
  numberVerified?: number;
  postcode?: string;
}

const NoSocialVisitPage: React.FC<NoSocialVisitPageProps> = ({ area, postcode, numberVerified }) => {

  const theme = UI.useTheme();
  return (
    <UI.Box>
      <UI.Grid container spacing={3}>
        <UI.Grid item xs={12} md={6}>
          {postcode ? <UI.Typography variant="h4" sx={{ mb: 2 }}>
            Help make social visits happen in this city
          </UI.Typography> :<UI.Typography variant="h4" sx={{ mb: 2 }}>
            Help make social visits happen in {area}
          </UI.Typography>}
          

          <UI.Typography
            variant="h5"
            sx={{ my: 1, color: theme.palette.primary.main }}
          >
            How can I make it happen?
          </UI.Typography>
          <UI.Typography variant="body1">
            To get social visits going in {area} we need to get <b>5</b> people
            verified within a 5month period. Currently we have <b>{numberVerified}</b> people verified in the area. If we
            don’t have enough people verified, you could help encourage more
            people to get involved by:
          </UI.Typography>
          <ul>
            <li>Making an announcement at a group session </li>
            <li>Encouraging your friends to get verified</li>
            <li>
              Asking your TaskForce and/or area activator to help you recruit
              more people{" "}
            </li>
            <li>
              Posting on social media to encourage people to get involved{" "}
            </li>
          </ul>
          <UI.Typography
            variant="h5"
            sx={{ my: 1, color: theme.palette.primary.main }}
          >
            Why does it work like this?
          </UI.Typography>
          <UI.Typography variant="body1">
            We wait until 5 people are verified so we don’t waste referrers time
            and they have some options when they get to the website. Once we
            have 5 verified GoodGymers then we get in touch with local
            organisations who can refer older people.
          </UI.Typography>
          <UI.Typography
            variant="h5"
            sx={{ my: 1, color: theme.palette.primary.main }}
          >
            Over five people verified?
          </UI.Typography>
          <UI.Typography variant="body1" sx={{ mb: 2 }}>
            If there are five or more people verified in your area, you can help
            us by writing a message to us that we can pass on to the local
            organisations that we work with. Click the button below to tell us
            you’re ready to go and we’ll pass on your message to local
            organisations who work with older people to encourage them to refer.
            Please let us know if you would rather we don’t share your name. To
            make your message compelling and personal it might be useful to
            include:
          </UI.Typography>
          <ul>
            <li>your reasons for wanting to get involved </li>
            <li>a mention of local place names </li>
          </ul>

          <UI.Button
            sx={{ mt: 1 }}
            href="https://docs.google.com/forms/d/e/1FAIpQLSfsgBG2xuNHsxmC4uX8JzZkisfQSHG9LwhvhFckyRHr471NIQ/viewform"
          >
            Request a referral
          </UI.Button>
        </UI.Grid>
        <UI.Grid item xs={12} md={6}>
          <UI.Box
            sx={{
              borderStyle: "solid",
              p: 1,
              borderWidth: "0.8px",
              background: theme.palette.grey[300],
              textAlign: "center",
            }}
          >
            <UI.Box
              component="img"
              sx={{
                width: { xs: "250px", sm: "280px", md: "310px", lg: "400px" },
                height: "auto",
                m: 2,
                overflow: "hidden",
              }}
              src={Image}
            />

            <UI.Typography variant="body1">
              “This was my first social visit, and I went to see Mr R. I had
              been nervous about whether we would have enough to talk about, but
              I needn't have worried, he was a great story-teller and we
              actually found we had quite a bit in common, even though I
              couldn't compete with him having met the Queen!”
              <br /> <br />
              Sheila from W&M
            </UI.Typography>
          </UI.Box>
        </UI.Grid>
      </UI.Grid>
    </UI.Box>
  );
};

export default NoSocialVisitPage;
