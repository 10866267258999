/** @jsxImportSource @emotion/react */
import React from "react";
import * as Icons from "@goodgym/icons";
import * as UI from "@goodgym/components";
import * as T from "@goodgym/graphql/types";
import * as u from "@goodgym/util";
import _ from "lodash";
import { useAuth } from "@goodgym/auth";

export type RunnerCardProps = UI.CardProps & {
  runner: T.RunnerFragment &
    T.RunnerStatusFragment & {
      roles: string[];
      homeArea?: Optional<T.AreaFragment>;
      followsYou: boolean;
      followedByYou: boolean;
      blockedByYou: boolean;
    };
};

const RunnerCard: React.FC<React.PropsWithChildren<RunnerCardProps>> = ({
  runner,
  ...props
}) => {
  const { runner: you } = useAuth();

  return (
    <UI.Box sx={{ p: 1 }}>
      <UI.Box sx={{ display: "flex", alignItems: "flex-start" }}>
        <UI.Box sx={{ flexGrow: 0, flexShrink: 0, mr: 1 }}>
          <UI.Avatar
            src={runner.avatarUrl}
            component={UI.Link}
            to={u.links.profile(runner)}
            sx={{
              width: "32px",
              height: "32px",
            }}
          />
        </UI.Box>
        <UI.Box sx={{ flexGrow: 1, flexWrap: "wrap" }}>
          <UI.Link
            variant="subtitle1"
            color="black.main"
            to={u.links.profile(runner)}
            sx={{ display: "block" }}
          >
            {" "}
            {runner.name}
            {runner.pronouns && (
              <UI.Typography
                component="span"
                sx={{ ml: "4px" }}
                variant="body2"
                color="neutral.main"
              >
                ({runner.pronouns})
              </UI.Typography>
            )}
          </UI.Link>
        </UI.Box>
      </UI.Box>
      {runner.homeArea && (
        <UI.AreaChip area={runner.homeArea} sx={{ mt: 2, mb: 1 }} />
      )}

      {runner.roles.map((r) => {
        const icon = u.roles.icon(r);
        const label = u.roles.label(r);
        if (!icon || !label) return null;
        return (
          <UI.Box
            key={r}
            sx={{
              my: 1,
              color: "neutral.dark",
              fontSize: "14px",
            }}
          >
            <UI.Avatar
              sx={{
                fontSize: "18px",
                height: "18px",
                width: "18px",
                display: "inline-block",
                mr: "4px",
                verticalAlign: "text-bottom",
              }}
            >
              {u.roles.icon(r)}
            </UI.Avatar>{" "}
            {label}
          </UI.Box>
        );
      })}

      {!u.roles.hasAnyRole(runner, ["TRAINER", "TASK_FORCE", "RUN_LEADER"]) &&
        runner.isMember && (
          <UI.Box
            sx={{
              my: 1,
              color: "neutral.dark",
              fontSize: "14px",
            }}
          >
            <UI.Avatar
              sx={{
                fontSize: "18px",
                height: "18px",
                width: "18px",
                display: "inline-block",
                mr: "4px",
                verticalAlign: "text-bottom",
              }}
            >
              {runner.verified
                ? u.status.icons.verified
                : u.status.icons.member}
            </UI.Avatar>{" "}
            {runner.verified ? "Verified member" : "Member"}
          </UI.Box>
        )}

      {!!runner && runner.neverRun && (
        <UI.Box
          sx={{
            my: 1,
            color: "neutral.dark",
            fontSize: "14px",
          }}
        >
          <UI.Avatar
            sx={{
              fontSize: "18px",
              height: "18px",
              width: "18px",
              display: "inline-block",
              mr: "4px",
              verticalAlign: "text-bottom",
            }}
          >
            {u.status.icons.neverRun}
          </UI.Avatar>{" "}
          Not been to a session
        </UI.Box>
      )}

      {runner.followsYou && (
        <UI.Typography variant="body2" color="neutral.dark" sx={{ mb: 1 }}>
          {runner.followedByYou ? "You follow each other" : "Follows you"}
        </UI.Typography>
      )}
      {runner.id !== you?.id && <UI.FollowButton runner={runner} />}
    </UI.Box>
  );
};

export default RunnerCard;
