/** @jsxImportSource @emotion/react */
import React from "react";
import * as Icons from "@goodgym/icons";
import * as UI from "@goodgym/components";
import * as T from "@goodgym/graphql/types";
import * as u from "@goodgym/util";
import _ from "lodash";

export type GroupFitnessActivitySelectProps = {
  onClose: React.MouseEventHandler;
  next: (gfaId: T.GroupFitnessActivityFragment["id"] | "NONE") => any;
  session: T.SessionCardFragment & {
    groupFitnessActivities: (T.GroupFitnessActivityFragment & {
      signups: T.RunnerFragment[];
      ledBy: T.RunnerFragment;
    })[];
  };
};

const GroupFitnessActivitySelect: React.FC<
  React.PropsWithChildren<GroupFitnessActivitySelectProps>
> = ({ session, next, onClose, ...props }) => {
  const noGfaId = _.uniqueId();
  const [selected, setSelected] = React.useState<Optional<string>>(null);

  const onClick: React.MouseEventHandler = (e) => {
    e.preventDefault();
    if (selected) {
      next(selected);
    }
  };

  return (
    <>
      <UI.DialogTitle>
        Getting there
        <UI.CloseButton onClick={onClose} />
      </UI.DialogTitle>
      <UI.DialogContent sx={{ mb: 0, pb: 0 }}>
        <UI.Typography>
          You're signing up to <strong>{session.title}</strong>. There are a few
          options for getting to the task:
        </UI.Typography>
        <UI.List>
          {session.type !== "group run" && (
            <UI.ListItem
              disableGutters
              component="label"
              htmlFor={noGfaId}
              sx={{ cursor: "pointer" }}
            >
              <UI.ListItemIcon sx={{ mr: 2 }}>
                <UI.Avatar style={{ fontSize: "28px" }}>
                  <Icons.Person />
                </UI.Avatar>
              </UI.ListItemIcon>
              <UI.ListItemText
                primary="Make your own way there"
                secondary="We encourage you to walk, run, or cycle"
              />
              <UI.Checkbox
                id={noGfaId}
                checked={selected === "NONE"}
                onChange={() => setSelected("NONE")}
                sx={{ ml: 2 }}
              />
            </UI.ListItem>
          )}
          {session.groupFitnessActivities.map((gfa) => {
            const Icon = u.session.groupFitnessActivityIcon(gfa);
            const id = _.uniqueId();
            return (
              <UI.ListItem
                key={gfa.id}
                disableGutters
                sx={{ alignItems: "flex-start", cursor: "pointer" }}
                component="label"
                htmlFor={id}
              >
                <UI.ListItemIcon sx={{ mr: 2, mt: 1 }}>
                  <UI.Avatar style={{ fontSize: "28px" }}>
                    <Icon />
                  </UI.Avatar>
                </UI.ListItemIcon>
                <UI.ListItemText
                  primary={`${gfa.type} ${gfa.distance}km from ${gfa.address.postcode}`}
                  secondary={
                    <>
                      <UI.RunnerLinks runners={gfa.signups} />{" "}
                      {gfa.signups.length === 1 ? "is" : "are"} going to{" "}
                      {gfa.type.toLocaleLowerCase()} from{" "}
                      {session.address ? (
                        <UI.Link
                          href={u.geo.directionsLink(
                            gfa.address,
                            session.address
                          )}
                        >
                          {gfa.address.line1}
                        </UI.Link>
                      ) : (
                        gfa.address.line1
                      )}
                    </>
                  }
                />
                <UI.Checkbox
                  id={id}
                  checked={selected === gfa.id}
                  onChange={() => setSelected(gfa.id)}
                  sx={{ ml: 2 }}
                />
              </UI.ListItem>
            );
          })}
        </UI.List>
      </UI.DialogContent>
      <UI.DialogActions sx={{ mt: 0, pt: 0, m: 2 }}>
        <UI.Button variant="text" color="neutral" fullWidth onClick={onClose}>
          Cancel
        </UI.Button>
        <UI.Button
          color="secondary"
          fullWidth
          disabled={selected === null}
          onClick={onClick}
        >
          Next
        </UI.Button>
      </UI.DialogActions>
    </>
  );
};

export default GroupFitnessActivitySelect;
