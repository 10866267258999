/** @jsxImportSource @emotion/react */
import React from "react";
import * as Icons from "@goodgym/icons";
import * as UI from "@goodgym/components";
import * as T from "@goodgym/graphql/types";
import * as u from "@goodgym/util";
import _ from "lodash";

export type SearchResultProps = UI.BoxProps & {
  address: T.AddressSearchResultFragment;
};

const SearchResult: React.FC<React.PropsWithChildren<SearchResultProps>> = ({
  address,
  ...props
}) => {
  return (
    <UI.Box {...props} sx={{ display: "flex", width: "100%", ...props.sx }}>
      <UI.Box sx={{ flexGrow: 1 }}>{u.text.compositeAddress(address)}</UI.Box>
      <UI.Box sx={{ flexShrink: 0 }}>
        {address.area ? (
          <UI.AreaChip area={address.area} />
        ) : (
          <UI.Chip
            size="small"
            label="Not in goodgym area"
            sx={{
              borderRadius: 1,
              height: 24,
              ...props.sx,
              verticalAlign: "baseline",
              backgroundColor: "neutral.light",
              fontWeight: "bold",
            }}
          />
        )}
      </UI.Box>
    </UI.Box>
  );
};

export default SearchResult;
