/** @jsxImportSource @emotion/react */
import React from "react";
import * as Icons from "@goodgym/icons";
import * as UI from "@goodgym/components";
import * as T from "@goodgym/graphql/types";
import * as u from "@goodgym/util";
import _ from "lodash";
import { Helmet } from "react-helmet-async";
import * as images from "./images";

export type SeoProps = {
  title: string;
  description?: string;
  image?: string;
  url: string;
};

const Seo: React.FC<React.PropsWithChildren<SeoProps>> = ({
  title,
  description = u.seo.description,
  image = images.split,
  url,
  children,
}) => {
  return (
    <Helmet>
      <title>
        {u.seo.title} {title ? ` | ${title}` : ""}
      </title>
      <meta name="description" content={description} />
      <link rel="canonical" href={`https://www.goodgym.org${url}`} />
      <link rel="image_src" href={`https://www.goodgym.org${image}`} />
      <meta property="fb:app_id" content="289361721186540" />

      <meta property="og:title" content={title} />
      <meta property="og:description" content={description} />
      <meta property="og:image" content={`https://www.goodgym.org${image}`} />
      <meta property="og:url" content={`https://www.goodgym.org${url}`} />
      <meta property="og:type" content="website" />

      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:site" content="@goodgym" />
      <meta name="twitter:title" content={title} />
      <meta name="twitter:description" content={description} />
      <meta name="twitter:image" content={`https://www.goodgym.org${image}`} />
      {children}
    </Helmet>
  );
};

export default Seo;
