/** @jsxImportSource @emotion/react */
import React from "react";
import * as Icons from "@goodgym/icons";
import * as UI from "@goodgym/components";
import * as T from "@goodgym/graphql/types";
import * as u from "@goodgym/util";
import * as C from "..";
import _ from "lodash";

export type ProfileInfoProps = UI.BoxProps & {
  runner: T.ProfilePageQuery["runner"];
};

const ProfileInfo: React.FC<React.PropsWithChildren<ProfileInfoProps>> = ({
  runner,
  ...props
}) => {
  return (
    <UI.Box {...props}>
      {runner.isMember && (
        <C.ProfileInfoItem
          icon={
            runner.verified ? u.status.icons.verified : u.status.icons.member
          }
          text={runner.verified ? "Verified member" : "Member"}
          tooltip={
            runner.verified
              ? "A GoodGym member who has completed a DBS check."
              : "A GoodGym member."
          }
        />
      )}
      {runner.roles.map((r) => {
        const icon = u.roles.icon(r);
        const label = u.roles.label(r);
        const tooltip = u.roles.description(r);
        if (!icon || !label) return null;
        return (
          <C.ProfileInfoItem
            key={r}
            icon={icon}
            text={label}
            tooltip={tooltip}
          />
        );
      })}
      {runner.twitter && (
        <C.ProfileInfoItem
          icon={
            <Icons.Twitter
              sx={{ backgroundColor: "neutral.light", color: "#1D9BF0" }}
            />
          }
          text={`@${runner.twitter}`}
          href={`https://twitter.com/${runner.twitter}`}
        />
      )}
      {runner.instagram && (
        <C.ProfileInfoItem
          icon={
            <Icons.Instagram
              sx={{ backgroundColor: "neutral.light", color: "#e94475" }}
            />
          }
          text={`@${runner.instagram}`}
          href={`https://instagram.com/${runner.instagram}/`}
        />
      )}
      <C.ProfileInfoItem
        icon={<Icons.Today sx={{ backgroundColor: "neutral.light" }} />}
        text={`Doing good since ${u.time.format(runner.joinedAt, "MMMM yyyy")}`}
      />
    </UI.Box>
  );
};

export default ProfileInfo;
