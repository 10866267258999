/** @jsxImportSource @emotion/react */
import React from "react";
import * as Icons from "@goodgym/icons";
import * as UI from "@goodgym/components";
import * as T from "@goodgym/graphql/types";
import * as u from "@goodgym/util";
import _ from "lodash";
import { titleize } from "inflection";

export type ProfileCardProps = UI.CardProps & {
  runner: (T.RunnerFragment | T.CurrentRunnerFragment) & {
    homeArea: T.AreaFragment;
    roles: string[];
    stats: T.RunnerStatsFragment;
    streaks: T.RunnerStreakFragment[];
  };
};

const ProfileCard: React.FC<ProfileCardProps> = ({ runner, ...props }) => {
  return (
    <UI.Card {...props} sx={{ textAlign: "center", pb: 3, ...props.sx }}>
      <UI.Box
        sx={{
          mx: "auto",
          my: 1,
        }}
      >
        <UI.RunnerAvatar
          runner={runner}
          size={"huge"}
          sx={{
            border: "3px solid",
            borderColor: "black.main",
          }}
        />
      </UI.Box>
      <UI.Typography variant="subtitle1">{runner.name}</UI.Typography>
      <UI.AreaName sx={{ my: 1, mx: 2 }} area={runner.homeArea}></UI.AreaName>
      <UI.Grid
        container
        spacing={1}
        justifyContent="center"
        alignItems="center"
        sx={{ mt: 1 }}
      >
        {runner.roles.map((role) => {
          return (
            <UI.Grid item key={role} sx={{ px: 1 }}>
              <UI.Chip
                label={
                  role === "TRAINER"
                    ? "Area Activator"
                    : role === "TASK_FORCE"
                    ? "TaskForce"
                    : titleize(role)
                }
                variant="filled"
              />
            </UI.Grid>
          );
        })}
      </UI.Grid>
      <UI.Divider sx={{ my: 4, mx: 0 }}></UI.Divider>
      <UI.Box>
        <UI.Grid container>
          <UI.Grid item xs={6} sx={{ textAlign: "right", pr: 1 }}>
            <UI.Typography variant="subtitle1">Good deeds</UI.Typography>
          </UI.Grid>
          <UI.Grid item xs={6}>
            <UI.Typography
              variant="subtitle1"
              sx={{ color: "primary.main", textAlign: "left", pl: 1 }}
            >
              {runner.stats.goodDeeds}
            </UI.Typography>
          </UI.Grid>
        </UI.Grid>
        <UI.Grid container>
          <UI.Grid item xs={6} sx={{ textAlign: "right", pr: 1 }}>
            <UI.Typography variant="subtitle1">Workouts</UI.Typography>
          </UI.Grid>
          <UI.Grid item xs={6}>
            <UI.Typography
              variant="subtitle1"
              sx={{ color: "primary.main", textAlign: "left", pl: 1 }}
            >
              {runner.stats.runs}
            </UI.Typography>
          </UI.Grid>
        </UI.Grid>

        <UI.Grid container>
          <UI.Grid item xs={6} sx={{ textAlign: "right", pr: 1 }}>
            <UI.Typography variant="subtitle1">Cheers</UI.Typography>
          </UI.Grid>
          <UI.Grid item xs={6}>
            <UI.Typography
              variant="subtitle1"
              sx={{ color: "primary.main", textAlign: "left", pl: 1 }}
            >
              {runner.stats.cheersReceived}
            </UI.Typography>
          </UI.Grid>
        </UI.Grid>
      </UI.Box>
      <UI.Grid container sx={{ mt: 2, mb: 3 }}>
        {runner.streaks.map((streak) => {
          return (
            <UI.Grid
              item
              key={streak.id}
              xs={(12 / runner.streaks.length) as any}
            >
              <UI.RunnerStreak runnerStreak={streak} />
            </UI.Grid>
          );
        })}
      </UI.Grid>
      <UI.Link to={u.links.profile(runner)} variant="subtitle1">
        View full profile
      </UI.Link>
    </UI.Card>
  );
};
// @ts-ignore
ProfileCard.muiName = UI.Card.muiName;
export default ProfileCard;
