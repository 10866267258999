import { useBlockMutation } from "@goodgym/graphql/hooks";
import * as T from "@goodgym/graphql/types";
import * as UI from "@goodgym/components";
import { useConfirm } from "@goodgym/hooks";
import * as u from "@goodgym/util";

export const useBlock = ({
  runner,
}: {
  runner: T.RunnerFragment & T.FollowStatusFragment;
}): React.MouseEventHandler => {
  const { confirm } = useConfirm();
  const [blockMutation] = useBlockMutation({
    onError: console.log,
    optimisticResponse: {
      response: {
        __typename: "BlockMutationPayload",
        runners: [
          {
            __typename: "Runner",
            id: runner.id,
            followsYou: false,
            followedByYou: false,
            blockedByYou: true,
          },
        ],
      },
    },
  });

  return (e) => {
    e?.preventDefault();
    confirm({
      title: `Block ${runner.name}`,
      text: (
        <>
          <UI.Typography sx={{ mb: 2 }}>
            Are you sure you want to block {runner.name}?
          </UI.Typography>
          <UI.Typography sx={{ mb: 2 }}>
            This will prevent {runner.name} from following you on the website
            and viewing your profile and activity. You will receive a
            notification if they register for a session you are signed up to.
          </UI.Typography>
          <UI.Typography>
            Blocking someone on the website does not prevent them from signing
            up to sessions you are registered for. If their behaviour is making
            you feel unsafe, or is otherwise in breach of GoodGym's{" "}
            <UI.Link href={u.links.codeOfConduct()}>Code of Conduct</UI.Link>{" "}
            please contact{" "}
            <UI.Link href="mailto:support@goodgym.org?subject=Code of Conduct violation">
              support@goodgym.org
            </UI.Link>{" "}
            so we can investigate further.
          </UI.Typography>
        </>
      ),
      onConfirm: () => blockMutation({ variables: { runnerIds: [runner.id] } }),
    });
  };
};
