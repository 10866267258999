/** @jsxImportSource @emotion/react */
import React from "react";
import * as UI from "@goodgym/components";
import { useSessionsFeed } from "@goodgym/components/SessionsFeed/hooks";
import * as T from "@goodgym/graphql/types";
import * as Icons from "@goodgym/icons";

export type SessionsProps = UI.BoxProps & {
  area: NonNullable<T.AreaPageQuery["area"]>;
  type?: string[];
};

const Sessions: React.FC<SessionsProps> = ({ area, type, ...props }) => {
  const state = useSessionsFeed({ area, type });
  const { items, error, more } = state;

  if (error) return <UI.Error error={error} />;

  return (
    <>
      <UI.Grid
        container
        spacing={2}
        sx={{ pt: 2, mb: 2, display: { xs: "flex", md: "none" } }}
      >
        <UI.Grid item xs={12}>
          <UI.Button
            fullWidth
            variant="outlined"
            color="neutral"
            onClick={state.drawers.filters.onClick}
          >
            <Icons.FilterList sx={{ mr: "4px" }} /> Filters
          </UI.Button>
        </UI.Grid>
      </UI.Grid>
      <UI.Grid container spacing={3}>
        <UI.Grid item xs={12} md={7}>
          <UI.Box sx={{ my: 1, flexGrow: 1 }}>
            <UI.SessionsFeed.ResultCount more={more} count={items.length} />
          </UI.Box>
        </UI.Grid>
        <UI.Grid container spacing={3}>
          <UI.Grid item md={8}>
            <UI.Box sx={{ ml: 3, flexGrow: 1 }}>
              <UI.SessionsFeed state={state} />
            </UI.Box>
          </UI.Grid>
          <UI.Grid item md={4} sx={{ mt: 0, mb: 3 }}>
            <UI.Card
              sx={{ display: { xs: "none", md: "block" }, mt: 1, mb: 3 }}
            >
              <UI.CardContent>
                <UI.Typography
                  variant="subtitle1"
                  color="neutral.dark"
                  sx={{
                    mb: 2,
                    pb: 1,
                    borderBottom: "1px solid #e0e0e0",
                    display: "flex",
                  }}
                >
                  <UI.Box
                    component="span"
                    sx={{ flexGrow: 1, alignSelf: "center" }}
                  >
                    Filters
                  </UI.Box>
                </UI.Typography>

                <UI.SessionsFeed.Filters
                  state={state}
                  areaPage={true}
                  sx={{ display: { xs: "none", md: "block" } }}
                />
              </UI.CardContent>
            </UI.Card>

            <UI.Drawer
              anchor="right"
              open={state.drawers.filters.open}
              onClose={state.drawers.filters.onClose}
              sx={{ display: { xs: "block", md: "none" } }}
            >
              <UI.DrawerHeader
                title="Filters"
                anchor="right"
                onClose={state.drawers.filters.onClose}
                open={state.drawers.filters.open}
              />
              <UI.SessionsFeed.Filters state={state} areaPage={true} sx={{ pt: 7, m: 2 }} />
            </UI.Drawer>
          </UI.Grid>
        </UI.Grid>
      </UI.Grid>
    </>
  );
};

export default Sessions;
