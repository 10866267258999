/** @jsxImportSource @emotion/react */
import React from "react";
import * as Icons from "@goodgym/icons";
import * as UI from "@goodgym/components";
import * as T from "@goodgym/graphql/types";
import * as u from "@goodgym/util";
import _ from "lodash";

export type ConfirmDialogProps = UI.DialogProps & {
  title: string;
  text: string | React.ReactNode;
  confirmText?: Optional<string>;
  cancelText?: Optional<string>;
  onConfirm: () => any;
  onCancel?: () => any;
  alert?: boolean;
};

const ConfirmDialog: React.FC<ConfirmDialogProps> = ({
  title,
  text,
  confirmText = "Confirm",
  cancelText = "Cancel",
  onConfirm,
  onCancel,
  alert,
  ...props
}) => {
  return (
    <UI.Dialog open={true} maxWidth="sm" fullWidth>
      <UI.DialogTitle>{title}</UI.DialogTitle>
      <UI.Box position="absolute" top={8} right={8}>
        <UI.IconButton onClick={onCancel}>
          <Icons.Close />
        </UI.IconButton>
      </UI.Box>
      <UI.DialogContent>
        <UI.Box sx={{ whiteSpace: "pre-wrap" }}>{text}</UI.Box>
      </UI.DialogContent>
      <UI.DialogActions sx={{ mb: 1, mr: 1 }}>
        <UI.Button color="neutral" variant="text" onClick={onCancel}>
          {cancelText}
        </UI.Button>
       {!alert && <UI.Button color="primary" variant="contained" onClick={onConfirm}>
          {confirmText}
        </UI.Button>}
      </UI.DialogActions>
    </UI.Dialog>
  );
};

export default ConfirmDialog;
