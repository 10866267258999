/** @jsxImportSource @emotion/react */
import { usePublishStoryMutation } from "@goodgym/graphql/hooks";
import * as T from "@goodgym/graphql/types";
import * as UI from "@goodgym/components";
import * as u from "@goodgym/util";
import { useConfirm } from "@goodgym/hooks";
import { useNavigate } from "react-router-dom";

export type PublishStoryMutationHook = (params: {
  story: T.StoryFragment;
  runner: Optional<T.CurrentRunnerFragment>;
}) => React.MouseEventHandler;

export const usePublishStory: PublishStoryMutationHook = ({
  story,
  runner,
}) => {
  const { confirm } = useConfirm();

  const navigate = useNavigate();

  const [publishStory] = usePublishStoryMutation({
    onError: console.log,
    variables: {
      storyId: story.id,
    },
    optimisticResponse: {
      response: {
        __typename: "PublishStoryPayload",
        story: {
          ...story,
        },
      },
    },
  });

  return (e) => {
    e?.preventDefault();
    if (u.roles.hasRole(runner, "CENTRAL_ADMIN")) {
      confirm({
        title:
          !story.publishedAt && story.discardedAt
            ? "Publish Story?"
            : "Draft Story",
        text:
          !story.publishedAt && story.discardedAt
            ? "Are you sure you want to publish this story?"
            : "Are you sure you want to draft this story?",
        onConfirm: () => {
          publishStory();
          navigate(u.links.stories());
        },
      });
    }
  };
};
