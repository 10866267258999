/** @jsxImportSource @emotion/react */
import React from "react";
import * as Icons from "@goodgym/icons";
import * as UI from "@goodgym/components";
import * as T from "@goodgym/graphql/types";
import * as u from "@goodgym/util";
import _ from "lodash";

export type RunnerInfoProps = UI.BoxProps & {
  runner: NonNullable<T.DashboardPageQuery["runner"]>;
};

const RunnerInfo: React.FC<React.PropsWithChildren<RunnerInfoProps>> = ({
  runner,
  ...props
}) => {
  return (
    <>
      <UI.Box {...props} sx={{ display: "flex", ...props.sx }}>
        <UI.Box sx={{ flexGrow: 0, flexShrink: 0, textAlign: "center" }}>
          <UI.RunnerAvatar size="huge" runner={runner} />
          <UI.Typography
            variant="subtitle1"
            sx={{
              mt: 1,
              display: "flex",
              alignItems: "center",
              color: "neutral.dark",
            }}
          >
            <UI.Box component="span" sx={{ color: "primary.main", mr: "4px" }}>
              {runner.stats.goodDeeds}
            </UI.Box>{" "}
            good deeds
          </UI.Typography>
          <UI.Link
            color="neutral.dark"
            variant="body2"
            to={u.links.profile(runner)}
          >
            view profile
          </UI.Link>
        </UI.Box>
        <UI.Box
          sx={{
            flexGrow: 1,
            ml: 3,
          }}
        >
          <UI.Typography
            variant="h5"
            color="black.main"
            sx={{ whiteSpace: "wrap" }}
          >
            {runner.name}
          </UI.Typography>
          {runner.pronouns && (
            <UI.Typography
              variant="subtitle1"
              color="neutral.main"
              sx={{ mt: 1 }}
            >
              ({runner.pronouns})
            </UI.Typography>
          )}
          <UI.AreaChip area={runner.homeArea} sx={{ mt: 1, mb: 1 }} />
        </UI.Box>
      </UI.Box>
    </>
  );
};

export default RunnerInfo;
