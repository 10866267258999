/** @jsxImportSource @emotion/react */
import React from "react";
import * as Icons from "@goodgym/icons";
import * as UI from "@goodgym/components";
import * as T from "@goodgym/graphql/types";
import * as u from "@goodgym/util";
import _ from "lodash";

export type InfiniteScrollLoaderProps = UI.BoxProps & { more: boolean };

const InfiniteScrollLoader: React.FC<InfiniteScrollLoaderProps> = ({
  more,
  ...props
}) => {
  return more ? (
    <UI.Typography
      variant="h6"
      component="p"
      align="center"
      color="primary"
      sx={{ my: 2, mb: 6  }}
    >
      Loading...
    </UI.Typography>
  ) : (
    <div />
  );
};

export default InfiniteScrollLoader;
