/** @jsxImportSource @emotion/react */
import React from "react";
import * as Icons from "@goodgym/icons";
import * as UI from "@goodgym/components";
import * as T from "@goodgym/graphql/types";
import * as u from "@goodgym/util";
import _ from "lodash";

export type ProfileStatsProps = UI.BoxProps & {
  runner: T.ProfilePageQuery["runner"];
};

const ProfileStat = ({ stat, label }: { label: string; stat: number }) => (
  <UI.Box sx={{ display: "flex", mb: 1 }}>
    <UI.Typography variant="subtitle1" sx={{ flexGrow: 1 }}>
      {label}
    </UI.Typography>
    <UI.Typography
      variant="subtitle1"
      sx={{
        color: "primary.main",
        minWidth: "30px",
        mr: 1,
        textAlign: "right",
      }}
    >
      {stat}
    </UI.Typography>
  </UI.Box>
);

const ProfileStats: React.FC<React.PropsWithChildren<ProfileStatsProps>> = ({
  runner,
  ...props
}) => {
  return (
    <UI.Box>
      <UI.Box sx={{ textAlign: "center", mb: 2 }}>
        <UI.Typography variant="h2" color="primary.main">
          {runner.stats.goodDeeds}
        </UI.Typography>
        <UI.Typography variant="h3">Good Deeds</UI.Typography>
      </UI.Box>
      <ProfileStat label="Workouts" stat={runner.stats.runs} />
      <ProfileStat label="Cheers given" stat={runner.stats.cheersGiven} />
      <ProfileStat label="Cheers received" stat={runner.stats.cheersReceived} />
    </UI.Box>
  );
};

export default ProfileStats;
