/** @jsxImportSource @emotion/react */
import React from "react";
import * as Icons from "@goodgym/icons";
import * as UI from "@goodgym/components";
import * as T from "@goodgym/graphql/types";
import * as u from "@goodgym/util";
import _ from "lodash";

export type ListingCardProps = UI.CardProps & {
  listing: T.SessionCardFragment | T.TaskRequestCardFragment;
  onClick?: React.MouseEventHandler;
};

const ListingCard: React.FC<ListingCardProps> = ({
  listing,
  onClick,
  ...props
}) => {
  switch (listing.__typename) {
    case "Session":
      return <UI.SessionCard session={listing} onClick={onClick} {...props} />;
    case "TaskRequest":
      return (
        <UI.TaskRequestCard
          taskRequest={listing}
          onClick={onClick}
          {...props}
        />
      );
    default:
      return <UI.Box {...props}></UI.Box>;
  }
};

//@ts-ignore
ListingCard.muiName = UI.Card.muiName;
export default ListingCard;
