/** @jsxImportSource @emotion/react */
import React from "react";
import * as Icons from "@goodgym/icons";
import * as UI from "@goodgym/components";
import * as T from "@goodgym/graphql/types";
import * as u from "@goodgym/util";
import _ from "lodash";
import { ReactComponent as SVG } from "./logo.svg";

export type LogoProps = { height?: number; color?: "red" | "black" | "white" };

const Logo: React.FC<LogoProps> = ({ color = "red", height = 40 }) => {
  const theme = UI.useTheme();

  const fill = (() => {
    switch (color) {
      case "red":
        return theme.palette.primary.main;
      case "black":
        return theme.palette.black.main;
      case "white":
        return theme.palette.white.main;
    }
  })();

  return (
    <UI.Link to={u.links.home()} aria-label="GoodGym home page">
      <SVG fill={fill} css={{ height, verticalAlign: "middle" }} />
    </UI.Link>
  );
};

export default Logo;
