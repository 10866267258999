/** @jsxImportSource @emotion/react */
import React from "react";
import * as Icons from "@goodgym/icons";
import * as UI from "@goodgym/components";
import * as T from "@goodgym/graphql/types";
import * as u from "@goodgym/util";
import _ from "lodash";

export type RunnerLinkProps = UI.LinkProps & {
  runner: T.RunnerFragment | T.CurrentRunnerFragment;
};

const RunnerLink: React.FC<RunnerLinkProps> = ({ runner, ...props }) => {
  return runner.slug === "hidden" ? (
    <strong>{runner.name}</strong>
  ) : (
    <UI.RunnerTooltip runner={runner}>
      <UI.Box component="span">
        <UI.Link {...props} to={u.links.profile(runner)}>
          {runner.name}
        </UI.Link>
        {runner.pronouns && (
          <UI.Typography
            component="span"
            variant="body2"
            sx={{
              color: "neutral.main",
              "&:hover": { textDecoration: "none" },
            }}
          >
            {" "}
            ({runner.pronouns})
          </UI.Typography>
        )}
      </UI.Box>
    </UI.RunnerTooltip>
  );
};

export default RunnerLink;
