import * as UI from "@goodgym/components";
import theme from "@goodgym/theme/";
import Looks3Icon from "@mui/icons-material/Looks3";
import LooksOneIcon from "@mui/icons-material/LooksOne";
import LooksTwoIcon from "@mui/icons-material/LooksTwo";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import { useState } from "react";
import { styled } from "@mui/system";

const TableContents: React.FC = () => {
  const [TabPanelValue, setTabPanelValue] = useState("1");

  const breakpoint = theme.breakpoints.values["md"];
  const shouldBeHorizontal = UI.useMediaQuery(`(min-width:${breakpoint}px)`);

  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    setTabPanelValue(newValue);
  };

  const ListItem = styled(UI.ListItem)`
    list-style-type: disc;
    display: list-item;
  `;

  const List = styled(UI.List)`
    padding-left: 40px;
  `;

  return (
    <UI.Container
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        mb: 6,
      }}
    >
      <UI.Box
        sx={{
          width: "100%",
          bgcolor: " #F5F5F5",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <TabContext value={TabPanelValue}>
          <UI.Box sx={{ borderBottom: 1, borderColor: "divider" }}>
            <TabList
              orientation={shouldBeHorizontal ? "horizontal" : "vertical"}
              variant="scrollable"
              onChange={handleChange}
              aria-label="table of content"
            >
              <UI.Tab
                icon={<LooksOneIcon sx={{ fontSize: "25px" }} />}
                label={
                  <UI.Typography variant="h5" sx={{ paddingBottom: 3 }}>
                    General Expectations
                  </UI.Typography>
                }
                value="1"
              />
              <UI.Tab
                icon={<LooksTwoIcon sx={{ fontSize: "25px" }} />}
                label={
                  <UI.Typography variant="h5">
                    Task Force members and Run Leaders
                  </UI.Typography>
                }
                value="2"
              />
              <UI.Tab
                icon={<Looks3Icon sx={{ fontSize: "25px" }} />}
                label={
                  <UI.Typography variant="h5" sx={{ paddingBottom: 3 }}>
                    Raising Concerns
                  </UI.Typography>
                }
                value="3"
              />
            </TabList>
          </UI.Box>

          <TabPanel sx={{ fontSize: theme.typography.body1 }} value="1">
            GoodGym promotes good physical and mental health and everyone who
            participates in a GoodGym activity should feel safe and welcome.
            GoodGym asks everyone attending an activity to:
            <List>
              <ListItem>
                <strong>Be friendly and approachable</strong>. GoodGym
                encourages participants to connect with each other and you can
                do this by participating in discussions and listening to others
                views.
              </ListItem>

              <ListItem>
                <strong>Be respectful of differences.</strong>
                {""} At a GoodGym session it is likely you will meet and be
                alongside people from all sorts of backgrounds. Embrace these
                differences and use them as an opportunity to learn and develop.
              </ListItem>
              <ListItem>
                {" "}
                <strong>Contribute positively.</strong> At each GoodGym session
                you will be working with others towards a common goal in
                assisting an older person or community organisation.
              </ListItem>
              <ListItem>
                <strong>Look out for your own and each other’s safety.</strong>{" "}
                Inform the group if you notice something which could cause harm.
              </ListItem>
            </List>
            When attending a GoodGym activity you are accountable for your own
            behaviour. The following is not acceptable at a GoodGym activity and
            should be challenged by members:{" "}
            <List>
              {" "}
              <ListItem>
                Behaviour which may be deemed dangerous and puts the physical
                safety of other runners at risk.
              </ListItem>
              <ListItem>
                Behaviour or language which discriminates on the grounds of
                race, gender, disability, nationality, religious or
                philosophical belief, age or sexual orientation.
              </ListItem>{" "}
              <ListItem>Behaviour which excludes other participants.</ListItem>{" "}
              <ListItem>Abusive or threatening language or behaviour.</ListItem>
            </List>
            If GoodGym or its participants feel that your behaviour falls below
            the standard stated above then GoodGym reserves the right to refuse
            or withdraw your access to the website and/or any GoodGym activity.
            <List sx={{ listStyleType: "disc", pl: 4 }}></List>
          </TabPanel>
          <TabPanel value="2">
            GoodGym TaskForce members and Run Leaders have additional access on
            the website and are likely to have contact with external
            organisations. We ask all TaskForce members and Run Leaders to do
            the following:
            <List sx={{ listStylePosition: "inside", paddingLeft: "1rem" }}>
              <ListItem>
                Act in the best interests of the organisation – consider what is
                best for the organisation and avoid any behaviour which may
                bring it into disrepute.
              </ListItem>{" "}
              <ListItem>
                Engage considerately and respectfully with all – respect
                diversity of all participants and external partners and avoid
                giving offence.
              </ListItem>{" "}
              <ListItem>
                Respect confidentiality – keep personal details of task owners
                and other external organisations private. Personal details
                should only be used for the purposes of GoodGym activities.
              </ListItem>{" "}
              <ListItem>Act within the law </ListItem>
            </List>
            If GoodGym or its participants feel that your behaviour falls below
            the standard stated above then GoodGym reserves the right to remove
            a member's TaskForce and/or Run Leader access on the website.
          </TabPanel>
          <TabPanel value="3">
            If you believe anyone participating in a GoodGym activity is not
            adhering to this code of conduct please contact {""}
            <a
              style={{ color: "#E11018", fontWeight: "bold" }}
              href={"mailto:"}
            >
              getinvolved@goodgym.org
            </a>
          </TabPanel>
        </TabContext>
      </UI.Box>
    </UI.Container>
  );
};

export default TableContents;
