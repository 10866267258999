/** @jsxImportSource @emotion/react */
import * as UI from "@goodgym/components";
import * as u from "@goodgym/util";
import React from "react";

export type LandscapeImageProps = UI.BoxProps & { src: string };

const LandscapeImage: React.FC<LandscapeImageProps> = React.forwardRef(
  ({ src, ...props }, ref) => {
    // on load we check the image dimensions, and
    // if they're somewhere between 16:9 and 4:3 we render
    // the image as is. if the image dimensions are outside that range
    // we add blurred borders to make them 4:3
    const [needsBlurredEdges, setNeedsBlurredEdges] = React.useState(false);
    React.useEffect(() => {
      if (!u.isServer) {
        const img = document.createElement("img");
        img.addEventListener("load", function () {
          const ratio = this.naturalHeight / this.naturalWidth;
          setNeedsBlurredEdges(ratio < 0.5 || ratio > 0.75);
        });
        img.src = src;
      }
    }, [src]);

    const ratio43 = "75%"; // 4:3

    if (!needsBlurredEdges) {
      return (
        <UI.Box
          ref={ref}
          {...props}
          sx={{ position: "relative", overflow: "hidden", ...props.sx }}
        >
          <UI.Box
            component="img"
            src={src}
            alt=""
            sx={{ width: "100%", mb: -1 }}
          />
        </UI.Box>
      );
    } else {
      return (
        <UI.Box
          ref={ref}
          {...props}
          sx={{
            ...props.sx,
            height: 0,
            position: "relative",
            pt: ratio43,
            overflow: "hidden",
          }}
        >
          <UI.Box
            sx={{
              top: -8,
              left: -8,
              right: -8,
              bottom: -8,
              pt: ratio43,
              position: "absolute",
              background: `center center / cover no-repeat url(${src})`,
              filter: "blur(8px)",
            }}
          />
          <UI.Box
            {...props}
            sx={{
              top: 0,
              left: 0,
              right: 0,
              pt: ratio43,
              position: "absolute",
              background: `center center / contain no-repeat url(${src})`,
            }}
          />
        </UI.Box>
      );
    }
  }
);

export default LandscapeImage;
