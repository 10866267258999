/** @jsxImportSource @emotion/react */
import { useDismissCtaMutation } from "@goodgym/graphql/hooks";
import * as T from "@goodgym/graphql/types";
import * as UI from "@goodgym/components";
import * as u from "@goodgym/util";

export type DismissCtaMutationHook = (params: {
  cta: T.CtaFragment;
}) => React.MouseEventHandler;

export const useDismissCta: DismissCtaMutationHook = ({ cta }) => {
  const [dismissCta] = useDismissCtaMutation({
    onError: console.log,
    variables: { id: cta.id },
  });

  return (e) => {
    e?.preventDefault();
    dismissCta();
    u.analytics.track("cta_dismissed", { cta_id: cta.id });
  };
};
