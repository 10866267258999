/** @jsxImportSource @emotion/react */
import React from "react";
import * as Icons from "@goodgym/icons";
import * as UI from "@goodgym/components";
import * as T from "@goodgym/graphql/types";
import * as u from "@goodgym/util";
import _ from "lodash";
import { useLocation } from "react-router-dom";
import { useAuth } from "@goodgym/auth";

export type SignInDialogProps = UI.DialogProps & {
  title?: Optional<string>;
  description?: Optional<string>;
  open: boolean;
  onClose: () => any;
};

const SignInDialog: React.FC<SignInDialogProps> = ({
  title,
  description,
  open,
  onClose,
  ...props
}) => {
  const { ready, runner } = useAuth();
  const location = useLocation();
  // don't flash up dialog when page is refreshed after logging in
  // during session signup flow
  if (!ready || runner) return null;
  return (
    <UI.Dialog {...props} open={open} onClose={onClose}>
      <UI.Form action="/users/sign_in">
        <UI.DialogTitle>
          {title || "Sign in to GoodGym"}
          <UI.CloseButton onClick={onClose} />
        </UI.DialogTitle>
        <UI.DialogContent sx={{ pb: 1 }}>
          <UI.DialogContentText sx={{ mb: 2 }}>
            {description || ""} Enter your email address and password to sign in
            to your GoodGym account.
          </UI.DialogContentText>
          <input
            type="hidden"
            name="redirect_to"
            value={location.pathname + location.search}
          />
          <UI.TextField
            autoFocus
            margin="dense"
            id="email"
            label="Email Address"
            type="email"
            name="user[email]"
            fullWidth
          />
          <UI.PasswordField
            margin="dense"
            id="password"
            label="Password"
            name="user[password]"
            fullWidth
          />
          <UI.FormControlLabel
            label="Stay signed in"
            control={<UI.Checkbox defaultChecked name="user[remember_me]" />}
          />
        </UI.DialogContent>
        <UI.DialogActions sx={{ mx: 2 }}>
          <UI.Button fullWidth type="submit" onClick={onClose}>
            Sign in
          </UI.Button>
        </UI.DialogActions>
      </UI.Form>
      <UI.Form action="/auth/facebook">
        <UI.DialogActions sx={{ mx: 2 }}>
          <UI.Button
            fullWidth
            type="submit"
            color="secondary"
            onClick={onClose}
          >
            <Icons.Facebook sx={{ mr: 1 }} /> Sign in with Facebook
          </UI.Button>
        </UI.DialogActions>
      </UI.Form>
      <UI.DialogActions sx={{ mx: 2, mb: 2 }}>
        <UI.Grid container>
          <UI.Grid
            item
            xs={12}
            sm={6}
            sx={{ px: 1, textAlign: { xs: "center", sm: "right" } }}
          >
            <UI.Link href="/users/sign_up">Create an account</UI.Link>
          </UI.Grid>
          <UI.Grid
            item
            xs={12}
            sm={6}
            sx={{ px: 1, textAlign: { xs: "center", sm: "left" } }}
          >
            <UI.Link href="/users/password/new">Forgot your password?</UI.Link>
          </UI.Grid>
        </UI.Grid>
      </UI.DialogActions>
    </UI.Dialog>
  );
};

export default SignInDialog;
