import * as T from "@goodgym/graphql/types";
import * as Icons from "@goodgym/icons";
import * as u from "@goodgym/util";

export const icon = (
  runner: T.RunnerFragment | (T.RunnerFragment & T.RunnerStatusFragment),
  currentRunner?: Optional<T.CurrentRunnerFragment>
) => {
  if (!("roles" in runner)) {
    // if we don't have the role information, we can assume we
    // don't want to show a status icon
    return null;
  } else if (u.roles.hasRole(runner, "TRAINER")) {
    return u.roles.icon("TRAINER");
  } else if (u.roles.hasRole(runner, "RUN_LEADER")) {
    return u.roles.icon("RUN_LEADER");
  } else if (u.roles.hasRole(runner, "TASK_FORCE")) {
    return u.roles.icon("TASK_FORCE");
  } else if (runner.verified) {
    return icons.verified;
  } else if (runner.isMember) {
    return icons.member;
  } else if (
    !u.roles.hasAnyRole(currentRunner, [
      "TRAINER",
      "RUN_LEADER",
      "TASK_FORCE",
      "CENTRAL_ADMIN",
    ])
  ) {
    // only area activators, taskforce, or run leaders should see the
    // remaining status badges
    return null;
  } else if (runner.neverRun) {
    return icons.neverRun;
  } else {
    return icons.run;
  }
};

export const color = (
  runner: T.RunnerFragment,
  currentRunner?: Optional<T.CurrentRunnerFragment>
) => {
  if (u.roles.hasRole(runner, "TRAINER")) {
    return u.roles.colors.trainer;
  } else if (u.roles.hasRole(runner, "RUN_LEADER")) {
    return u.roles.colors.runLeader;
  } else if (u.roles.hasRole(runner, "TASK_FORCE")) {
    return u.roles.colors.taskForce;
  } else if (runner.verified) {
    return colors.verified;
  } else if (runner.isMember) {
    return colors.member;
  } else if (
    !u.roles.hasAnyRole(currentRunner, [
      "TRAINER",
      "RUN_LEADER",
      "TASK_FORCE",
      "CENTRAL_ADMIN",
    ])
  ) {
    // only area activators, taskforce, or run leaders should see the
    // remaining status badges
    return {};
  } else if (runner.neverRun) {
    return colors.neverRun;
  } else {
    return colors.run;
  }
};

export const colors = {
  neverRun: {
    backgroundColor: "outline.main",
    color: "white.main",
  },
  run: {
    backgroundColor: "white.main",
    color: "outline.main",
  },
  member: {
    backgroundColor: "white.main",
    color: "success.main",
    borderColor: "success.main",
  },
  verified: {
    backgroundColor: "white.main",
    color: "success.main",
    borderColor: "success.main",
  },
};

export const icons = {
  neverRun: <Icons.DoNotStep sx={colors.neverRun} />,
  run: <Icons.CheckCircle sx={colors.run} />,
  member: <Icons.CheckCircle sx={colors.member} />,
  verified: <Icons.VerifiedUser sx={colors.verified} />,
};
