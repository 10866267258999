/** @jsxImportSource @emotion/react */
import { useUnblockMutation } from "@goodgym/graphql/hooks";
import * as T from "@goodgym/graphql/types";
import * as UI from "@goodgym/components";
import * as u from "@goodgym/util";
import { useConfirm } from "@goodgym/hooks";

export type UnblockMutationParams = {
  runner: T.RunnerFragment & T.FollowStatusFragment;
};

export const useUnblock = ({
  runner,
}: UnblockMutationParams): React.MouseEventHandler => {
  const { confirm } = useConfirm();
  const [unblockMutation] = useUnblockMutation({
    onError: console.log,
    optimisticResponse: {
      response: {
        __typename: "UnblockMutationPayload",
        runners: [
          {
            __typename: "Runner",
            id: runner.id,
            followsYou: runner.followsYou,
            followedByYou: runner.followedByYou,
            blockedByYou: false,
          },
        ],
      },
    },
  });

  return (e) => {
    e?.preventDefault();

    confirm({
      title: `Unblock ${runner.name}`,
      text: (
        <>
          <UI.Typography sx={{ mb: 2 }}>
            Are you sure you want to unblock {runner.name}?
          </UI.Typography>
          <UI.Typography>
            This will allow {runner.name} to follow you on the website and view
            your profile and activity. You will no longer receive a notification
            if they register for a session you are signed up to.
          </UI.Typography>
        </>
      ),
      onConfirm: () =>
        unblockMutation({ variables: { runnerIds: [runner.id] } }),
    });
  };
};

export default useUnblock;
