/** @jsxImportSource @emotion/react */
import React from "react";
import * as Icons from "@goodgym/icons";
import * as UI from "@goodgym/components";
import * as T from "@goodgym/graphql/types";
import * as u from "@goodgym/util";
import _ from "lodash";

export type NavbarButtonProps = UI.ButtonProps<typeof UI.Link> & {
  id?: string;
  icon: any;
  label: string;
  active?: boolean;
  counter?: number;
};

const NavbarButton: React.FC<NavbarButtonProps> = ({
  icon: Icon,
  label,
  counter,
  active = false,
  ...props
}) => {
  return (
    <UI.Button
      variant="text"
      color="neutral"
      component={UI.Link}
      {...props}
      sx={{
        width: "90px",
        flexDirection: "column",
        ...props.sx,
      }}
    >
      <UI.MuiBadge badgeContent={counter} color="primary" sx={{ top: "4px" }}>
        <Icon
          sx={{
            height: "24px",
            width: "24px",
            color: active ? "primary.main" : "text.secondary",
          }}
        />
      </UI.MuiBadge>
      <UI.Typography
        variant="caption"
        color={active ? "primary" : "text.secondary"}
      >
        {label}
      </UI.Typography>
    </UI.Button>
  );
};

//@ts-ignore
NavbarButton.muiName = UI.Button.muiName;
export default NavbarButton;
