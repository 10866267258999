/** @jsxImportSource @emotion/react */
import React from "react";
import * as Icons from "@goodgym/icons";
import * as UI from "@goodgym/components";
import * as T from "@goodgym/graphql/types";
import * as u from "@goodgym/util";
import _ from "lodash";
import { useAuth } from "@goodgym/auth";
import { useUpdateArea } from "@goodgym/graphql/mutations";

export type AboutProps = UI.GridProps & {
  area: NonNullable<T.AreaPageQuery["area"]>;
};

const About: React.FC<AboutProps> = ({ area, ...props }) => {
  const { runner } = useAuth();
  const updateArea = useUpdateArea({ area });

  return (
    <UI.Grid container columnSpacing={5} {...props}>
      <UI.Grid item xs={12} sx={{ mt: 3 }}>
        <UI.EditableTextArea
          placeholder="Enter a description for this area"
          content={area.description}
          editable={u.roles.hasAreaAdmin(runner, area)}
          handleSave={(description) => updateArea({ description })}
        />
        <UI.Box sx={{ mt: 2 }}>
          <UI.Link href={u.links.about()}>Read more about GoodGym</UI.Link>
        </UI.Box>
        <UI.Divider sx={{ my: 3 }} />
      </UI.Grid>
      <UI.Grid item xs={12} md={6}>
        {area.areaActivator && (
          <>
            <UI.Typography variant="h5">Area activator</UI.Typography>
            <UI.Typography sx={{ mt: 1 }}>
              The area activator for {area.name} is{" "}
              <UI.RunnerLink runner={area.areaActivator} />.
            </UI.Typography>
            <UI.Box
              sx={{
                display: "flex",
                my: 2,
                alignItems: "flex-start",
                backgroundColor: "neutral.light",
                p: 2,
              }}
            >
              <UI.Box sx={{ flexGrow: 0, flexShrink: 0, mr: 2 }}>
                <UI.RunnerAvatar runner={area.areaActivator} size="large" />
              </UI.Box>
              <UI.Typography
                variant="body2"
                sx={{
                  flexGrow: 1,
                  whiteSpace: "pre-wrap",
                }}
              >
                {area.areaActivator.bio}
              </UI.Typography>
            </UI.Box>

            <UI.Typography>
              Area activators support the growth of GoodGym, are passionate
              about fitness and community, and are here to help us do more.
            </UI.Typography>
            <UI.Divider sx={{ my: 3 }} />
          </>
        )}

        {area.taskforce.filter((tf) => tf.slug !== "hidden").length > 0 && (
          <>
            
            <UI.Typography variant="h5">TaskForce</UI.Typography>
            <UI.Typography sx={{ mt: 1 }}>
              These brilliant GoodGymers make up the {area.name} TaskForce.
            </UI.Typography>

            <UI.Grid container rowSpacing={2} columnSpacing={3} sx={{ mt: 1 }}>
              {area.taskforce
                .filter((tf) => tf.slug !== "hidden")
                .map((runner) => (
                  <UI.Grid item key={runner.id}>
                    <UI.RunnerAvatar runner={runner} size="large" />
                  </UI.Grid>
                ))}
            </UI.Grid>
            <UI.Typography sx={{ mt: 4 }}>
              The TaskForce supports the operation of GoodGym in {area.name}.
              They manage social media accounts, support new GoodGymers and
              organise sessions.
            </UI.Typography>
          </>
        )}

        {area.runLeaders.filter((tf) => tf.slug !== "hidden").length > 0 && (
          <>
            <UI.Divider sx={{ my: 3 }} />
            <UI.Typography variant="h5">Run Leaders</UI.Typography>
            <UI.Typography sx={{ mt: 1 }}>
              GoodGym {area.name} group runs are led by these fantastic
              volunteer run leaders.
            </UI.Typography>

            <UI.Grid container rowSpacing={2} columnSpacing={3} sx={{ mt: 1 }}>
              {area.runLeaders
                .filter((tf) => tf.slug !== "hidden")
                .map((runner) => (
                  <UI.Grid item key={runner.id}>
                    <UI.RunnerAvatar runner={runner} size="large" />
                  </UI.Grid>
                ))}
            </UI.Grid>
            <UI.Typography sx={{ mt: 4 }}>
              GoodGym run leaders have completed the England Athletics{" "}
              <UI.Link href="https://www.englandathletics.org/coaching/qualifications/leadership-in-running-fitness/">
                Leadership in Running Fitness (LiRF)
              </UI.Link>{" "}
              training.
            </UI.Typography>
          </>
        )}
      </UI.Grid>
      <UI.Grid item xs={12} md={6}>
        <UI.Typography variant="h5">Contact us</UI.Typography>
        <UI.Typography sx={{ mt: 1 }}>
          You can <UI.Link href={`mailto:${area.email}`}> email </UI.Link>
          GoodGym {area.name}
          {area.areaActivator?.name
            ? `'s area activator ${area.areaActivator.name}`
            : ""}
          {(area.twitter || area.facebook || area.instagram) && (
            <>
              GoodGym {area.name} also has{" "}
              {u.text.toSentence(
                [
                  area.twitter && "twitter",
                  area.facebook && "facebook",
                  area.instagram && "instagram",
                ].filter(u.identity)
              )}
              .
            </>
          )}
        </UI.Typography>
        <UI.Box sx={{ mt: 1, mb: 3 }}>
          {area.email && (
            <UI.IconButton
              href={`mailto:${area.email}`}
              target="_blank"
              style={{ padding: 0, marginTop: "8px", marginRight: "8px" }}
            >
              <UI.Avatar sx={{ backgroundColor: "#7f7f7f" }}>
                <Icons.Email sx={{ color: "#fff" }} />
              </UI.Avatar>
            </UI.IconButton>
          )}
          {area.twitter && (
            <UI.IconButton
              href={area.twitter}
              target="_blank"
              style={{ padding: 0, marginTop: "8px", marginRight: "8px" }}
            >
              <UI.Avatar sx={{ backgroundColor: "#00aced" }}>
                <Icons.Twitter sx={{ color: "#fff" }} />
              </UI.Avatar>
            </UI.IconButton>
          )}
          {area.facebook && (
            <UI.IconButton
              href={area.facebook}
              target="_blank"
              style={{ padding: 0, marginTop: "8px", marginRight: "8px" }}
            >
              <UI.Avatar sx={{ backgroundColor: "#3b5998" }}>
                <Icons.Facebook sx={{ color: "#fff" }} />
              </UI.Avatar>
            </UI.IconButton>
          )}
          {area.instagram && (
            <UI.IconButton
              href={area.instagram}
              target="_blank"
              style={{ padding: 0, marginTop: "8px", marginRight: "8px" }}
            >
              <UI.Avatar sx={{ backgroundColor: "#e94475" }}>
                <Icons.Instagram sx={{ color: "#fff" }} />
              </UI.Avatar>
            </UI.IconButton>
          )}
        </UI.Box>
        <UI.Divider sx={{ my: 3 }} />
        <UI.Typography variant="h5">Request a task</UI.Typography>
        <UI.Typography sx={{ mt: 1 }}>
          If you're a community organisation and have a physical task then we'd
          love to help.{" "}
          <UI.Link href={u.links.requestATask(area)}>Request a task</UI.Link>{" "}
          and someone from GoodGym {area.name} will be in touch!
        </UI.Typography>
        <UI.Button
          variant="contained"
          color="primary"
          sx={{ mt: 2 }}
          href={u.links.requestATask(area)}
        >
          Request a task
        </UI.Button>
        <UI.Divider sx={{ my: 3 }} />
        <UI.Typography variant="h5">Want to get involved?</UI.Typography>

        <UI.Typography sx={{ mt: 1 }}>
          {!runner ? (
            <>
              <UI.Link href={u.links.signup()}>Sign up</UI.Link>, view{" "}
            </>
          ) : (
            "View "
          )}
          <UI.Link to={`/v3/areas/${area.slug}/sessions`}>
            upcoming sessions
          </UI.Link>{" "}
          or see{" "}
          <UI.Link to={`/v3/areas/${area.slug}/reports`}>
            what we've been up to recently!
          </UI.Link>
        </UI.Typography>
        {!runner && (
          <UI.Button
            variant="contained"
            href="/users/sign_up"
            color="primary"
            sx={{ mt: 2 }}
          >
            Join GoodGym
          </UI.Button>
        )}

        {area.partners.length > 0 && (
          <>
            <UI.Divider sx={{ my: 3 }} />
            {area.partners.map((partner) => (
              <UI.Box
                key={partner.id}
                sx={{
                  my: 2,
                  width: "100%",
                }}
              >
                <UI.Typography variant="body2" sx={{ mb: 2 }}>
                  {partner.description}
                </UI.Typography>
                <UI.Link href={partner.url} target="_blank">
                  <UI.Box
                    component="img"
                    src={partner.imageUrl}
                    alt={partner.name}
                    sx={{
                      maxHeight: "100px",
                      maxWidth: "100%",
                      display: "block",
                    }}
                  />
                </UI.Link>
              </UI.Box>
            ))}
          </>
        )}
      </UI.Grid>
    </UI.Grid>
  );
};

export default About;
