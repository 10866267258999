/** @jsxImportSource @emotion/react */
import React from "react";
import * as Icons from "@goodgym/icons";
import * as UI from "@goodgym/components";
import * as T from "@goodgym/graphql/types";
import * as u from "@goodgym/util";
import _ from "lodash";

export type WhatToLookOutForProps = UI.BoxProps & {
  session: Pick<
    T.SessionPageQuery["session"],
    | "area"
    | "ledBy"
    | "type"
    | "taskRequest"
    | "walkable"
    | "groupFitnessActivities"
    | "minDistance"
    | "maxDistance"
    | "risks"
  >;
};




const WhatToLookOutFor: React.FC<WhatToLookOutForProps> = ({
  session,
  ...props
}) => {

  const travelOptions = _.uniq(
    session.groupFitnessActivities.map((gfa) => gfa.type)
  );
  return (
    <UI.Box {...props}>
      <UI.Typography variant="h5" sx={{ mb: 2 }}>
        What to look out for
      </UI.Typography>

      {/* General guidance */}
      <UI.Typography sx={{ mb: 2 }}>
        Make sure you've read our{" "}
        <UI.Link href={u.links.guidelines()}>
          general guidelines for safety
        </UI.Link>{" "}
        for taking part in a GoodGym session.
      </UI.Typography>

      {session.risks.map((risk) => (
        <UI.Box
          key={risk.id}
          sx={{ display: "flex", alignItems: "flex-start", mb: 2 }}
        >
          <UI.Box
            src={risk.imageUrl}
            component="img"
            alt={risk.name}
            sx={{ width: "50px", mr: 1, mt: 1 }}
          />
          <UI.Box>
            <UI.Typography variant="subtitle2" sx={{ mb: 1 }}>
              {risk.name}
            </UI.Typography>
            <UI.Typography variant="body2">{risk.description}</UI.Typography>
          </UI.Box>
        </UI.Box>
      ))}

      {/* Group runs */}
      {session.type === "group run" && (
        <>
          <UI.Typography sx={{ mb: 2 }}>
            This is a GoodGym group run
            {session.walkable ? ", with the option of walking too. " : ". "}
            You'll meet other GoodGymers at the start location and run
            {session.walkable ? " or walk" : ""} to the task together. If{" "}
            {session.walkable
              ? ""
              : "you can't run for more than 15 minutes without stopping or "}
            you're worried about any aspect of the session, contact{" "}
            {session.area?.areaActivator ? (
              <>
                your area activator{" "}
                <UI.RunnerLink runner={session.area.areaActivator} />
              </>
            ) : (
              "us"
            )}
            {" by email at "}
            <UI.Link
              href={`mailto:${
                session.area?.email || "getinvolved@goodgym.org"
              }`}
            >
              {session.area?.email || "getinvolved@goodgym.org"}
            </UI.Link>
            .
          </UI.Typography>
          {(session.minDistance || session.maxDistance) && (
            <>
              {session.minDistance !== session.maxDistance ? (
                <UI.Typography sx={{ mb: 2 }}>
                  Options are available to run between{" "}
                  <strong>{session.minDistance}km</strong> and{" "}
                  <strong>{session.maxDistance}km</strong> in total.
                </UI.Typography>
              ) : (
                <UI.Typography sx={{ mb: 2 }}>
                  The total distance of this group run is{" "}
                  <strong>
                    {session.maxDistance}
                    km
                  </strong>
                  .
                </UI.Typography>
              )}
              <UI.Typography sx={{ mb: 2 }}>
                Remember that we will be{" "}
                {session.walkable ? "running or walking" : "running"} to and
                from the task, so you'll only be{" "}
                {session.walkable ? "running or walking" : "running"} for half
                this distance in one go.
              </UI.Typography>
            </>
          )}
        </>
      )}

      {/* Community Missions */}
      {session.type === "community mission" && (
        <UI.Typography sx={{ mb: 2 }}>
          This is a GoodGym community mission.{" "}
          {travelOptions.length === 0
            ? "When we're doing a community mission we make our own way to the task, so you can choose to walk, run, or cycle."
            : `There ${
                session.groupFitnessActivities.length > 1 ? "are" : "is an"
              } ${u.inflect(
                "option",
                session.groupFitnessActivities.length
              )} to meet other GoodGymers and ${u.text.toSentence(
                travelOptions.map((o) => o.toLocaleLowerCase()),
                "or"
              )} to the task.`}{" "}
          Thinking of cycling - check out{" "}
          <UI.Link
            href={
              "https://goodgym.kayako.com/article/27-can-i-cycle-at-goodgym-sessions"
            }
          >
            our FAQs
          </UI.Link>
        </UI.Typography>
      )}

      {/* TaskForce-led sessions */}
      {(session.type === "community mission" ||
        session.type === "training session") &&
        u.roles.hasRole(session.ledBy, "TASK_FORCE") && (
          <UI.Typography sx={{ mb: 2 }}>
            This session is organised by your Taskforce, a group of experienced
            GoodGym members in your area. They are not responsible for your
            safety. You should feel confident to participate in the session as
            it is described and you do so at your own risk.
          </UI.Typography>
        )}
      {session.type === "training session" && (
        <UI.Typography sx={{ mb: 2 }}>
          Cycling at this training session? Check out our FAQs on cycling:
          <UI.Link
            href={
              "https://goodgym.kayako.com/article/27-can-i-cycle-at-goodgym-sessions"
            }
          >
            our FAQs
          </UI.Link>
        </UI.Typography>
      )}

      {/* Races */}
      {session.type === "race" && (
        <UI.Typography sx={{ mb: 2 }}>
          This event is not organised by GoodGym and GoodGym takes no
          responsibility for your participation in the event.
        </UI.Typography>
      )}

      {/* Community mission with risks */}

      {/* Risky mission */}
      {session.type === "mission" && session.taskRequest?.risky === "yes" && (
        <>
          <UI.Typography sx={{ mb: 2 }}>
            This mission was requested by a referrer who has noted some risks of
            visiting {session.taskRequest.beneficiary.name}. It is essential
            that you take time to understand the risks that their behaviour or
            environment may pose to you.
          </UI.Typography>

          <UI.Typography sx={{ mb: 2 }}>
            To make sure you are safe, please ensure you have read the notes
            from the referrer and that you are confident in your{" "}
            <UI.Link href={u.links.training.summary("missions-training")}>
              missions training
            </UI.Link>
            . Remember that the risks outlined by the referrer may not be the
            only risks.
          </UI.Typography>
        </>
      )}

      {/* No risks identified */}
      {session.type === "mission" && session.taskRequest?.risky === "no" && (
        <>
          <UI.Typography sx={{ mb: 2 }}>
            This mission was from a referrer who states that they know
            {session.taskRequest.beneficiary.name}'s behaviour and environment
            enough to say that they do not pose a risk to you.
          </UI.Typography>
          <UI.Typography sx={{ mb: 2 }}>
            No mission is ever completely safe; to make sure you are safe,
            please ensure you are confident in your{" "}
            <UI.Link href={u.links.training.summary("missions-training")}>
              missions training
            </UI.Link>
            . It is possible that {session.taskRequest.beneficiary.name}'s
            situation may have changed or that the referrer has not identified
            every potential risk.
          </UI.Typography>
        </>
      )}

      {/* Risks unknown */}
      {session.type === "mission" && session.taskRequest?.risky === "unknown" && (
        <UI.Typography sx={{ mb: 2 }}>
          This mission was from a referrer with little or no knowledge of
          {session.taskRequest.beneficiary.name}. This means that their
          behaviour or environment may pose significant risks to you. To make
          sure you are safe, please ensure you are confident in your{" "}
          <UI.Link href={u.links.training.summary("missions-training")}>
            missions training
          </UI.Link>
          .
        </UI.Typography>
      )}

      {/* Missing TaskReferral */}
      {session.type === "mission" && !session.taskRequest && (
        <>
          <UI.Typography sx={{ mb: 2 }}>
            This referral is from an organisation that works with
            older/vulnerable adults. GoodGym has not risk assessed the
            environment you are going into. You must use your own judgement and
            our guidance to ensure you are safe.
          </UI.Typography>
          <UI.Typography sx={{ mb: 2 }}>
            By signing up to this mission you agree that you have read the
            potential risks outlined by GoodGym and accept that and agree that
            you will not hold us responsible for any accidents or injuries that
            occur.
          </UI.Typography>
          <UI.Typography sx={{ mb: 2 }}>
            Please also ensure you are confident in your{" "}
            <UI.Link href={u.links.training.summary("missions-training")}>
              missions training
            </UI.Link>
            .
          </UI.Typography>
        </>
      )}

      {/* Mission email sharing */}
      {session.type === "mission" && (
        <UI.Typography sx={{ mb: 2 }}>
          By signing up to this mission, you agree for GoodGym to share your
          email address with other runners signed up to the mission.
        </UI.Typography>
      )}
    </UI.Box>
  );
};

export default WhatToLookOutFor;
