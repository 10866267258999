/** @jsxImportSource @emotion/react */
import React from "react";
import * as Icons from "@goodgym/icons";
import * as UI from "@goodgym/components";
import * as T from "@goodgym/graphql/types";
import * as u from "@goodgym/util";
import _ from "lodash";

export type AddressSearchResultDialogProps = UI.DialogProps & {
  address: T.AddressSearchResultFragment;
  onClose: () => any;
};

const AddressSearchResultDialog: React.FC<
  React.PropsWithChildren<AddressSearchResultDialogProps>
> = ({ address, onClose, ...props }) => {
  return (
    <UI.Dialog {...props} open={true} maxWidth="sm" fullWidth>
      <UI.DialogTitle>Address</UI.DialogTitle>
      <UI.Box position="absolute" top={8} right={8}>
        <UI.IconButton onClick={onClose}>
          <Icons.Close />
        </UI.IconButton>
      </UI.Box>
      <UI.DialogContent>
        <UI.Box sx={{ display: "flex" }}>
          <UI.Box sx={{ flexGrow: 1, whiteSpace: "pre-wrap" }}>
            {u.text.compositeAddress(address, { multiline: true })}
          </UI.Box>
          <UI.Box sx={{ flexShrink: 0 }}>
            {address.area ? (
              <UI.AreaChip area={address.area} />
            ) : (
              <UI.Chip
                size="small"
                label="Not in goodgym area"
                sx={{
                  borderRadius: 1,
                  height: 24,
                  ...props.sx,
                  verticalAlign: "baseline",
                  backgroundColor: "neutral.light",
                  fontWeight: "bold",
                }}
              />
            )}
          </UI.Box>
        </UI.Box>
      </UI.DialogContent>
      <UI.DialogActions sx={{ mb: 1, mr: 1 }}>
        <UI.Button color="neutral" variant="text" onClick={onClose}>
          Close
        </UI.Button>
        {address.area && (
          <UI.Button
            color="secondary"
            variant="contained"
            to={u.links.area(address.area)}
            component={UI.Link}
            sx={{ ml: 1 }}
          >
            Go to area page
          </UI.Button>
        )}
      </UI.DialogActions>
    </UI.Dialog>
  );
};

export default AddressSearchResultDialog;
