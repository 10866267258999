/** @jsxImportSource @emotion/react */
import React from "react";
import * as Icons from "@goodgym/icons";
import * as UI from "@goodgym/components";
import * as T from "@goodgym/graphql/types";
import * as u from "@goodgym/util";
import * as C from "./components";
import _ from "lodash";
import { useAuth } from "@goodgym/auth";
import { useCreateComment } from "@goodgym/graphql/mutations";
import useExpandableComments from "./hooks/useExpandableComments";

export type CommentsProps = UI.BoxProps & {
  commentable: T.CommentableItem;
};

const Comments: React.FC<CommentsProps> = ({ commentable, ...props }) => {
  const { runner } = useAuth();

  const { top, bottom, expand } = useExpandableComments({
    items: commentable.comments,
  });

  const [content, setContent] = React.useState("");

  const createComment = useCreateComment({
    runner,
    commentable,
    content,
    setContent,
  });

  const onKeyDown: React.KeyboardEventHandler = (e) => {
    if (e.key === "Enter" && !e.shiftKey) {
      createComment(e);
    }
  };

  return (
    <UI.Box {...props}>
      {_.slice(commentable.comments, 0, top).map((c) => (
        <C.Comment
          key={c.id}
          comment={c}
          commentable={commentable}
          runner={runner}
        />
      ))}

      {top < commentable.comments.length && (
        <UI.Box
          sx={{
            mb: 1,
            ml: 7,
            mr: 1,
            textAlign: "center",
          }}
        >
          <UI.Button
            variant="outlined"
            fullWidth
            color="neutral"
            onClick={expand}
          >
            <Icons.UnfoldMore /> More
          </UI.Button>
        </UI.Box>
      )}

      {_.slice(commentable.comments, bottom).map((c) => (
        <C.Comment
          key={c.id}
          comment={c}
          commentable={commentable}
          runner={runner}
        />
      ))}

      {runner ? (
        <UI.Box sx={{ display: "flex", my: 1, py: 1 }}>
          <UI.Box sx={{ alignSelf: "top", flexShrink: 0, mr: 1, pt: "4px" }}>
            <UI.RunnerAvatar runner={runner} size="medium" />
          </UI.Box>
          <UI.TextField
            sx={{ width: "100%", ml: 1 }}
            multiline={true}
            maxRows={4}
            placeholder="Write a comment..."
            onChange={(e) => setContent(e.target.value)}
            onKeyDown={onKeyDown}
            value={content}
          />
        </UI.Box>
      ) : (
        <UI.Box sx={{ mb: 3 }}>
          <UI.SignInButton variant="text">Sign in to comment</UI.SignInButton>
        </UI.Box>
      )}
    </UI.Box>
  );
};

export default Comments;
