export {
  formatDay as day,
  formatDate as date,
  formatTime as time,
  formatMonth as month,
  formatDateTime as dateTime,
  formatDuration as duration,
  formatDayOfWeek as dayOfWeek,
  formatDayOfMonth as dayOfMonth,
} from "./time";

export const postcode = (input: Optional<string>) => {
  if (!input) return "";
  const pc = input.replace(/\s+/g, "").toUpperCase();
  if (pc.length > 7 || pc.length < 5) {
    // invalid, can't format
    return "";
  } else {
    return [
      pc.slice(0, pc.length - 3),
      " ",
      pc.slice(pc.length - 3, pc.length),
    ].join("");
  }
};
