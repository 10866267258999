/** @jsxImportSource @emotion/react */
import React from "react";
import { useFollowMutation } from "@goodgym/graphql/hooks";
import * as T from "@goodgym/graphql/types";
import * as UI from "@goodgym/components";
import * as u from "@goodgym/util";

export type FollowMutationHook = () => (
  runner: T.RunnerFragment & T.FollowStatusFragment
) => (message: string) => any;

export const useFollow: FollowMutationHook = () => {
  const [follow] = useFollowMutation({
    onError: console.log,
  });

  return (runner) => (message) => {
    follow({
      variables: { runnerIds: [runner.id], message },
      optimisticResponse: {
        response: {
          __typename: "FollowMutationPayload",
          runners: [
            {
              __typename: "Runner",
              id: runner.id,
              followsYou: runner.followsYou,
              followedByYou: true,
              blockedByYou: false,
            },
          ],
        },
      },
    });
  };
};
