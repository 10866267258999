/** @jsxImportSource @emotion/react */
import React from "react";
import * as Icons from "@goodgym/icons";
import * as UI from "@goodgym/components";
import * as T from "@goodgym/graphql/types";
import * as u from "@goodgym/util";
import _ from "lodash";

export type MegaMenuItemProps = {
  icon: any;
  label: string;
  href?: string;
  to?: string;
};

const MegaMenuItem: React.FC<MegaMenuItemProps> = ({
  icon,
  label,
  ...props
}) => {
  return (
    <UI.ListItem
      sx={{
        px: 0,
        borderBottom: "1px solid #efefef",
      }}
    >
      <UI.ListItemButton
        disableGutters
        component={UI.Link}
        {...props}
        sx={{ pr: 1 }}
      >
        <UI.ListItemIcon>{icon}</UI.ListItemIcon>
        <UI.ListItemText sx={{ color: "neutral.dark" }} primary={label} />
      </UI.ListItemButton>
    </UI.ListItem>
  );
};

export default MegaMenuItem;
