/** @jsxImportSource @emotion/react */
import { useRequestTestPairingMutation } from "@goodgym/graphql/hooks";
import * as T from "@goodgym/graphql/types";
import { useConfirm } from "@goodgym/hooks";
import {
  daysOfWeek,
  timeIntervals,
} from "@goodgym/pages/SocialVisitPage/components/RequestPairing/AvailabilityForm/helper";
import _ from "lodash";
import { useNavigate } from "react-router-dom";
export type RequestTestPairingMutationProps = (params: {
  coachReferral: T.CoachReferralFragment;
  runnerSuggestedTime: { day: String; from: String; to: String }[];
  runner?: Optional<T.CurrentRunnerFragment>;
  previousHadTestPairing?:boolean
}) => React.MouseEventHandler;

export const useRequestTestPairing: RequestTestPairingMutationProps = ({
  coachReferral,
  runnerSuggestedTime,
  previousHadTestPairing
}) => {
  const { confirm } = useConfirm();

  //Validate the requested time. Validation fails if they do not input all the required fields
  const validateSubmittedTimes = runnerSuggestedTime.map((array) => {
    const result =
      _.includes(daysOfWeek, array.day) &&
      _.includes(timeIntervals, array.from) &&
      _.includes(timeIntervals, array.to);
    return result;
  });

  const navigate = useNavigate();

  const [RequestTestPairing] = useRequestTestPairingMutation({
    onError: console.log,
    variables: {
      coachId: coachReferral.id,
      runnerSuggestedTimes: runnerSuggestedTime,
    },
    optimisticResponse: {
      response: {
        __typename: "RequestTestPairingMutationPayload",
        testPairing: {
          __typename: "TestPairing",
          acceptedAt: null,
          completedAt: null,
          id: coachReferral.id,
          notesForRunner: null,
          rejectedAt: null,
          rejectionNotes: null,
          scheduledAt: null,
          runnerVisitFeedback: null,
          runnerVisitNotes: null,
          runnerWantsPairing: null,
          cancelledAt: null,
          cancellationNotes:null,
          coach: {
            ...coachReferral,
          },
        },
        errors: [],
      },
    },
  });
  return (e) => {
    e?.preventDefault();
    if (validateSubmittedTimes.includes(false)) {
      confirm({
        title: "Invalid time",
        text: "Please make sure you have entered valid time",
        onConfirm: () => {},
        alert: true,
      });
    } else {
      confirm({
        title:  "Requesting a trial visit",
        text: previousHadTestPairing ? "It looks like you already requested a trial visit with this person, are you sure you want to proceed?" : "Are you sure you want to request a trial visit?" , 
        alert: false,
        onConfirm: async() => {
          let result = await RequestTestPairing();
          if (result.data?.response.errors?.length) {
            console.log("error", result.data?.response.errors[0]);
            alert(result.data?.response.errors[0]);
          } 
          else {
            navigate(`/v3/trial-visits/${result.data?.response.testPairing?.id}`);
          }
        },
      });
    }
  };;
};
