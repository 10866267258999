import { createTheme } from "@mui/material";
import createCache from "@emotion/cache";
import "./types";

export const cache = () => createCache({ key: "css" });
export const v = {};
export { default as global } from "./global";

export const theme = createTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: 576,
      md: 768,
      lg: 1200,
      xl: 1600,
    },
  },
  palette: {
    primary: { main: "#E11018", dark: "#B20000", light: "#FF5F4E" },
    secondary: { main: "#8EB0F9", dark: "#5477A9", light: "#DDE8FE" },
    neutral: {
      main: "#858585",
      dark: "#6C757D",
      light: "#F5F5F5",
    },
    outline: { main: "rgba(0, 0, 0, 0.22)" },
    white: { main: "#FFF" },
    black: { main: "#333" },
    success: { main: "#006B52" },
    error: { main: "#B20000" },
    warning: { main: "#EB9B00" },
  },
  typography: {
    h1: {
      fontFamily: "'Barlow', sans-serif",
      fontSize: 48,
      fontWeight: "bold",
      fontStyle: "italic",
      textTransform: "uppercase",
    },
    h2: {
      fontFamily: "'Barlow', sans-serif",
      fontSize: 34,
      fontWeight: "bold",
    },
    h3: {
      fontFamily: "'Barlow', sans-serif",
      fontSize: 24,
      textTransform: "uppercase",
      fontWeight: "bold",
      fontStyle: "italic",
    },
    h4: {
      fontFamily: "'Barlow', sans-serif",
      fontSize: 24,
      fontWeight: "bold",
    },
    h5: {
      fontFamily: "'Barlow', sans-serif",
      fontSize: 20,
      fontWeight: "bold",
    },
    h6: {
      fontFamily: "'Barlow', sans-serif",
      fontSize: 20,
      fontWeight: "bold",
      textTransform: "uppercase",
      fontStyle: "italic",
    },
    subtitle1: {
      fontFamily: "'Barlow', sans-serif",
      fontSize: 16,
      lineHeight: "24px",
      fontWeight: "bold",
    },
    subtitle2: {
      fontFamily: "'Barlow', sans-serif",
      fontSize: 14,
      fontWeight: "bold",
    },
    body1: {
      fontFamily: "'Barlow', sans-serif",
      fontSize: 16,
    },
    body2: {
      fontFamily: "'Barlow', sans-serif",
      lineHeight: "24px",
      fontSize: 14,
    },
    button: {
      fontFamily: "'Barlow', sans-serif",
      fontSize: 16,
      fontWeight: "bold",
      textTransform: "none",
    },
    caption: {
      fontFamily: "'Barlow', sans-serif",
      fontSize: 12,
    },
    overline: {
      fontFamily: "'Barlow Condensed', sans-serif",
      fontSize: 10,
      textTransform: "uppercase",
    },
  },
  components: {
    MuiDivider: {
      styleOverrides: { root: { borderColor: "#dadada" } },
    },
    MuiTextField: { defaultProps: { color: "secondary" } },
    MuiCheckbox: { defaultProps: { color: "secondary" } },
    MuiSelect: { defaultProps: { color: "secondary" } },
    MuiDialogTitle: {
      styleOverrides: {
        root: { paddingRight: "60px" },
      },
    },
    MuiDrawer: {
      styleOverrides: {
        paper: { width: "100vw" },
      },
    },
    MuiListItemIcon: {
      styleOverrides: {
        root: {
          minWidth: "32px",
          fontSize: "20px",
        },
      },
    },
    MuiButton: {
      defaultProps: {
        variant: "contained",
      },
    },
    MuiAvatar: {
      styleOverrides: {
        root: { textDecoration: "none" },
      },
    },
    MuiLink: {
      styleOverrides: {
        root: {
          cursor: "pointer",
          textDecoration: "none",
          ":hover": {
            textDecoration: "underline",
          },
        },
      },
    },
    MuiInputBase: {
      styleOverrides: {
        root: { backgroundColor: "#fff" },
      },
    },
    MuiTooltip: {
      defaultProps: {
        enterTouchDelay: 0,
      },
      styleOverrides: {
        tooltip: {
          color: "#333",
          backgroundColor: "#fff",
          boxShadow: "0 0 8px rgba(0, 0, 0, 0.22)",
        },
      },
    },
    MuiCardMedia: {
      styleOverrides: {
        root: {
          height: 0,
          paddingTop: "56.25%",
          position: "relative",
        },
      },
    },
    MuiSvgIcon: {
      defaultProps: { fontSize: "inherit" },
      styleOverrides: {
        root: {
          verticalAlign: "top",
        },
      },
    },
    MuiChip: {
      styleOverrides: {
        root: {
          fontFamily: "'Barlow', sans-serif",
          lineHeight: "24px",
          fontSize: 14,
        },
      },
    },
    MuiToggleButton: {
      styleOverrides: {
        root: { fontSize: 16, borderColor: "#c2c2c2" },
      },
    },
  },
});

export default theme;
