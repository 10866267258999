import * as UI from "@goodgym/components";
import { styled as Styled } from "@mui/system";

export type HeaderProps = { title: string };

const HeaderContainer = Styled(UI.Box)`
    display:flex;
    height:100%;
    width:100%;
     @media (max-width:576px){
     background: rgb(51, 51, 51);
    }
`;
const HeaderTitle = Styled(UI.Container)`
    height:180px;
    width:60%; 
    position:relative; 
   
    @media (min-width: 576px) {
      &:after {
      content: "";
      background-image: linear-gradient(to right top, transparent, transparent 49%, rgb(50, 50, 50) 49%, rgb(50, 50, 50));
      display: block;
      width: 7.5rem;
      height: 100%;
      position: absolute;
      top: 0px;
      right: 0px;
    }
  }
`;
const LandingHeader = Styled(UI.Container)`
  @media (max-width: 576px){
    display:none;
  }
    height:180px;
    width:40%;
    background: rgb(51, 51, 51);
    position:relative;

`;

const Title = Styled(UI.Typography)`
   margin-top:3rem;
    width:80%;
    @media (max-width: 576px){
    width:100%;
  }
`;

const Header: React.FC<HeaderProps> = ({ title }) => {
  return (
    <HeaderContainer>
      <HeaderTitle>
        <Title
          sx={{
            color: { xs: "#FFF", sm: "#000" },
            typography: { md: "h1", sm: "h2", xs: "h4" },
            textAlign: "center",
          }}
          variant="h1"
        >
          {title}
        </Title>
      </HeaderTitle>
      <LandingHeader />
    </HeaderContainer>
  );
};

export default Header;
