/** @jsxImportSource @emotion/react */
import * as UI from "@goodgym/components";
import * as T from "@goodgym/graphql/types";
import * as u from "@goodgym/util";
import React from "react";

import { useSignUpToSession } from "@goodgym/graphql/mutations";

export type SummaryProps = {
  onClose: React.MouseEventHandler;
  session: T.SessionCardFragment & { risks: T.SessionRiskFragment[] };
  gfa?: Optional<
    T.GroupFitnessActivityFragment & {
      ledBy: T.RunnerFragment;
      signups: T.RunnerFragment[];
    }
  >;
  taskforceRoles: T.TaskforceRoleFragment[];
};

const Summary: React.FC<React.PropsWithChildren<SummaryProps>> = ({
  onClose,
  session,
  gfa,
  taskforceRoles,
}) => {
  const signUpToSession = useSignUpToSession({
    session,
    gfa,
    taskforceRoles,
  });

  return (
    <>
      <UI.DialogTitle>
        You're signing up to
        <UI.CloseButton onClick={onClose} />
      </UI.DialogTitle>
      <UI.DialogContent sx={{ mb: 0, pb: 0 }}>
        <UI.Typography variant="subtitle1">{session.title}</UI.Typography>
        <UI.Box
          sx={{ float: "right", width: "200px", ml: 2, mb: 2 }}
          component="img"
          src={session.imageUrl}
        />
        <UI.Typography variant="body2" color="neutral.main">
          {u.time.formatDateTime(session.startedAt)}
        </UI.Typography>
        {gfa && (
          <UI.Typography variant="body2" sx={{ my: 3 }}>
            You're going to {gfa.type.toLocaleLowerCase()} {gfa.distance}km from{" "}
            {session.address ? (
              <UI.Link
                href={u.geo.directionsLink(gfa.address, session.address)}
              >
                {gfa.address.line1}
              </UI.Link>
            ) : (
              gfa.address.line1
            )}{" "}
            with <UI.RunnerLinks runners={gfa.signups} />, starting at{" "}
            {u.time.formatTime(gfa.startedAt)}.
          </UI.Typography>
        )}
        {taskforceRoles.length > 0 && (
          <UI.Typography variant="body2" sx={{ my: 3 }}>
            You've volunteered to take on the role of{" "}
            {u.text.toSentence(taskforceRoles.map((r) => r.name))}. Nice one!
          </UI.Typography>
        )}
        <UI.Typography variant="body2" sx={{ my: 2 }}>
          Make sure you've read our{" "}
          <UI.Link href={u.links.guidelines()}>
            general guidelines for safety
          </UI.Link>{" "}
          for taking part in a GoodGym session.
        </UI.Typography>

        {session.risks.map((risk) => (
          <UI.Box
            key={risk.id}
            sx={{ display: "flex", alignItems: "flex-start", mb: 2 }}
          >
            <UI.Box
              src={risk.imageUrl}
              component="img"
              alt={risk.name}
              sx={{ width: "50px", mr: 1, mt: 1 }}
            />
            <UI.Box>
              <UI.Typography variant="subtitle2" sx={{ mb: 1 }}>
                {risk.name}
              </UI.Typography>
              <UI.Typography variant="body2">{risk.description}</UI.Typography>
            </UI.Box>
          </UI.Box>
        ))}

        <UI.Typography variant="body2" sx={{ my: 2 }}>
          By signing up to this event you confirm you are medically fit to
          participate and accept that GoodGym and its members, or those
          operating on its behalf, will not be held liable for any loss,
          accident or injury, before, during or resulting from the event.
        </UI.Typography>
      </UI.DialogContent>
      <UI.DialogActions sx={{ mt: 0, pt: 0, m: 2 }}>
        <UI.Button variant="text" color="neutral" fullWidth onClick={onClose}>
          Cancel
        </UI.Button>
        <UI.Button
          color="secondary"
          fullWidth
          onClick={async (e) => {
            await signUpToSession();
            onClose(e);
          }}
        >
          Complete signup
        </UI.Button>
      </UI.DialogActions>
    </>
  );
};

export default Summary;
