import * as UI from "@goodgym/components";

export type MidBannerProps = {
  numberOfDeeds?: number;
  badge?: string;
  subtitle?: string;
};
const MidBanner: React.FC<MidBannerProps> = ({
  numberOfDeeds,
  badge,
  subtitle,
}) => {
  return (
    <>
    
      <UI.Box
        sx={{
          bgcolor: "black.main",
          minHeight: "400px",
          textAlign: "center",
          position: "relative",
          pt: 3,
          mt: 9,
          pb: 9,
          px: 1,
        }}
      >
        <img
          src={badge}
          alt="badge"
          style={{ height: "160px", width: "auto" }}
        />
        <UI.Typography
          variant="h1"
          color="neutral.light"
          sx={{ textTransform: "uppercase", fontColor: "white" }}
        >
          {numberOfDeeds?.toLocaleString()}
        </UI.Typography>
        <UI.Typography
          variant="h1"
          color="neutral.light"
          sx={{ textTransform: "uppercase" }}
        >
          {subtitle}
        </UI.Typography>
      </UI.Box>
    </>
  );
};

export default MidBanner;
