/** @jsxImportSource @emotion/react */
import React from "react";
import * as Icons from "@goodgym/icons";
import * as UI from "@goodgym/components";
import * as T from "@goodgym/graphql/types";
import * as u from "@goodgym/util";
import * as csrf from "@goodgym/csrf";
import _ from "lodash";

export type FormProps = React.PropsWithChildren<{
  action: string;
  method?: string;
}>;

const Form: React.FC<FormProps> = ({ action, method = "post", children }) => {
  const csrfToken = csrf.useCSRFToken();
  return (
    <form action={action} method={method}>
      <input type="hidden" name="authenticity_token" value={csrfToken} />
      {children}
    </form>
  );
};

export default Form;
