/** @jsxImportSource @emotion/react */
import * as UI from "@goodgym/components";
import { useAreaSearchQuery } from "@goodgym/graphql/types";
import React, { useEffect } from "react";
import { State } from "../../../types";

export type AreasFilterProps = UI.BoxProps & {
  state: Pick<State, "areas" | "sections" | "filters" | "runner">;
};

const AreasFilter: React.FC<AreasFilterProps> = ({ state, ...props }) => {
  const { filters, runner } = state;

  const { data } = useAreaSearchQuery({
    variables: {},
  });

  const initialSelectedValue = () => {
    switch (state.filters.values.areaIds.length) {
      case 0:
        return "all";
      case 1:
        return state.filters.values.areaIds[0];
      default:
        return "your";
    }
  };
  const [areaSelected, setAreaSelected] = React.useState(
    initialSelectedValue()
  );


  const runnerAreas = runner?.areas?.map((a) => a.id) || [];
  useEffect(() => {
    const handleBeforeUnload = () => {
      localStorage.clear(); // Clear localStorage only on page refresh
    };

    // Retrieve sessionArea from localStorage and parse it
    const sessionArea = localStorage.getItem("sessionArea");
    const sessionType = localStorage.getItem("sessionType");

    const parsedSessionArea =
      sessionArea && sessionArea !== "your" && sessionArea !== "all"
        ? JSON.parse(sessionArea)
        : null;

    const parsedSessionType =
      sessionType && sessionType !== "all" && sessionType !== "Group Sessions"
        ? sessionType
        : null;
    /* this makes the session type work ! Not sure if the Area filter still works although I think it does so maybe have a look at filter.update.
I wonder if uodated AreaIds here resets the sessiontype to its default value? Good luck
*/
    if (parsedSessionArea && parsedSessionType !== null) {
      filters.update({
        areaIds: parsedSessionArea,
        types: [parsedSessionType],
      });
      setAreaSelected(parsedSessionArea);
    } else if (parsedSessionArea !== null && parsedSessionType === null) {
      filters.update({
        areaIds: parsedSessionArea,
      });
    }
      else if (parsedSessionArea === null && parsedSessionType !== null) {
      filters.update({
        types: [parsedSessionType],
      });
      setAreaSelected(parsedSessionArea); // Ensure this line is consistent with logic
    } else {
      filters.update({ areaIds: runnerAreas });
    }

    // Add event listener for page unload
    window.addEventListener("beforeunload", handleBeforeUnload);

    // Cleanup event listener on component unmount
    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <UI.Box {...props}>
      <UI.Typography variant="subtitle1">
        <UI.Grid container>
          <UI.Grid item sx={{ alignSelf: "center", flexGrow: 1, mb: 1 }}>
            Areas
          </UI.Grid>
        </UI.Grid>
      </UI.Typography>

      <UI.Box sx={{ minWidth: 120 }}>
        <UI.FormControl fullWidth>
          <UI.InputLabel id="area-select-label">Areas</UI.InputLabel>

          <UI.Select
            id="area-select-filter"
            label="Area"
            onChange={(event) => {
              const value = (): Array<string> => {
                switch (event.target.value) {
                  case "all":
                    return [];
                  case "your":
                    return runnerAreas;
                  default:
                    return [event.target.value];
                }
              };

              filters.update({ areaIds: value() });
              localStorage.setItem("sessionArea", event.target.value);
              setAreaSelected(event.target.value);
            }}
            value={areaSelected}
          >
            <UI.MenuItem value="all">All Areas</UI.MenuItem>
            <UI.MenuItem value="your">Your Areas</UI.MenuItem>
            {data?.areas.map((area) => (
              <UI.MenuItem key={area.id} value={area.id} aria-label={area.name}>
                {area.name}
              </UI.MenuItem>
            ))}
          </UI.Select>
        </UI.FormControl>
      </UI.Box>
    </UI.Box>
  );
};

export default AreasFilter;
