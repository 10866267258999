/** @jsxImportSource @emotion/react */
import React from "react";
import * as Icons from "@goodgym/icons";
import * as UI from "@goodgym/components";
import * as T from "@goodgym/graphql/types";
import * as u from "@goodgym/util";
import _ from "lodash";
import { Helmet } from "react-helmet-async";

export type NotFoundProps = {
  notAuthorized?: boolean;
};

const NotFound: React.FC<NotFoundProps> = ({ notAuthorized }) => {
  return (
    <UI.Backdrop
      open={true}
      sx={{ textAlign: "center", backgroundColor: "neutral.main" }}
    >
      <Helmet>
        <meta name="robots" content="noindex" />
      </Helmet>
      <UI.Box>
        <Icons.ErrorOutline sx={{ height: "100px", width: "100px", mb: 2 }} />
        {notAuthorized ? (
          <>
            {" "}
            <UI.Typography variant="h1">Not Authorized</UI.Typography>
            <UI.Box>
              <UI.Typography variant="h5" sx={{ my: 2 }}>
                You are not authorized to view this page.
              </UI.Typography>
            </UI.Box>
          </>
        ) : (
          <>
            <UI.Typography variant="h1">Not found</UI.Typography>
            <UI.Box>
              <UI.Typography variant="h5" sx={{ my: 2 }}>
                We couldn't find the page you've asked for.
              </UI.Typography>
            </UI.Box>
          </>
        )}
        <UI.Box>
          <UI.Typography variant="h5" sx={{ my: 2 }}>
            <UI.Link
              to={u.links.home()}
              color="black.main"
              sx={{ textDecoration: "underline" }}
            >
              Back to homepage
            </UI.Link>
          </UI.Typography>
        </UI.Box>
      </UI.Box>
    </UI.Backdrop>
  );
};

export default NotFound;
