/** @jsxImportSource @emotion/react */
import * as UI from "@goodgym/components";
import * as Icons from "@goodgym/icons";
import * as u from "@goodgym/util";
import React from "react";
import { useLocation } from "react-router-dom";

export type SessionsProps = {};

const Sessions: React.FC<SessionsProps> = ({}) => {
  const { pathname } = useLocation();
  const isActiveSessions = pathname.startsWith("/v3/sessions");


  return (
    <UI.NavbarButton
      icon={Icons.Event}
      label="Sessions"
      active={isActiveSessions}
      to={u.links.sessions()}
    />
  );
};

export default Sessions;
