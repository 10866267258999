/** @jsxImportSource @emotion/react */
import React from "react";
import * as Icons from "@goodgym/icons";
import * as UI from "@goodgym/components";
import * as T from "@goodgym/graphql/types";
import * as u from "@goodgym/util";
import * as C from "..";
import _ from "lodash";

export type AwardProps = { award: T.NewsfeedAwardFragment };

const Award: React.FC<AwardProps> = ({ award }) => {
  return (
    <UI.Card>
      <UI.CardHeader
        title={
          <UI.Typography variant="subtitle1">
            <UI.RunnerLink runner={award.runner} /> {award.badge.description}{" "}
            {u.emoji.random(award.id)}
          </UI.Typography>
        }
        subheader={
          <UI.Typography variant="body2">
            {u.time.formatDate(award.time)}
          </UI.Typography>
        }
        avatar={<UI.RunnerAvatar runner={award.runner} noStatus />}
      />

      <UI.CardContent sx={{ py: 0 }}>
        <UI.Badge
          sx={{ float: "right", display: "inline-block" }}
          badge={award.badge}
        />
        <UI.Typography variant="h4">{award.badge.name}</UI.Typography>
        <UI.Typography variant="body1" sx={{ mt: 1 }}>
          <UI.RunnerLink runner={award.runner} /> {award.badge.description}
        </UI.Typography>
        <UI.Typography
          variant="body1"
          sx={{ mt: 1 }}
          dangerouslySetInnerHTML={{ __html: award.description }}
        />
      </UI.CardContent>
      <C.CheersAndComments cheerable={award} commentable={award} defaultOpen />
    </UI.Card>
  );
};

export default Award;
