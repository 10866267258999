import * as UI from "@goodgym/components";
import { State } from "../../types";
import React from "react";
import Filters from "../Filters";
import ShowSessionsLink from "../Header/ShowSessionsLink";


export type FilterDrawerProps = UI.BoxProps & {
  state: State;

};

const Drawer: React.FC<React.PropsWithChildren<FilterDrawerProps>> = ({
  state,
}) => {
  const { drawers } = state;

  return (
    
    <UI.Drawer
      anchor="right"
      open={drawers.filters.open}
      onClose={drawers.filters.onClose}
      sx={{ display: { xs: "block", md: "none"},  pt: { xs: "50px", sm: "100px" } }}
    >
      <UI.DrawerHeader
        title="Filters"
        anchor="right"
        onClose={drawers.filters.onClose}
        open={drawers.filters.open}
      />
      <UI.Box sx={{ mt: 6 }}>
        <Filters state={state} sx={{ m: 2 }} />
        <ShowSessionsLink />
      </UI.Box>
    </UI.Drawer>
  );
};

export default Drawer;
