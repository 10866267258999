/** @jsxImportSource @emotion/react */
import React from "react";
import * as Icons from "@goodgym/icons";
import * as UI from "@goodgym/components";
import * as T from "@goodgym/graphql/types";
import * as u from "@goodgym/util";
import _ from "lodash";
import { CheerButton } from "./components";

export type CheersProps = UI.BoxProps & {
  cheerable: T.CheerableItem;
};

const Cheers: React.FC<CheersProps> = ({ cheerable, ...props }) => {
  const { cheers } = cheerable;
  const cheerers = _.uniqBy(_.map(cheers, "runner"), "id");

  return (
    <UI.Box
      {...props}
      sx={{ ...props.sx, display: "flex", alignItems: "center" }}
    >
      <UI.Box
        sx={{
          flexGrow: 1,
        }}
      >
        <UI.AvatarGroup
          max={6}
          componentsProps={{
            additionalAvatar: {
              sx: {
                height: u.avatarSize("medium"),
                width: u.avatarSize("medium"),
              },
            },
          }}
        >
          {cheerers.map((runner) => (
            <UI.RunnerAvatar
              key={runner.id}
              size="medium"
              runner={runner}
              noStatus
            />
          ))}
        </UI.AvatarGroup>
      </UI.Box>
      <UI.Box
        sx={{
          flexGrow: 0,
          flexShrink: 0,
          ml: 1,
        }}
      >
        <CheerButton cheerable={cheerable}></CheerButton>
      </UI.Box>
    </UI.Box>
  );
};

export default Cheers;
