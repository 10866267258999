import * as T from "@goodgym/graphql/types";
import * as u from ".";

export const home = () => "/v3";
export const more = () => "/v3/more";

export const profile = (
  runner: Pick<T.RunnerFragment, "slug">,
  section?: string,
  react?: boolean
) => {
  if (section && !react)
    return `/runners/${runner.slug}/${section}`
  else if (section && react)
    return `/v3/profile/${runner.slug}/${section}`
  else
    return `/v3/profile/${runner.slug}`
}

export const runnerProfile = (
  slug: string
) =>
 `/v3/profile/${slug}`;  


export const runnerIdChecks = (runner: Pick<T.RunnerFragment, "slug">) =>
  `/document-checker/runners/${runner.slug}`;

export const legacyProfile = (runner: Pick<T.RunnerFragment, "slug">) =>
  `/runners/${runner.slug}?legacy=true`;

export const legacySession = (session: Pick<T.SessionFragment, "slug">) =>
  `/happenings/${session.slug}?legacy=true`;

export const newSession = (session: Pick<T.SessionFragment, "slug">) =>
  `/v3/sessions/${session.slug}`;

export const adminProfile = (runner: Pick<T.RunnerFragment, "slug">) =>
  `/admin/runners/${runner.slug}`;

export const sessions = () => `/v3/sessions`;

export const session = (session: Pick<T.SessionFragment, "slug">) =>
  u.isDevOrBeta
    ? `/v3/sessions/${session.slug}`
    : `/happenings/${session.slug}`;
 
 export const socialVisit = (coachReferral: Pick<T.CoachReferralFragment, "id">) =>
 `/v3/social-visits/${coachReferral.id}`;

export const stories = () => `/v3/stories`;

export const socialVisits = () => `/v3/social-visits`;

export const draftedStories = () => `/v3/stories/drafted`;

export const deletedStories = () => `/v3/stories/deleted`;

export const story = (story: Pick<T.StoryFragment, "slug">) =>
  `/v3/stories/${story.slug}`;

export const draftedStory = (story: Pick<T.StoryFragment, "slug">) =>
  `/v3/stories/drafted/${story.slug}`;

export const deletedStory = (story: Pick<T.StoryFragment, "slug">) =>
  `/v3/stories/deleted/${story.slug}`;

export const confirmSessionRegistration = (
  session: Pick<T.SessionFragment, "slug">
) => `/happenings/${session.slug}/registration`;

export const editSession = (session: Pick<T.SessionFragment, "slug">) =>
  `/admin/happenings/${session.slug}/edit`;

export const newGroupFitnessActivity = (
  session: Pick<T.SessionFragment, "slug">
) => `/admin/happenings/${session.slug}/group_fitness_activities/new`;

export const editGroupFitnessActivity = (
  session: Pick<T.SessionFragment, "slug">,
  gfa: Pick<T.GroupFitnessActivityFragment, "id">
) =>
  `/admin/happenings/${session.slug}/group_fitness_activities/${gfa.id}/edit`;

export const sessionDigest = (session: Pick<T.SessionFragment, "slug">) =>
  `/admin/happenings/${session.slug}/digest`;

export const reports = () => `/v3/reports`;

export const testPairings = () => '/v3/trial-visits'

export const report = (report: Pick<T.ReportFragment, "slug">) =>
  `/v3/reports/${report.slug}`;

export const editReport = (report: Pick<T.ReportFragment, "slug">) =>
  `/v3/reports/${report.slug}/edit`;

export const organisation = (
  organisation: Pick<T.OrganisationFragment, "slug">
) => `/v3/organisations/${organisation.slug}`;

export const taskRequest = (taskRequest: Pick<T.TaskRequest, "slug">) =>
  `/task_referrals/${taskRequest.slug}`;

export const employer = (
  employer: Pick<T.EmploymentFragment["employer"], "slug">
) => `/employers/${employer.slug}`;

export const area = (area: Pick<T.AreaFragment, "slug">) =>
  `/v3/areas/${area.slug}`;

export const requestATask = (area: Pick<T.AreaFragment, "slug">) =>
  `/areas/${area.slug}/request-a-task`;

export const guidelines = () => "/general_group_run_risks";

export const signup = () => "/users/sign_up";

export const codeOfConduct = () => "/v3/conduct";

export const contactPage = () => "/v3/contact";

export const training = {
  summary: (slug: string) => `/trainings/${slug}/summary`,
};

export const about = () => "/about";

export const proposal = (area: Pick<T.AreaFragment, "slug">) =>
  `/proposals/${area.slug}`;

export const selfLog = () => "/my/reports/new";

export const powerTheGood = () => "/campaigns/power-the-good";

export const faq = (slug: string) =>
  `https://goodgym.kayako.com/article/${slug}`;

export const areaActivatorAdmin = () => "/admin";

export const taskforceAdmin = () => "/taskforce/dashboard";

export const opsSupport = () => "/v3/ops-support";

export const beneficiary = (
  beneficiary: Pick<T.RestrictedBeneficiaryFragment, "slug">
) => `/befriending_coordinator/coaches/${beneficiary.slug}`;
