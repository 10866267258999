/** @jsxImportSource @emotion/react */
import { useUnfollowMutation } from "@goodgym/graphql/hooks";
import * as T from "@goodgym/graphql/types";
import * as UI from "@goodgym/components";
import * as u from "@goodgym/util";
import { useConfirm } from "@goodgym/hooks";

export type UnfollowMutationHook = (params: {
  runner: T.RunnerFragment & T.FollowStatusFragment;
}) => React.MouseEventHandler;

export const useUnfollow: UnfollowMutationHook = ({ runner }) => {
  const { confirm } = useConfirm();

  const [unfollow] = useUnfollowMutation({
    onError: console.log,
    variables: { runnerIds: [runner.id] },
    optimisticResponse: {
      response: {
        __typename: "UnfollowMutationPayload",
        runners: [
          {
            __typename: "Runner",
            id: runner.id,
            followsYou: runner.followsYou,
            followedByYou: false,
            blockedByYou: false,
          },
        ],
      },
    },
  });

  return (e) => {
    e?.preventDefault();
    confirm({
      title: `Unfollow ${runner.name}`,
      text: `Are you sure you want to unfollow ${runner.name}? You will no longer see their activity on GoodGym.`,
      onConfirm: () => unfollow(),
    });
  };
};
