/** @jsxImportSource @emotion/react */
import { useUnpublishReportMutation } from "@goodgym/graphql/hooks";
import * as T from "@goodgym/graphql/types";
import { useConfirm } from "@goodgym/hooks";
import * as u from "@goodgym/util";
import { useNavigate } from "react-router-dom";

export type UnpublishReportMutationHook = (params: {
  report: T.ReportFragment;
  runner: Optional<T.CurrentRunnerFragment>;
}) => React.MouseEventHandler;

export const useUnpublishReport: UnpublishReportMutationHook = ({
  report,
  runner,
}) => {
  const { confirm } = useConfirm();

  const navigate = useNavigate();

  const [unpublishReport] = useUnpublishReportMutation({
    onError: console.log,
    variables: {
      reportId: report.id,
    },
    optimisticResponse: {
      response: {
        __typename: "UnpublishReportMutationPayload",
        report: {
          ...report,
        },
      },
    },
  });
  return (e) => {
    e?.preventDefault();
    if (u.roles.hasRole(runner, "CENTRAL_ADMIN")) {
      confirm({
        title: "Unpublish Report?",
        text: "Are you sure you want to unpublish this report?",
        onConfirm: () => {
          unpublishReport();
          navigate(u.links.reports());
        },
      });
    }
  };
};
