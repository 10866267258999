
      export interface PossibleTypesResultData {
        possibleTypes: {
          [key: string]: string[]
        }
      }
      const result: PossibleTypesResultData = {
  "possibleTypes": {
    "Cheerable": [
      "NewsfeedAttendance",
      "NewsfeedAward",
      "NewsfeedCoachVisit",
      "NewsfeedRegistration",
      "NewsfeedWorkout"
    ],
    "Commentable": [
      "NewsfeedAttendance",
      "NewsfeedAward",
      "NewsfeedCoachVisit",
      "NewsfeedRegistration",
      "NewsfeedWorkout",
      "Report",
      "Session"
    ],
    "NewsfeedItem": [
      "NewsfeedAttendance",
      "NewsfeedAward",
      "NewsfeedCoachVisit",
      "NewsfeedRegistration",
      "NewsfeedWorkout"
    ]
  }
};
      export default result;
    